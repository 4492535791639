import React from 'react';
import ReactApexChart from 'react-apexcharts';

const DoubleLineBasic = ({ data, xAsisData, lable,color }) => {


    const series = [
        {
            name: data.label1,
            data: data.currentdata
        },
        {
            name: data.label2,
            data: data.prevdata
        }]
    const options = {
        chart: {
            height: 340,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        colors: color,
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 4,
            colors: undefined,
            strokeColors: '#fff',
            strokeWidth: 0,
        },
        stroke: {
            curve: 'straight',
            lineCap: 'butt',
            width: 3,

        },
        title: {
            // text: 'Revenue Trend',
            //align: 'left'
        },
        legend: {
            align: 'right',
            position: 'top',

        },
        grid: {
            row: {
                //colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                //opacity: 0.5
            },
        },
        yaxis: {
            title: {
                text: lable.yAxis
            }
        },
        xaxis: {
            title: {
                text: lable.xAxis
            },
            categories: xAsisData,
            tickAmount: 24
        }
    }


    return (

        <>
            <div id="DoubleLineBasic">
                <ReactApexChart options={options} series={series} type="line" height={340} />
            </div>
        </>


    );
}

export default DoubleLineBasic;