
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { GetKeyPointsApi } from '../functions/dashboard'

//whichScreen should be dashboard or Ticket
const KeyPoints = ({ refresh }) => {
    const [keyPoint, setKeyPoints] = useState({});    
    const [keyStatus, setKeyStatus] = useState("");
    useEffect(() => {
        loadKeyPoints();
    }, [refresh]);   

    const loadKeyPoints = () => {
        GetKeyPointsApi().then(res => {
            const result = res;
            if (result != undefined) {
                setKeyPoints(result);
            }
        }).catch();
    }

    return (        
                    <ul>
                        <li class="box box-solid card1 ">
                            <Link to="tickets/Not Assigned" class="">                             
                                <div class="share-icon go-corner" >  <div class="go-arrow"><i class="fa fa-share"></i></div></div>
                                <div class="ovr-card">
                                   {keyPoint.unassigned == undefined ? <img src="images/progress.gif" width="30" /> : <h4>{keyPoint.unassigned}</h4> } 
                                    <p>Unassigned</p>
                                </div>
                            </Link>
                        </li>
                        <li class="box box-solid card1 ">
                            <Link to="tickets/Assigned" class="">
                                <div class="share-icon go-corner" >  <div class="go-arrow"><i class="fa fa-share"></i></div></div>
                                <div class="ovr-card">
                                {keyPoint.assigned == undefined ? <img src="images/progress.gif" width="30" /> : <h4>{keyPoint.assigned}</h4> }
                                    {/* <h4>{keyPoint.assigned}</h4> */}
                                    <p>In Progress</p>
                                </div>
                            </Link>
                        </li>
                        <li class="box box-solid card1 ">
                            <Link to="tickets/Draft Estimate" class="">
                                <div class="share-icon go-corner" >  <div class="go-arrow"><i class="fa fa-share"></i></div></div>
                                <div class="ovr-card">
                                {keyPoint.draftEstimate == undefined ? <img src="images/progress.gif" width="30" /> : <h4>{keyPoint.draftEstimate}</h4> }
                                    {/* <h4>{keyPoint.draftEstimate}</h4> */}
                                    <p>Estimate Drafted</p>
                                </div>
                            </Link>
                        </li>
                        <li class="box box-solid card1 ">
                            <Link to="tickets/Submitted Estimate" class="">
                                <div class="share-icon go-corner" >  <div class="go-arrow"><i class="fa fa-share"></i></div></div>
                                <div class="ovr-card">
                                {keyPoint.submittedEstimate == undefined ? <img src="images/progress.gif" width="30" /> : <h4>{keyPoint.submittedEstimate}</h4> }
                                    {/* <h4>{keyPoint.submittedEstimate}</h4> */}
                                    <p>Estimate Pending for Approval</p>
                                </div>
                            </Link>
                        </li>
                        <li class="box box-solid card1 ">
                            <Link to="tickets/Draft Invoice" class="">
                                <div class="share-icon go-corner" >  <div class="go-arrow"><i class="fa fa-share"></i></div></div>
                                <div class="ovr-card">
                                {keyPoint.drafetInvoice == undefined ? <img src="images/progress.gif" width="30" /> : <h4>{keyPoint.drafetInvoice}</h4> }
                                    {/* <h4>{keyPoint.drafetInvoice}</h4> */}
                                    <p>Work Completed</p>
                                </div>
                            </Link>
                        </li>
                    </ul>
                    
                    
      

    )
}
export default KeyPoints;
