import React, { useState, useEffect } from 'react'
import Header from '../../components/nav/Header'
import Sidebar from '../../components/nav/Sidebar'
import CreateUpdateUser from '../../forms/CreateUpdateUser'
import DeletePopUp from '../../forms/DeletePopUp'
import { toast } from 'react-toastify'
import { validateEmail } from '../../functions/commonfunction'
import { CreateUser_api, LoadUsers_api, UpdateUser_api, ChangeUserStatus_api, DeleteUser_api } from '../../functions/users'
import { encrytion } from '../../functions/encryption'
import { Height } from '@material-ui/icons'


function Users() {

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    //  const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [userType, setUserType] = useState("");
    const [selectedTab, setSelectedTab] = useState("All");
    const [searchText, setSearchText] = useState("");
    const [userList, SetUserList] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [screentype, setScreenType] = useState("Create");
    const [userID, setUserID] = useState(0);
    const [allowAppLogin, setAllowAppLogin] = useState(false);
    const [image, setImage] = useState('');
    const [color, setColor] = useState("#008945");     // added by Sonali 08/04/22
    let loggedUserType = localStorage.getItem("usertype");

    // useEffect(() => {
    //     loadUsers();
    // }, [])

    useEffect(() => {
        loadUsers();
    }, [selectedTab])

    useEffect(() => {
        loadUsers();
    }, [searchText])


    const loadUsers = () => {
        setIsLoaded(false);
        LoadUsers_api(selectedTab, searchText).then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {
                    SetUserList([])
                }
                else
                    SetUserList(result.data);
            }
            setIsLoaded(true);
        }).catch();
    }

    const showUserCreationPopUp = () => {
        setName("");
        setUserType("");
        setEmail("");
        setPhone("");
        setUserID("");
        setScreenType("Create");
        setAllowAppLogin(false);
        window.$("#AddUser").modal('show');
    }

    const CreateUser_submit = (e) => {
        e.preventDefault();
        if (name == "" && phone == "" && email == "")
            return toast.error("Please fill all fields.");
        else if (name.trim() == "")
            return toast.error("please enter name.");
        else if (phone.trim() == "")
            return toast.error("Please enter phone number.");
        else if (phone.trim().length != 10)
            return toast.error("Please enter valid phone number");
        else if (email.trim() == "")
            return toast.error("Please enter email id.");
        else if (validateEmail(email.trim()) == false)
            return toast.error("Please enter valid email id.");
        else if (userType.trim() == "" || userType == null)
            return toast.error("Please select user type.");
        else if (password.trim() == "")
            return toast.error("Please enter password.");

        var req = {
            name: name,
            usertype: userType,
            email: email,
            phone: phone,
            profilepic: image,
            password: password,
            allowapplogin: allowAppLogin,
            colorcode: color                             // added by Sonali on 08/04/22
            //password: encrytion(password)
        }
        CreateUser_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                window.$("#AddUser").modal('hide');
                setName("");
                setEmail("");
                setUserType("");
                setPhone("");
                setPassword("");
                setColor("#008945");                             // added by Sonali on 08/04/22
                loadUsers();

            }
        })
            .catch();

    }

    const UpdateUser_submit = (e) => {
        e.preventDefault();
        if (name == "" && phone == "" && email == "")
            return toast.error("Please fill all fields.");
        else if (name.trim() == "")
            return toast.error("please enter name.");
        else if (phone.trim() == "")
            return toast.error("Please enter phone number.");
        else if (phone.trim().length != 10)
            return toast.error("Please enter valid phone number");
        else if (email.trim() == "")
            return toast.error("Please enter email id.");
        else if (validateEmail(email.trim()) == false)
            return toast.error("Please enter valid email id.");
        else if (userType.trim() == "" || userType == null)
            return toast.error("Please select user type.");

        var req = {
            name: name,
            usertype: userType,
            email: email,
            phone: phone,
            profilepic: image,
            password: password,
            allowapplogin: allowAppLogin,
            userid: userID,
            colorcode: color                             // added by Sonali on 08/04/22
        }
        UpdateUser_api(req).then(res => {
            if (res != undefined) {
                console.log(res)
                toast.success(res.message);
                window.$("#AddUser").modal('hide');
                setName("");
                setEmail("");
                setUserType("");
                setPhone("");
                setPassword("");
                setColor("#008945");                             // added by Sonali on 08/04/22
                loadUsers();
            }
        })
            .catch();

    }

    const UpdateUser_Status_submit = (userid, newstatus) => {

        ChangeUserStatus_api(userid, newstatus).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                loadUsers();
            }
        })
            .catch();

    }


    const loadUserDetails = (item) => {
        setName(item.name);
        setUserType(item.userType);
        setEmail(item.email);
        setPhone(item.phone);
        setUserID(item.userId);
        setColor(item.colorCode);                             // added by Sonali on 08/04/22
        setAllowAppLogin(item.allowAppLogin == "No" ? false : true)
        setScreenType("Update");
        window.$("#AddUser").modal('show');
    }

    const deleteUserPopUp = (userid) => {
        setUserID(userid)
        window.$("#DeletePopUp").modal('show');
    }

    const DeleteUser_submit = (userid) => {
        DeleteUser_api(userid).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                window.$("#DeletePopUp").modal('hide');
                loadUsers();
            }
        })
            .catch();

    }
    return (
        <div className="hold-transition skin-black sidebar-mini">
            <div className="wrapper" >

                {/* SideBar */}
                <Header />
                <Sidebar />
                {/* <!-- Content Wrapper. Contains page content --> */}
                <div className="content-wrapper db-background">

                    {/* <!-- Main content --> */}
                    <section className="content content-body">
                        {/* <!-- Main row --> */}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="content-header ">
                                    <h1 className="">
                                        Users  </h1>
                                    <div className="topbtn-right">
                                        <a type="" className="btn btn-theme filterbtn" data-toggle="modal" onClick={() => showUserCreationPopUp()}>Add User</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="top-btn-group ">
                                    <div className="btn-group ">
                                        <a type="button" class={selectedTab == "All" ? "btn btn-theme" : "btn btn-default"} onClick={() => setSelectedTab("All")}>All</a>
                                        <a type="button" class={selectedTab == "Admin" ? "btn btn-theme" : "btn btn-default"} onClick={() => setSelectedTab("Admin")}>Admin</a>
                                        <a type="button" class={selectedTab == "Supervisor" ? "btn btn-theme" : "btn btn-default"} onClick={() => setSelectedTab("Supervisor")}>Supervisor</a>
                                        <a type="button" class={selectedTab == "Accounts" ? "btn btn-theme" : "btn btn-default"} onClick={() => setSelectedTab("Accounts")}>Accounts</a>
                                        <a type="button" class={selectedTab == "Technician" ? "btn btn-theme" : "btn btn-default"} onClick={() => setSelectedTab("Technician")}>Technician</a>
                                        <a type="button" class={selectedTab == "Dispatcher" ? "btn btn-theme" : "btn btn-default"} onClick={() => setSelectedTab("Dispatcher")}>Dispatcher</a>
                                    </div>

                                    <div className="pull-right">
                                        <div className="pull-left serch-fild top-serching">

                                            <input type="text" className="form-control ih36" placeholder="" value={searchText} onChange={e => setSearchText(e.target.value)} />
                                            <i className="fa fa-search"></i>

                                        </div>
                                    </div>
                                </div>

                                <div className="">
                                    <div className="box box-solid">
                                        {/* <!-- /.box-header --> */}
                                        {
                                            isLoaded == false ? <div className="loader"><img src="images/loader.gif" alt="" /></div>
                                                :
                                                <div className="box-body table-responsive no-padding userlist">
                                                    <table className="table ">
                                                        <tbody>
                                                            <tr>
                                                                <th>User Name</th>
                                                                <th>User Photo</th>
                                                                <th>User Role</th>
                                                                <th>Email</th>
                                                                <th>Phone </th>
                                                                <th>App Login </th>
                                                                {/* <th>TimeZone</th> */}
                                                                <th>Verification<br></br>Status</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                            {
                                                                (userList != undefined && userList != null && userList != "null") &&
                                                                    userList.length == 0 ?
                                                                    "No Record Found."
                                                                    :
                                                                    userList.map(item => (
                                                                        <tr>
                                                                            <td> <span style={{ marginRight: "5px", verticalAlign: "middle", display: "inline-block", height: "15px", width: "15px", borderRadius: "50%", backgroundColor: item.colorCode === null ? "#008945" : item.colorCode }}></span> <span style={{ cursor: 'pointer' }} onClick={() => loadUserDetails(item)}>{"  " + item.name}</span></td>
                                                                            <td>{
                                                                                item.profilePic != "NA" && item.profilePic != ""
                                                                                &&
                                                                                <img src={item.profilePic} className="ass-circle" style={{ borderRadius: "50%" }} />
                                                                            }</td>
                                                                            <td>{item.userType}</td>
                                                                            <td>{item.email}</td>
                                                                            <td>{item.phone}</td>
                                                                            <td>{item.allowAppLogin}</td>
                                                                            <td>{item.verificationStatus}</td>
                                                                            <td className="">
                                                                                {
                                                                                    item.status == "Active"
                                                                                        ?
                                                                                        <a style={{ cursor: 'pointer' }} className="stsbtn sts-active " onClick={() => UpdateUser_Status_submit(item.userId, 0)} >Active</a>
                                                                                        :
                                                                                        <a style={{ cursor: 'pointer' }} className="stsbtn sts-inactive" onClick={() => UpdateUser_Status_submit(item.userId, 1)}>Inactive</a>
                                                                                }

                                                                            </td>

                                                                            <td className="">
                                                                                {item.userType != "Admin" &&
                                                                                    <a style={{ cursor: 'pointer' }} className="stsbtn sts-inactive" onClick={() => deleteUserPopUp(item.userId)}>Delete</a>
                                                                                }

                                                                            </td>
                                                                        </tr>
                                                                    ))

                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>

                                        }{/* <!-- /.box-body --> */}
                                    </div>
                                </div>


                            </div>
                            {/* <!-- /.row (main row) --> */}
                        </div>
                        {/* <!-- /.content --> */}
                    </section>
                    {/* <!-- /.content-wrapper -->       */}
                </div>

                {/* Create Form */}
                <CreateUpdateUser
                    screentype={screentype}
                    name={name}
                    setName={setName}
                    phone={phone}
                    setPhone={setPhone}
                    email={email}
                    setEmail={setEmail}
                    password={password}
                    setPassword={setPassword}
                    userType={userType}
                    setUserType={setUserType}
                    CreateUser_submit={CreateUser_submit}
                    UpdateUser_submit={UpdateUser_submit}
                    userid={userID}
                    image={image}
                    setImage={setImage}
                    setAllowAppLogin={setAllowAppLogin}
                    allowAppLogin={allowAppLogin}
                    color={color}                                             // added by Sonali on /11/04/22
                    setColor={setColor}                                       // added by Sonali on /11/04/22
                />

                <DeletePopUp
                    DeleteUser_submit={DeleteUser_submit}
                    id={userID}
                    getreqFrom="User" />



                {/* <!-- /.modal --> */}

            </div>
        </div>
    )
}

export default Users
