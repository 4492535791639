import { toast } from 'react-toastify'


const API_Base_Url = process.env.REACT_APP_API_BASE_URL;
var AccessToken = localStorage.getItem("AccessToken");
const loggeduserid = Number(localStorage.getItem("userid"));

//Customer List
export const LoadCustomers_api = async (tab, searchtext) => {
    try {
        var clientid = localStorage.getItem("clientid")
        const response = await fetch(`${API_Base_Url}customers/list?clientid=${clientid}&type=${tab}&search=${searchtext}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.errors != undefined) {
                toast.error(result.errors[0])
            }

        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }

}

// Add Customers
export const CreateCustomer_api = async (reqdata) => {
    try {
        var clientid = localStorage.getItem("clientid")
        const response = await fetch(`${API_Base_Url}customers/add`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "clientid": Number(clientid),
                "customername": reqdata.customername,
                "customertype": reqdata.customertype,
                "address": reqdata.address,
                "state": reqdata.state,
                "city": reqdata.city,
                "location": reqdata.location,
                "zipcode": reqdata.zipcode,
                "latitude": reqdata.latitude,
                "longitude": reqdata.longitude,
                "fieldcontactname": reqdata.fieldcontactname,
                "fieldcontactemail": reqdata.fieldcontactemail,
                "fieldcontactphone": reqdata.fieldcontactphone,
                "accountingcontactname": reqdata.accountingcontactname,
                "accountingcontactemail": reqdata.accountingcontactemail,
                "accountingcontactphone": reqdata.accountingcontactphone,
                "marginpercentage": reqdata.marginpercentage,
                "discountpercentage": reqdata.discountpercentage,
                "addedby": Number(localStorage.getItem("userid"))
            })
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.errors != undefined) {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

//Update Customer
export const UpdateCustomer_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}customers/update`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "customerid": reqdata.customerid,
                "customername": reqdata.customername,
                "customertype": reqdata.customertype,
                "address": reqdata.address,
                "state": reqdata.state,
                "city": reqdata.city,
                "location": reqdata.location,
                "zipcode": reqdata.zipcode,
                "latitude": reqdata.latitude,
                "longitude": reqdata.longitude,
                "fieldcontactname": reqdata.fieldcontactname,
                "fieldcontactemail": reqdata.fieldcontactemail,
                "fieldcontactphone": reqdata.fieldcontactphone,
                "accountingcontactname": reqdata.accountingcontactname,
                "accountingcontactemail": reqdata.accountingcontactemail,
                "accountingcontactphone": reqdata.accountingcontactphone,
                "marginpercentage": reqdata.marginpercentage,
                "discountpercentage": reqdata.discountpercentage,
                "updatedby": Number(localStorage.getItem("userid"))
            })
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.errors != undefined) {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

//Delete Customer
export const DeleteCustomer_api = async (customerid) => {
    try {
        const response = await fetch(`${API_Base_Url}customers/delete`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "customerid": customerid,
                "deletedby": Number(localStorage.getItem("userid"))
            })
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.errors != undefined) {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }

};

//Active Not Active status update
export const ChangeCustomerStatus_api = async (customerid, statusid) => {
    try {
        const response = await fetch(`${API_Base_Url}customers/status`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "customerid": customerid,
                "status": statusid,
                "updatedby": Number(localStorage.getItem("userid"))
            })
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.errors != undefined) {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }

};

//Customer profile 
export const Customers_Profile_api = async (customerid) => {
    try {
        const response = await fetch(`${API_Base_Url}customers/profile?customerid=${customerid}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
      
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.errors != undefined) {
                toast.error(result.errors[0])
            }

        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }

}