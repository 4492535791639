import { GoogleMap, withGoogleMap, withScriptjs, Marker, InfoWindow } from "react-google-maps"
import { compose, withProps } from "recompose"
import React, { useState, useEffect, useRef } from 'react'
import TicketDetails from '../components/popups/TicketDetails'
import { GetKeyPointsApi, GetAssignmentsApi, GetMapdataApi } from '../functions/dashboard'
import moment from 'moment'
import { ListAltRounded } from "@material-ui/icons"

//GoogleMap.setApiKey("AIzaSyA6KSQ1K4AYunLrJLZzibbZOAMWBSuqIPo");

// let iconMarker = new window.google.maps.MarkerImage(
//     "https://lh3.googleusercontent.com/bECXZ2YW3j0yIEBVo92ECVqlnlbX9ldYNGrCe0Kr4VGPq-vJ9Xncwvl16uvosukVXPfV=w300",
//     null, /* size is determined at runtime */
//     null, /* origin is 0,0 */
//     null, /* anchor is bottom center of the scaled image */
//     new window.google.maps.Size(32, 32)
// );

const exampleMapStyles = [
    {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
            {
                color: "#eeeeee",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#9e9e9e",
            },
        ],
    },
];


const MyMapComponent = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyA6KSQ1K4AYunLrJLZzibbZOAMWBSuqIPo&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div className="mapheight" />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
)((props) => {

    const elementRef = useRef();
    const [selectedCenter, setSelectedCenter] = useState(null);
    const [refresh, setRefresh] = useState(0)
    const [ticketMapData, setTicketMapData] = useState([]);
    const [technicianMapData, setTechnicialMapData] = useState([]);
    const [test, setTest] = useState(0);
    const [latLng, setLatLng] = useState({ lat: 33.0312515, lng: -96.70522659999999 })
    const [defaultZoom, setDefaultZoom] = useState(8);
    const [defineKey, setDefineKey] = useState(0);
    const [selectedTicketId, setSelectedTicketId] = useState(0);
    useEffect(() => {
        const listener = e => {
            if (e.key === "Escape") {
                setSelectedCenter(null);
            }
        };
        window.addEventListener("keydown", listener);
        return () => {
            window.removeEventListener("keydown", listener);
        };
        setDefineKey(defineKey + 1)
    },
        []);

    const OpenTicketDetails = (ticketid) => {

        setSelectedTicketId(ticketid);
        window.$("#TicketDetails").modal('show');
        props.setTicketId(ticketid)
    }

    var intervaltime;
    useEffect(() => {
        intervaltime = setInterval(refreshmap, 20000)
    }, [refresh])


    useEffect(() => {
        //  loadMapData()
    }, []);

    useEffect(() => {
        loadMapData()
    }, [props.searchData]);

    const refreshmap = () => {
        clearInterval(intervaltime)
        setRefresh(refresh + 1)
        if (props.searchData === 0 && props.searchDateVal === "" && props.searchTechnicianList > 0 && props.searchValue === "" && props.searchOrderTypeList.length > 0) {
            setRefresh(refresh + 1)
            loadMapData();
        }
    }

    const loadMapData = () => {
        GetMapdataApi().then(res => {
            const result = res;
            if (result != undefined) {
                console.log("mapresult", result)
                setTechnicialMapData(result.technicians)
                setTicketMapData(null)

                var list = [];
                var isFilterApplied = false;
                if (props.searchDateVal !== "" && list.length > 0) {
                    //setTicketMapData(result.tickets.filter(x => x.scheduleddate === moment(props.searchDateVal).format("DD MMM YYYY").toString()))
                    var aa = list.filter(x => x.scheduleddate === moment(props.searchDateVal).format("DD MMM YYYY").toString());
                    if (aa.length > 0) {
                        setDefaultZoom(13)
                        setLatLng({ lat: Number(aa[0].latitude), lng: Number(aa[0].longitude) })
                    }
                    list = list.filter(x => x.scheduleddate === moment(props.searchDateVal).format("DD MMM YYYY").toString());
                    isFilterApplied = true;
                }
                else if (props.searchDateVal !== "" && (list === null || list === undefined || list.length === 0)) {

                    var aa = result.tickets.filter(x => x.scheduleddate === moment(props.searchDateVal).format("DD MMM YYYY").toString());
                    if (aa.length > 0) {
                        setDefaultZoom(13)
                        setLatLng({ lat: Number(aa[0].latitude), lng: Number(aa[0].longitude) })
                    }
                    list = result.tickets.filter(x => x.scheduleddate === moment(props.searchDateVal).format("DD MMM YYYY").toString());
                    console.log('listdata', list)
                    isFilterApplied = true;
                }
                if (props.searchOrderTypeList.length > 0 && list.length > 0) {
                    var aa = list.filter(a => props.searchOrderTypeList.includes(a.ordertype));
                    if (aa.length > 0) {
                        setDefaultZoom(13)
                        setLatLng({ lat: Number(aa[0].latitude), lng: Number(aa[0].longitude) })

                    }
                    list = list.filter(a => props.searchOrderTypeList.includes(a.ordertype));
                    isFilterApplied = true;
                }
                else if (props.searchOrderTypeList.length > 0 && (list === null || list === undefined || list.length === 0)) {
                    const aa = result.tickets.filter(a => props.searchOrderTypeList.includes(a.ordertype));
                    if (aa.length > 0) {
                        setDefaultZoom(13)
                        setLatLng({ lat: Number(aa[0].latitude), lng: Number(aa[0].longitude) })
                    }
                    list = result.tickets.filter(a => props.searchOrderTypeList.includes(a.ordertype));
                    isFilterApplied = true;
                }
                if (props.searchTechnicianList.length > 0 && list.length > 0) {

                    var aa = list.filter(a => props.searchTechnicianList.includes(a.technicianid));
                    if (aa.length > 0) {
                        setDefaultZoom(13)
                        setLatLng({ lat: Number(aa[0].latitude), lng: Number(aa[0].longitude) })
                    }
                    list = list.filter(a => props.searchTechnicianList.includes(a.technicianid));
                    isFilterApplied = true;
                }
                else if (props.searchTechnicianList.length > 0 && (list === null || list === undefined || list.length === 0)) {
                    var aa = result.tickets.filter(a => props.searchTechnicianList.includes(a.technicianid));
                    if (aa.length > 0) {
                        setDefaultZoom(13)
                        setLatLng({ lat: Number(aa[0].latitude), lng: Number(aa[0].longitude) })
                    }
                    list = result.tickets.filter(a => props.searchTechnicianList.includes(a.technicianid));
                    isFilterApplied = true;
                }
                if (props.searchValue !== "" && list.length > 0) {

                    var aa = list.filter(x => x.ticketid === Number(props.searchValue));
                    if (aa.length > 0) {
                        setDefaultZoom(13)
                        setLatLng({ lat: Number(aa[0].latitude), lng: Number(aa[0].longitude) })
                    }
                    list = list.filter(x => x.ticketid === Number(props.searchValue));
                    isFilterApplied = true;
                }
                else if (props.searchValue !== "" && (list === null || list === undefined || list.length === 0)) {

                    var aa = result.tickets.filter(x => x.ticketid === Number(props.searchValue));
                    if (aa.length > 0) {
                        setDefaultZoom(13)
                        setLatLng({ lat: Number(aa[0].latitude), lng: Number(aa[0].longitude) })
                    }
                    list = result.tickets.filter(x => x.ticketid === Number(props.searchValue));
                    isFilterApplied = true;

                }
                if ((list.length > 0 && list !== null && list !== undefined) || isFilterApplied === true) {
                    setTicketMapData(list)
                    console.log('listdata1', list)
                    // setTest(test + 1)
                    //props.setRefreshComponentAfterSearch(test + 1)
                }
                else {
                    setTicketMapData(result.tickets)
                    setDefaultZoom(8)
                    setLatLng({ lat: 33.0312515, lng: -96.70522659999999 })
                    console.log('listdata2', list)
                    //setTest(test + 1)
                    //props.setRefreshComponentAfterSearch(test + 1)
                }
                // props.setRefreshComponentAfterSearch(test + 1)
                //clearLatLng(result.tickets);

            }
        }).catch();
    }



    useEffect(() => () => {
        //unmount       
    })

    const handleSearch = () => {

    }

    return (
        <>
            <GoogleMap
                zoom={defaultZoom}
                center={latLng}
                key={defineKey}
                defaultOptions={{
                    styles: exampleMapStyles,
                }}
            >
                {
                    (ticketMapData != undefined && ticketMapData != null && ticketMapData.length > 0) &&
                    ticketMapData.map(item => {
                        var defaultcolor = "#f81300"
                        var defaultcolorblue = "#3b5998"
                        var svg = `<svg xmlns="http://www.w3.org/2000/svg" width="108.256" height="108.256" viewBox="0 0 108.256 108.256">
                        <g id="Group_5333" data-name="Group 5333" transform="translate(-153 -152)">
                          <g id="Group_5331" data-name="Group 5331" transform="translate(153 152)">
                            <path id="Path_6957" data-name="Path 6957" d="M0,0H108.256V108.256H0Z" fill="none"/>
                            <path id="Path_6958" data-name="Path 6958" d="M36.575,2A31.552,31.552,0,0,0,5,33.575C5,57.256,36.575,92.214,36.575,92.214S68.15,57.256,68.15,33.575A31.552,31.552,0,0,0,36.575,2Zm0,42.851A11.277,11.277,0,1,1,47.851,33.575,11.277,11.277,0,0,1,36.575,44.851Z" transform="translate(17.553 7.021)" fill="${item.colorcode !== null ? item.colorcode : defaultcolor}"/>
                            <g id="Ellipse_510" data-name="Ellipse 510" transform="translate(31.466 16.686)" fill="#fff" stroke="${item.colorcode !== null ? item.colorcode : defaultcolor}" stroke-width="1">
                              <circle cx="23.512" cy="23.512" r="23.512" stroke="none"/>
                              <circle cx="23.512" cy="23.512" r="23.012" fill="none"/>
                            </g>
                          </g>
                          <g id="tickets_1_" data-name="tickets (1)" transform="translate(192.57 177.27)">
                            <g id="Group_1825" data-name="Group 1825">
                              <path id="Path_3028" data-name="Path 3028" d="M29.206,8.76a4.16,4.16,0,0,1-5.884-5.884L20.445,0,0,20.445l2.877,2.877A4.16,4.16,0,1,1,8.76,29.206l2.877,2.877L32.083,11.637Zm-13.195,2.7-1.335-1.335L16.006,8.8l1.335,1.335Zm2.669,2.669L17.346,12.8l1.329-1.329L20.01,12.8ZM21.349,16.8l-1.335-1.335,1.329-1.329,1.335,1.335Z" fill="${item.colorcode !== null ? item.colorcode : defaultcolor}"/>
                            </g>
                          </g>
                        </g>
                      </svg>`;
                        var svgblue = `<svg xmlns="http://www.w3.org/2000/svg" width="108.256" height="108.256" viewBox="0 0 108.256 108.256">
                        <g id="Group_5333" data-name="Group 5333" transform="translate(-153 -152)">
                          <g id="Group_5331" data-name="Group 5331" transform="translate(153 152)">
                            <path id="Path_6957" data-name="Path 6957" d="M0,0H108.256V108.256H0Z" fill="none"/>
                            <path id="Path_6958" data-name="Path 6958" d="M36.575,2A31.552,31.552,0,0,0,5,33.575C5,57.256,36.575,92.214,36.575,92.214S68.15,57.256,68.15,33.575A31.552,31.552,0,0,0,36.575,2Zm0,42.851A11.277,11.277,0,1,1,47.851,33.575,11.277,11.277,0,0,1,36.575,44.851Z" transform="translate(17.553 7.021)" fill="${item.colorcode !== null ? item.colorcode : defaultcolorblue}"/>
                            <g id="Ellipse_510" data-name="Ellipse 510" transform="translate(31.466 16.686)" fill="#fff" stroke="${item.colorcode !== null ? item.colorcode : defaultcolorblue}" stroke-width="1">
                              <circle cx="23.512" cy="23.512" r="23.512" stroke="none"/>
                              <circle cx="23.512" cy="23.512" r="23.012" fill="none"/>
                            </g>
                          </g>
                          <g id="tickets_1_" data-name="tickets (1)" transform="translate(192.57 177.27)">
                            <g id="Group_1825" data-name="Group 1825">
                              <path id="Path_3028" data-name="Path 3028" d="M29.206,8.76a4.16,4.16,0,0,1-5.884-5.884L20.445,0,0,20.445l2.877,2.877A4.16,4.16,0,1,1,8.76,29.206l2.877,2.877L32.083,11.637Zm-13.195,2.7-1.335-1.335L16.006,8.8l1.335,1.335Zm2.669,2.669L17.346,12.8l1.329-1.329L20.01,12.8ZM21.349,16.8l-1.335-1.335,1.329-1.329,1.335,1.335Z" fill="${item.colorcode !== null ? item.colorcode : defaultcolorblue}"/>
                            </g>
                          </g>
                        </g>
                      </svg>`;
                        return <>
                            {item.longitude !== null
                                ?
                                <Marker
                                    ref={elementRef}

                                    icon={
                                        item.status === "Awaited" ? {

                                            url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg),
                                            anchor: new window.google.maps.Point(17, 46),
                                            scaledSize: new window.google.maps.Size(37, 37)
                                        } : {

                                            url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgblue),
                                            anchor: new window.google.maps.Point(17, 46),
                                            scaledSize: new window.google.maps.Size(37, 37)
                                        }}
                                    position={{ lat: Number(item.latitude), lng: Number(item.longitude) }}
                                    onClick={() => {
                                        setSelectedCenter(item);
                                    }}
                                    key={item}
                                    name={'Current location'}
                                >
                                    {selectedCenter && (
                                        (selectedCenter.latitude == item.latitude)
                                        &&
                                        <InfoWindow className="ticketinfobox"
                                            onCloseClick={() => {
                                                setSelectedCenter(null);
                                            }}
                                            position={{
                                                lat: parseFloat(selectedCenter.latitude),
                                                lng: parseFloat(selectedCenter.longitude)
                                            }}
                                        >
                                            <div className="ticketcontentbox">
                                                <a onClick={() => OpenTicketDetails(item.ticketid)} style={{ cursor: 'pointer' }}>
                                                    <h5><span style={{ color: "#000" }}>Ticket ID : </span> {selectedCenter.ticketid}</h5>
                                                    <h5>{selectedCenter.customername}</h5>
                                                    <h5>{selectedCenter.jobname}</h5>
                                                    <h5>{selectedCenter.address}</h5>
                                                </a>

                                            </div>
                                        </InfoWindow>
                                    )}

                                </Marker>
                                :
                                null}
                        </>
                    })

                }
                {
                    technicianMapData != undefined && technicianMapData != null &&
                    technicianMapData.map(item => {
                        var defaultcolor = "#008945"
                        var svg = `<svg xmlns="http://www.w3.org/2000/svg" width="108.935" height="108.935" viewBox="0 0 108.935 108.935">
                        <g id="Group_5332" data-name="Group 5332" transform="translate(-247.689 -151.322)">
                          <g id="Group_5330" data-name="Group 5330" transform="translate(247.689 151.322)">
                            <path id="Path_6957" data-name="Path 6957" d="M0,0H108.935V108.935H0Z" fill="none"/>
                            <path id="Path_6958" data-name="Path 6958" d="M36.773,2A31.749,31.749,0,0,0,5,33.773C5,57.6,36.773,92.779,36.773,92.779S68.545,57.6,68.545,33.773A31.749,31.749,0,0,0,36.773,2Zm0,43.12A11.347,11.347,0,1,1,48.12,33.773,11.347,11.347,0,0,1,36.773,45.12Z" transform="translate(17.695 7.078)" fill="${item.colorcode !== null ? item.colorcode : defaultcolor}"/>
                            <g id="Ellipse_510" data-name="Ellipse 510" transform="translate(31.663 16.79)" fill="#fff" stroke="${item.colorcode !== null ? item.colorcode : defaultcolor}" stroke-width="1">
                              <circle cx="23.659" cy="23.659" r="23.659" stroke="none"/>
                              <circle cx="23.659" cy="23.659" r="23.159" fill="none"/>
                            </g>
                          </g>
                          <g id="man-user" transform="translate(285.416 173.751)">
                            <path id="Path_6939" data-name="Path 6939" d="M113.144,20.913c4.754,0,8.609-4.681,8.609-10.456S120.487,0,113.144,0s-8.609,4.681-8.609,10.456S108.389,20.913,113.144,20.913Z" transform="translate(-96.882)" fill="${item.colorcode !== null ? item.colorcode : defaultcolor}"/>
                            <path id="Path_6940" data-name="Path 6940" d="M41.9,300.553c0-.352,0-.1,0,0Z" transform="translate(-41.895 -263.675)" fill="${item.colorcode !== null ? item.colorcode : defaultcolor}"/>
                            <path id="Path_6941" data-name="Path 6941" d="M308.085,301.806c0-.1,0-.669,0,0Z" transform="translate(-275.564 -264.653)" fill="${item.colorcode !== null ? item.colorcode : defaultcolor}"/>
                            <path id="Path_6942" data-name="Path 6942" d="M74.409,192.481c-.159-10.06-1.473-12.926-11.527-14.741a6.027,6.027,0,0,1-4.714,1.8,6.028,6.028,0,0,1-4.714-1.8c-9.944,1.795-11.338,4.619-11.521,14.414-.015.8-.022.842-.025.749,0,.174,0,.5,0,1.057,0,0,2.394,4.825,16.259,4.825s16.259-4.825,16.259-4.825c0-.361,0-.611,0-.782A6.464,6.464,0,0,1,74.409,192.481Z" transform="translate(-41.904 -156.827)" fill="${item.colorcode !== null ? item.colorcode : defaultcolor}"/>
                          </g>
                        </g>
                      </svg>`;
                        return <Marker
                            position={{ lat: Number(item.latitude), lng: Number(item.longitude) }}
                            icon={{

                                url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg),
                                anchor: new window.google.maps.Point(17, 46),
                                scaledSize: new window.google.maps.Size(37, 37),
                                fillColor: '#34495e',
                                fillOpacity: 1,
                                strokeColor: '#000',
                                strokeWeight: 1,
                                scale: 1,

                            }}

                            onClick={() => {
                                setSelectedCenter(item);
                            }}


                        >
                            {selectedCenter && (
                                (selectedCenter.latitude == item.latitude)
                                &&
                                <InfoWindow
                                    onCloseClick={() => {
                                        setSelectedCenter(null);
                                    }}
                                    position={{
                                        lat: selectedCenter.latitude,
                                        lng: selectedCenter.longitude
                                    }}
                                >
                                    <div>
                                        {/* <a style={{ cursor: 'pointer' }}> */}
                                        <h5>ID {selectedCenter.userid},  {selectedCenter.name}</h5>
                                        {/* </a> */}


                                    </div>
                                </InfoWindow>
                            )}
                        </Marker>
                    })

                }
            </GoogleMap>

        </>
    )
}

)

export default MyMapComponent
