import { toast } from 'react-toastify'
const API_Base_Url = process.env.REACT_APP_API_BASE_URL;

//Update Estimate
export const UpdateEstimate_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}estimate/update`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify(reqdata)
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
}

export const FinalizeEstimate_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}estimate/finalize`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify(reqdata)
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
}

export const DraftEstimate_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}estimate/draft`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify(reqdata)
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
}

//Submitted
export const SubmitEstimate_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}estimate/submit`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify(reqdata)
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
}

//Approve
export const ApproveEstimate_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}estimate/approve`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify(reqdata)
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
}

//Estimate List
export const LoadEstimates_api = async (userid, type, searchtext) => {
    try {
        const response = await fetch(`${API_Base_Url}estimate/list?userid=${userid}&type=${type}&search=${searchtext}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }

}

//Delete Estimate
export const DeleteEstimate_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}estimate/delete`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify(reqdata)
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
}