
import React, { useState, useEffect } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'
const Sidebar = () => {
    const [activeMenu, setActiveMenu] = useState("")
    const [userType, setUserType] = useState(localStorage.getItem("usertype"));
    const history = useHistory();
    const gotoDashbaord = () => {
        history.push("/dashboard")
    }
    const gotoTickets = () => {
        history.push("/tickets")
    }
    const gotoCustomers = () => {
        history.push("/customers")
    }
    const gotoInvoices = () => {
        history.push("/invoices")
    }
    const gotoEstimates = () => {
        history.push("/estimates")
    }
    const gotoUsers = () => {
        history.push("/users")
    }
    const gotoCalendar = () => {
        history.push("/calendar")
    }
    const gotoSetting = () => {
        history.push("/ordertype")
    }
    const gotoReports = () => {
        history.push("/revenuebytech")
    }

     // function gotoDeletedTickets added by Sonali 01-04-22 feedback 
    const gotoDeletedTickets = () => {
        history.push("/archive-tickets")
    }                                                 

    useEffect(() => {
        if (window.location.toString().match(/dashboard/)) {
            //Do stuff
            setActiveMenu("dashboard")
        }
        if (window.location.toString().match(/users/)) {
            //Do stuff
            setActiveMenu("users")
        }
        if (window.location.toString().match(/customers/)) {
            //Do stuff
            setActiveMenu("customers")
        }
        if (window.location.toString().match(/tickets/)) {
            //Do stuff
            setActiveMenu("tickets")
        }
        if (window.location.toString().match(/invoices/)) {
            //Do stuff
            setActiveMenu("invoices")
        }
        if (window.location.toString().match(/estimates/)) {
            //Do stuff
            setActiveMenu("estimates")
        }
        if (window.location.toString().match(/ordertype/)) {
            //Do stuff
            setActiveMenu("ordertype")
        }
        if (window.location.toString().match(/analytics/)) {
            //Do stuff
            setActiveMenu("analytics")
        }

        if (window.location.toString().match(/archive-tickets/)) {
            //Do stuff
            setActiveMenu("deletedtickets")
        }   // added by Sonali 01-04-22 feedback 

        
        // if (window.location.toString().match(/revenuebytech/)) {
        //     //Do stuff
        //     setActiveMenu("reports")
        // }
        // if (window.location.toString().match(/revenuebycustomer/)) {
        //     //Do stuff
        //     setActiveMenu("reports")
        // }
        // if (window.location.toString().match(/collectionreport/)) {
        //     //Do stuff
        //     setActiveMenu("reports")
        // }
        // if (window.location.toString().match(/techperformance/)) {
        //     //Do stuff
        //     setActiveMenu("reports")
        // }
        // if (window.location.toString().match(/techutilization/)) {
        //     //Do stuff
        //     setActiveMenu("reports")
        // }
      
    }, [])
    return (
        <aside className="main-sidebar">
            {/* <!-- sidebar: style can be found in sidebar.less --> */}
            <section className="sidebar">
                {/* <!-- sidebar menu: : style can be found in sidebar.less --> */}
                <ul className="sidebar-menu" data-widget="tree">
                    {
                        (userType == "Admin" || userType == "Supervisor") &&
                        <li class={`treeview ${activeMenu == "dashboard" && "active"}`}>
                            <Link to="/dashboard" className="" onClick={() => gotoDashbaord()}>
                                <img src="images/home.svg" className="menu-icon mb-manual-5" />
                                <span style={{ paddingTop: '5px' }}>Home</span>
                            </Link>
                        </li>
                    }
                    {
                        (userType == "Admin" || userType == "Supervisor" || userType == "Dispatcher") &&
                        <li class={`treeview ${activeMenu == "tickets" && "active"}`}>
                            <Link to="/tickets" onClick={() => gotoTickets()}>
                                <img src="images/tickets.svg" className="menu-icon mb-manual-5" />
                                <span>Tickets</span>
                            </Link>
                        </li>
                    }
                    {
                        (userType == "Admin" || userType == "Supervisor") &&
                        <li class={`treeview ${activeMenu == "ordertype" && "active"}`}>
                            <Link to="/calendar" onClick={() => gotoCalendar()}>
                                <img src="images/calendar.svg" className="menu-icon mb-manual-5" />
                                <span>Calendar</span>
                            </Link>
                        </li>
                    }
                    {
                        (userType == "Admin" || userType == "Supervisor" || userType == "Accounts") &&
                        <li class={`treeview ${activeMenu == "customers" && "active"}`}>
                            <Link to="/customers" onClick={() => gotoCustomers()}>
                                <img src="images/Customers.svg" className="menu-icon" />
                                <span>Customers</span>
                            </Link>
                        </li>
                    }
                    {
                        (userType == "Admin" || userType == "Accounts" || userType == "Supervisor")
                        &&
                        <li class={`treeview ${activeMenu == "invoices" && "active"}`}>
                            <Link to="/invoices" onClick={() => gotoInvoices()}>
                                <img src="images/Invoices.svg" className="menu-icon mb-manual-5" />
                                <span>Invoices</span>
                            </Link>
                        </li>
                    }
                    {
                        (userType == "Admin" || userType == "Accounts" || userType == "Supervisor")
                        &&
                        <li class={`treeview ${activeMenu == "estimates" && "active"}`}>
                            <Link to="/estimates" onClick={() => gotoEstimates()}>
                                <img src="images/Estimates.svg" className="menu-icon mb-manual-5" />
                                <span>Estimates</span>
                            </Link>
                        </li>
                    }
                    {
                        userType == "Admin" &&
                        <li class={`treeview ${activeMenu == "users" && "active"}`}>
                            <Link to="/users" onClick={() => gotoUsers()}>
                                <img src="images/users.svg" className="menu-icon mb-manual-5" />
                                <span>Users</span>
                            </Link>
                        </li>
                    }
                    <li class={activeMenu == "analytics" && "active"}>
                        <NavLink to="/analytics">
                            <img src="images/Analytics.svg" className="menu-icon mb-manual-5" />
                            <span>Analytics</span>
                        </NavLink>
                    </li>
                    {        // added by Sonali 01-04-22 feedback
                        (userType == "Admin" || userType == "Supervisor" || userType == "Dispatcher") &&
                        <li class={`treeview ${activeMenu == "deletedtickets" && "active"}`}>
                            <Link to="/archive-tickets" onClick={() => gotoDeletedTickets()}>
                                <img src="images/archive.svg" className="menu-icon mb-manual-5" />
                                <span>Archive</span>
                            </Link>
                        </li>
                    }
                    {/* {
                        (userType == "Admin" || userType == "Accounts" || userType == "Supervisor") &&
                        <li class={`treeview ${activeMenu == "reports" && "active"}`}>
                            <Link to="/revenuebytech" onClick={() => gotoReports()}>
                                <img src="images/Invoices.svg" className="menu-icon mb-manual-5" />
                                <span>Reports</span>
                            </Link>
                        </li>
                    } */}
                    {
                        (userType == "Admin" || userType == "Supervisor") &&
                        <li class={`treeview ${activeMenu == "ordertype" && "active"}`}>
                            <Link to="/ordertype" onClick={() => gotoSetting()}>
                                <img src="images/settings.svg" className="menu-icon mb-manual-5" />
                                <span>Settings</span>
                            </Link>
                        </li>
                    }




                </ul>
            </section>
            {/* <!-- /.sidebar --> */}

        </aside>

    )
}

export default Sidebar

