import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Header from '../../components/nav/Header'
import Sidebar from '../../components/nav/Sidebar'
import { LoadEstimates_api, DeleteEstimate_api } from '../../functions/estimates'
import EstimateDetails from '../../components/popups/EstimateDetails'
import DeletePopUp from '../../forms/DeletePopUp'
import TicketDetails from '../../components/popups/TicketDetails'

function Estimates() {

    var userid = Number(localStorage.getItem("userid"));
    const [estimateList, setEstimateList] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedTab, setSelectedTab] = useState(localStorage.getItem("usertype") == "Accounts" ? "Submitted" : "Draft");
    const [searchText, setSearchText] = useState("");
    const [totalResult, setTotalResult] = useState("");
    const [activityid, setActivityId] = useState(0);
    const [ticketid, setTicketId] = useState(0);
    const [estimateid, setEstimateId] = useState(0);
    const [selectedTicketId, setselectedTicketId] = useState(0);
    const usertype = localStorage.getItem("usertype");


    useEffect(() => {
        loadEstimates();
    }, []);

    useEffect(() => {
        loadEstimates();
    }, [selectedTab]);

    useEffect(() => {
        loadEstimates();
    }, [searchText])



    const loadEstimates = () => {
        setIsLoaded(false);
        LoadEstimates_api(userid, selectedTab, searchText).then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {
                    setEstimateList([])
                }
                else
                    setEstimateList(result.data);
                setTotalResult(result)
            }
            setIsLoaded(true);
        }).catch();
    }

    const openEstimateDetails = (item) => {
        setTicketId(item.ticketid)
        setActivityId(item.activityid);
        setEstimateId(item.estimateid)
    }
    const OpenTicketDetails = (ticketid) => {
        setselectedTicketId(ticketid)
    }
   
    const DeleteEstimate = () => {
        var req = {
            ticketid: ticketid,
            estimateid: estimateid,
            deletedby: userid
        }
        DeleteEstimate_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                loadEstimates();
            }
        })
            .catch();
        window.$("#DeletePopUp").modal('hide');
    }


    return (
        <>
            <div className="hold-transition skin-black sidebar-mini">
                <div className="wrapper">
                    <Header />
                    <Sidebar />
                    {
                        (activityid != 0 && ticketid != 0)
                        &&
                        <EstimateDetails activityid={activityid} ticketid={ticketid} estimateid={estimateid} loadEstimates={loadEstimates} />

                    }
                    <TicketDetails
                        ticketid={selectedTicketId}
                        loadAgain={() => { }}
                        ticketType={"normal"}
                    />

                    <div className="content-wrapper">
                        {/* <!-- Main content --> */}
                        <section className="content content-body">
                            {/* <!-- Main row --> */}
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="content-header">
                                        <h1 className="">
                                            Estimate
                                        </h1>
                                        <div className="topbtn-right">
                                        <div className="pull-left serch-fild top-serching mr-15">
                                            <input type="text" value={searchText} onChange={e => setSearchText(e.target.value)} className="form-control ih36" placeholder="" />
                                            <i className="fa fa-search"></i>
                                        </div>
                                    </div>
                                    </div>
                                    
                                </div>
                                <div className="col-md-12">
                                    <div className="">
                                        <div className="db-Overview">
                                            <ul>
                                                {/* {(usertype == "Admin" || usertype == "Supervisor") &&
                                                    <li className="box box-solid card1">
                                                        <a style={{ cursor: 'pointer' }} onClick={() => setSelectedTab("All")} className="">
                                                            <div className="share-icon go-corner" >  <div className="go-arrow"><i className="fa fa-share"></i></div></div>
                                                            <div className="ovr-card">
                                                                {totalResult.countAll == undefined ? <img src="images/progress.gif" width="30" /> : <h4> {totalResult.countAll}</h4>}
                                                                <p>All</p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                } */}
                                                {
                                                    (usertype == "Admin" || usertype == "Supervisor") &&
                                                    <li className="box box-solid card1">
                                                        <a style={{ cursor: 'pointer' }} onClick={() => setSelectedTab("Draft")} className="">
                                                            <div className="share-icon go-corner" >  <div className="go-arrow"><i className="fa fa-share"></i></div></div>
                                                            <div className="ovr-card">
                                                                {totalResult.countDrafted == undefined ? <img src="images/progress.gif" width="30" /> : <h4> {totalResult.countDrafted}</h4>}
                                                                <p>Draft</p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                }
                                                {
                                                    (usertype == "Admin" || usertype == "Supervisor") &&
                                                    <li className="box box-solid card1">
                                                        <a style={{ cursor: 'pointer' }} onClick={() => setSelectedTab("Finalized")} className="">
                                                            <div className="share-icon go-corner" >  <div className="go-arrow"><i className="fa fa-share"></i></div></div>
                                                            <div className="ovr-card">
                                                                {totalResult.countFinalized == undefined ? <img src="images/progress.gif" width="30" /> : <h4> {totalResult.countFinalized}</h4>}
                                                                <p>Finalized</p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                }
                                                {
                                                    (usertype == "Admin" || usertype == "Supervisor" || usertype == "Accounts") &&
                                                    <li className="box box-solid card1">
                                                        <a style={{ cursor: 'pointer' }} onClick={() => setSelectedTab("Submitted")} className="">
                                                            <div className="share-icon go-corner" >  <div className="go-arrow"><i className="fa fa-share"></i></div></div>
                                                            <div className="ovr-card">
                                                                {totalResult.countSubmitted == undefined ? <img src="images/progress.gif" width="30" /> : <h4> {totalResult.countSubmitted}</h4>}
                                                                <p>Submitted</p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                }
                                                {
                                                    (usertype == "Admin" || usertype == "Supervisor" || usertype == "Accounts") &&
                                                    <li className="box box-solid card1">
                                                        <a style={{ cursor: 'pointer' }} onClick={() => setSelectedTab("approvedNotAssigned")} className="">
                                                            <div className="share-icon go-corner" >  <div className="go-arrow"><i className="fa fa-share"></i></div></div>
                                                            <div className="ovr-card">
                                                                {totalResult.countAproovedNotAssigned == undefined ? <img src="images/progress.gif" width="30" /> : <h4> {totalResult.countAproovedNotAssigned}</h4>}
                                                                <p>Unassigned</p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                }
                                                {
                                                    (usertype == "Admin" || usertype == "Supervisor" || usertype == "Accounts") &&
                                                    <li className="box box-solid card1">
                                                        <a style={{ cursor: 'pointer' }} onClick={() => setSelectedTab("approvedAssigned")} className="">
                                                            <div className="share-icon go-corner" >  <div className="go-arrow"><i className="fa fa-share"></i></div></div>
                                                            <div className="ovr-card">
                                                                {totalResult.countAproovedAssigned == undefined ? <img src="images/progress.gif" width="30" /> : <h4> {totalResult.countAproovedAssigned}</h4>}
                                                                <p>Assigned</p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Left col 8--> */}
                                <div className="col-md-12">
                                    <div className="box box-solid">
                                        <div className="est-msg">{totalResult.result} <span className="pull-right totalamount"> <label>Total Amount:</label> ${totalResult.totalamountgrid}</span></div>
                                    </div>
                                    <div className="box box-solid">
                                        {/* <!-- /.box-header --> */}
                                        {
                                            isLoaded == false ? <div className="loader"><img src="images/loader.gif" alt="" /></div>
                                                :

                                                <div className="box-body table-responsive ">
                                                    <table className="table table-hover ticket-list">
                                                        <tbody>
                                                            <tr>
                                                                <th>Est ID</th>
                                                                <th>Created On</th>
                                                                <th>Ticket ID</th>
                                                                <th>Customer</th>
                                                                <th>Created By</th>
                                                                <th>Amount</th>
                                                                <th>Status</th>
                                                                <th>Submitted On</th>
                                                                <th>Download</th>
                                                                {/* <th>Action</th> */}
                                                            </tr>

                                                            {
                                                                estimateList != undefined &&
                                                                    estimateList.length == 0 ?
                                                                    "No Record Found."
                                                                    :
                                                                    estimateList.map(item => (
                                                                        <tr>
                                                                            <td><a onClick={() => openEstimateDetails(item)} className="ticketID" data-toggle="modal" data-target="#EstimatesDetail">{item.estimateid}</a></td>
                                                                            <td>{item.creationdate}<br />{item.creationtime}</td>
                                                                            <td><a onClick={() => OpenTicketDetails(item.ticketid)} className="ticketID" data-toggle="modal" data-target="#TicketDetails">{item.ticketid}</a></td>
                                                                            <td>{item.customername}<br />{item.customerphone}</td>
                                                                            <td><div className="img-flex"><div className="inline">
                                                                                {
                                                                                    item.profilepic != "NA" && item.profilepic != ""
                                                                                    &&
                                                                                    <img src={item.profilepic} className="ass-circle" />
                                                                                }

                                                                            </div><div className="inline"><span>{item.createdbyName}</span><br /> {item.createdbyUserType}</div> </div></td>
                                                                            <td>${item.amountPayable}</td>
                                                                            <td><span className="stsbtn sts-active " >{item.status}</span></td>
                                                                            <td> {item.status == "Submitted" && <>{item.submittedDate}<br />{item.submittedTime}</>}</td>
                                                                            <td> {
                                                                                item.downloadpath != null && item.downloadpath != "" &&
                                                                                item.downloadpath != "null"
                                                                                &&
                                                                                <a href={item.downloadpath} target="_blank"><img src="images/download.png" width="20px" /></a>
                                                                            }</td>

                                                                            {/* <td>{

                                                                                item.status == "Draft"
                                                                                &&
                                                                                <a className="stsbtn sts-inactive" onClick={() => OpenDeleteEstimate(item.estimateid, item.ticketid)} >Delete</a>
                                                                            }
                                                                            </td> */}

                                                                        </tr>

                                                                    ))
                                                            }




                                                        </tbody>
                                                    </table>
                                                </div>
                                        }
                                        {/* <!-- /.box-body --> */}
                                    </div>

                                </div>
                            </div>
                            {/* <!-- /.row (main row) --> */}
                        </section>
                        {/* <!-- /.content --> */}

                    </div >
                </div >
            </div >

            <DeletePopUp
                DeleteUser_submit={DeleteEstimate}
                id={0}
                getreqFrom="Estimate" />
        </>

    )
}

export default Estimates
