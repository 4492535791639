import { toast } from 'react-toastify'
const API_Base_Url = process.env.REACT_APP_API_BASE_URL;



//Load Ticket Actions
export const LoadTicketActions_api = async (userid, ticketid) => {
    try {
        const response = await fetch(`${API_Base_Url}ticket/actionlist?userid=${userid}&ticketid=${ticketid}&type=web`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.errors != undefined) {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
}

//Ticket List/search Result

export const ticketList_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}ticket/list`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "requestedby": reqdata.userid,
                "fromdate": reqdata.fromdate,
                "todate": reqdata.todate,
                "status": reqdata.status,
                "technicianid": Number(reqdata.assigntouserid),
                "searchkeyword": reqdata.searchkeyword
            })
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.errors != undefined) {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
}

//Load notes
export const LoadNotesDetails_api = async (ticketid, userid, activityid) => {
    try {
        const response = await fetch(`${API_Base_Url}notes/load?userid=${userid}&ticketid=${ticketid}&activityid=${activityid}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
           
            if (result.errors != undefined) {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
}

//load Photos
export const LoadPhotos_api = async (ticketid, userid, activityid) => {
    try {
        const response = await fetch(`${API_Base_Url}photos/load?userid=${userid}&ticketid=${ticketid}&activityid=${activityid}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.errors != undefined) {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
}

//Load Estimates
export const LoadEstimates_api = async (ticketid, userid, activityid) => {
    try {
        const response = await fetch(`${API_Base_Url}estimate/load?userid=${userid}&ticketid=${ticketid}&activityid=${activityid}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.errors != undefined) {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
}

//Load Invoices
export const LoadInvoices_api = async (ticketid, userid, activityid) => {
    try {
        const response = await fetch(`${API_Base_Url}invoice/load?userid=${userid}&ticketid=${ticketid}&activityid=${activityid}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.errors != undefined) {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
}

//Ticket Details
export const LoadTicketDetails_api = async (ticketid, userid) => {
    try {
        const response = await fetch(`${API_Base_Url}ticket/details?userid=${userid}&ticketid=${ticketid}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            console.log(result,userid, ticketid,"response")
            return result;
        }
        else if (response.status === 400) {
            if (result.errors != undefined) {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
}



//Search Duplicate Ticket
export const SearchDuplicateTicket_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}ticket/searchduplicate`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({                
                "ponumber": reqdata.ponumber,
                "wonumber": reqdata.workordernumber,
                "jobname": reqdata.jobname
                
            })
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            console.log("res_req",result)
            return result;
        }
        else if (response.status === 400) {
            if (result.errors != undefined) {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
}

//Ticket Add
export const CreateTicket_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}ticket/add`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "customerid": reqdata.customerid,
                "ordertypeid": reqdata.ordertypeid,
                "orderdetails": reqdata.orderdetails,
                "scheduledate": reqdata.scheduledate,
                "scheduletime": reqdata.scheduletime,
                "technicianid": reqdata.technicianid,
                //"porequired": reqdata.porequired,
                "pofilepath": reqdata.pofilepath,
                "ponumber": reqdata.ponumber,
                "workordernumber": reqdata.workordernumber,
                "address": reqdata.address,
                "state": reqdata.state,
                "city": reqdata.city,
                "zipcode": reqdata.zipcode,
                "latitude": reqdata.latitude,
                "longitude": reqdata.longitude,
                "jobname": reqdata.jobname,
                "jobcontactname": reqdata.jobcontactname,
                "jobcontactemail": reqdata.jobcontactemail,
                "jobcontactphone": reqdata.jobcontactphone,
                "addedby": reqdata.addedby,
                "expectedtime":reqdata.expectedtime,
                "priority":reqdata.priority
            })
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            console.log("res_req",result)
            return result;
        }
        else if (response.status === 400) {
            if (result.errors != undefined) {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
}

// Delete Ticket                     added by sonali  31/03/22
export const DeleteTicket_api = async (ticketid) => {
    try {
        const response = await fetch(`${API_Base_Url}ticket/delete`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "ticketid": ticketid
            })
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }

};


// Deleted Ticket List Api     added by Sonali 01-04-22 feedback

export const deletedticketList_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}ticket/deletedticket`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "requestedby": reqdata.userid,
                "fromdate": reqdata.fromdate,
                "todate": reqdata.todate,
                "status": reqdata.status,
                "technicianid": Number(reqdata.assigntouserid),
                "searchkeyword": reqdata.searchkeyword
            })
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.errors != undefined) {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
}

// Restore Ticket                     added by sonali  01/04/22 feedback
export const RestoreTicket_api = async (ticketid) => {
    try {
        const response = await fetch(`${API_Base_Url}ticket/restore`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "ticketid": ticketid
            })
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }

};



//Deleted Ticket Details
export const LoadDeletedTicketDetails_api = async (ticketid, userid) => {
    try {
        const response = await fetch(`${API_Base_Url}ticket/deleteddetails?userid=${userid}&ticketid=${ticketid}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            console.log(result,userid, ticketid,"response")
            return result;
        }
        else if (response.status === 400) {
            if (result.errors != undefined) {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
}


//Notes Add
export const CreateNotes_api = async (req) => {
    try {
        const response = await fetch(`${API_Base_Url}notes/add`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify(req)
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.errors != undefined) {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
}

//Update order details
export const UpdateTicketOrderDetails_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}ticket/updateorder`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify(reqdata)
        })
        const result = await response.json();
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.errors != undefined) {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
}

//update po work order number
export const UpdateTicketPOWorkOrder_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}ticket/updatepodetail`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify(reqdata)
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.errors != undefined) {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
}

//Update Assignee
export const UpdateTicketAssinee_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}ticket/updateassignment`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify(reqdata)
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.errors != undefined) {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
}

//Update status
export const UpdateTicketStatus_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}ticket/status`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify(reqdata)
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.errors != undefined) {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
}


//send Email
export const SendEmail_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}mail/send`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify(reqdata)
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.errors != undefined) {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
}

//Resend Email
export const ResendEmail_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}mail/resend`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify(reqdata)
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.errors != undefined) {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
}


