import React, { useState } from 'react';
import { ResetPassord_api_3 } from '../../functions/auth'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

function ResetPassword3() {
    const history = useHistory();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const LoginBtnClick = (e) => {
        e.preventDefault();
        if (password === "" || password === null) {
            return toast.error("Please enter password.")
        }
        else if (confirmPassword === "" || confirmPassword === null) {
            return toast.error("Please confirm password")
        }
        else if (password !== confirmPassword) {
            return toast.error("Password not matched")
        }
        next();
    }
    const next = async () => {
        try {
            var req = {
                username: localStorage.getItem("ChagePasswordUserName"),
                code: localStorage.getItem("ResetPasswordOTP"),
                password: password
            }

            ResetPassord_api_3(req).then(res => {
                if (res !== undefined) {
                    toast.success(res.message);
                    history.push("/resetpassword4");
                }
            })
                .catch()
        } catch (error) {

        }
    }
    return (
        <div className="hold-transition login-page login2">
            <div className="login2-body">
                <div className="login-left-pnl " >
                    <div className="logo1">
                        <img src="images/logo-1.png" className="" alt=""/>
                        <h4>Reset Password</h4>
                    </div>
                    <form onSubmit={(e) => LoginBtnClick(e)} >
                        <div className="form-group mt30">
                            <input type="text" className="form-control loignh" autoComplete="off" placeholder="Create New Password" value={password} maxLength="50" onChange={(e) => { setPassword(e.target.value) }} />
                        </div>
                        <div className="form-group ">
                            <input type="password" className="form-control loignh" autoComplete="off" placeholder="Confirm New Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                        </div>
                        <div className="spacetologo">
                            <button type="submit" className="btn btn-green btn-block">FINISH</button>
                        </div>
                    </form>
                </div>

                <div className="login-right-pnl">
                    <h4 className="text-center">Field Service Management System</h4>
                    <img src="images/login-bnr.png" alt=""/>
                </div>

                <div className="clearfix"></div>
            </div>

        </div>
    )
}

export default ResetPassword3