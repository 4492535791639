import { Fragment, useState, useEffect } from "react";
import { Button, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { ProcessedEvent, Scheduler, SchedulerHelpers } from "@aldabil/react-scheduler";
//import { EVENTS1 } from "./data";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import axios from 'axios';
import { toast } from 'react-toastify'
import { CreateTicket_api,SearchDuplicateTicket_api } from '../../functions/tickets'
import { LoadOrderType_api } from '../../functions/masters'
import { LoadUsers_api, LoadTechnicians_api } from '../../functions/users'
import CreateUpdateOrderType from '../../forms/CreateUpdateOrderType'
import { CreateOrderType_api } from '../../functions/orderType'
import { Customers_Profile_api } from '../../functions/customers'
import { LoadCustomers_api } from '../../functions/customers'
import moment from "moment";
import { useHistory } from "react-router-dom";
import TicketDetails from '../../components/popups/TicketDetails'
const API_Base_Url = process.env.REACT_APP_API_BASE_URL;

const CustomEditor = ({ scheduler }) => {
  const history = useHistory()
  var date_lo = localStorage.getItem("calendarstart");
  var formateddate = moment(date_lo).format("YYYY-MM-DD");
  var formatedtime = moment(date_lo).format("hh:mm");
  const event = scheduler.edited;
  const API_Base_Url = process.env.REACT_APP_API_BASE_URL;
  const AccessToken = localStorage.getItem("AccessToken");
  const [txtcustomername, setCustomername] = useState("");
  const [keypointRefresh, setKeyPointsRefresh] = useState(0);
  const [selectedCustomerID, setSelectedCustomerID] = useState(0);
  const [ticketTitle, setTicketTitle] = useState("");
  const [orderType, setOrderType] = useState("");
  const [orderDetail, setOrderDetail] = useState("");
  const [ticketDate, setTicketDate] = useState(formateddate.toString());
  const [ticketTime, setTicketTime] = useState(formatedtime.toString());
  const [poRequired, setPoRequired] = useState(false);
  const [poDOc, setPoDoc] = useState("");
  const [technicianid, setTechnicianid] = useState(0);
  const loggeduserid = Number(localStorage.getItem("userid"));
  const [orderTypeList, setOrderTypeList] = useState([]);
  const [technicianList, setTechnicianlist] = useState([]);
  const [technicianId, setTechnicianID] = useState(localStorage.getItem("calendaruserid"));
  const [txtsearchOrderType, setTxtSearchOrderType] = useState("");
  const [allOrderTypes, setAllOrderTypes] = useState([]);
  const [screentype, setScreenType] = useState("Create");
  const [orderTypeId, setOrderTypeId] = useState(0);
  const [test, setText] = useState(0);
  const [showsearch, setshowsearch] = useState(false);
  const [poNumber, setPoNumber] = useState("");
  const [workOrderNumber, setWorkOrderNumber] = useState("");
  const [serviceAddress, setServiceAddress] = useState("");
  const [sameAsBilling, setSameAsBilling] = useState(false);
  const [customerAddress, setCustomerAddress] = useState("");
  const [state, setState] = useState("")
  const [city, setCity] = useState("")
  const [zipcode, setZipCode] = useState("")
  const [latitude, setLatitude] = useState("")
  const [longitude, setLongitude] = useState("")
  const [customer_state, setcustomer_State] = useState("")
  const [customer_city, setcustomer_City] = useState("")
  const [customer_zipcode, setcustomer_ZipCode] = useState("")
  const [customer_latitude, setcustomer_Latitude] = useState("")
  const [customer_longitude, setcustomer_Longitude] = useState("")
  const usertype = localStorage.getItem("usertype");
  const [jobname, setJobName] = useState("");
  const [jobcontactname, setJobContactName] = useState("");
  const [jobcontactemail, setJobContactEmail] = useState("");
  const [jobcontactphone, setJobContactPhone] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedCustomerName, setSelectedCustomerName] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [testId, setTestId] = useState(0);
  const [Isunavailable, setUnavailable] = useState(false);
  const [ticketendtime, setTicketEndTime] = useState("");
  const [expectedtime, setExpectedtime] = useState("0")
  const [priorityId, setPriorityID] = useState(0)


  useEffect(() => {
    loadOrderType();
    loadTechnicians();
    window.$("#searchresultarea").hide();
    window.$("#searchOrderType").hide();
  }, []);

  useEffect(() => {
    setTxtSearchOrderType("")
    setOrderTypeId(0);
    setOrderType("")
    setOrderDetail("");
    //setTicketDate("");
    //setTicketTime("");
    //setTechnicianID("")
    setPoDoc("");
    setPoRequired(false);
    setSameAsBilling(false)
    if (selectedCustomerID !== undefined && selectedCustomerID !== 0 && selectedCustomerID !== null)
      loadCustomerProfile();
  }, [testId])

  useEffect(() => {
    if (selectedCustomerID !== undefined && selectedCustomerID !== 0 && selectedCustomerID !== null)
      loadCustomerProfile();
  }, [selectedCustomerID])

  useEffect(() => {
    if (sameAsBilling) {
      setServiceAddress(customerAddress)
      setCity(customer_city)
      setState(customer_state)
      setLatitude(customer_latitude)
      setLongitude(customer_longitude)
      setZipCode(customer_zipcode)
    }
    else
      setServiceAddress("");
  }, [sameAsBilling])

  const loadCustomers = (txtsearch) => {
    setIsLoaded(false);
    LoadCustomers_api("All", txtsearch).then(res => {
      const result = res;
      if (result != undefined) {
        if (result.data == null) {
          setCustomerList([])
        }
        else {
          setCustomerList(result.data);
        }
      }
      setIsLoaded(true);
    }).catch();
  }

  const refreshDashboard = () => {
    setKeyPointsRefresh(keypointRefresh + 1);
    setCustomername("");
    setSelectedCustomerID(0);
  }

  const SearchCustomer = (e) => {
    setCustomername(e.target.value);
    if (e.target.value.length < 2) {
      window.$("#searchresultarea").hide();

    }
    else {
      window.$("#searchresultarea").show();
      loadCustomers(e.target.value);
    }

  }

  const SelectNewAddedCustomer = (selectedCustomerID) => {
    LoadCustomers_api("All", txtcustomername).then(res => {
      const result = res;
      if (result != undefined) {
        if (result.data == null) {

        }
        else {
          var cust = result.data.filter(x => x.customerid == selectedCustomerID)[0];
          ChooseCustomer(cust);
        }
      }
      setIsLoaded(true);
    }).catch();
  }

  const ChooseCustomer = (item) => {
    setSelectedCustomerName(item.customername)
    setSelectedCustomerID(item.customerid)
    window.$("#searchresultarea").hide();
    setCustomername(item.customername)
  }

  const showCustomerCreationPopUp = () => {
    setCustomername("");
    setTestId(testId + 1)
    window.$("#CustomerFormPop").modal('show');
    window.$("#searchresultarea").hide();
  }

  const loadCustomerProfile = () => {
    Customers_Profile_api(selectedCustomerID).then(res => {
      const result = res;
      if (result != undefined) {
        if (result == null) {
          //SetUserList([])
        }
        else {
          setCustomerAddress(result.address)
          setcustomer_State(result.state)
          setcustomer_City(result.city)
          setcustomer_Latitude(result.latitude)
          setcustomer_Longitude(result.longitude)
          setcustomer_ZipCode(result.zipcode)
        }

        //SetUserList(result.data);
      }
    }).catch();
  }


  const loadOrderType = () => {
    LoadOrderType_api().then(res => {
      const result = res;
      if (result != undefined) {
        if (result.data == null) {
          setOrderTypeList([])
          setAllOrderTypes([])
        }
        else {
          setOrderTypeList(result.data);
          setAllOrderTypes(result.data);
        }
      }

    }).catch();
  }
  const loadTechnicians = () => {
    LoadTechnicians_api().then(res => {
      const result = res;
      if (result != undefined) {
        if (result.data == null) {
          setTechnicianlist([])
        }
        else
          setTechnicianlist(result.data);
      }

    }).catch();
  }

  const CreateOrderType_submit = (e) => {
    e.preventDefault();
    if (orderType.trim() == "")
      return toast.error("Please enter order type.");

    var req = {
      "ordertype": orderType,
      "addedby": loggeduserid
    }
    CreateOrderType_api(req).then(res => {
      if (res != undefined) {
        //toast.success(res.message);
        window.$("#AddOrderType").modal('hide');
        setOrderType("");
        loadOrderType();
        SelectNewOrderType(res.ordertypeid)
      }
    })
      .catch();

  }

  const submitTicket = (e) => {
    e.preventDefault();
    if (selectedCustomerID == 0)
      return toast.error('Please select customer')
    else if (orderTypeId == 0 || orderTypeId == null)
      return toast.error("Please choose order type.");
    else if ((ticketDate == "" || ticketDate == null) && usertype !== "Dispatcher")
      return toast.error("Please enter ticket date.");
    else if ((ticketTime == "" || ticketTime == null) && usertype !== "Dispatcher")
      return toast.error("Please enter ticket time.");
    else if (expectedtime === "0")
      return toast.error("Please enter expected time.");
    else if ((latitude == "" || latitude == null))
      return toast.error("Please select complete address.");
    else if (longitude == "" || longitude == null)
      return toast.error("Please select complete address.");

    else {
      var req = {
        "customerid": selectedCustomerID,
        //"customername": ticketTitle,
        "ordertypeid": Number(orderTypeId),
        "orderdetails": orderDetail,
        "scheduledate": ticketDate,
        "scheduletime": ticketTime,
        "expectedtime": expectedtime,
        "technicianid": Number(technicianId),
        "ponumber": poNumber,
        "workordernumber": workOrderNumber,
        "address": serviceAddress,
        "state": state,
        "city": city,
        "zipcode": zipcode,
        "latitude": String(latitude),
        "longitude": String(longitude),
        "jobname": jobname,
        "jobcontactname": jobcontactname,
        "jobcontactemail": jobcontactemail,
        "jobcontactphone": jobcontactphone,
        "pofilepath": poDOc,
        "addedby": loggeduserid,
        "priority": Number(priorityId)
      }

      SearchDuplicateTicket_api(req).then(result => {
        console.log(result, "duplicateticket")
        if (result.message === "Ticket exists") {
          window.$("#SearchDuplicateTicket").modal('show');
        }
        else {
          window.$("#SearchDuplicateTicket").modal('hide');
          CreateTicket();
        }
      })
    }
  }

    const CreateTicket = (e) => {
      var req = {
        "customerid": selectedCustomerID,
        //"customername": ticketTitle,
        "ordertypeid": Number(orderTypeId),
        "orderdetails": orderDetail,
        "scheduledate": ticketDate,
        "scheduletime": ticketTime,
        "expectedtime": expectedtime,
        "technicianid": Number(technicianId),
        "ponumber": poNumber,
        "workordernumber": workOrderNumber,
        "address": serviceAddress,
        "state": state,
        "city": city,
        "zipcode": zipcode,
        "latitude": String(latitude),
        "longitude": String(longitude),
        "jobname": jobname,
        "jobcontactname": jobcontactname,
        "jobcontactemail": jobcontactemail,
        "jobcontactphone": jobcontactphone,
        "pofilepath": poDOc,
        "addedby": loggeduserid,
        "priority": Number(priorityId)
      }
      CreateTicket_api(req).then(res => {
        if (res != undefined) {
          toast.success(res.message);
          window.$("#CreateTicket").modal('hide');
          window.$("#SearchDuplicateTicket").modal('hide');
          //loadCustomers();
          refreshDashboard();
          clearFields();
          scheduler.close();
          let res1;
          let evt1;
          let evtfinal;
          const clientid = localStorage.getItem("clientid");
          try {
            axios.get(API_Base_Url + "calendar/summary?clientid=" + clientid, {
              headers: {
                'Accept': 'application/json, text/plain, */*',
                //'Content-Type': 'application/json'
              }
            })
              .then(response => {
                res1 = response.data.technicians.map(item => {
                  return {
                    id: item.technicianID,
                    name: item.technicianname,
                    avatar: item.profileimage === "" ? "images/avtar.png" : item.profileimage
                  }

                })
                evt1 = response.data.data.map((item, i) => {
                  if (item.totalTickets > 0) {
                    return {
                      id: i * 100 + 1,
                      bgColor: "#A2D2FF",
                      resourceId: item.techncianID,
                      title: item.totalTickets + " tickets " + " (" + item.totalHours + " Hours)",
                      start: item.date + ' 09:00:00',
                      end: item.date + ' 11:00:00'
                    }
                  }
                  else {
                    return {
                      id: 0,
                      bgColor: "#ffffff",
                      resourceId: '',
                      title: '',
                      start: '',
                      end: ''
                    }
                  }


                })
                evtfinal = response.data.data.map((item, i) => {
                  if (item.unavailableTiming !== "") {

                    evt1.push({
                      id: i * 200 + 1,
                      bgColor: "#dfdfdf",
                      resourceId: item.techncianID,
                      title: "Block (" + (item.unavailableTiming) + ")",
                      start: item.date + ' 09:00:00',
                      end: item.date + ' 11:00:00'
                    })
                  }


                })
                localStorage.setItem("evt", JSON.stringify(evt1))
                localStorage.setItem("res", JSON.stringify(res1))
                window.location.reload();

              });
          }
          catch (err) {
            //console.log(err)
          }


        }
      })
        .catch();
   
}

const HandleClose = (e) => {
  window.$("#SearchDuplicateTicket").modal('hide');
  window.$("#CreateTicket").modal('hide');
  refreshDashboard();
  clearFields();
  scheduler.close();
          let res1;
          let evt1;
          let evtfinal;
          const clientid = localStorage.getItem("clientid");
          try {
            axios.get(API_Base_Url + "calendar/summary?clientid=" + clientid, {
              headers: {
                'Accept': 'application/json, text/plain, */*',
                //'Content-Type': 'application/json'
              }
            })
              .then(response => {
                res1 = response.data.technicians.map(item => {
                  return {
                    id: item.technicianID,
                    name: item.technicianname,
                    avatar: item.profileimage === "" ? "images/avtar.png" : item.profileimage
                  }

                })
                evt1 = response.data.data.map((item, i) => {
                  if (item.totalTickets > 0) {
                    return {
                      id: i * 100 + 1,
                      bgColor: "#A2D2FF",
                      resourceId: item.techncianID,
                      title: item.totalTickets + " tickets " + " (" + item.totalHours + " Hours)",
                      start: item.date + ' 09:00:00',
                      end: item.date + ' 11:00:00'
                    }
                  }
                  else {
                    return {
                      id: 0,
                      bgColor: "#ffffff",
                      resourceId: '',
                      title: '',
                      start: '',
                      end: ''
                    }
                  }


                })
                evtfinal = response.data.data.map((item, i) => {
                  if (item.unavailableTiming !== "") {

                    evt1.push({
                      id: i * 200 + 1,
                      bgColor: "#dfdfdf",
                      resourceId: item.techncianID,
                      title: "Block (" + (item.unavailableTiming) + ")",
                      start: item.date + ' 09:00:00',
                      end: item.date + ' 11:00:00'
                    })
                  }


                })
                localStorage.setItem("evt", JSON.stringify(evt1))
                localStorage.setItem("res", JSON.stringify(res1))
                window.location.reload();

              });
          }
          catch (err) {
            //console.log(err)
          }

}


const UploadDocument = async (e) => {

  try {
    let multifile = [];
    const selectedFile = e.target.files;
    //for (let i = 0; i < selectedFile.length; i++) {
    const formData = new FormData();
    var today = new Date();
    var doctype = "";
    const ext = selectedFile[0].name.split('.').pop();
    const datevalue = today.getDate() + today.getMonth() + today.getFullYear() + today.getHours() + today.getMinutes() + today.getSeconds();
    const filename = "file_" + datevalue + "." + ext;
    if (ext == "png" || ext == "jpeg" || ext == "svg" || ext == "wpeg" || ext == "PNG" || ext == "JPEG" || ext == "JPG" || ext == "jpg" || ext == "SVG" || ext == "WPEG") {
      doctype = "Image";
    }
    else if (ext == "pdf" || ext == "PDF") {
      doctype = "PDF";
    }
    else if (ext == "docx" || ext == "DOCX" || ext == "doc" || ext == "DOC" || ext == "txt" || ext == "TXT") {
      doctype = "Document";
    }
    else if (ext == "pptx" || ext == "PPTX" || ext == "ppt" || ext == "PPT") {
      doctype = "Presentation";
    }
    else if (ext == "xlsx" || ext == "XLSX" || ext == "xls" || ext == "XLS") {
      doctype = "Workbook";
    }
    else {
      return toast.error('Please Select Valid file');
    }
    // Update the formData object 
    formData.append("uploadedby", loggeduserid);
    formData.append("file", selectedFile[0], filename);
    const Request = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + AccessToken
      },
      body: formData
    }
    const response = await fetch(API_Base_Url + "upload", Request)
    const result = await response.json();
    if (response.ok) {
      // multifile = multifile.concat(result)
      setPoDoc(result.filepath)
      //$("#file-picker1").hide();
    }
    else if (response.status === 400) {
      if (result.errors == undefined) { }
      else {
        toast.error(result.errors)
      }
    }
    else if (response.status === 401) {
      toast.error('Your Session has been expired, Please login again.');
      window.setTimeout(function () {
        localStorage.clear();
        window.location.href = "/#/login";
      }, 2000);
    }
    else {
      //setisLoaded(true)
    }
    //}
  } catch (error) {
    toast.error('Something went wrong , Please try again later.')
  }


}

const clearFields = () => {
  setOrderType("");
  setOrderDetail("");
  //setTechnicianid(0);
  //setTicketDate("");
  //setTicketTime("");

}

const showOrderTypeCreationPopUp = () => {
  //setCustomername("");
  window.$("#AddOrderType").modal('show');
}

// useEffect(() => {

// }, [txtsearchOrderType])

const search_fn = (e) => {
  var searchText = e.target.value;
  setTxtSearchOrderType(e.target.value)
  if (searchText.length > 1) {
    window.$("#searchOrderType").show()
    setOrderTypeList(allOrderTypes.filter(x => x.ordertype.toLowerCase().includes(searchText.toLowerCase())))
  }
  else {
    window.$("#searchOrderType").hide()
  }
}

const SelectNewOrderType = (ordertypeid) => {
  LoadOrderType_api().then(res => {
    const result = res;
    if (result != undefined) {
      if (result.data == null) {

      }
      else {
        var ordertype = result.data.filter(x => x.ordertypeid == ordertypeid)[0];
        selectOrderType_fn(ordertype);
      }
    }

  }).catch();
}

const selectOrderType_fn = (item) => {
  setText(1)
  window.$("#searchOrderType").hide();
  setOrderTypeId(item.ordertypeid);
  window.$("#searchOrderType").hide();
  setTxtSearchOrderType(item.ordertype)
}

function initMap_create() {
  var input = document.getElementById('searchMapInput_create');
  setServiceAddress(input.value)
  var autocomplete = new window.google.maps.places.Autocomplete(input);

  autocomplete.addListener('place_changed', function () {
    var place = autocomplete.getPlace();
    let div = document.createElement('div');
    div.innerHTML = place.adr_address;
    let cityname = div.getElementsByClassName('locality')[0] != undefined ? div.getElementsByClassName('locality')[0].innerHTML : "";
    let statename = div.getElementsByClassName('region')[0] != undefined ? div.getElementsByClassName('region')[0].innerHTML : "";
    let lat = place.geometry.location.lat();
    let long = place.geometry.location.lng();
    setCity(cityname)
    setState(statename)
    setLatitude(lat)
    setLongitude(long)
    setServiceAddress(place.formatted_address)
  });
}

const onfocusSearch = () => {
  window.$("#searchMapInput_create").attr("autocomplete", "new-password");
}

const MarkUnavailable = (e) => {
  // const clientid = localStorage.getItem("clientid");
  if (ticketendtime === "") {
    toast.error("Plaese enter End time");
  }
  else {
    const req = {
      "date": moment(ticketDate).format("YYYY-MM-DD"),
      "fromtime": ticketTime,
      "totime": ticketendtime,
      "status": "unavailable",
      "userid": Number(localStorage.getItem("calendaruserid"))
    };
    console.log("req", req)
    axios.post(API_Base_Url + "calendar/mark", req, {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        toast.success(response.data.message)
        window.$("#CreateTicket").modal('hide');
        refreshDashboard();
        clearFields();
        scheduler.close();
        let res1;
        let evt1;
        let evtfinal;
        const clientid = localStorage.getItem("clientid");
        try {
          axios.get(API_Base_Url + "calendar/summary?clientid=" + clientid, {
            headers: {
              'Accept': 'application/json, text/plain, */*',
              //'Content-Type': 'application/json'
            }
          })
            .then(response => {
              res1 = response.data.technicians.map(item => {
                return {
                  id: item.technicianID,
                  name: item.technicianname,
                  avatar: item.profileimage === "" ? "images/avtar.png" : item.profileimagee
                }

              })
              evt1 = response.data.data.map((item, i) => {
                if (item.totalTickets > 0) {
                  return {
                    id: i * 100 + 1,
                    bgColor: "#A2D2FF",
                    resourceId: item.techncianID,
                    title: item.totalTickets + " tickets " + " (" + item.totalHours + " Hours)",
                    start: item.date + ' 09:00:00',
                    end: item.date + ' 11:00:00'
                  }
                }
                else {
                  return {
                    id: 0,
                    bgColor: "#ffffff",
                    resourceId: '',
                    title: '',
                    start: '',
                    end: ''
                  }
                }


              })
              evtfinal = response.data.data.map((item, i) => {
                if (item.unavailableTiming !== "") {

                  evt1.push({
                    id: i * 200 + 1,
                    bgColor: "#dfdfdf",
                    resourceId: item.techncianID,
                    title: "Block (" + (item.unavailableTiming) + ")",
                    start: item.date + ' 09:00:00',
                    end: item.date + ' 11:00:00'
                  })
                }


              })
              localStorage.setItem("evt", JSON.stringify(evt1))
              localStorage.setItem("res", JSON.stringify(res1))
              window.location.reload();

            });
        }
        catch (err) {
          //console.log(err)
        }
      });
  }
}


return (
  <div>
    <div>
      <div className="" id="CreateTicket">
        <div className="modal-dialog" style={{ margin: 0 }}>
          <div className="modal-content">
            <div className="modal-header" style={{ border: "none", display: 'none' }}>
              <Button onClick={scheduler.close} className="close" ><img src="images/close.png" style={{ width: "20px" }} /></Button>
            </div>
            <div className="modal-body">
              <div className="add-custmer-form" style={Isunavailable ? { display: "none" } : { display: "block" }}>
                <h5 className="modal-title" style={{ margin: "20px 0px" }}>Add New Ticket
                  <a style={{ cursor: "pointer", float: "right" }} onClick={(e) => setUnavailable(Isunavailable ? false : true)}>{Isunavailable ? "Add Ticket" : " Mark Unavailable"}</a>
                </h5>
                <div className="row">

                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="custmer-search">
                        <input type="text" autoComplete="off" value={txtcustomername} onChange={(e) => SearchCustomer(e)} className="form-control" placeholder="Select Customer" />
                        <i className="fa fa-search"></i>
                      </div>
                      <div className="search-suggestion " id="searchresultarea" style={{ zIndex: '9999' }}>
                        <div className="searchdiv" >
                          {
                            customerList.length == 0
                              ?
                              null
                              :
                              customerList.map(item => (
                                <div className="search-list" style={{ cursor: 'pointer' }} onClick={() => ChooseCustomer(item)}>
                                  <h5>{item.customername}</h5>
                                  <p>{item.contactname}</p>
                                </div>
                              ))
                          }
                        </div>
                        <div className="addnewbtn">
                          <a onClick={() => showCustomerCreationPopUp()} style={{ cursor: 'pointer' }}><img src="images/plus.png" />&nbsp;&nbsp; Add New Customer</a>
                        </div>

                      </div>

                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control" autoComplete="false" placeholder="Job Type" maxLength="20" value={txtsearchOrderType} onChange={(e) => search_fn(e)} />
                      {/* <button onClick={() => showOrderTypeCreationPopUp()}>Add New</button> */}
                    </div>

                    <div className="search-suggestion" id="searchOrderType" style={{ zIndex: '9999', border: '1px solid' }}>
                      <div className="searchdiv" >
                        {
                          orderTypeList.length == 0
                            ?
                            null
                            :
                            orderTypeList.map(item => (
                              <div className="search-list" style={{ cursor: 'pointer' }} onClick={() => selectOrderType_fn(item)} >
                                <h5>{item.ordertype}</h5>
                              </div>
                            ))
                        }
                      </div>
                      <div className="addnewbtn">
                        <a style={{ cursor: 'pointer' }} onClick={() => showOrderTypeCreationPopUp()}><img src="images/plus.png" />&nbsp;&nbsp; Add New Order Type</a>
                      </div>

                    </div>

                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="">
                        <textarea className="form-control" autoComplete="false" rows="3" placeholder="Job Details" value={orderDetail} onChange={(e) => setOrderDetail(e.target.value)}></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input type="text" className="form-control" autoComplete="false" placeholder="Job Name" maxLength="20" value={jobname} onChange={(e) => setJobName(e.target.value)} />
                      {/* <button onClick={() => showOrderTypeCreationPopUp()}>Add New</button> */}
                    </div>
                  </div>

                  {/* Service Address */}
                  <div className="col-md-12">
                    <div className="form-group">
                      {/* <textarea className="form-control" autocomplete="off"  name="searchgoogle" id="searchMapInput_create" onChange={(e) => initMap_create()} rows="2" placeholder="Service Address" value={serviceAddress} ></textarea> */}
                      <textarea className="form-control" autoComplete="false" id="searchMapInput_create" onFocus={() => onfocusSearch()} onChange={(e) => initMap_create()} rows="2" placeholder="Job Address" value={serviceAddress} ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input type="checkbox" id="sameasbilling" name="sameasbilling" value={sameAsBilling} checked={sameAsBilling ? true : false} onChange={() => setSameAsBilling(!sameAsBilling)} />
                      &nbsp;<label for="sameasbilling"> Same as Billing Address </label>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label for="email">Job Contact</label>
                      <input type="text" className="form-control" placeholder="Contact Name" maxLength="20" value={jobcontactname} onChange={e => setJobContactName(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-7 pright0">
                    <div className="form-group">
                      <input type="email" className="form-control" placeholder="Contact Email" maxLength="50" value={jobcontactemail} onChange={e => setJobContactEmail(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Contact Phone" maxLength="20" value={jobcontactphone} onChange={e => setJobContactPhone(e.target.value.replace(/\D/, ''))} />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <input type="date" className="form-control" autoComplete="false" pattern="(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))" placeholder="Date" value={ticketDate} onChange={(e) => setTicketDate(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input type="time" className="form-control" autoComplete="false" placeholder="Time" value={ticketTime} onChange={(e) => setTicketTime(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <select className="form-control" value={expectedtime} onChange={(e) => setExpectedtime(e.target.value)}>
                        <option vlaue="0">Estimated Hours</option>
                        <option value="00:30">00:30</option>
                        <option value="01:00">01:00</option>
                        <option value="01:30">01:30</option>
                        <option value="02:00">02:00</option>
                        <option value="02:30">02:30</option>
                        <option value="03:00">03:00</option>
                        <option value="03:30">03:30</option>
                        <option value="04:00">04:00</option>
                        <option value="04:30">04:30</option>
                        <option value="05:00">05:00</option>
                        <option value="05:30">05:30</option>
                        <option value="06:00">06:00</option>
                        <option value="06:30">06:30</option>
                        <option value="07:00">07:00</option>
                        <option value="07:30">07:30</option>
                        <option value="08:00">08:00</option>
                        <option value="08:30">08:30</option>
                        <option value="09:00">09:00</option>
                        <option value="09:30">09:30</option>
                        <option value="10:00">10:00</option>
                        <option value="10:30">10:30</option>

                      </select>
                    </div>
                  </div>
                  {usertype !== "Dispatcher" &&
                    <>
                      <div className="col-md-12">
                        <div className="form-group">
                          <select className="form-control" value={technicianId} onChange={(e) => setTechnicianID(e.target.value)}>
                            <option>Technician</option>
                            {
                              technicianList.map(item => (
                                <option value={item.userId}>{item.name}</option>
                              ))
                            }

                          </select>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <select className="form-control" value={priorityId} onChange={(e) => setPriorityID(e.target.value)}>
                            <option value="0">Priority</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <input type="textbox" className="form-control" autoComplete="false" placeholder="PO Number" value={poNumber} onChange={(e) => setPoNumber(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input type="textbox" className="form-control" placeholder="Work Order Number" value={workOrderNumber} onChange={(e) => setWorkOrderNumber(e.target.value)} />
                        </div>
                      </div>
                    </>
                  }
                  {usertype !== "Dispatcher" ?
                    <div className="col-md-8 pright0" >
                      <input type="file" onChange={(e) => UploadDocument(e)} className="form-control tic-file" placeholder="PO File" />
                    </div>
                    :
                    <div className="col-md-8 pright0" >
                      &nbsp;
                    </div>
                  }
                  <div className="col-md-12">
                    <div className="form-group">
                      <a type="submit" className="btn btn-danger pull-right" style={{ cursor: 'pointer', marginLeft: '5px' }} onClick={scheduler.close} >Cancel </a>
                      <a type="submit" className="btn btn-theme pull-right" style={{ cursor: 'pointer' }} onClick={(e) => submitTicket(e)}>Create </a>

                    </div>
                  </div>
                </div>
              </div>
              <div style={Isunavailable ? { display: "block" } : { display: "none" }}>
                <h5 className="modal-title" style={{ margin: "20px 0px", fontWeight: "700" }}>Add Unavailable Date/Time</h5>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input type="date" className="form-control" autoComplete="false" pattern="(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))" placeholder="Date" value={ticketDate} onChange={(e) => setTicketDate(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input type="time" className="form-control" autoComplete="false" placeholder="Time" value={ticketTime} onChange={(e) => setTicketTime(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input type="time" className="form-control" autoComplete="false" placeholder="Time" value={ticketendtime} onChange={(e) => setTicketEndTime(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">

                      <a type="submit" className="btn btn-theme pull-right" style={{ cursor: 'pointer' }} onClick={(e) => MarkUnavailable(e)}>Add </a>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
          {/* <!-- /.modal-content --> */}
        </div>
        {/* <!-- /.modal-dialog --> */}
      </div>

      <div className="modal fade fsm-popup" id="SearchDuplicateTicket">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <a type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <img src="images/close.png" /></a>
                            <h5 className="modal-title"> </h5>
                        </div>
                        <div className="modal-body">
                            <div className="add-custmer-form ">
                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <p>The record already exists, do you want to proceed?</p>
                                        </div>
                                    </div>

                                    <div className="col-md-2 pull-right">
                                        <a type="button" className="btn btn-danger pull-right" onClick={(e) => HandleClose(e)}>NO</a>
                                    </div>
                                    <div className="col-md-2 pull-right">
                                        <a type="submit" className="btn btn-theme pull-right" style={{ cursor: 'pointer' }} onClick={(e) => CreateTicket(e)}>YES </a>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog --> */}
            </div>


      <CreateUpdateOrderType
        screentype={screentype}
        orderType={orderType}
        setOrderType={setOrderType}
        CreateOrderType_submit={CreateOrderType_submit}
        orderTypeId={orderTypeId}
      />
    </div>

  </div>
);
};


function FullCalendarWidget2({ slotid }) {
  const [mode, setMode] = useState("tabs");
  const [userid, setUserid] = useState(Number(slotid));
  const [id, setID] = useState(Number(slotid))
  const [color, setColor] = useState("#fff")
  const [title, setTitle] = useState("")
  const [res, setRes] = useState([])
  const [RESOURCES, setResourses] = useState([])
  const [EVENTS, setEvents] = useState([])
  const [selectedTicketId, setSelectedTicketId] = useState(0);
  const [image, setImage] = useState('')


  useEffect(() => {
    if (id === 0) {
      event(slotid)
      localStorage.setItem("calendaruserid", slotid)
    }
    else {
      event(id)
      localStorage.setItem("calendaruserid", id)
    }
  }, [id, slotid])

  useEffect(() => {
    empdata()
    if (userid === 0) {
      event(slotid)
      localStorage.setItem("calendaruserid", slotid)
    }
    else {
      event(userid)
      localStorage.setItem("calendaruserid", userid)
    }

    if (RESOURCES.length > 0 && EVENTS.length > 0) {
      var temp = RESOURCES.filter(x => x[0].admin_id === Number(userid === 0 ? slotid : userid))
      console.log("xyz", userid, temp)

      setTitle(temp[0][0].title)
      setID(temp[0][0].admin_id)
      setImage(temp[0][0].avatar)
      setRes(temp[0])
    }
    // console.log('res1111 ',res,temp[0])
  }, [userid, slotid])

  const empdata = () => {
    const clientid = localStorage.getItem("clientid");
    axios.get(API_Base_Url + "calendar/summary?clientid=" + clientid, {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {

        let res1 = response.data.technicians.map(item => {
          return [{
            admin_id: item.technicianID,
            title: item.technicianname,
            mobile: "555666777",
            avatar: item.profileimage === "" ? "images/avtar.png" : item.profileimage

          }]

        })

        setResourses(res1)
        res1.length > 0 && res1.map((item, i) => (
          res1[i].length > 0 && res1[i].map((item1, j) => (
            (item[j].admin_id === (userid === 0 ? slotid : userid)) && setRes([item1])
          )
          ))
        )

      });

  }

  const event = (id) => {
    const clientid = localStorage.getItem("clientid");
    axios.get(API_Base_Url + "calendar/technician?clientid=" + clientid + "&technicianid=" + id, {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        console.log("hfjkd", response)
        let evt = response.data.data.length > 0 ?
          response.data.data.map((item, i) => {

            let tempstart = item.date + " " + item.starttime
            let tempend = item.date + " " + item.endtime
            if (item.starttime === item.endtime) {
              let a = moment.duration(item.starttime).asMinutes() + moment.duration("01:00").asMinutes()
              var minutes = a % 60;
              var hours = (a - minutes) / 60;
              var end = (hours + ":" + minutes);
              tempend = item.date + " " + end
            }
            else {
              if (item.endtime === "02:00") {
                let a = moment.duration(item.endtime).asMinutes() + moment.duration("12:00").asMinutes()
                var minutes = a % 60;
                var hours = (a - minutes) / 60;
                var end = (hours + ":" + minutes);
                tempend = item.date + " " + end
              }
              else {
                tempend = item.date + " " + item.endtime
              }
            }
            return {
              event_id: item.ticketid,
              admin_id: response.data.technicianID,
              title: item.status !== "Unavailable" ? "#" +item.ticketid+" - "+item.customerName + " " + item.jobtype + " " + item.jobdetails : "Unavailable",
              start: new Date(tempstart),
              end: new Date(tempend),
              color: item.status !== "Unavailable" ? "#A2D2FF" : "#dfdfdf"
            }
          })
          :
          [{
            event_id: 0,
            admin_id: '',
            title: '',
            start: '',
            end: '',
            color: "#ffffff"
          }]
        console.log("evttt", evt, id, res)
        setTitle(response.data.technicianname)
        setImage(response.data.profileimage)
        setEvents(evt)

      });
    
  }
  const handleSearch = () => {

  }


  // useEffect(() => {
  //   if (RESOURCES.length > 0) {
  //     var temp = RESOURCES.filter(x => x[0].admin_id === Number(userid))
  //     setTitle(temp[0][0].title)
  //     setID(temp[0][0].admin_id)
  //     setRes(temp[0])
  //   }
  // }, [userid])


  const setValue = (id, title, img) => {
    setImage(img)
    setTitle(title)
    setID(id)
    setColor(color)
    var temp = RESOURCES.length > 0 && RESOURCES.filter(x => x[0].admin_id === id)
    setRes(temp[0])
  }

  return (
    <>
      <div className="modal fade fsm-popup" id="calendarpopup">
        <div className="modal-dialog" style={{ width: "100%", margin: "0px" }}>
          <div className="modal-content">
            <div className="modal-header">
              <a type="button" className="close" data-dismiss="modal" aria-label="Close">
                <img src="images/close.png" /></a>
              <h5 className="modal-title"></h5>
            </div>
            <div className="modal-body">
              {RESOURCES.length > 0 && EVENTS.length > 0 &&
                <div className="fntsize">
                  <div className="dropdown actddlbtn pull-right" style={{ margin: "0px 30px 0px 30px" }}>
                    <a className="btn dropdown-toggle" type="button" data-toggle="dropdown" style={{ background: color, color: "grey", fontSize: "14px", fontWeight: 400 }}><img src={image !== "" && image !== undefined ? image : "images/avtar.png"} style={{ width: "35px", borderRadius: "50%" }} />&nbsp; {title} &nbsp;<i style={{ fontSize: "12px", fontWeight: 400 }} className="fa fa-chevron-down" aria-hidden="true"></i></a>
                    <ul className="dropdown-menu act-dropdown">
                      {
                        RESOURCES.length > 0
                        &&
                        RESOURCES.map(item => (
                          <>
                            <li style={{ padding: "5px 10px", cursor: "pointer" }} onClick={(e) => setValue(item[0].admin_id, item[0].title, item[0].avatar)}><img src={item[0].avatar !== "" && item[0].avatar !== undefined ? item[0].avatar : "images/avtar.png"} style={{ width: "35px", borderRadius: "50%" }} />&nbsp; {item[0].title}</li>
                          </>
                        ))
                      }
                    </ul>
                  </div>
                  <div style={{ clear: "both" }}>
                  </div>
                  <Scheduler
                    customEditor={(scheduler) => <CustomEditor scheduler={scheduler} />}
                    events={EVENTS}
                    resources={res}
                    week={
                      { weekDays: [0, 1, 2, 3, 4, 5], weekStartOn: 6, startHour: 0, endHour: 23, step: 60 }
                    }
                    resourceFields={{
                      idField: "admin_id",
                      colorField: "color",
                      textField: "",
                      subTextField: ""
                    }}
                    resourceViewMode="tabs"
                    selectedDate={new Date()}
                    fields={[
                      {
                        name: "admin_id",
                        type: "select",
                        default: RESOURCES[0].admin_id,
                        options: RESOURCES.map((res) => {
                          return {
                            id: res[0].admin_id,
                            text: `${res[0].title}`,
                            value: res[0].admin_id //Should match "name" property
                          };
                        }),
                        config: { label: "Assignee", required: true }
                      }
                    ]}
                  />
                </div>}
            </div>
          </div>
        </div>
      </div>
      <TicketDetails
        ticketid={selectedTicketId}
        loadAgain={handleSearch}
        ticketType={"normal"}
      />
    </>
  );
}

export default FullCalendarWidget2;