import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { LoadInvoices_api, LoadTicketDetails_api, SendEmail_api, ResendEmail_api } from '../../functions/tickets'
import { FinalizeInvoice_api, DraftInvoice_api, SubmitInvoice_api, PaidInvoice_api, UpdateInvoice_api, UpdateInvoicedate_api } from '../../functions/invoices'
import StatusUpdate from '../popups/StatusUpdate'
import { Loadservice_api } from '../../functions/service'
import moment from 'moment'
import { format } from 'date-fns'
import DateUpdate from '../popups/Invoicedateupdate'
import DueDateUpdate from '../popups/Invoiceduedateupdate'
import Invoicenoupdate from '../popups/Invoicenoupdate'

const InvoiceDetails = ({ invoiceid, ticketid, activityid, loadInvoiceList }) => {

    var userid = Number(localStorage.getItem("userid"));
    const [test, setTest] = useState(0);
    const [InvoicesDetails, setInvoicesDetails] = useState({});
    const [selectedStatusId, setSelectedStatusId] = useState(0);
    const [ticketDetails, setTicketDetails] = useState({});
    const [poNumber, setPoNumber] = useState("");
    const [isMailSent_invoice, setIsMailSent_invoice] = useState(true);
    const userType = localStorage.getItem("usertype");
    const [searchText, setSearchText] = useState("");
    const [serviceList, setserviceList] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [invoiceduedate, setInvoiceduedate] = useState("");
    const [invoicedate, setInvoicedate] = useState("");
    const [invoiceno, setInvoiceno] = useState("");

    useEffect(() => {
        loadInvoice();
        setIsMailSent_invoice(false)
    }, [activityid])

    useEffect(() => {
        loadTicketDetails();
    }, [])

    const loadservices = (searchText) => {
        setIsLoaded(false);
        Loadservice_api(searchText).then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {
                    setserviceList([])
                }
                else
                    setserviceList(result.data);
            }
            setIsLoaded(true);
        }).catch();
    }

    const SearchCustomer = (e) => {
        if (e.target.value.length < 2) {
            window.$("#searchresultarea").hide();

        }
        else {
            window.$("#searchresultarea").show();
            loadservices(e.target.value);
        }

    }

    useEffect(() => {
        if (Object.keys(InvoicesDetails).length > 0)
            sum();
    }, [InvoicesDetails])

    const loadTicketDetails = () => {
        // setIsLoaded(false);
        LoadTicketDetails_api(ticketid, userid).then(res => {
            const result = res;
            if (result != undefined) {
                if (result == null) {
                    setTicketDetails([])
                }
                else
                    setTicketDetails(result);
            }
            //setIsLoaded(true);
        }).catch();
    }

    const sum = () => {
        var totalmaterialamt = 0;
        var totallaboramt = 0;
        for (var i = 0; i < InvoicesDetails.invoicedata.filter(x => x.type == "Labor").length; i++) {
            if (!(isNaN(InvoicesDetails.invoicedata.filter(x => x.type == "Labor")[i].amount) || InvoicesDetails.invoicedata.filter(x => x.type == "Labor")[i].amount === null || InvoicesDetails.invoicedata.filter(x => x.type == "Labor")[i].amount === 0)) {
                totallaboramt = Number(totallaboramt) + Number(InvoicesDetails.invoicedata.filter(x => x.type == "Labor")[i].amount);
            }
        }

        for (var i = 0; i < InvoicesDetails.invoicedata.filter(x => x.type == "Material").length; i++) {
            if (!(isNaN(InvoicesDetails.invoicedata.filter(x => x.type == "Material")[i].amount) || InvoicesDetails.invoicedata.filter(x => x.type == "Material")[i].amount === null || InvoicesDetails.invoicedata.filter(x => x.type == "Material")[i].amount === 0)) {
                totalmaterialamt = Number(totalmaterialamt) + Number(InvoicesDetails.invoicedata.filter(x => x.type == "Material")[i].amount);
            }
        }

        InvoicesDetails.amountLabour = parseFloat(totallaboramt).toFixed(2);
        InvoicesDetails.amountMaterial = parseFloat(totalmaterialamt).toFixed(2);
        sumPayable_invoice()
    }

    const sumPayable_invoice = () => {
        var amtMaterial = 0;
        var amtLabour = 0;
        var amtTaxMaterial = 0;
        var amtTaxLabour = 0;
        var amtDiscount = 0;
        var amtNetPayable = 0;

        if (!(isNaN(InvoicesDetails.amountMaterial) || InvoicesDetails.amountMaterial === null)) {
            amtMaterial = Number(InvoicesDetails.amountMaterial);
            if (!(isNaN(InvoicesDetails.amountTaxMaterialRate) || InvoicesDetails.amountTaxMaterialRate === null)) {
                amtTaxMaterial = InvoicesDetails.amountTaxMaterial = parseFloat(((Number(InvoicesDetails.amountMaterial) * Number(InvoicesDetails.amountTaxMaterialRate)) / 100)).toFixed(2);
            }
        }

        if (!(isNaN(InvoicesDetails.amountLabour) || InvoicesDetails.amountLabour === null)) {
            amtLabour = Number(InvoicesDetails.amountLabour);
            if (!(isNaN(InvoicesDetails.amountTaxLabourRate) || InvoicesDetails.amountTaxLabourRate === null)) {
                amtTaxLabour = InvoicesDetails.amountTaxLabour = parseFloat(((Number(InvoicesDetails.amountLabour) * Number(InvoicesDetails.amountTaxLabourRate)) / 100)).toFixed(2);
            }
        }
        if (!(isNaN(InvoicesDetails.amountDiscount) || InvoicesDetails.amountDiscount === null)) {
            if (!(isNaN(InvoicesDetails.discountpercentage) || InvoicesDetails.discountpercentage === null)) {
                amtDiscount = InvoicesDetails.amountDiscount = parseFloat((Number(amtMaterial) + Number(amtLabour)) * Number(InvoicesDetails.discountpercentage) / 100).toFixed(2);
            }
        }
        InvoicesDetails.amountPayable = parseFloat((Number(amtMaterial) + Number(amtLabour) + Number(amtTaxMaterial) + Number(amtTaxLabour)) - Number(amtDiscount)).toFixed(2);
        setTest(test + 1);
    }

    const loadInvoice = () => {
        LoadInvoices_api(ticketid, userid, activityid).then(res => {
            const result = res;
            if (result != undefined) {

                if (result == null) {
                    setInvoicesDetails({});
                }
                else {
                    setInvoicesDetails(result);
                    setPoNumber(result.ponumber)
                }
            }

        }).catch();
    }

    const handleChange_TextValue_item_invoice = (item, event) => {
        var index = InvoicesDetails.invoicedata.indexOf(item);
        InvoicesDetails.invoicedata[index].item = event.target.value;
        setTest(test + 1)
    }

    const handleChange_tax_material_invoice = (event) => {
        const amount = event.target.value;
        if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
            InvoicesDetails.amountTaxMaterialRate = amount;
        }
        InvoicesDetails.amountTaxMaterial = ((InvoicesDetails.amountMaterial * event.target.value) / 100)
        sumPayable_invoice();
        setTest(test + 1)
    }

    const handleChange_tax_labor_invoice = (event) => {
        const amount = event.target.value;
        if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
            InvoicesDetails.amountTaxLabourRate = amount;
        }
        InvoicesDetails.amountTaxLabour = ((InvoicesDetails.amountLabour * event.target.value) / 100);
        sumPayable_invoice();
        setTest(test + 1)
    }

    const handleChange_TextValue_qty_material = (item, event) => {
        var index = InvoicesDetails.invoicedata.indexOf(item);
        InvoicesDetails.invoicedata[index].qty = event.target.value;
        var num = parseFloat(event.target.value.match(/[\d\.]+/))
        InvoicesDetails.invoicedata[index].qtyno = num;
        InvoicesDetails.invoicedata[index].amount = calculateAmount(item);
        sumPayable_invoice()
        sum()
        setTest(test + 1)
    }

    const handleChange_TextValue_qty_labor = (item, event) => {
        var index = InvoicesDetails.invoicedata.indexOf(item);

        InvoicesDetails.invoicedata[index].qty = event.target.value;
        var num = parseFloat(event.target.value.match(/[\d\.]+/));
        InvoicesDetails.invoicedata[index].qtyno = num;
        InvoicesDetails.invoicedata[index].amount = calculateAmount(item);
        sumPayable_invoice()
        sum()
        setTest(test + 1)
    }
    const handleChange_TextValue_UnitPrice_material = (item, event) => {
        var index = InvoicesDetails.invoicedata.indexOf(item);

        var num = parseFloat(item.qty.match(/[\d\.]+/));
        InvoicesDetails.invoicedata[index].qtyno = num;

        InvoicesDetails.invoicedata[index].unitprice = event.target.value;
        InvoicesDetails.invoicedata[index].amount = Number(calculateAmount(item));
        sumPayable_invoice()
        sum()
        setTest(test + 1)
    }

    const handleChange_TextValue_UnitPrice_labor = (item, event) => {
        var index = InvoicesDetails.invoicedata.indexOf(item);

        var num = parseFloat(item.qty.match(/[\d\.]+/));
        InvoicesDetails.invoicedata[index].qtyno = num;

        InvoicesDetails.invoicedata[index].unitprice = event.target.value;
        InvoicesDetails.invoicedata[index].amount = calculateAmount(item);
        sumPayable_invoice()
        sum()
        setTest(test + 1)
    }

    const finalizeInvoice = (e) => {
        e.preventDefault();
        if (InvoicesDetails.invoicedata.length == 0)
            return toast.error('Please add atleast one row for material or labor');

        var countRecords = InvoicesDetails.invoicedata.filter(x => x.type === "Material").length;
        if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
            var lastIndexTitle_material = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].item;
            var lastIndexQty_material = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].qty;
            var lastIndexUnitPrice_material = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].unitprice;
            var lastIndexAmount_material = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].amount;
            if (lastIndexTitle_material == "" || lastIndexTitle_material == null) {
                return toast.error("Please Enter Material Title.");
            }
            else if (lastIndexQty_material == "" || lastIndexQty_material == 0) {
                return toast.error("Please Enter Material Qty.");
            }
            else if (lastIndexUnitPrice_material == "" || lastIndexUnitPrice_material == 0) {
                return toast.error("Please Enter Material Unit Price.");
            }
            else if (lastIndexAmount_material == "" || lastIndexAmount_material == 0) {
                return toast.error("Please Enter Material Amount.");
            }
        }

        countRecords = InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length;
        if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
            var lastIndexTitle_labor = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].item;
            var lastIndexQty_labor = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].qty;
            var lastIndexUnitPrice_labor = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].unitprice;
            var lastIndexAmount_labor = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].amount;
            if (lastIndexTitle_labor == "" || lastIndexTitle_labor == null) {
                return toast.error("Please Enter Labor Title.");
            }
            else if (lastIndexQty_labor == "" || lastIndexQty_labor == 0) {
                return toast.error("Please Enter Labor Qty.");
            }
            else if (lastIndexUnitPrice_labor == "" || lastIndexUnitPrice_labor == 0) {
                return toast.error("Please Enter Labor Unit Price.");
            }
            else if (lastIndexAmount_labor == "" || lastIndexAmount_labor == 0) {
                return toast.error("Please Enter Labor Amount.");
            }
        }

        var invoicedata = [];
        for (var i = 0; i < InvoicesDetails.invoicedata.length; i++) {
            if (InvoicesDetails.invoicedata[i].type != null && InvoicesDetails.invoicedata[i].type != "" && InvoicesDetails.invoicedata[i].type != "null") {
                invoicedata.push({
                    "id": InvoicesDetails.invoicedata[i].id,
                    "type": InvoicesDetails.invoicedata[i].type,
                    "item": InvoicesDetails.invoicedata[i].item,
                    "qty": String(InvoicesDetails.invoicedata[i].qty),
                    "unitprice": Number(InvoicesDetails.invoicedata[i].unitprice),
                    "amount": Number(InvoicesDetails.invoicedata[i].amount)
                })
            }
        }
        var req = {
            "ticketid": ticketid,
            "activityid": activityid,
            "invoicedata": invoicedata,
            "amountMaterials": Number(InvoicesDetails.amountMaterial),
            "amountLabour": Number(InvoicesDetails.amountLabour),
            "amountTotal": Number(InvoicesDetails.amountMaterial) + Number(InvoicesDetails.amountLabour),
            "amountDiscount": !isNaN(Number(InvoicesDetails.amountDiscount)) ? Number(InvoicesDetails.amountDiscount) : 0,
            "amountTax": Number(InvoicesDetails.amountTax),
            "discountPercentage": InvoicesDetails.discountpercentage == null ? 0 : Number(InvoicesDetails.discountpercentage),
            "amountTaxMaterialRate": Number(InvoicesDetails.amountTaxMaterialRate),
            "amountTaxMaterial": Number(InvoicesDetails.amountTaxMaterial),
            "amountTaxLabourRate": Number(InvoicesDetails.amountTaxLabourRate),
            "amountTaxLabour": Number(InvoicesDetails.amountTaxLabour),
            "amountPayable": Number(InvoicesDetails.amountPayable),
            "ponumber": poNumber,
            "notes": InvoicesDetails.notes,
            "updatedby": userid

        }
        console.log(req)
        FinalizeInvoice_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                loadInvoiceList();
                loadInvoice();
            }
        })
            .catch();
    }

    const draftInvoice = (e) => {
        e.preventDefault();
        if (InvoicesDetails.invoicedata.length == 0)
            return toast.error('Please add atleast one row for material or labor');

        var countRecords = InvoicesDetails.invoicedata.filter(x => x.type === "Material").length;
        if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
            var lastIndexTitle_material = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].item;
            var lastIndexQty_material = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].qty;
            var lastIndexUnitPrice_material = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].unitprice;
            var lastIndexAmount_material = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].amount;
            if (lastIndexTitle_material == "" || lastIndexTitle_material == null) {
                return toast.error("Please Enter Material Title.");
            }
            else if (lastIndexQty_material == "" || lastIndexQty_material == 0) {
                return toast.error("Please Enter Material Qty.");
            }
            else if (lastIndexUnitPrice_material == "" || lastIndexUnitPrice_material == 0) {
                return toast.error("Please Enter Material Unit Price.");
            }
            else if (lastIndexAmount_material == "" || lastIndexAmount_material == 0) {
                return toast.error("Please Enter Material Amount.");
            }
        }

        countRecords = InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length;
        if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
            var lastIndexTitle_labor = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].item;
            var lastIndexQty_labor = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].qty;
            var lastIndexUnitPrice_labor = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].unitprice;
            var lastIndexAmount_labor = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].amount;
            if (lastIndexTitle_labor == "" || lastIndexTitle_labor == null) {
                return toast.error("Please Enter Labor Title.");
            }
            else if (lastIndexQty_labor == "" || lastIndexQty_labor == 0) {
                return toast.error("Please Enter Labor Qty.");
            }
            else if (lastIndexUnitPrice_labor == "" || lastIndexUnitPrice_labor == 0) {
                return toast.error("Please Enter Labor Unit Price.");
            }
            else if (lastIndexAmount_labor == "" || lastIndexAmount_labor == 0) {
                return toast.error("Please Enter Labor Amount.");
            }
        }

        var invoicedata = [];
        for (var i = 0; i < InvoicesDetails.invoicedata.length; i++) {
            invoicedata.push({
                "type": InvoicesDetails.invoicedata[i].type,
                "item": InvoicesDetails.invoicedata[i].item,
                "qty": String(InvoicesDetails.invoicedata[i].qty),
                "unitprice": Number(InvoicesDetails.invoicedata[i].unitprice),
                "amount": Number(InvoicesDetails.invoicedata[i].amount)
            })
        }

        var req = {
            "ticketid": ticketid,
            "invoicedata": invoicedata,
            "amountMaterials": 0,
            "amountLabour": 0,
            "amountTotal": 0,
            "amountDiscount": 0,
            "amountTax": 0,
            "amountTaxMaterialRate": 0,
            "amountTaxMaterial": 0,
            "amountTaxLabourRate": 0,
            "amountTaxLabour": 0,
            "amountPayable": 0,
            "customername": "",
            "comment": "",
            "addedby": userid,
            "latitude": "",
            "longitude": "",
            "signature": "",
            "notes": InvoicesDetails.notes,

        }
        DraftInvoice_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                loadInvoiceList();
                loadInvoice();
            }
        })
            .catch();
    }

    const updateInvoice = (e) => {
        e.preventDefault();
        if (InvoicesDetails.invoicedata.length == 0)
            return toast.error('Please add atleast one row for material or labor');

        var countRecords = InvoicesDetails.invoicedata.filter(x => x.type === "Material").length;
        if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
            var lastIndexTitle_material = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].item;
            var lastIndexQty_material = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].qty;
            var lastIndexUnitPrice_material = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].unitprice;
            var lastIndexAmount_material = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].amount;
            if (lastIndexTitle_material == "" || lastIndexTitle_material == null) {
                return toast.error("Please Enter Material Title.");
            }
            else if (lastIndexQty_material == "" || lastIndexQty_material == 0) {
                return toast.error("Please Enter Material Qty.");
            }
            else if (lastIndexUnitPrice_material == "" || lastIndexUnitPrice_material == 0) {
                return toast.error("Please Enter Material Unit Price.");
            }
            else if (lastIndexAmount_material == "" || lastIndexAmount_material == 0) {
                return toast.error("Please Enter Material Amount.");
            }
        }

        countRecords = InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length;
        if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
            var lastIndexTitle_labor = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].item;
            var lastIndexQty_labor = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].qty;
            var lastIndexUnitPrice_labor = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].unitprice;
            var lastIndexAmount_labor = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].amount;
            if (lastIndexTitle_labor == "" || lastIndexTitle_labor == null) {
                return toast.error("Please Enter Labor Title.");
            }
            else if (lastIndexQty_labor == "" || lastIndexQty_labor == 0) {
                return toast.error("Please Enter Labor Qty.");
            }
            else if (lastIndexUnitPrice_labor == "" || lastIndexUnitPrice_labor == 0) {
                return toast.error("Please Enter Labor Unit Price.");
            }
            else if (lastIndexAmount_labor == "" || lastIndexAmount_labor == 0) {
                return toast.error("Please Enter Labor Amount.");
            }
        }

        var invoicedata = [];
        for (var i = 0; i < InvoicesDetails.invoicedata.length; i++) {
            if (InvoicesDetails.invoicedata[i].type != null && InvoicesDetails.invoicedata[i].type != "" && InvoicesDetails.invoicedata[i].type != "null") {
                invoicedata.push({
                    "id": InvoicesDetails.invoicedata[i].id,
                    "type": InvoicesDetails.invoicedata[i].type,
                    "item": InvoicesDetails.invoicedata[i].item,
                    "qty": String(InvoicesDetails.invoicedata[i].qty),
                    "unitprice": Number(InvoicesDetails.invoicedata[i].unitprice),
                    "amount": Number(InvoicesDetails.invoicedata[i].amount)
                })
            }
        }
        var req = {
            "ticketid": ticketid,
            "activityid": activityid,
            "invoicedata": invoicedata,
            "amountMaterials": Number(InvoicesDetails.amountMaterial),
            "amountLabour": Number(InvoicesDetails.amountLabour),
            "amountTotal": Number(InvoicesDetails.amountMaterial) + Number(InvoicesDetails.amountLabour),
            "amountDiscount": !isNaN(Number(InvoicesDetails.amountDiscount)) ? Number(InvoicesDetails.amountDiscount) : 0,
            "amountTax": Number(InvoicesDetails.amountTax),
            "discountPercentage": InvoicesDetails.discountpercentage == null ? 0 : Number(InvoicesDetails.discountpercentage),
            "amountTaxMaterialRate": Number(InvoicesDetails.amountTaxMaterialRate),
            "amountTaxMaterial": Number(InvoicesDetails.amountTaxMaterial),
            "amountTaxLabourRate": Number(InvoicesDetails.amountTaxLabourRate),
            "amountTaxLabour": Number(InvoicesDetails.amountTaxLabour),
            "amountPayable": Number(InvoicesDetails.amountPayable),
            "ponumber": poNumber,
            "updatedfrom": "web",
            "notes": InvoicesDetails.notes,
            "updatedby": userid

        }
        console.log(req)
        UpdateInvoice_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                loadInvoiceList();
                loadInvoice();
            }
        })
            .catch();
    }

    const addMoreMaterialRow_invoice = (e) => {
        e.preventDefault();
        //alert(JSON.stringify(InvoicesDetails.invoicedata))
        if (InvoicesDetails.invoicedata != undefined) {
            var countRecords = InvoicesDetails.invoicedata.filter(x => x.type === "Material").length;
            if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
                var lastIndexTitle = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].item;
                var lastIndexQty = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].qty;
                var lastIndexUnitPrice = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].unitprice;
                var lastIndexAmount = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].amount;
                if (lastIndexTitle == "" || lastIndexTitle == null) {
                    return toast.error("Please Enter Material Title.");
                }
                else if (lastIndexQty == "" || lastIndexQty == 0) {
                    return toast.error("Please Enter Material Qty.");
                }
                else if (lastIndexUnitPrice == "" || lastIndexUnitPrice == 0) {
                    return toast.error("Please Enter Material Unit Price.");
                }
                else if (lastIndexAmount == "" || lastIndexAmount == 0) {
                    return toast.error("Please Enter Material Amount.");
                }
            }
        }
        if (InvoicesDetails.invoicedata == null) {
            InvoicesDetails.invoicedata = [];
        }
        InvoicesDetails.invoicedata.push({
            "id": 0,
            "item": "",
            "type": "Material",
            "qty": 0,
            "unitprice": 0,
            "amount": 0
        })
        setTest(test + 1)
    }

    const addMoreLaborRow_invoice = (e) => {
        e.preventDefault();
        if (InvoicesDetails.invoicedata != undefined && InvoicesDetails.invoicedata != null) {
            var countRecords = InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length;
            if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
                var lastIndexTitle = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].item;
                var lastIndexQty = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].qty;
                var lastIndexUnitPrice = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].unitprice;
                var lastIndexAmount = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].amount;
                if (lastIndexTitle == "" || lastIndexTitle == null) {
                    return toast.error("Please Enter Labor Title.");
                }
                else if (lastIndexQty == "" || lastIndexQty.trim() == "" || lastIndexQty == 0) {
                    return toast.error("Please Enter Labor Qty.");
                }
                else if (lastIndexUnitPrice == "" || lastIndexUnitPrice == 0) {
                    return toast.error("Please Enter Labor Unit Price.");
                }
                else if (lastIndexAmount == "" || lastIndexAmount == 0) {
                    return toast.error("Please Enter Labor Amount.");
                }
            }
        }
        if (InvoicesDetails.invoicedata == null) {
            InvoicesDetails.invoicedata = [];
        }
        InvoicesDetails.invoicedata.push({
            "id": 0,
            "item": "",
            "type": "Labor",
            "qty": 0,
            "unitprice": 0,
            "amount": 0
        })
        setTest(test + 1)
    }

    const showStatusUpdate = () => {
        setSelectedStatusId(InvoicesDetails.currentstatusid);
        window.$("#StatusPop").modal('show');
    }

    const calculateAmount = (_item) => {
        var _qty = 0;
        var _unitprice = 0;
        var _total = 0;

        try {
            _qty = _item.qtyno //.replace(/\D/g, '');
        } catch (error) {

        }
        try {
            _unitprice = _item.unitprice;//.replace(/\D/g, '');
        } catch (error) {

        }

        if (!(isNaN(_qty) || _qty === null || _qty === 0)) {
            if (!(isNaN(_unitprice) || _unitprice === null || _unitprice === 0)) {
                _total = _qty * _unitprice;
            }
        }

        return _total;
    }

    const removeRow = (item) => {
        InvoicesDetails.invoicedata = InvoicesDetails.invoicedata.filter(x => x !== item);
        sum()
        setTest(test + 1)
    }

    const sendMail = () => {
        var photos = [];
        var selectedphotos = [];
        if (InvoicesDetails.photos != undefined && InvoicesDetails.photos.length > 0) {
            selectedphotos = InvoicesDetails.photos.filter(x => x.selected == true);
            if (selectedphotos.length > 0) {
                for (var i = 0; i < selectedphotos.length; i++) {
                    photos.push({
                        "photoid": selectedphotos[i].photoid
                    })
                }
            }
        }
        var req = {
            "ticketid": ticketid,
            "activityid": activityid,
            "photos": photos,
            "sentby": userid

        }
        SendEmail_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                setIsMailSent_invoice(true)
                //loadInvoice();
                loadTicketDetails()
            }
        })
            .catch();
    }


    const reSendMail = () => {
        var photos = [];
        var selectedphotos = [];
        if (InvoicesDetails.photos != undefined && InvoicesDetails.photos.length > 0) {
            selectedphotos = InvoicesDetails.photos.filter(x => x.selected == true);
            if (selectedphotos.length > 0) {
                for (var i = 0; i < selectedphotos.length; i++) {
                    photos.push({
                        "photoid": selectedphotos[i].photoid
                    })
                }
            }
        }
        var req = {
            "ticketid": ticketid,
            "activityid": activityid,
            "photos": photos,
            "sentby": userid

        }
        ResendEmail_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                //loadInvoice();
                loadTicketDetails();
            }
        })
            .catch();
    }

    const handlenotes_invoice = (text) => {
        InvoicesDetails.notes = text;
        setTest(test + 1)
    }
    const SelectImage_invoice = (item) => {
        var index = InvoicesDetails.photos.indexOf(item);
        InvoicesDetails.photos[index].selected = InvoicesDetails.photos[index].selected ? false : true;
        setTest(test + 1)
    }
    const handleChange_disc_percentage_invoice = (event) => {
        const amount = event.target.value;
        if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
            InvoicesDetails.discountpercentage = amount;
        }
        //InvoicesDetails.discountpercentage = event.target.value.replace(/\D/, '');
        sumPayable_invoice();
        setTest(test + 1)
    }

    const editinvoiceduedate = (date, date2,invoiceno) => {
        setInvoiceduedate(format(new Date(date),"MM/dd/yyyy"))
        setInvoicedate(format(new Date(date2),"MM/dd/yyyy"))
        setInvoiceno(invoiceno)
        window.$("#duedatepopup").modal('show');
    }

    const editinvoicedate = (date, date2,invoiceno) => {
        setInvoiceduedate(format(new Date(date2),"MM/dd/yyyy"))
        setInvoicedate(format(new Date(date),"MM/dd/yyyy"))
        setInvoiceno(invoiceno)
        window.$("#datepopup").modal('show');
    }

    const editinvoiceno = (date, date2,invoiceno) => {
        setInvoiceduedate(format(new Date(date),"MM/dd/yyyy"))
        setInvoicedate(format(new Date(date2),"MM/dd/yyyy"))
        setInvoiceno(invoiceno)
        window.$("#invoiceno").modal('show');
    }


    return (
        <>
            <div className="modal fade modal-fullscreen fsm-popup" id="InvoiceDetail" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <a className="close" data-dismiss="modal" aria-hidden="true"> <img src="images/close.png" /></a>
                            <h5 className="modal-title" id="myModalLabel">
                                {/* #{invoiceid} - Invoice Details */}
                                #{ticketid} - Ticket Details</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="box box-solid ">
                                        <div className="box-body">
                                            <div className="order-details">
                                                <div className="row">
                                                    <div className="col-md-3  col-sm-12 ">
                                                        <div className="od-col4">
                                                            <div className="ed-top">
                                                                <h4 className="ed-title"><span className="mr-15">{ticketDetails.ordertype}</span><a href="" className="od-edit">
                                                                    {/* <i className="fa fa-pencil"></i> */}
                                                                </a></h4>
                                                                <p className="rpc">{ticketDetails.orderdetail}</p>
                                                                <p className="rpc">{ticketDetails.address}</p>
                                                            </div>


                                                        </div>
                                                    </div>
                                                    <div className="col-md-3  col-sm-5 ">
                                                        <div className="od-col4">
                                                            <div className="ed-top">
                                                                <h4 className="ed-title"><span className="mr-15">PO / Work Order</span></h4>
                                                                <div className="ord-assigned">
                                                                    <p className="rpc">PO No. {ticketDetails.ponumber}</p>
                                                                    <p className="rpc">Work Order No.  {ticketDetails.workordernumber}</p>
                                                                </div>
                                                            </div>
                                                            <div className="purchase-order" >
                                                                {
                                                                    (ticketDetails.pofilepath != "" && ticketDetails.pofilepath != "NA" && ticketDetails.poavailable)
                                                                        ?
                                                                        <a href={ticketDetails.pofilepath} target="_blank">
                                                                            <span className="" >  <img src="images/download.png" width="17" className="" /> </span>

                                                                            <span className="ed-title"><span className="mr-15">Purchase Order </span>
                                                                            </span>
                                                                        </a>
                                                                        :
                                                                        "No Purchase order available."

                                                                }

                                                            </div>


                                                        </div>
                                                    </div>

                                                    <div className="col-md-3  col-sm-5 ">
                                                        <div className="od-col4">
                                                            <div className="ed-top">
                                                                <h4 className="ed-title"><span className="mr-15">Assigned to</span><a href="" className="od-edit">
                                                                    {/* <i className="fa fa-pencil"></i> */}
                                                                </a></h4>
                                                                <div className="ord-assigned">
                                                                    {
                                                                        InvoicesDetails.assignedtoProfilePic != "" && InvoicesDetails.assignedtoProfilePic != "NA"
                                                                        &&
                                                                        <img src={InvoicesDetails.assignedtoProfilePic} className="user-icon" />
                                                                    }

                                                                    <div className="user-detl">
                                                                        <p className="name">{InvoicesDetails.assignedtoName} </p>
                                                                        <span className="phone">{InvoicesDetails.assignedtoPhone}</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="asmt-status-bar ">
                                                                <span className="ass-to mr-15">Status </span>
                                                                <br />
                                                                <span className="blue-dot"><i className="fa fa-circle"></i></span>
                                                                {/* <span className="dot-status ed-title"><span className="mr-15">{InvoicesDetails.currentstatus}</span> <a href="" className="od-edit">
                                                                {/* <i className="fa fa-pencil"></i> */}
                                                                <span className="dot-status ed-title"><span className="mr-15">{InvoicesDetails.currentstatus}  ({InvoicesDetails.currentstatusactivity})</span> <a style={{ cursor: 'pointer' }} onClick={() => showStatusUpdate()} className="od-edit"><i className="fa fa-pencil"></i></a></span>


                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-3  col-sm-7">
                                                        <div className="">
                                                            <div className="order-box-desc">
                                                                <h4 className="mr-15">{InvoicesDetails.customername} </h4>
                                                                <a className="btn btn-gray btn-sm">{InvoicesDetails.customertype}</a>
                                                                <h5>{InvoicesDetails.address}</h5>
                                                                <p><img src="images/person.png" />{InvoicesDetails.contactname} </p>
                                                                <p><img src="images/phone.png" /> {InvoicesDetails.contactphone} </p>
                                                                <p><img src="images/email.png" /> {InvoicesDetails.contactemail} </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="activity-title">
                                        <h4 className="act-heading">Activities</h4>
                                    </div>
                                    <div className="box box-solid activitycontainer">
                                        <div className="box-body">
                                            <div className="line">
                                                <div className="line-content">
                                                    {
                                                        ticketDetails.activities != null && ticketDetails.activities != undefined
                                                            &&
                                                            ticketDetails.activities.length == 0
                                                            ?
                                                            "No Record Found."
                                                            :
                                                            ticketDetails.activities != null
                                                            &&
                                                            ticketDetails.activities.map(item => (

                                                                <div className="timeline-content">
                                                                    {item.activitytype == "Notes" &&
                                                                        <div className="timeline-text ">
                                                                            <h1>{item.activity}</h1>
                                                                            <p>{item.activityday}, {item.activitydate} {item.activitytime} </p>
                                                                        </div>
                                                                    }

                                                                    {item.activitytype == "Photos" &&
                                                                        <div className="timeline-text " >
                                                                            <h1>{item.activity}</h1>
                                                                            <p>{item.activityday}, {item.activitydate} {item.activitytime} </p>
                                                                        </div>
                                                                    }

                                                                    {item.activitytype == "Estimate" &&
                                                                        <div className="timeline-text " >
                                                                            <h1>{item.activity}</h1>
                                                                            <p>{item.activityday}, {item.activitydate} {item.activitytime} </p>
                                                                        </div>
                                                                    }

                                                                    {item.activitytype == "Invoice" &&
                                                                        <div className="timeline-text " >
                                                                            <h1>{item.activity}</h1>
                                                                            <p>{item.activityday}, {item.activitydate} {item.activitytime}  </p>
                                                                        </div>
                                                                    }

                                                                    {item.activitytype != "Invoice" && item.activitytype != "Estimate" && item.activitytype != "Photos" && item.activitytype != "Notes" &&
                                                                        <div className="timeline-text">
                                                                            <h1>{item.activity}</h1>
                                                                            <p>{item.activityday}, {item.activitydate} {item.activitytime}</p>
                                                                        </div>
                                                                    }
                                                                </div>


                                                            ))
                                                    }


                                                </div>
                                            </div>
                                            {/* <div className="actaddbtn">
                                            <a href="">ADD</a>
                                        </div> */}

                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-9">
                                    <div className="col-md-12">
                                        <div className="top-btn-group " style={{ margin: 0 }}>
                                            <div className="search-right">
                                                <div className="serch-fild top-serching">
                                                    <input type="text" className="form-control ih36" placeholder="Search Services.." onChange={(e) => SearchCustomer(e)} />
                                                    <i className="fa fa-search"></i>
                                                </div>
                                                <div className="search-suggestion " id="searchresultarea" style={{ zIndex: '9999' }}>
                                                    <div className="searchdiv" >
                                                        {
                                                            serviceList.length == 0
                                                                ?
                                                                null
                                                                :
                                                                serviceList.map(item => (
                                                                    <div className="search-list" style={{ cursor: 'pointer' }}>
                                                                        <h5>{item.serviceName}</h5>
                                                                        <p>${item.serviceAmount}</p>
                                                                    </div>
                                                                ))
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box box-solid ">
                                        <div className="box-body act-body">
                                            <div className="act-box-heading">
                                                <h4 className="act-title">Invoice Details</h4>
                                                <div className="invoice-box-desc">
                                                    <div className="col-md-3">
                                                        <h5>Invoice Date</h5>
                                                        <p className="act-time">
                                                            <span className="dot-status ed-title">
                                                                <span className="mr-15">
                                                                    <div style={{ display: "inline" }}>{InvoicesDetails.invoicedate}</div>
                                                                </span>
                                                                <a style={((userType === "Accounts" || userType === "Admin"))?{ display: "inline", cursor: 'pointer', marginLeft: "5px" }:{display:"none"}} onClick={(e) => editinvoicedate(InvoicesDetails.invoicedate, InvoicesDetails.invoiceduedate,InvoicesDetails.invoicenumber)} className="od-edit"><i className="fa fa-pencil"></i></a>
                                                            </span>
                                                        </p>

                                                    </div>
                                                    <div className="col-md-3">
                                                        <h5>Due Date</h5>
                                                        <div className="act-time">
                                                            <span className="dot-status ed-title">
                                                                <span className="mr-15">
                                                                    <div style={{ display: "inline" }}>{InvoicesDetails.invoiceduedate}</div>
                                                                </span>
                                                                <a style={(userType === "Accounts" || userType === "Admin")?{ display: "inline", cursor: 'pointer', marginLeft: "5px" }:{display:"none"}} onClick={(e) => editinvoiceduedate(InvoicesDetails.invoiceduedate, InvoicesDetails.invoicedate,InvoicesDetails.invoicenumber)} className="od-edit"><i className="fa fa-pencil"></i></a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <h5>Invoice No.</h5>
                                                        <div className="act-time">
                                                            <span className="dot-status ed-title">
                                                                <span className="mr-15">
                                                                    <div style={{ display: "inline" }}>{InvoicesDetails.invoicenumber}</div>
                                                                </span>
                                                                <a style={(userType === "Accounts" || userType === "Admin")?{ display: "inline", cursor: 'pointer', marginLeft: "5px" }:{display:"none"}} onClick={(e) => editinvoiceno(InvoicesDetails.invoiceduedate, InvoicesDetails.invoicedate,InvoicesDetails.invoicenumber)} className="od-edit"><i className="fa fa-pencil"></i></a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">

                                                        {
                                                            ((InvoicesDetails.status == "Finalized" || InvoicesDetails.status == "Submitted") && isMailSent_invoice == false && (userType === "Accounts" || userType === "Admin"))
                                                            &&
                                                            <a onClick={() => sendMail()} className="btn btn-default" style={{ margin: "0px 10px 0px 0px" }}>Send Mail</a>
                                                        }
                                                        {
                                                            ((InvoicesDetails.status == "Finalized" || InvoicesDetails.status == "Submitted") && isMailSent_invoice == true && (userType === "Accounts" || userType === "Admin"))
                                                            &&
                                                            <a onClick={() => reSendMail()} className="btn btn-default" style={{ margin: "0px 10px 0px 0px" }}>Resend Mail</a>
                                                        }
                                                        {
                                                            InvoicesDetails.downloadpath != ""
                                                            &&
                                                            <a className="btn btn-default" href={InvoicesDetails.downloadpath} target="_blank" style={{ margin: "0px 10px 0px 0px" }}>Download</a>
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="mt30">
                                                <div className="row estinvform">
                                                    <div className="col-md-6">
                                                        <div className="estsub-title">
                                                            <h4 className="esttitle mt0">Material</h4>
                                                            <div className="est-addbtn">
                                                                {InvoicesDetails.status != "Paid" &&
                                                                    <a style={{ cursor: 'pointer' }} onClick={(e) => addMoreMaterialRow_invoice(e)} className="btn btn-gray btn-sm"><i className="fa fa-plus"></i> &nbsp; Add</a>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div>
                                                                <div className="col-md-5 pright0">
                                                                    <div className="form-group">
                                                                        Title
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 pright0">
                                                                    <div className="form-group">
                                                                        Qty
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        Unit Price
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        Amount
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {
                                                                InvoicesDetails.invoicedata != null &&
                                                                InvoicesDetails.invoicedata.length > 0
                                                                &&
                                                                InvoicesDetails != null &&
                                                                InvoicesDetails.invoicedata.filter(x => x.type == "Material").map(item => (
                                                                    <div>
                                                                        <div className="col-md-5">
                                                                            <div className="form-group">
                                                                                <input type="text" className="form-control" value={item.item} placeholder="" onChange={(e) => handleChange_TextValue_item_invoice(item, e)} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-3 pright0">
                                                                            <div className="form-group">
                                                                                <input type="text" className="form-control" value={item.qty} placeholder="Quantity" onChange={(e) => handleChange_TextValue_qty_material(item, e)} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-2 pright0">
                                                                            <div className="form-group">
                                                                                <input type="text" className="form-control right" value={item.unitprice} placeholder="Unit Price" onChange={(e) => handleChange_TextValue_UnitPrice_material(item, e)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <div className="form-group itemamount">
                                                                                <input type="text" className="form-control right" disabled value={item.amount} placeholder="Total Amt" />
                                                                                {InvoicesDetails.status != "Paid" &&
                                                                                    <a className="removeitem" onClick={() => removeRow(item)} ><img src="images/remove.svg" alt="" /></a>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }


                                                            <div className="col-md-6 pright0">

                                                            </div>
                                                            <div className="col-md-3 pright0">
                                                                <h4 className="total-right">Total</h4>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <input type="text" className="form-control right" disabled value={InvoicesDetails.amountMaterial} placeholder="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="estsub-title">
                                                            <h4 className="esttitle mt0">Labor</h4>
                                                            <div className="est-addbtn">
                                                                {InvoicesDetails.status != "Paid" &&
                                                                    <a style={{ cursor: 'pointer' }} onClick={(e) => addMoreLaborRow_invoice(e)} className="btn btn-gray btn-sm"><i className="fa fa-plus"></i> &nbsp; Add</a>

                                                                }    </div>
                                                        </div>
                                                        <div className="row">
                                                            <div>
                                                                <div className="col-md-5 pright0">
                                                                    <div className="form-group">
                                                                        Title
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 pright0">
                                                                    <div className="form-group">
                                                                        Qty
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        Unit Price
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        Amount
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {
                                                                InvoicesDetails.invoicedata != null &&
                                                                InvoicesDetails.invoicedata.length > 0
                                                                &&
                                                                InvoicesDetails.invoicedata.filter(x => x.type == "Labor").map(item => (
                                                                    <div>
                                                                        <div className="col-md-5">
                                                                            <div className="form-group">
                                                                                <input type="text" className="form-control" value={item.item} placeholder="" onChange={(e) => handleChange_TextValue_item_invoice(item, e)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3 pright0">
                                                                            <div className="form-group">
                                                                                <input type="text" className="form-control" value={item.qty} placeholder="" onChange={(e) => handleChange_TextValue_qty_labor(item, e)} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-2 pright0">
                                                                            <div className="form-group">
                                                                                <input type="text" className="form-control right" value={item.unitprice} placeholder="Unit Price" onChange={(e) => handleChange_TextValue_UnitPrice_labor(item, e)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <div className="form-group itemamount">
                                                                                <input type="text" className="form-control right" disabled value={item.amount} placeholder="" />
                                                                                {InvoicesDetails.status != "Paid" &&
                                                                                    <a className="removeitem" onClick={() => removeRow(item)} ><img src="images/remove.svg" alt="" /></a>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }


                                                            <div className="col-md-6 pright0">

                                                            </div>
                                                            <div className="col-md-3 pright0">
                                                                <h4 className="total-right">Total</h4>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <input type="text" className="form-control right" value={InvoicesDetails.amountLabour} disabled placeholder="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div>
                                                            <div className="od-notes">
                                                                <h4 className="ed-title"><img src="images/notes.png" /><span className="mr-15">Notes </span></h4>

                                                                <textarea rows="4" cols="64" value={InvoicesDetails.notes} onChange={(e) => handlenotes_invoice(e.target.value)}   ></textarea>
                                                                <br />
                                                                <div className="od-photos">
                                                                    <h4 className=""><img src="images/photo-camera.png" />Photos </h4>
                                                                    <div className="od-photos-list">

                                                                        {
                                                                            InvoicesDetails?.photos?.length == 0 ?
                                                                                "No Photos Available."
                                                                                :
                                                                                // photosList != null &&
                                                                                InvoicesDetails?.photos?.map(item => (
                                                                                    <a onClick={() => SelectImage_invoice(item)} style={{ cursor: 'pointer' }} >
                                                                                        <img src={item.photopath} className={item.selected ? "selectedphoto" : ""} />
                                                                                    </a>
                                                                                ))
                                                                        }


                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="total-count">
                                                            {/* <div className="total-count-list">
                                                                <h5 className="list-name">PO Number</h5>
                                                                <input type="text" className="list-amount" style={{ width: '100px' }} value={poNumber} onChange={(e) => setPoNumber(e.target.value)} />
                                                            </div> */}
                                                            <div className="total-count-list">
                                                                <h5 className="list-name">Total Material</h5>
                                                                <h5 className="list-amount">{InvoicesDetails.amountMaterial}</h5>
                                                            </div>
                                                            <div className="total-count-list">
                                                                <h5 className="list-name">Total Labor</h5>
                                                                <h5 className="list-amount">{InvoicesDetails.amountLabour}</h5>
                                                            </div>

                                                            <div className="total-count-list">
                                                                <h5 className="list-name tax">Material Taxes</h5>
                                                                {/* <h5 className="list-amount">{InvoicesDetails.amountTax}</h5> */}
                                                                <input type="text" className="list-amount" style={{ width: '50px' }} value={InvoicesDetails.amountTaxMaterial} disabled />
                                                                <input type="text" disabled={InvoicesDetails.status == "Paid" ? true : false} className="list-amount nofloat" max="100" placeholder="%" style={{ width: '50px' }} value={InvoicesDetails.amountTaxMaterialRate} onChange={(e) => handleChange_tax_material_invoice(e)} />%

                                                            </div>
                                                            <div className="total-count-list">
                                                                <h5 className="list-name tax">Labor Taxes</h5>
                                                                {/* <h5 className="list-amount">{InvoicesDetails.amountTax}</h5> */}
                                                                <input type="text" className="list-amount" style={{ width: '50px' }} value={InvoicesDetails.amountTaxLabour} disabled />

                                                                <input type="text" disabled={InvoicesDetails.status == "Paid" ? true : false} className="list-amount nofloat" placeholder="%" max="100" style={{ width: '50px' }} value={InvoicesDetails.amountTaxLabourRate} onChange={(e) => handleChange_tax_labor_invoice(e)} />%

                                                            </div>
                                                            <div className="total-count-list">
                                                                <h5 className="list-name tax">Discounts </h5>
                                                                <input type="text" className="list-amount" style={{ width: '50px' }} disabled value={InvoicesDetails.amountDiscount} />
                                                                {InvoicesDetails.status == "Paid" ?
                                                                    <><input type="text" className="list-amount nofloat txtdisable" disabled value={InvoicesDetails.discountpercentage} onChange={(e) => handleChange_disc_percentage_invoice(e)} />%</>
                                                                    :
                                                                    <><input type="text" className="list-amount nofloat txtdisable" disabled={ticketDetails.customertype == "Preferred" ? false : true} max="100" placeholder="%" style={{ width: '50px' }} value={InvoicesDetails.discountpercentage} onChange={(e) => handleChange_disc_percentage_invoice(e)} />%</>

                                                                }
                                                            </div>

                                                            <div className="total-count-list">
                                                                <h5 className="list-name"><b>Total Due</b></h5>
                                                                <h5 className="list-amount"><b>{InvoicesDetails.amountPayable}</b></h5>
                                                            </div>
                                                        </div>
                                                        {/* stutus {InvoicesDetails.status} */}
                                                        <div className="topbtn-right mb-20">
                                                            {/* {
                                                                isAddNewInvoice
                                                                    ?
                                                                    <a style={{ cursor: 'pointer' }} onClick={(e) => draftInvoice(e)} className="btn btn-theme" >Draft</a>
                                                                    :
                                                                    <a style={{ cursor: 'pointer' }} onClick={(e) => finalizeInvoice(e)} className="btn btn-theme" >Finalize</a>

                                                            } */}


                                                            {
                                                                (InvoicesDetails.status == "" || InvoicesDetails.status == null || InvoicesDetails.status == undefined)
                                                                &&
                                                                <a style={{ cursor: 'pointer' }} onClick={(e) => draftInvoice(e)} className="btn btn-theme" >Save</a>
                                                            }
                                                            {
                                                                InvoicesDetails.status == "Draft"
                                                                &&
                                                                <a style={{ cursor: 'pointer' }} onClick={(e) => finalizeInvoice(e)} className="btn btn-theme" >Save</a>
                                                            }

                                                            {
                                                                InvoicesDetails.status == "Finalized"
                                                                &&
                                                                <a style={{ cursor: 'pointer' }} onClick={(e) => updateInvoice(e)} className="btn btn-theme" >Save</a>
                                                            }

                                                            {
                                                                (InvoicesDetails.status == "Submitted")
                                                                &&
                                                                <a style={{ cursor: 'pointer' }} onClick={(e) => updateInvoice(e)} className="btn btn-theme" >Save</a>
                                                                // <a style={{ cursor: 'pointer' }} onClick={(e) => PaidInvoice(e)} className="btn btn-theme" >Submit</a>
                                                            }
                                                            {
                                                                (InvoicesDetails.status == "Paid")
                                                                &&
                                                                null
                                                                // <a style={{ cursor: 'pointer' }} onClick={(e) => updateInvoice(e)} className="btn btn-theme" >Save</a>
                                                                // <a style={{ cursor: 'pointer' }} onClick={(e) => PaidInvoice(e)} className="btn btn-theme" >Submit</a>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <StatusUpdate ticketid={ticketid} selectedStatusId_prop={selectedStatusId} loadagain={loadInvoice} loadTicketDetails={loadTicketDetails} whichPage="Invoice" />
            <DateUpdate ticketid={ticketid} activityid={activityid} loadInvoice={loadInvoice} invoicedate1={invoicedate} invoiceduedate1={invoiceduedate} invoiceno={invoiceno}/>
            <DueDateUpdate ticketid={ticketid} activityid={activityid} loadInvoice={loadInvoice} invoicedate1={invoicedate} invoiceduedate1={invoiceduedate} invoiceno={invoiceno}/>
            <Invoicenoupdate ticketid={ticketid} activityid={activityid} loadInvoice={loadInvoice} invoicedate1={invoicedate} invoiceduedate1={invoiceduedate} invoiceno1={invoiceno}/>

        </>
    )
}

export default InvoiceDetails
