import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import ColorPicker from 'react-color-picker'

import 'react-color-picker/index.css'

const ApiBaseURL = process.env.REACT_APP_API_BASE_URL;
const CreateUpdateUser = ({ screentype, CreateUser_submit, UpdateUser_submit, name, setName, phone, setPhone, email, setEmail, password, setPassword, userType, setUserType, setAllowAppLogin, allowAppLogin, image, setImage, color, setColor }) => {
    let loggeduserid = localStorage.getItem("userid");

    const image_upload = async (e) => {

        try {
            var uploadimage = [];
            uploadimage = e.target.files;
            if (uploadimage == null)
                return alert(JSON.stringify(e));

            const formData = new FormData();
            var today = new Date();
            var doctype = "";
            const ext = uploadimage[0].name.split('.').pop();
            const datevalue = today.getDate() + today.getMonth() + today.getFullYear() + today.getHours() + today.getMinutes() + today.getSeconds();
            const filename = "profilepic_" + datevalue + "." + ext;
            if (ext == "png" || ext == "jpeg" || ext == "jpg" || ext == "PNG" || ext == "JPEG" || ext == "JPG") {
                doctype = "Image";
            }
            else
                return alert('Please select valid image.');
            formData.append("uploadedby", loggeduserid);
            formData.append("uploadedfrom", "web");
            formData.append("filename", filename);
            formData.append("file", uploadimage[0]);
            const requestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
                },
                body: formData
            }
            const response = await fetch(ApiBaseURL + 'upload', requestOptions);
            const result = await response.json();
            if (response.ok) {
                setImage(result.filepath)
            }
            else if (response.status === 400) {
                console.log(result.errors)
                toast.error(result.errors)
            }
            else if (response.status === 401) {
                toast.error('Your Session has been expired, Please login again.');
                window.setTimeout(function () {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }, 2000);
            }
            else {

            }

        } catch (error) {
            toast.error('Something went wrong , Please try again later.')
        }

    }

    return <div className="modal fade fsm-popup" id="AddUser">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <a type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <img src="images/close.png" /></a>
                    <h5 className="modal-title">{screentype == "Create" ? "Add" : "Update"} User</h5>
                </div>
                <div className="modal-body">
                    <div className="add-custmer-form ">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <select className="form-control" value={userType} onChange={e => setUserType(e.target.value)}>
                                        <option value="">User Type</option>
                                        <option value="Admin">Admin</option>
                                        <option value="Supervisor">Supervisor</option>
                                        <option value="Accounts">Accounts</option>
                                        <option value="Technician">Technician</option>
                                        <option value="Dispatcher">Dispatcher</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="">
                                        <input type="text" className="form-control" placeholder="Name" maxLength="20" value={name} onChange={e => setName(e.target.value)} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="">
                                        <input type="text" className="form-control" placeholder="Phone" value={phone} maxLength="10" onChange={e => setPhone(e.target.value.replace(/\D/, ''))} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="">
                                        <input type="email" className="form-control" placeholder="Email" maxLength="50" value={email} onChange={e => setEmail(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="">
                                        <input type="file" accept="image/*" id="img_profile_setting" onChange={(e) => image_upload(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className='col-md-4'>
                                    <label>Color Code</label></div>
                                <div className='col-md-3'>
                                    <div className="form-group">
                                        <input type="color" className='form-control' value={color} onChange={e => setColor(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            {
                                screentype == "Create" &&
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Password" maxLength="20" value={password} onChange={e => setPassword(e.target.value)} />
                                    </div>
                                </div>
                            }

                            <div className="col-md-12">
                                <div className="col-md-6">

                                    <input type="checkbox" id="vehicle1" name="vehicle1" value={allowAppLogin} checked={allowAppLogin ? true : false} onChange={() => setAllowAppLogin(!allowAppLogin)} />
                                    &nbsp;<label for="vehicle1"> Allow app login </label>
                                </div>

                                <div className="col-md-2">
                                    {
                                        screentype == "Create"
                                            ?
                                            <a type="submit" className="btn btn-theme pull-right" onClick={CreateUser_submit} >Save </a>
                                            :
                                            <a type="submit" className="btn btn-theme pull-right" onClick={UpdateUser_submit} >Update </a>
                                    }
                                </div>
                                <div className="col-md-2" style={{ marginLeft: '10px' }}>
                                    <a type="button" className="btn btn-danger pull-right" data-dismiss="modal" aria-label="Close">Cancel</a>
                                </div>



                            </div>

                            {/* {
                                screentype == "Create"
                                    ?
                                    <div className="col-md-12">
                                        <a type="submit" className="btn btn-theme pull-right" onClick={CreateUser_submit} >Save </a>
                                    </div>

                                    :
                                    <div className="col-md-12">
                                        <a type="submit" className="btn btn-theme pull-right" onClick={UpdateUser_submit} >Update </a>
                                    </div>
                            } */}

                        </div>
                    </div>

                </div>

            </div>
            {/* <!-- /.modal-content --> */}
        </div>
        {/* <!-- /.modal-dialog --> */}
    </div>

}

export default CreateUpdateUser
