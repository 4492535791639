
import React from 'react'
import { Switch, Route, HashRouter } from 'react-router-dom'
import { BrowserRouter } from 'react-router-dom'
import Login from './pages/auth/Login'
import { ToastContainer, Slide } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"
import Users from './pages/admin/Users'
import Customers from './pages/admin/Customers'
import Dashboard from './pages/admin/Dashboard'
import Tickets from './pages/admin/Tickets'
import Invoices from './pages/admin/Invoices'
import Estimates from './pages/admin/Estimates'
import OrderType from './pages/admin/OrderType'
import ResetPassword1 from './pages/auth/ResetPassword1'
import ResetPassword2 from './pages/auth/ResetPassword2'
import ResetPassword3 from './pages/auth/ResetPassword3'
import ResetPassword4 from './pages/auth/ResetPassword4'
import RevenueByTech from './components/reports/RevenueByTech'
import RevenueByCustomer from './components/reports/RevenueByCustomer'
import CollectionReport from './components/reports/CollectionReport'
import TechPerformance from './components/reports/TechPerformance'
import MyProvider from './contextapi/MyProvider'
import PrivateRoute from './privateRoutes/PrivateRoute'
import AdminRoutes from './privateRoutes/AdminRoutes'
import TechUtilization from './components/reports/TechUtilization'
import Services from './pages/admin/Services'
import FullCalendarWidget from './pages/admin/Fullcalender'
import FullCalendarWidget2 from './pages/admin/Fullcalender2'
import TicketDetails from './components/popups/CalendarTicketDetails'
import DashboardAnalytics from './pages/admin/DashboardAnalytics'
import AvgTicketResolTime from './pages/admin/AvgTicketResolTime'
import TechOccupancy from './pages/admin/TechOccupancy'
import RevenueTrend from './pages/admin/RevenueTrend'
import AvgResponseTime from './pages/admin/AverageResponseTime'
import AverageTicketLifecycle from './pages/admin/AverageTicketLifecycle'
import EstimatedActualTime from './pages/admin/EstimatedActualTime'
import AverageRevenuePertech from './pages/admin/AverageRevenuePertech'

import DeletedTickets from './pages/admin/DeletedTickets'          //    added by sonali 01-04-22 feedback



function App() {
  return (
    <MyProvider>
      <div>
        <ToastContainer
          transition={Slide}
          position="top-center"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit={1}
        />

        <BrowserRouter>
          <HashRouter>
            {/* <Switch> */}
            <Route exact path="/" component={Login} />
            <Route exact path="/resetpassword1" component={ResetPassword1} />
            <Route exact path="/resetpassword2" component={ResetPassword2} />
            <Route exact path="/resetpassword3" component={ResetPassword3} />
            <Route exact path="/resetpassword4" component={ResetPassword4} />
            <Route exact path="/calendar" component={FullCalendarWidget} />
            <Route exact path="/calendar2/:userid" component={FullCalendarWidget2} />
            <Route exact path="/ticketdetails/:ticketid" component={TicketDetails} />
            <PrivateRoute exact path="/users" component={Users} />
            <PrivateRoute exact path="/customers" component={Customers} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/tickets/:status?" component={Tickets} />
            <PrivateRoute exact path="/invoices" component={Invoices} />
            <PrivateRoute exact path="/estimates" component={Estimates} />
            <PrivateRoute exact path="/ordertype" component={OrderType} />
            <PrivateRoute exact path="/revenuebytech" component={RevenueByTech} />
            <PrivateRoute exact path="/revenuebycustomer" component={RevenueByCustomer} />
            <PrivateRoute exact path="/collectionreport" component={CollectionReport} />
            <PrivateRoute exact path="/techperformance" component={TechPerformance} />
            <PrivateRoute exact path="/techutilization" component={TechUtilization} />
            <PrivateRoute exact path="/services" component={Services} />
            <PrivateRoute exact path="/analytics" component={DashboardAnalytics} />
            <PrivateRoute exact path="/avg-ticket-resolution-time" component={AvgTicketResolTime} />
            <PrivateRoute exact path="/tech-occupancy" component={TechOccupancy} />
            <PrivateRoute exact path="/revenue" component={RevenueTrend} />
            <PrivateRoute exact path="/average-response-time" component={AvgResponseTime} />
            <PrivateRoute exact path="/average-ticket-life-cycle" component={AverageTicketLifecycle} />
            <PrivateRoute exact path="/estimate-actual-time" component={EstimatedActualTime} />
            <PrivateRoute exact path="/average-revenue-per-tech" component={AverageRevenuePertech} />


            <PrivateRoute exact path="/archive-tickets" component={DeletedTickets} />          {/* added by Sonali 01-04-22 feedback */}
            {/* </Switch> */}
          </HashRouter>
        </BrowserRouter>
      </div >
    </MyProvider>
  );
}

export default App;
