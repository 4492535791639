import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Header from '../nav/Header'
import Sidebar from '../nav/Sidebar'
import { Link } from 'react-router-dom'
import { LoadCustomers_api } from '../../functions/customers'
import { GetReport_CollectionReport_api } from '../../functions/reports'
import moment from 'moment'
import ReactExport from "react-data-export";

function CollectionReport() {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    const [excelDataSet, setExcelDataSet] = useState([]);

    const [period, setPeriod] = useState("This Week");
    const [selectedCustomerID, setSelectedCustomerID] = useState(0);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [customerList, setCustomerList] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [List, setList] = useState([]);

    const [paginationarr, setPaginationarr] = useState([]);
    const [selectedPageNo, setSelectedPageNo] = useState(1);
    let recordshowperpage = 30;

    useEffect(() => {
        loadCustomers();
        loadReport();
    }, [])

    // useEffect(() => {
    //     if (period == "Custom") {
    //         window.$("#dateSelection").modal('show')
    //     }
    // }, [period])

    const selectPeriod = (period) => {
        setPeriod(period)
        if (period == "Custom") {
            window.$("#dateSelection").modal('show')
        }
    }

    const loadCustomers = () => {
        LoadCustomers_api('All', '').then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {
                    setCustomerList([])
                }
                else
                    setCustomerList(result.data);
            }

        }).catch();
    }


    const loadReport = () => {
        var _period = period;
        var _fromdate = fromDate;
        var _todate = toDate;
        var _customerID = selectedCustomerID;

        if (period === "Custom") {
            if (_fromdate == "" || _fromdate == null) {
                return toast.error("Please select from date.")
            }
            else {
                _fromdate = moment(_fromdate).format("YYYY-MM-DD")
            }
            if (_todate == "" || _todate == null) {
                return toast.error("Please select to date.")
            }
            else {
                _todate = moment(_todate).format("YYYY-MM-DD")
            }
        }
        setIsLoaded(false);
        var req = {
            "periodtype": _period,
            "fromdate": _fromdate,
            "todate": _todate,
            "customerid": Number(_customerID)
        }
        GetReport_CollectionReport_api(req).then(res => {
            const result = res;
            if (result != undefined) {
                if (result == null) {
                    setList([])
                }
                else {
                    setList(result)
                    let data = result.data;
                    let dataset = [];
                    if (data.length > 0) {
                        for (var i = 0; i < data.length; i++) {
                            dataset.push({
                                PONumber: data[i].ponumber,
                                InvoiceNumber: data[i].invoicenumber,
                                InvoiceDate: data[i].invoicedate,
                                GrossAmount: data[i].grossamount,
                                NetAmount: data[i].netamount,
                                Technician: data[i].technicianname
                            })
                        }

                    }
                    {
                        dataset.push({
                            PONumber: "",
                            InvoiceNumber: "",
                            InvoiceDate: "",
                            GrossAmount: "",
                            NetAmount: "",
                            Technician: ""
                        })
                    }
                    setExcelDataSet(dataset)

                    // var data = result.data;
                    if (data.length > recordshowperpage) {
                        var totalpage = data.length / recordshowperpage;
                        var reminder = data.length % recordshowperpage;
                        var pages = [];
                        for (var i = 1; i <= totalpage; i++) {
                            pages.push(i);
                        }
                        if (reminder == 0) {
                            //pages = pages;
                        }
                        else {
                            pages.push(pages.length + 1)
                        }
                        setPaginationarr(pages);
                    }
                }
            }
            else {
                setPaginationarr([]);
            }
            setIsLoaded(true);
        }).catch();
    }


    return (
        <div>
            <div className="hold-transition skin-black sidebar-mini">
                <div className="wrapper">
                    <Header />
                    <Sidebar />
                    <div className="content-wrapper">
                        {/* <section className="content content-body"> */}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="reports">
                                    <ul>
                                        <li><Link to="revenuebytech">Revenue by Tech</Link></li>
                                        <li><Link to="revenuebycustomer">Revenue by Customer</Link></li>
                                        <li className="active_report"><Link to="collectionreport">Collection Report</Link></li>
                                        {/* <li><Link to="techperformance">Tech Performance</Link></li> */}
                                        <li><Link to="techutilization">Tech Utilization</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* </section> */}
                        <div className="filterbox">
                            <div className="">
                                <div className="boxmargin">
                                    <div className="box box-solid box-height">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group row">
                                                    <label for="colFormLabel" className="col-sm-4 col-form-label">Period</label>
                                                    <div className="col-sm-8">
                                                        <select className="form-control" onChange={(e) => selectPeriod(e.target.value)}>
                                                            <option>This Week</option>
                                                            <option>This Month</option>
                                                            <option>This Year</option>
                                                            <option>Custom</option>
                                                        </select>
                                                    </div>
                                                </div>


                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group row">
                                                    <label for="colFormLabel" className="col-sm-4 col-form-label">Customers</label>
                                                    <div className="col-sm-8">
                                                        <select className="form-control" value={selectedCustomerID} onChange={(e) => setSelectedCustomerID(e.target.value)}>
                                                            <option value="0">All</option>
                                                            {
                                                                customerList.map(item => (
                                                                    <option value={item.customerid}>{item.customername}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <div className="form-group row">
                                                    <div className="col-sm-3">
                                                        <button className="btn btn-primary" onClick={() => loadReport()}>View</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <div className="form-group row">
                                                    <div className="col-sm-3">
                                                        <ExcelFile filename="collections" element={<button className="btn btn-primary">Export to Excel</button>} >
                                                            <ExcelSheet data={excelDataSet} name="Collections" >
                                                                <ExcelColumn label="PO Number" value="PONumber" />
                                                                <ExcelColumn label="Invoice Number" value="InvoiceNumber" />
                                                                <ExcelColumn label="Invoice Date" value="InvoiceDate" />
                                                                <ExcelColumn label="Gross Amount" value="GrossAmount" />
                                                                <ExcelColumn label="Net Amount" value="NetAmount" />
                                                                <ExcelColumn label="Technician" value="Technician" />
                                                            </ExcelSheet>
                                                        </ExcelFile>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            period == "Custom"
                                            &&
                                            <div>
                                                <b>From - </b> {fromDate != "" && moment(fromDate).format("DD MMM YYYY")} &nbsp;&nbsp; <b>To -  </b>{toDate != "" &&  moment(toDate).format("DD MMM YYYY")}
                                                &nbsp; &nbsp;<i className="fa fa-pencil" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => window.$("#dateSelection").modal('show')}></i>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="rptcontainer">

                            {
                                isLoaded == false ? <div className="loader"><img src="images/loader.gif" alt="" /></div>
                                    :
                                    <>
                                        <div className="customertbl">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th colspan="3">{List.customername}</th>
                                                    <th className="alignright">${List.totalGrossAmount}</th>
                                                    <th className="alignright">${List.totalNetAmount}</th>
                                                    <th className="alignright"></th>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th>PO Number</th>
                                                    <th>Invoice Number</th>
                                                    <th>Invoice Date</th>
                                                    <th className="alignright">Gross Amount</th>
                                                    <th className="alignright">Net Amount</th>
                                                    <th className="alignright">Tech</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    List.data != undefined &&
                                                    <>
                                                        {
                                                            List.data.length == 0
                                                                ?
                                                                "No Record Found."
                                                                :
                                                                List.data.slice(selectedPageNo * recordshowperpage - recordshowperpage, selectedPageNo * recordshowperpage).map((item, index) => (

                                                                    <tr>
                                                                        <td>{item.ponumber}</td>
                                                                        <td>{item.invoicenumber}</td>
                                                                        <td>{item.invoicedate}</td>
                                                                        <td className="alignright">${item.grossamount}</td>
                                                                        <td className="alignright">${item.netamount}</td>
                                                                        <td className="alignright">{item.technicianname}</td>
                                                                    </tr>
                                                                ))
                                                        }
                                                    </>
                                                }
                                            </tbody>
                                        </table>
                                        </div>
                                    </>
                            }
                            <ul className="pagination pull-right mt0">
                                {
                                    paginationarr.length > 0 &&
                                    paginationarr.map(item => (
                                        <li class={`paginate_button ${item == selectedPageNo && "active"}`}><a style={{ cursor: 'pointer' }} onClick={() => setSelectedPageNo(item)}>{item}</a></li>
                                    ))
                                }
                            </ul>
                            <div className="clearfix"></div>         
                        </div>
                        <div className="clearfix"></div>         
                    </div>
                </div>
            </div>

            <div className="modal fade bd-example-modal-sm" id="dateSelection" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <div className="dateselectionModal">
                            <div className="form-group row">
                                <label for="colFormLabel" className="col-sm-4 col-form-label">From </label>
                                <div className="col-sm-8">
                                    <input type="date" className="form-control" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="colFormLabel" className="col-sm-4 col-form-label">To </label>
                                <div className="col-sm-8">
                                    <input type="date" className="form-control" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                                </div>
                            </div>
                            <div className="row">
                                <button className="btn btn-primary" onClick={() => window.$("#dateSelection").modal('hide')}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CollectionReport
