import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Header from '../../components/nav/Header'
import Sidebar from '../../components/nav/Sidebar'
import { Link } from 'react-router-dom'
import PieChartResponseTime from '../../Charts/PieChartResponseTime'
import { GetReport_AvgResponseTime_api } from '../../functions/reports'
import { LoadOrderType_api } from '../../functions/orderType'
import moment from 'moment'
import ReactExport from "react-data-export";

function AvgResponseTime() {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    const [excelDataSet, setExcelDataSet] = useState([]);

    const [paginationarr, setPaginationarr] = useState([]);
    const [selectedPageNo, setSelectedPageNo] = useState(1);

    const [period, setPeriod] = useState("This Year");

    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [List, setList] = useState([]);

    const [PieChartData, setPieChartData] = useState({ part1: 0, part2: 0,})
    let recordshowperpage = 30;

    useEffect(() => {
        loadDataList();
    }, [])

    const loadDataList = () => {
        setIsLoaded(false)
        GetReport_AvgResponseTime_api(period).then(res => {
            if (res !== undefined) {
                setData(res)
                setPieChartData({part2:res.entryToScheduled,part1:res.scheduledToVisit})
                if (res.tbldata.length > 0) {
                    setList(res.tbldata)
                }
                else {
                    setList([])
                }
                setIsLoaded(true)
            }
        }).catch();
    }
    const loadReport = () => {
        loadDataList();
    }



    // const loadReport = () => {
    //     var _period = period;
    //     var _fromdate = fromDate;
    //     var _todate = toDate;
    //     var _technicialID = (selectedTechnicianID == undefined || selectedTechnicianID == null) ? 0 : selectedTechnicianID;
    //     if (period === "Custom") {
    //         if (_fromdate == "" || _fromdate == null) {
    //             return toast.error("Please select from date.")
    //         }
    //         else {
    //             _fromdate = moment(_fromdate).format("YYYY-MM-DD")
    //         }
    //         if (_todate == "" || _todate == null) {
    //             return toast.error("Please select to date.")
    //         }
    //         else {
    //             _todate = moment(_todate).format("YYYY-MM-DD")
    //         }
    //     }
    //     setIsLoaded(false);
    //     var req = {
    //         "periodtype": _period,
    //         "fromdate": _fromdate,
    //         "todate": _todate,
    //         "techid": Number(_technicialID)
    //     }
    //     Get(req).then(res => {
    //         const result = res;
    //         if (result != undefined) {
    //             if (result == null) {
    //                 setList([])
    //             }
    //             else {
    //                 setList(result)
    //                 let data = result.data;
    //                 let dataset = [];
    //                 if (data.length > 0) {
    //                     for (var i = 0; i < data.length; i++) {
    //                         if (data[i].invoices != undefined && data[i].invoices != null) {
    //                             for (var j = 0; j < data[i].invoices.length; j++) {
    //                                 dataset.push(
    //                                     {
    //                                         TechnicianID: data[i].technicianID,
    //                                         Technician: data[i].technicianname,
    //                                         Revenue: data[i].revenue,
    //                                         Customer: data[i].invoices[j].customername,
    //                                         ponumber: data[i].invoices[j].ponumber,
    //                                         amount: data[i].invoices[j].invoiceamt
    //                                     }
    //                                 )
    //                             }
    //                         }
    //                     }
    //                 }
    //                 {
    //                     dataset.push({
    //                         TechnicianID: 0,
    //                         Technician: "",
    //                         Revenue: "",
    //                         Customer: "",
    //                         ponumber: "",
    //                         amount: 0
    //                     })
    //                 }
    //                 setExcelDataSet(dataset);
    //                 console.log("Dataset", dataset)
    //                 //var data = result.data;
    //                 if (data.length > recordshowperpage) {
    //                     var totalpage = data.length / recordshowperpage;
    //                     var reminder = data.length % recordshowperpage;
    //                     var pages = [];
    //                     for (var i = 1; i <= totalpage; i++) {
    //                         pages.push(i);
    //                     }
    //                     if (reminder == 0) {
    //                         //pages = pages;
    //                     }
    //                     else {
    //                         pages.push(pages.length + 1)
    //                     }
    //                     setPaginationarr(pages);
    //                 }
    //             }
    //         }
    //         else {
    //             setPaginationarr([]);
    //         }

    //         setIsLoaded(true);
    //     }).catch();
    // }

    return (
        <div>
            <div className="hold-transition skin-black sidebar-mini">
                <div className="wrapper">
                    <Header />
                    <Sidebar />
                    <div className="content-wrapper db-background">
                        <div className="">
                            <div style={{ paddingTop: 30 }}>
                                <div className="filterbox">
                                    <div className="boxmargin">
                                        <div className="box box-solid box-height">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3">
                                                    <div className="form-group row">
                                                        <label for="colFormLabel" className="col-lg-3 col-sm-3 col-form-label">Period</label>
                                                        <div className="col-lg-9 col-sm-9">

                                                            <select className="form-control" value={period} onChange={(e) => setPeriod(e.target.value)}>
                                                                <option value="This Year">This Year</option>
                                                                <option value="Previous Year">Previous Year</option>
                                                                <option value="This Month">This Month</option>
                                                                <option value="Previous Month">Previous Month</option>
                                                                <option value="This Week">This Week</option>
                                                                <option value="Previous Week">Previous Week</option>
                                                            </select>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="fliterbtn">
                                                        <div className="inlinebtn">
                                                            <button className="btn btn-theme" onClick={() => loadReport()}>View</button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
{isLoaded?
                        <div className="chartcontainer">
                            <div className="row">
                                <div className="col-md-12"><h4 className="box-title">Average Response Time for {period.toLowerCase()} is {data.avgResponseTime}</h4></div>
                                <div className="col-md-8">
                                    <div className='box box-solid'>
                                        <div className="box-body table-responsive no-padding userlist">
                                            <table className="table table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <th>Ticket ID</th>
                                                        <th>Customer</th>
                                                        <th>Order Type</th>
                                                        <th>Ticket Date</th>
                                                        <th>Entry to Scheduled </th>
                                                        <th>Schedule to Visit</th>
                                                        <th>Response Time</th>
                                                    </tr>
                                                    {List.length > 0 ?
                                                        List.map(item => (
                                                            <tr>
                                                                <td>{item.ticketno}</td>
                                                                <td>{item.customername}</td>
                                                                <td>{item.ordertype}</td>
                                                                <td>{item.ticketdate}</td>
                                                                <td>{item.entryToScheduled}</td>
                                                                <td>{item.scheduledToVisit}</td>
                                                                <td>{item.responseTime}</td>
                                                            </tr>

                                                        ))
                                                        : "No Record Found"
                                                    }

                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="box box-solid" style={{height:"200px"}}>
                                        Entry
                                            <PieChartResponseTime lable={["Entry to Scheduled","Schedule to Visit"]} data={PieChartData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <div className="loader"><img src="images/loader.gif" alt="" /></div>}
                    </div>
                </div>
            </div>

           
        </div>
    )
}

export default AvgResponseTime
