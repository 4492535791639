import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Header from '../../components/nav/Header'
import Sidebar from '../../components/nav/Sidebar'
import DoubleLineBasic from '../../Charts/DoubleLineChart'
import { Link } from 'react-router-dom'
import { GetRevenueTrend_api } from '../../functions/analytics'
import moment from 'moment'
import ReactExport from "react-data-export";

function RevenueTrend() {


    const [period, setPeriod] = useState("this month");
    const [title, setTitle] = useState("")

    const [xAsisData, setxAxisData] = useState([])
    const [lineChartData, setLineChartData] = useState({ currentdata: [], prevdata: [], label1: "", label2: "" })
    const [lineChartTitle, setLineChartTitle] = useState({ xAxis: "Months", yAxis: "Revenue" })
    const [total, setTotal] = useState({ currentTotal: 0, prevTotal: 0 })


    const [isLoaded, setIsLoaded] = useState(false);
    const [List, setList] = useState([]);
    let recordshowperpage = 30;

    useEffect(() => {
        loadRevenueTrend();
    }, [period])

    const loadRevenueTrend = () => {
        GetRevenueTrend_api(period).then(res => {
            if (res !== undefined) {

                var dt = new Date();
                var currentyear = dt.getFullYear();
                var prevyear = currentyear - 1;
                var data = res;
                var label_data = [];
                var thisyear_data = [];
                var prevyear_data = [];
                var currenttotal = 0;
                var prevtotal = 0;
                data.forEach(item => {
                    const val1 = item.series1Revenue !== null ? item.series1Revenue.toFixed(2) : 0
                    const val2 = item.series2Revenue !== null ? item.series2Revenue.toFixed(2) : 0
                    currenttotal = data.length > 0 ? data.reduce((a, b) => a + b.series1Revenue, 0).toFixed(2) : 0;
                    prevtotal = data.length > 0 ? data.reduce((a, b) => a + b.series2Revenue, 0).toFixed(2) : 0;
                    label_data.push(item.series1Label)
                    thisyear_data.push(val1)
                    prevyear_data.push(val2)
                });
                setList(res)
               
                setTotal({ currentTotal: currenttotal, prevTotal: prevtotal })
                setxAxisData(label_data)
                setLineChartData({ currentdata: thisyear_data, prevdata: prevyear_data, label2: prevyear + " - $" + prevtotal, label1: currentyear + " - $" + currenttotal })
            }
            setIsLoaded(true)
        }).catch();
    }



    return (
        <div>
            <div className="hold-transition skin-black sidebar-mini">
                <div className="wrapper">
                    <Header />
                    <Sidebar />
                    <div className="content-wrapper db-background">
                        <div className="">
                            <div style={{ paddingTop: 30 }}>
                                <div className="filterbox">
                                    <div className="boxmargin">
                                        <div className="box box-solid box-height">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3">
                                                    <div className="form-group row">
                                                        <label for="colFormLabel" className="col-lg-3 col-sm-3 col-form-label">Period</label>
                                                        <div className="col-lg-9 col-sm-9">
                                                            <select className="form-control" value={period} onChange={(e) => setPeriod(e.target.value)}>
                                                                <option value="this month">This Month</option>
                                                                <option value="previous month">Previous Month</option>
                                                                <option value="this year">This Year</option>
                                                                <option value="previous year">Previous Year</option>
                                                                <option value="this week">This Week</option>
                                                                <option value="previous week">Previous Week</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isLoaded?
                        <div className="chartcontainer">
                            <h4 className="box-title">Revenue Trend</h4>
                            <div className='tabsname'>
                                <DoubleLineBasic color={["#3B5998", "#8B9DC3"]} lable={lineChartTitle} data={lineChartData} xAsisData={xAsisData} />
                            </div>


                            <h4 className="box-title">{title}</h4>

                            <div className="rptcontainer">

                                <div className="box-body table-responsive no-padding userlist">
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Period</th>
                                                <th>Tickets</th>
                                                <th>Estimate</th>
                                                <th>Invoices</th>
                                                <th>Revenue </th>
                                            </tr>
                                            {List.length > 0 ?
                                                List.map(item => (
                                                    <tr>
                                                        <td>{item.series1Label}</td>
                                                        <td>{item.series1Tickets}</td>
                                                        <td>{item.series1Estimates}</td>
                                                        <td>{item.series1Invoices}</td>
                                                        <td>{item.series1Revenue !== null ? "$" + item.series1Revenue.toFixed(2) : "0"}</td>
                                                    </tr>
                                                ))

                                                : "No Record Found."
                                            }

                                        </tbody>
                                    </table>

                                </div>
                            </div>

                        </div>
                         :<div className="loader"><img src="images/loader.gif" alt="" /></div>}
                    </div>



                </div>

            </div>
        </div >
    )
}

export default RevenueTrend
