import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Header from '../../components/nav/Header'
import Sidebar from '../../components/nav/Sidebar'
import { LoadTechnicians_api } from '../../functions/users'
import { GetReport_AvgTicketResolutionTime_api } from '../../functions/reports'
import { LoadOrderType_api } from '../../functions/orderType'
import moment from 'moment'
import ReactExport from "react-data-export";
import DoubleLineBasic from '../../Charts/DoubleLineChart'

function AvgTicketResolTime() {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    const [excelDataSet, setExcelDataSet] = useState([]);

    const [paginationarr, setPaginationarr] = useState([]);
    const [selectedPageNo, setSelectedPageNo] = useState(1);

    const [period, setPeriod] = useState("This Year");
    const [type, setType] = useState("Order Type")
    const [filterval, setfilterval] = useState(0);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");

    const [ordertypeList, setOrdertypeList] = useState([]);
    const [technicianList, setTechnicianlist] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const [tabledataList, setTableDataList] = useState([])
    const [chartDataList, setChartDataList] = useState([])

    const [List, setList] = useState([]);
    const [xAsisData, setxAxisData] = useState([])
    const [lineChartData, setLineChartData] = useState({ currentdata: [], label: ""})
    const [lineChartTitle, setLineChartTitle] = useState({ xAxis: "", yAxis: "Hours" })

    let recordshowperpage = 30;

    useEffect(() => {
        loadOrderTypelist();
        loadTechnicians();
        LoadDataList();
    }, [])

    const loadReport = () => {
        LoadDataList();
    }

    useEffect(() => {
        setfilterval(0)
    }, [type])

    const LoadDataList = () => {
        setIsLoaded(false)
        var req = {
            "period": period,
            "type": type,
            "id": Number(filterval),
            "fromdate": "",
            "todate": ""
        }
        GetReport_AvgTicketResolutionTime_api(req).then(res => {
            if (res !== undefined) {
                if(res.tbldata.length>0){
                setTableDataList(res.tbldata)
                }
                else{
                    setTableDataList([])
                }
                if(res.chartdata.length>0){
                    var data = res.chartdata;
                var label_data = [];
                var thisyear_data = [];
                var currenttotal = 0;
                data.forEach(item => {
                    label_data.push(item.serieslabel)
                    thisyear_data.push(item.seriesvalue)
                });
                setxAxisData(label_data)
                setLineChartData({ currentdata: thisyear_data })
                }
                setIsLoaded(true)
            }
        })
    }




    const loadOrderTypelist = () => {
        const val = "";
        LoadOrderType_api(val).then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {
                    setOrdertypeList([])
                }
                else
                    setOrdertypeList(result.data);
            }
        }).catch();
    }


    const loadTechnicians = () => {
        LoadTechnicians_api().then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {
                    setTechnicianlist([])
                }
                else
                    setTechnicianlist(result.data);
            }
        }).catch();
    }


    const AccordianChange = (item) => {
        var accordionitem = item;
        window.$("#" + accordionitem).slideToggle().parent().siblings().find(".accordion-content").slideUp();
        window.$("#" + accordionitem).toggleClass("active-title");
        window.$("#" + "item_" + accordionitem).toggleClass("active-title");
        window.$("#" + accordionitem).parent().siblings().find(".accordion-title").removeClass("active-title");
        window.$("i.fa-chevron-down", "#" + item).toggleClass("chevron-top");
        window.$("#" + accordionitem).parent().siblings().find(".accordion-title i.fa-chevron-down").removeClass("chevron-top");
    }


    return (
        <div>
            <div className="hold-transition skin-black sidebar-mini">
                <div className="wrapper">
                    <Header />
                    <Sidebar />
                    <div className="content-wrapper db-background">
                        <div className="">
                            <div style={{ paddingTop: 30 }}>
                                <div className="filterbox">
                                    <div className="boxmargin">
                                        <div className="box box-solid box-height">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="form-group row">
                                                        <label for="colFormLabel" className="col-lg-3 col-md-5 col-form-label">Period</label>
                                                        <div className="col-lg-9 col-md-7">
                                                            <select className="form-control" value={period} onChange={(e) => setPeriod(e.target.value)}>
                                                                <option value="This Year">This Year</option>
                                                                <option value="Previous Year">Previous Year</option>
                                                                <option value="This Month">This Month</option>
                                                                <option value="Previous Month">Previous Month</option>
                                                                <option value="This Week">This Week</option>
                                                                <option value="Previous Week">Previous Week</option>

                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                < div className="col-md-3 ">
                                                    <label className="radio-inline">
                                                        <input type="radio" name="optradio" value="Order Type" onChange={e => setType(e.target.value)} checked={type === "Order Type" ? "checked" : ""} /> Order Type
                                                    </label>
                                                    <label className="radio-inline">
                                                        <input type="radio" name="optradio" value="Technician" onChange={e => setType(e.target.value)} checked={type === "Technician" ? "checked" : ""} /> Technician
                                                    </label>
                                                </div>
                                                <div className="col-md-3 ">
                                                    <div className="form-group row">
                                                        <label for="colFormLabel" className="col-lg-5 col-sm-5 col-form-label">{type === "Order Type" ? "Order Type" : "Technician"}</label>
                                                        <div className="col-lg-7 col-sm-7">
                                                            {type === "Order Type" &&
                                                                <select className="form-control" onChange={(e) => setfilterval(e.target.value)}>
                                                                    <option value="0">All</option>
                                                                    {
                                                                        ordertypeList.map(item => (
                                                                            <option value={item.ordertypeid}>{item.ordertype}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            }
                                                            {type === "Technician" &&
                                                                <select className="form-control" onChange={(e) => setfilterval(e.target.value)}>
                                                                    <option value="0">All</option>
                                                                    {
                                                                        technicianList.map(item => (
                                                                            <option value={item.userId}>{item.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="fliterbtn">
                                                        <div className="inlinebtn">
                                                            <button className="btn btn-theme" onClick={() => loadReport()}>View</button>
                                                        </div>
                                                        <div className="inlinebtn">
                                                            <ExcelFile filename="Revenue by Technician" element={<button className="btn btn-theme">Export to Excel</button>} >
                                                                <ExcelSheet data={excelDataSet} name="customer revenue" >
                                                                    <ExcelColumn label="Technician" value="Technician" />
                                                                    <ExcelColumn label="Customer" value="Customer" />
                                                                    <ExcelColumn label="PO Number" value="ponumber" />
                                                                    <ExcelColumn label="Amount" value="amount" />
                                                                </ExcelSheet>
                                                            </ExcelFile>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="chartcontainer">

                            <h4 className="box-title">Avg. Ticket Resolution Time for this year is 3 hr 50min</h4>
                            <div className="box box-solid" >
                                <DoubleLineBasic color={["#3B5998"]} lable={lineChartTitle} data={lineChartData} xAsisData={xAsisData}  />
                            </div>
                        </div>


                        <div className="rptcontainer">
                            {
                                isLoaded == false ? <div className="loader"><img src="images/loader.gif" alt="" /></div>
                                    :
                                    <div className="accordions">
                                        {
                                            tabledataList != undefined &&
                                            <>
                                                {
                                                    tabledataList.length == 0
                                                        ?
                                                        "No Record Found."
                                                        :
                                                        tabledataList.slice(selectedPageNo * recordshowperpage - recordshowperpage, selectedPageNo * recordshowperpage).map((item, index) => (
                                                            <>
                                                                <div className="accordion-item ">
                                                                    <div className="accordion-title" data-tab={`item-${index}`} onClick={() => AccordianChange(`item-${index}`)}>
                                                                        <div className="row">
                                                                            <div className="col-md-10">
                                                                                <p className="name">{item.name}</p>
                                                                            </div>
                                                                            <div className="col-md-2 alignright">
                                                                                <div className="amount">{item.avgResolutionTime}</div>
                                                                                <i className="fa fa-angle-down"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="accordion-content" id={`item-${index}`}>
                                                                        <div className="customertbl">
                                                                            <table className="table table-striped">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Customer</th>
                                                                                        <th>Ticket No</th>
                                                                                        <th className="alignright">Resolution Time</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <colgroup>
                                                                                    <col span="1" style={{ width: '30%' }} />
                                                                                    <col span="1" style={{ width: '55%' }} />
                                                                                    <col span="1" style={{ width: '15%' }} className="alignright" />
                                                                                </colgroup>
                                                                                <tbody>
                                                                                    {
                                                                                        item.details != undefined &&

                                                                                            item.details.length == 0
                                                                                            ?
                                                                                            "No Record Found."
                                                                                            :
                                                                                            item.details != null &&
                                                                                            item.details.map(inneritem => (
                                                                                                <tr>
                                                                                                    <td >{inneritem.customername}</td>
                                                                                                    <td>{inneritem.ticketno}</td>
                                                                                                    <td className="alignright rpt1">{inneritem.resolutiontime}</td>
                                                                                                </tr>
                                                                                            ))
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))
                                                }
                                            </>
                                        }
                                    </div>
                            }
                            <ul className="pagination pull-right mt0">
                                {
                                    paginationarr.length > 0 &&
                                    paginationarr.map(item => (
                                        <li className={`paginate_button ${item == selectedPageNo && "active"}`}><a style={{ cursor: 'pointer' }} onClick={() => setSelectedPageNo(item)}>{item}</a></li>
                                    ))
                                }

                            </ul>
                        </div>

                    </div>
                </div>
            </div>

            <div className="modal fade bd-example-modal-sm" id="dateSelection" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <div className="dateselectionModal">
                            <div className="form-group row">
                                <label for="colFormLabel" className="col-sm-4 col-form-label">From </label>
                                <div className="col-sm-8">
                                    <input type="date" className="form-control" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="colFormLabel" className="col-sm-4 col-form-label">To </label>
                                <div className="col-sm-8">
                                    <input type="date" className="form-control" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                                </div>
                            </div>
                            <div className="row">
                                <button className="btn btn-primary" onClick={() => window.$("#dateSelection").modal('hide')}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AvgTicketResolTime
