import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { LoadOrderType_api, LoadTicketStatus_api } from '../../functions/masters'
import {
    UpdateTicketStatus_api
} from '../../functions/tickets'

const StatusUpdate = ({ ticketid, selectedStatusId_prop, loadagain, whichPage, loadTicketDetails }) => {

    const [selectedStatusId, setSelectedStatusId] = useState(selectedStatusId_prop);
    const [statusList, setStatusList] = useState([]);


    useEffect(() => {
        loadStatus();
    }, [])

    const loadStatus = () => {
        var type = "";
        var usertype = localStorage.getItem("usertype");
        if (whichPage == "Estimate" && usertype == "Supervisor") {
            type = "estimate_supervisor";
        }
        else if (whichPage == "Estimate" && usertype == "Accounts") {
            type = "estimate_accounts";
        }

        else if (whichPage == "Invoice" && usertype == "Supervisor") {
            type = "invoice_supervisor";
        }
        else if (whichPage == "Invoice" && usertype == "Accounts") {
            type = "invoice_accounts";
        }
        else {
            type = "web"
        }
       
        
        LoadTicketStatus_api(type).then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {
                    setStatusList([])
                }
                else
                    setStatusList(result.data);
            }

        }).catch();
    }

    const UpdateStatus = (e) => {
        e.preventDefault();
        if (selectedStatusId == 0 || selectedStatusId == null)
            return toast.error("Please choose status.");

        else {
            var req = {
                "ticketid": ticketid,
                "statusID": Number(selectedStatusId),
                "updatedby": Number(localStorage.getItem("userid"))
            }
            UpdateTicketStatus_api(req).then(res => {
                if (res != undefined) {
                    toast.success(res.message);
                    window.$("#StatusPop").modal('hide');
                    loadagain();
                    loadTicketDetails();
                }
            })
                .catch();
        }

    }

    return (
        <div className="modal fade fsm-popup" id="StatusPop">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <a type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <img src="images/close.png" /></a>
                        <h5 className="modal-title">Update Status </h5>
                    </div>
                    <div className="modal-body">
                        <div className="add-custmer-form ">
                            <div className="row">

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <select className="form-control" value={selectedStatusId} onChange={(e) => setSelectedStatusId(e.target.value)}>
                                            <option value="0">Status</option>
                                            {
                                                statusList.map(item => (
                                                    <option value={item.statusid}>{item.status}</option>
                                                ))
                                            }

                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 pull-right">
                                    <a type="submit" className="btn btn-theme pull-right" style={{ cursor: 'pointer' }} onClick={(e) => UpdateStatus(e)}>Update </a>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                {/* <!-- /.modal-content --> */}
            </div>
            {/* <!-- /.modal-dialog --> */}
        </div>

    )
}

export default StatusUpdate
