import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment'
import {
    LoadTicketDetails_api,
    LoadNotesDetails_api,
    LoadPhotos_api,
    LoadInvoices_api,
    LoadEstimates_api,
    CreateNotes_api,
    UpdateTicketOrderDetails_api,
    UpdateTicketAssinee_api,
    UpdateTicketStatus_api,
    UpdateTicketPOWorkOrder_api,
    LoadTicketActions_api,
    SendEmail_api, ResendEmail_api,
    LoadDeletedTicketDetails_api,
    RestoreTicket_api

} from '../../functions/tickets'
import { FinalizeEstimate_api, DraftEstimate_api, SubmitEstimate_api, ApproveEstimate_api, UpdateEstimate_api } from '../../functions/estimates'
import { FinalizeInvoice_api, DraftInvoice_api, SubmitInvoice_api, PaidInvoice_api, UpdateInvoice_api } from '../../functions/invoices'
import OrderTypeUpdate from '../../forms/OrderTypeUpdate'
import TicketAssigneeUpdate from '../../forms/TicketAssigneeUpdate'
import { LoadTechnicians_api } from '../../functions/users'
import { LoadOrderType_api, LoadTicketStatus_api } from '../../functions/masters'
import { Customers_Profile_api } from '../../functions/customers'
import { useHistory } from 'react-router-dom'
import { format } from 'date-fns'
import DateUpdate from '../popups/Invoicedateupdate'
import DueDateUpdate from '../popups/Invoiceduedateupdate'
import Invoicenoupdate from '../popups/Invoicenoupdate'
import CreateTicket from '../../forms/CreateTicket'
import DeletePopUp from '../../forms/DeletePopUp';

import { DeleteTicket_api } from '../../functions/tickets';
import { AirlineSeatLegroomReducedTwoTone, AlternateEmail } from '@material-ui/icons';

function TicketDetails({ ticketid, loadAgain, ticketType }) {
    //const ticketid = Number(localStorage.getItem("ticketid"));
    var userid = Number(localStorage.getItem("userid"));
    const API_Base_Url = process.env.REACT_APP_API_BASE_URL;
    const AccessToken = localStorage.getItem("AccessToken");

    const [locationKeys, setLocationKeys] = useState([])

    const [customerid, setCustomerid] = useState(0);
    const [customername, setCustomerName] = useState("");
    const [customertype, setCustomertype] = useState("");
    const [address, setaddress] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [location, setLocation] = useState("");
    const [zipcode, setZipCode] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [notesdetails, setNotesdetails] = useState({});

    const [ticketDetails, setTicketDetails] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [photosList, setPhotosList] = useState([]);
    const [EstimatesDetails, setEstimatesDetails] = useState({});
    const [InvoicesDetails, setInvoicesDetails] = useState({});
    const [openSection, setOpenSection] = useState("");
    const [txtnotes, setTxtNotes] = useState("");
    const [test, setTest] = useState(0);
    const [totalMaterialAmt, setTotalMaterialAmt] = useState(0)
    const [totalLoborAmt, setTotalLaborAmt] = useState(0);
    const [activiyid, setActivityId] = useState(0);
    const [isAddNewEstimate, setIsAddNewEstimate] = useState(false);
    const [isAddNewInvoice, setIsAddNewInvoice] = useState(false)

    const [technicianList, setTechnicianlist] = useState([]);
    const [technicianId, setTechnicianID] = useState(0);
    const [ticketDate, setTicketDate] = useState("");
    const [ticketTime, setTicketTime] = useState("");

    const [orderTypeList, setOrderTypeList] = useState([]);
    const [selectedOrderType, setSelectedOrderType] = useState(0);
    const [orderDetail, setOrderDetail] = useState("");
    const [statusList, setStatusList] = useState([]);
    const [selectedStatusId, setSelectedStatusId] = useState(0);

    const [jobcontactname, setJobContactName] = useState("")
    const [jobname, setJobName] = useState("")
    const [jobcontactemail, setJobContactEmail] = useState("")
    const [jobcontactphone, setJobContactPhone] = useState("")

    const [poRequired, setPoRequired] = useState(false);
    const [poDOc, setPoDoc] = useState("");
    const loggeduserid = Number(localStorage.getItem("userid"));
    const userType = localStorage.getItem("usertype");
    const [poNumber_estimate, setPoNumber_estimate] = useState("");
    const [poNumber_invoice, setPoNumber_invoice] = useState("");


    const [poNumber, setPoNumber] = useState("");
    const [workOrderNumber, setWorkOrderNumber] = useState("");
    const [serviceAddress, setServiceAddress] = useState("");
    const [sameAsBilling, setSameAsBilling] = useState(false);
    const [customerAddress, setCustomerAddress] = useState("");

    const history = useHistory()
    const [customer_state, setcustomer_State] = useState("")
    const [customer_city, setcustomer_City] = useState("")
    const [customer_zipcode, setcustomer_ZipCode] = useState("")
    const [customer_latitude, setcustomer_Latitude] = useState("")
    const [customer_longitude, setcustomer_Longitude] = useState("");
    const [actionList, setActionList] = useState([]);
    const [isEstimateEditable, setIsEstimateEditable] = useState(true);
    const [poDocument, setPoDocument] = useState("");
    const [isMailSent, setIsMailSent] = useState(true);
    const [isMailSent_invoice, setIsMailSent_invoice] = useState(true);
    const [invoiceduedate, setInvoiceduedate] = useState("");
    const [invoicedate, setInvoicedate] = useState("");
    const [invoiceno, setInvoiceno] = useState("");
    const [invoicestatus, setInvoiceStatus] = useState("");

    const [ticketId, setTicketId] = useState(0);
    const [testId, setTestId] = useState(0);
    const [cid, setCid] = useState(0)


    useEffect(() => {
        if (ticketid != 0 && ticketid != undefined) {
            setTicketDetails({})
            setEstimatesDetails({})
            setInvoicesDetails({})
            setOpenSection("")
            refresh();
            loadTechnicians();
            loadOrderType();
            loadStatus();
            loadActions(ticketid);
            setIsMailSent(false);
            setIsMailSent_invoice(false)
        }
        window.$("#addnotesdiv").hide();
    }, [ticketid]);

    const refresh = () => {
        loadTicketDetails();
        loadActions(ticketid);

    }
    useEffect(() => {
        if (sameAsBilling) {
            setServiceAddress(customerAddress)
            setCity(customer_city)
            setState(customer_state)
            setLatitude(customer_latitude)
            setLongitude(customer_longitude)
            setZipCode(customer_zipcode)
        }
        else
            setServiceAddress("");
    }, [sameAsBilling])

    useEffect(() => {
        if (Object.keys(EstimatesDetails).length > 0)
            sumEstimateAmt();
    }, [EstimatesDetails])

    useEffect(() => {
        if (Object.keys(InvoicesDetails).length > 0)
            sum_invoice();
    }, [InvoicesDetails])

    function getTwentyFourHourTime(amPmString) {
        // var d = new Date("1/1/2013 " + amPmString);
        // return d.getHours() + ':' + d.getMinutes();

        var convertedTime = moment(amPmString, 'hh:mm A').format('HH:mm')
        return convertedTime;
    }

    const sumEstimateAmt = () => {
        //alert(JSON.stringify(EstimatesDetails))
        var totalmaterialamt = 0;
        var totallaboramt = 0;
        for (var i = 0; i < EstimatesDetails.estimatedata.filter(x => x.type == "Labor").length; i++) {
            if (!(isNaN(EstimatesDetails.estimatedata.filter(x => x.type == "Labor")[i].amount) || EstimatesDetails.estimatedata.filter(x => x.type == "Labor")[i].amount === null || EstimatesDetails.estimatedata.filter(x => x.type == "Labor")[i].amount === 0)) {
                totallaboramt = Number(totallaboramt) + Number(EstimatesDetails.estimatedata.filter(x => x.type == "Labor")[i].amount);
            }
        }

        for (var i = 0; i < EstimatesDetails.estimatedata.filter(x => x.type == "Material").length; i++) {
            if (!(isNaN(EstimatesDetails.estimatedata.filter(x => x.type == "Material")[i].amount) || EstimatesDetails.estimatedata.filter(x => x.type == "Material")[i].amount === null || EstimatesDetails.estimatedata.filter(x => x.type == "Material")[i].amount === 0)) {
                totalmaterialamt = Number(totalmaterialamt) + Number(EstimatesDetails.estimatedata.filter(x => x.type == "Material")[i].amount);
            }
        }

        EstimatesDetails.amountLabour = parseFloat(totallaboramt).toFixed(2);
        EstimatesDetails.amountMaterial = parseFloat(totalmaterialamt).toFixed(2);
        sumPayable()
    }

    const sum_invoice = () => {

        var totalmaterialamt = 0;
        var totallaboramt = 0;
        for (var i = 0; i < InvoicesDetails.invoicedata.filter(x => x.type == "Labor").length; i++) {
            if (!(isNaN(InvoicesDetails.invoicedata.filter(x => x.type == "Labor")[i].amount) || InvoicesDetails.invoicedata.filter(x => x.type == "Labor")[i].amount === null || InvoicesDetails.invoicedata.filter(x => x.type == "Labor")[i].amount === 0)) {
                totallaboramt = Number(totallaboramt) + Number(InvoicesDetails.invoicedata.filter(x => x.type == "Labor")[i].amount);
                //alert(totallaboramt)
            }
        }

        for (var i = 0; i < InvoicesDetails.invoicedata.filter(x => x.type == "Material").length; i++) {
            if (!(isNaN(InvoicesDetails.invoicedata.filter(x => x.type == "Material")[i].amount) || InvoicesDetails.invoicedata.filter(x => x.type == "Material")[i].amount === null || InvoicesDetails.invoicedata.filter(x => x.type == "Material")[i].amount === 0)) {
                totalmaterialamt = Number(totalmaterialamt) + Number(InvoicesDetails.invoicedata.filter(x => x.type == "Material")[i].amount);
            }
        }

        InvoicesDetails.amountLabour = parseFloat(totallaboramt).toFixed(2);
        InvoicesDetails.amountMaterial = parseFloat(totalmaterialamt).toFixed(2);
        sumPayable_invoice()

    }

    //estimate sum
    const sumPayable = () => {
        var amtMaterial = 0;
        var amtLabour = 0;
        var amtTaxMaterial = 0;
        var amtTaxLabour = 0;
        var amtDiscount = 0;
        var amtNetPayable = 0;

        if (!(isNaN(EstimatesDetails.amountMaterial) || EstimatesDetails.amountMaterial === null)) {
            amtMaterial = Number(EstimatesDetails.amountMaterial);
            if (!(isNaN(EstimatesDetails.amountTaxMaterialRate) || EstimatesDetails.amountTaxMaterialRate === null)) {
                amtTaxMaterial = EstimatesDetails.amountTaxMaterial = parseFloat(((Number(EstimatesDetails.amountMaterial) * Number(EstimatesDetails.amountTaxMaterialRate)) / 100)).toFixed(2);
            }
        }

        if (!(isNaN(EstimatesDetails.amountLabour) || EstimatesDetails.amountLabour === null)) {
            amtLabour = Number(EstimatesDetails.amountLabour);
            if (!(isNaN(EstimatesDetails.amountTaxLabourRate) || EstimatesDetails.amountTaxLabourRate === null)) {
                amtTaxLabour = EstimatesDetails.amountTaxLabour = parseFloat(((Number(EstimatesDetails.amountLabour) * Number(EstimatesDetails.amountTaxLabourRate)) / 100)).toFixed(2);
            }
        }

        if (!(isNaN(EstimatesDetails.amountDiscount) || EstimatesDetails.amountDiscount === null)) {
            if (!(isNaN(EstimatesDetails.discountpercentage) || EstimatesDetails.discountpercentage === null)) {
                amtDiscount = EstimatesDetails.amountDiscount = parseFloat((Number(amtMaterial) + Number(amtLabour)) * Number(EstimatesDetails.discountpercentage) / 100).toFixed(2);
            }
        }
        EstimatesDetails.amountPayable = parseFloat((Number(amtMaterial) + Number(amtLabour) + Number(amtTaxMaterial) + Number(amtTaxLabour)) - Number(amtDiscount)).toFixed(2);
        setTest(test + 1)
    }

    const sumPayable_invoice = () => {
        var amtMaterial = 0;
        var amtLabour = 0;
        var amtTaxMaterial = 0;
        var amtTaxLabour = 0;
        var amtDiscount = 0;
        var amtNetPayable = 0;

        if (!(isNaN(InvoicesDetails.amountMaterial) || InvoicesDetails.amountMaterial === null)) {
            amtMaterial = Number(InvoicesDetails.amountMaterial);
            if (!(isNaN(InvoicesDetails.amountTaxMaterialRate) || InvoicesDetails.amountTaxMaterialRate === null)) {
                amtTaxMaterial = InvoicesDetails.amountTaxMaterial = parseFloat(((Number(InvoicesDetails.amountMaterial) * Number(InvoicesDetails.amountTaxMaterialRate)) / 100)).toFixed(2);
            }
        }

        if (!(isNaN(InvoicesDetails.amountLabour) || InvoicesDetails.amountLabour === null)) {
            amtLabour = Number(InvoicesDetails.amountLabour);
            if (!(isNaN(InvoicesDetails.amountTaxLabourRate) || InvoicesDetails.amountTaxLabourRate === null)) {
                amtTaxLabour = InvoicesDetails.amountTaxLabour = parseFloat(((Number(InvoicesDetails.amountLabour) * Number(InvoicesDetails.amountTaxLabourRate)) / 100)).toFixed(2);
            }
        }
        if (!(isNaN(InvoicesDetails.amountDiscount) || InvoicesDetails.amountDiscount === null)) {
            if (!(isNaN(InvoicesDetails.discountpercentage) || InvoicesDetails.discountpercentage === null)) {
                amtDiscount = InvoicesDetails.amountDiscount = parseFloat((Number(amtMaterial) + Number(amtLabour)) * Number(InvoicesDetails.discountpercentage) / 100).toFixed(2);
            }
        }
        InvoicesDetails.amountPayable = parseFloat((Number(amtMaterial) + Number(amtLabour) + Number(amtTaxMaterial) + Number(amtTaxLabour)) - Number(amtDiscount)).toFixed(2);
        setTest(test + 1)
    }

    const loadActions = (ticketid) => {
        if (ticketType === "normal") {

            LoadTicketActions_api(userid, ticketid).then(res => {
                const result = res;
                if (result != undefined) {
                    if (result.actionlist == null) {
                        setActionList([])
                    }
                    else
                        setActionList(result.actionlist.filter(x => x.allowed == true));
                }

            }).catch();
        }
    }

    const loadOrderType = () => {
        LoadOrderType_api().then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {
                    setOrderTypeList([])
                }
                else
                    setOrderTypeList(result.data);
            }

        }).catch();
    }

    const loadStatus = () => {
        LoadTicketStatus_api("web").then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {
                    setStatusList([])
                }
                else {
                    console.log(result.data)
                    setStatusList(result.data);
                }
            }

        }).catch();
    }

    const loadTechnicians = () => {
        LoadTechnicians_api().then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {
                    setTechnicianlist([])
                }
                else
                    setTechnicianlist(result.data);
            }

        }).catch();
    }


    const loadTicketDetails = () => {

        setIsLoaded(false);
        if (ticketType === "normal") {
            LoadTicketDetails_api(ticketid, userid).then(res => {
                const result = res;
                if (result != undefined) {
                    if (result == null) {
                        setTicketDetails([])
                    }
                    else {

                        console.log("ticketDetails", result)
                        setTicketDetails(result);
                        loadCustomerProfile(result.customerid)
                        setPoNumber(result.ponumber)
                        setWorkOrderNumber(result.workordernumber)
                        setInvoiceStatus(result.currentstatusactivity)
                        setCid(result.customerid)
                        // setServiceAddress()

                    }

                }
                setIsLoaded(true);
            }).catch();
        }
        else {
            LoadDeletedTicketDetails_api(ticketid, userid).then(res => {
                const result = res;
                if (result != undefined) {
                    if (result == null) {
                        setTicketDetails([])
                    }
                    else {
                        setTicketDetails(result);
                        loadCustomerProfile(result.customerid)
                        setPoNumber(result.ponumber)
                        setWorkOrderNumber(result.workordernumber)
                        setInvoiceStatus(result.currentstatusactivity)
                        setCid(result.customerid)
                        // setServiceAddress()

                    }

                }
                setIsLoaded(true);
            }).catch();
        }
    }


    //for drafting
    const loadTicketDetailsAfterDraft = (screen) => {
        // setIsLoaded(false);
        if (ticketType === "normal") {
            LoadTicketDetails_api(ticketid, userid).then(res => {
                const result = res;
                if (result != undefined) {
                    if (result == null) {
                        setTicketDetails([])
                    }
                    else {
                        setTicketDetails(result);
                        loadCustomerProfile(result.customerid)
                        setPoNumber(result.ponumber)
                        setWorkOrderNumber(result.workordernumber)
                        if (screen == "estimate")
                            loadEstimate(result.activities[0].activityid);
                        if (screen == "invoice")
                            loadInvoice(result.activities[0].activityid);
                        // setServiceAddress()

                    }

                }
                // setIsLoaded(true);
            }).catch();
        }
    }

    const loadCustomerProfile = (customerid) => {
        Customers_Profile_api(customerid).then(res => {

            const result = res;
            if (result != undefined) {
                if (result == null) {
                }
                else {
                    console.log(result)
                    setCustomerAddress(result.address)
                    setcustomer_State(result.state)
                    setcustomer_City(result.city)
                    setcustomer_Latitude(result.latitude)
                    setcustomer_Longitude(result.longitude)
                    setcustomer_ZipCode(result.zipcode)

                }
            }
        }).catch();
    }

    const loadNotesDetails = (activityid) => {
        setOpenSection("Notes")
        LoadNotesDetails_api(ticketid, userid, activityid).then(res => {
            const result = res;
            if (result != undefined) {
                if (result == null) {
                    setNotesdetails({})
                }
                else
                    setNotesdetails(result);
            }

        }).catch();
    }

    const loadPhotos = (activityid) => {
        setOpenSection("Photos")
        LoadPhotos_api(ticketid, userid, activityid).then(res => {
            const result = res;
            if (result != undefined) {
                if (result.photos == null) {
                    setPhotosList([]);
                }
                else
                    setPhotosList(result.photos);
            }

        }).catch();
    }

    const loadBoth = (activityid) => {
        loadEstimate(activityid);
        // sumEstimateAmt();
    }

    const loadEstimate = (activityid) => {

        setOpenSection("Estimates")
        setIsAddNewEstimate(false)
        if (activityid != 0 && activityid != undefined) {
            setActivityId(activityid)
            LoadEstimates_api(ticketid, userid, activityid).then(res => {
                const result = res;
                if (result != undefined) {
                    if (result == null) {
                        setEstimatesDetails({});
                    }
                    else {
                        setEstimatesDetails(result);
                        setPoNumber_estimate(result.ponumber)



                    }
                    //console.log(result)
                }

            }).catch();
        }
    }

    const loadInvoice = (activityid) => {
        setOpenSection("Invoices")
        setIsAddNewInvoice(false)
        if (activityid != 0 && activityid != undefined) {
            setActivityId(activityid)
            LoadInvoices_api(ticketid, userid, activityid).then(res => {
                const result = res;
                if (result != undefined) {

                    if (result == null) {
                        setInvoicesDetails({});
                    }
                    else {
                        setInvoicesDetails(result);
                        setPoNumber_invoice(result.ponumber)
                    }
                }

            }).catch();
        }
    }

    const submitNotes = (e) => {
        e.preventDefault();
        if (txtnotes.trim() == "")
            return toast.error("Please enter notes.")
        var req = {
            "ticketid": Number(ticketid),
            "notes": txtnotes,
            "customername": "",
            "comment": "",
            "addedby": userid,
            "latitude": "",
            "longitude ": "",
            "signature": ""

        }
        CreateNotes_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                window.$("#addnotesdiv").hide();
                loadTicketDetails();
            }
        })
            .catch();
    }

    const openNotesAdd = () => {
        window.$("#addnotesdiv").show();
        setOpenSection("");
        setTxtNotes("");
    }

    const handleChange_Material = (item, event) => {
        var index = EstimatesDetails.estimatedata.indexOf(item);
        EstimatesDetails.estimatedata[index].amount = event.target.value.replace(/\D/, ''); // Update it with the modified email.      
        var totalmaterialamt_aa = 0;

        if (item.type == "Material") {
            for (var i = 0; i < EstimatesDetails.estimatedata.filter(x => x.type == "Material").length; i++) {

                totalmaterialamt_aa = totalmaterialamt_aa + Number(EstimatesDetails.estimatedata.filter(x => x.type == "Material")[i].amount);
                //setTotalMaterialAmt(totalmaterialamt)                
                EstimatesDetails.amountMaterial = totalmaterialamt_aa;

            }
        }
        sumPayable()
        setTest(test + 1)
    }
    const handleChange_Labor = (item, event) => {
        var index = EstimatesDetails.estimatedata.indexOf(item);
        EstimatesDetails.estimatedata[index].amount = event.target.value.replace(/\D/, ''); // Update it with the modified email.


        var totallaboramt = 0;

        if (item.type == "Labor") {
            for (var i = 0; i < EstimatesDetails.estimatedata.filter(x => x.type == "Labor").length; i++) {
                totallaboramt = Number(totallaboramt) + Number(EstimatesDetails.estimatedata.filter(x => x.type == "Labor")[i].amount);

                // setTotalLaborAmt(totallaboramt)
                EstimatesDetails.amountLabour = totallaboramt;
            }
        }
        setTest(test + 1)
        sumPayable()
        setTest(test + 1)
    }



    const handleChange_TextValue_item = (item, event) => {
        var index = EstimatesDetails.estimatedata.indexOf(item);
        EstimatesDetails.estimatedata[index].item = event.target.value;
        setTest(test + 1)
    }

    const handleChange_TextValue_item_invoice = (item, event) => {
        var index = InvoicesDetails.invoicedata.indexOf(item);
        InvoicesDetails.invoicedata[index].item = event.target.value;
        setTest(test + 1)
    }

    const handleChange_disc = (event) => {
        EstimatesDetails.amountDiscount = event.target.value.replace(/\D/, '');
        sumPayable();
        setTest(test + 1)
    }


    const handleChange_tax_material = (event) => {
        const amount = event.target.value;
        if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
            EstimatesDetails.amountTaxMaterialRate = amount;
        }
        EstimatesDetails.amountTaxMaterial = ((EstimatesDetails.amountMaterial * event.target.value) / 100)
        sumPayable();
        setTest(test + 1)
    }

    const handleChange_tax_labor = (event) => {
        const amount = event.target.value;
        if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
            EstimatesDetails.amountTaxLabourRate = amount;
        }
        EstimatesDetails.amountTaxLabour = ((EstimatesDetails.amountLabour * event.target.value) / 100);
        sumPayable();
        setTest(test + 1)
    }

    const handleChange_tax_material_invoice = (event) => {
        const amount = event.target.value;
        if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
            InvoicesDetails.amountTaxMaterialRate = amount;
        }
        InvoicesDetails.amountTaxMaterial = ((InvoicesDetails.amountMaterial * event.target.value) / 100)
        sumPayable_invoice();
        setTest(test + 1)
    }

    const handleChange_tax_labor_invoice = (event) => {
        const amount = event.target.value;
        if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
            InvoicesDetails.amountTaxLabourRate = amount;
        }
        InvoicesDetails.amountTaxLabour = ((InvoicesDetails.amountLabour * event.target.value) / 100);
        sumPayable_invoice();
        setTest(test + 1)
    }


    const calculateAmount = (_item) => {
        var _qty = 0;
        var _unitprice = 0;
        var _total = 0;

        try {
            _qty = _item.qtyno;//replace(/\D/g, '');
        } catch (error) {

        }
        try {
            _unitprice = _item.unitprice;//.replace(/\D/g, '');
        } catch (error) {

        }

        if (!(isNaN(_qty) || _qty === null || _qty === 0)) {
            if (!(isNaN(_unitprice) || _unitprice === null || _unitprice === 0)) {
                _total = _qty * _unitprice;
            }
        }
        return _total;
    }

    const finalizeEstimate = (e) => {
        e.preventDefault();
        if (EstimatesDetails.estimatedata.length == 0)
            return toast.error('Please add atleast one row for material or labor');

        var countRecords = EstimatesDetails.estimatedata.filter(x => x.type === "Material").length;
        if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
            var lastIndexTitle_material = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].item;
            var lastIndexQty_material = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].qty;
            var lastIndexUnitPrice_material = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].unitprice;
            var lastIndexAmount_material = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].amount;
            if (lastIndexTitle_material == "" || lastIndexTitle_material == null) {
                return toast.error("Please Enter Material Title.");
            }
            else if (lastIndexQty_material == "" || lastIndexQty_material == 0) {
                return toast.error("Please Enter Material Qty.");
            }
            else if (lastIndexUnitPrice_material == "" || lastIndexUnitPrice_material == 0) {
                return toast.error("Please Enter Material Unit Price.");
            }
            else if (lastIndexAmount_material == "" || lastIndexAmount_material == 0) {
                return toast.error("Please Enter Material Amount.");
            }
        }

        countRecords = EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length;
        if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
            var lastIndexTitle_labor = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].item;
            var lastIndexQty_labor = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].qty;
            var lastIndexUnitPrice_labor = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].unitprice;
            var lastIndexAmount_labor = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].amount;
            if (lastIndexTitle_labor == "" || lastIndexTitle_labor == null) {
                return toast.error("Please Enter Labor Title.");
            }
            else if (lastIndexQty_labor == "" || lastIndexQty_labor == 0) {
                return toast.error("Please Enter Labor Qty.");
            }
            else if (lastIndexUnitPrice_labor == "" || lastIndexUnitPrice_labor == 0) {
                return toast.error("Please Enter Labor Unit Price.");
            }
            else if (lastIndexAmount_labor == "" || lastIndexAmount_labor == 0) {
                return toast.error("Please Enter Labor Amount.");
            }
        }
        var estimatedata = [];
        for (var i = 0; i < EstimatesDetails.estimatedata.length; i++) {
            estimatedata.push({
                "id": EstimatesDetails.estimatedata[i].id,
                "type": EstimatesDetails.estimatedata[i].type,
                "item": EstimatesDetails.estimatedata[i].item,
                "qty": String(EstimatesDetails.estimatedata[i].qty),
                "unitprice": Number(EstimatesDetails.estimatedata[i].unitprice),
                "amount": Number(EstimatesDetails.estimatedata[i].amount)
            })
        }
        var req = {
            "ticketid": ticketid,
            "activityid": activiyid,
            "estimatedata": estimatedata,
            "amountMaterials": Number(EstimatesDetails.amountMaterial),
            "amountLabour": Number(EstimatesDetails.amountLabour),
            "amountTotal": Number(EstimatesDetails.amountMaterial) + Number(EstimatesDetails.amountLabour),
            "amountDiscount": !isNaN(Number(EstimatesDetails.amountDiscount)) ? Number(EstimatesDetails.amountDiscount) : 0,
            "amountTax": EstimatesDetails.amountTax == null ? 0 : Number(EstimatesDetails.amountTax),
            "discountPercentage": EstimatesDetails.discountpercentage == null ? 0 : Number(EstimatesDetails.discountpercentage),
            "amountTaxMaterialRate": Number(EstimatesDetails.amountTaxMaterialRate),
            "amountTaxMaterial": Number(EstimatesDetails.amountTaxMaterial),
            "amountTaxLabourRate": Number(EstimatesDetails.amountTaxLabourRate),
            "amountTaxLabour": Number(EstimatesDetails.amountTaxLabour),
            "amountPayable": Number(EstimatesDetails.amountPayable),
            "ponumber": poNumber_estimate,
            "notes": EstimatesDetails.notes,
            "updatedby": userid

        }
        FinalizeEstimate_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                loadTicketDetails();
                loadEstimate(activiyid);
                loadActions(ticketid)
            }
        })
            .catch();
    }

    const draftEstimate = (e) => {
        e.preventDefault();
        if (EstimatesDetails.estimatedata.length == 0)
            return toast.error('Please add atleast one row for material or labor');

        var countRecords = EstimatesDetails.estimatedata.filter(x => x.type === "Material").length;
        if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
            var lastIndexTitle_material = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].item;
            var lastIndexQty_material = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].qty;
            var lastIndexUnitPrice_material = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].unitprice;
            var lastIndexAmount_material = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].amount;
            if (lastIndexTitle_material == "" || lastIndexTitle_material == null) {
                return toast.error("Please Enter Material Title.");
            }
            else if (lastIndexQty_material == "" || lastIndexQty_material == 0) {
                return toast.error("Please Enter Material Qty.");
            }
            else if (lastIndexUnitPrice_material == "" || lastIndexUnitPrice_material == 0) {
                return toast.error("Please Enter Material Unit Price.");
            }
            else if (lastIndexAmount_material == "" || lastIndexAmount_material == 0) {
                return toast.error("Please Enter Material Amount.");
            }
        }

        countRecords = EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length;
        if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
            var lastIndexTitle_labor = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].item;
            var lastIndexQty_labor = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].qty;
            var lastIndexUnitPrice_labor = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].unitprice;
            var lastIndexAmount_labor = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].amount;
            if (lastIndexTitle_labor == "" || lastIndexTitle_labor == null) {
                return toast.error("Please Enter Labor Title.");
            }
            else if (lastIndexQty_labor == "" || lastIndexQty_labor == 0) {
                return toast.error("Please Enter Labor Qty.");
            }
            else if (lastIndexUnitPrice_labor == "" || lastIndexUnitPrice_labor == 0) {
                return toast.error("Please Enter Labor Unit Price.");
            }
            else if (lastIndexAmount_labor == "" || lastIndexAmount_labor == 0) {
                return toast.error("Please Enter Labor Amount.");
            }
        }

        var estimatedata = [];
        for (var i = 0; i < EstimatesDetails.estimatedata.length; i++) {
            estimatedata.push({
                "type": EstimatesDetails.estimatedata[i].type,
                "item": EstimatesDetails.estimatedata[i].item,
                "qty": String(EstimatesDetails.estimatedata[i].qty),
                "unitprice": Number(EstimatesDetails.estimatedata[i].unitprice),
                "amount": Number(EstimatesDetails.estimatedata[i].amount)
            })
        }
        var req = {
            "ticketid": ticketid,
            "estimatedata": estimatedata,
            "amountMaterials": 0,
            "amountLabour": 0,
            "amountTotal": 0,
            "amountDiscount": 0,
            "amountTax": 0,
            "amountTaxMaterialRate": 0,
            "amountTaxMaterial": 0,
            "amountTaxLabourRate": 0,
            "amountTaxLabour": 0,
            "amountPayable": 0,
            "customername": "",
            "comment": "",
            "addedby": userid,
            "latitude": "",
            "longitude": "",
            "signature": "",
            "notes": EstimatesDetails.notes,

        }
        DraftEstimate_api(req).then(res => {
            if (res != undefined) {

                toast.success(res.message);
                if (ticketType === "normal") {
                    loadTicketDetailsAfterDraft("estimate");
                }
                // loadEstimate(activiyid);
                loadActions(ticketid)
            }
        })
            .catch();
    }

    const updateEstimate = (e) => {
        // return alert(EstimatesDetails.discountpercentage)
        e.preventDefault();
        if (EstimatesDetails.estimatedata.length == 0)
            return toast.error('Please add atleast one row for material or labor');

        var countRecords = EstimatesDetails.estimatedata.filter(x => x.type === "Material").length;
        if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
            var lastIndexTitle_material = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].item;
            var lastIndexQty_material = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].qty;
            var lastIndexUnitPrice_material = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].unitprice;
            var lastIndexAmount_material = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].amount;
            if (lastIndexTitle_material == "" || lastIndexTitle_material == null) {
                return toast.error("Please Enter Material Title.");
            }
            else if (lastIndexQty_material == "" || lastIndexQty_material == 0) {
                return toast.error("Please Enter Material Qty.");
            }
            else if (lastIndexUnitPrice_material == "" || lastIndexUnitPrice_material == 0) {
                return toast.error("Please Enter Material Unit Price.");
            }
            else if (lastIndexAmount_material == "" || lastIndexAmount_material == 0) {
                return toast.error("Please Enter Material Amount.");
            }
        }

        countRecords = EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length;
        if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
            var lastIndexTitle_labor = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].item;
            var lastIndexQty_labor = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].qty;
            var lastIndexUnitPrice_labor = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].unitprice;
            var lastIndexAmount_labor = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].amount;
            if (lastIndexTitle_labor == "" || lastIndexTitle_labor == null) {
                return toast.error("Please Enter Labor Title.");
            }
            else if (lastIndexQty_labor == "" || lastIndexQty_labor == 0) {
                return toast.error("Please Enter Labor Qty.");
            }
            else if (lastIndexUnitPrice_labor == "" || lastIndexUnitPrice_labor == 0) {
                return toast.error("Please Enter Labor Unit Price.");
            }
            else if (lastIndexAmount_labor == "" || lastIndexAmount_labor == 0) {
                return toast.error("Please Enter Labor Amount.");
            }
        }

        var estimatedata = [];
        for (var i = 0; i < EstimatesDetails.estimatedata.length; i++) {
            estimatedata.push({
                "id": EstimatesDetails.estimatedata[i].id,
                "type": EstimatesDetails.estimatedata[i].type,
                "item": EstimatesDetails.estimatedata[i].item,
                "qty": String(EstimatesDetails.estimatedata[i].qty),
                "unitprice": Number(EstimatesDetails.estimatedata[i].unitprice),
                "amount": Number(EstimatesDetails.estimatedata[i].amount)
            })
        }
        var req = {
            "ticketid": ticketid,
            "activityid": activiyid,
            "estimatedata": estimatedata,
            "amountMaterials": Number(EstimatesDetails.amountMaterial),
            "amountLabour": Number(EstimatesDetails.amountLabour),
            "amountTotal": Number(EstimatesDetails.amountMaterial) + Number(EstimatesDetails.amountLabour),
            "amountDiscount": !isNaN(Number(EstimatesDetails.amountDiscount)) ? Number(EstimatesDetails.amountDiscount) : 0,
            "amountTax": Number(EstimatesDetails.amountTax),
            "discountPercentage": EstimatesDetails.discountpercentage == null ? 0 : Number(EstimatesDetails.discountpercentage),
            "amountTaxMaterialRate": Number(EstimatesDetails.amountTaxMaterialRate),
            "amountTaxMaterial": Number(EstimatesDetails.amountTaxMaterial),
            "amountTaxLabourRate": Number(EstimatesDetails.amountTaxLabourRate),
            "amountTaxLabour": Number(EstimatesDetails.amountTaxLabour),
            "amountPayable": Number(EstimatesDetails.amountPayable),
            "ponumber": poNumber_estimate,
            "updatedfrom": "web",
            "notes": EstimatesDetails.notes,
            "updatedby": userid

        }
        UpdateEstimate_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                loadTicketDetails();
                loadEstimate(activiyid);
                loadActions(ticketid)
            }
        })
            .catch();
    }

    const addMoreMaterialRow = (e) => {
        e.preventDefault();

        if (EstimatesDetails.estimatedata != undefined && EstimatesDetails.estimatedata != null) {
            var countRecords = EstimatesDetails.estimatedata.filter(x => x.type === "Material").length;
            if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
                var lastIndexTitle = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].item;
                var lastIndexQty = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].qty;
                var lastIndexUnitPrice = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].unitprice;
                var lastIndexAmount = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].amount;
                if (lastIndexTitle == "" || lastIndexTitle == null) {
                    return toast.error("Please Enter Material Title.");
                }
                else if (lastIndexQty == "" || lastIndexQty == 0) {
                    return toast.error("Please Enter Material Qty.");
                }
                else if (lastIndexUnitPrice == "" || lastIndexUnitPrice == 0) {
                    return toast.error("Please Enter Material Unit Price.");
                }
                else if (lastIndexAmount == "" || lastIndexAmount == 0) {
                    return toast.error("Please Enter Material Amount.");
                }
            }
        }
        if (EstimatesDetails.estimatedata == null) {
            EstimatesDetails.estimatedata = [];
        }
        EstimatesDetails.estimatedata.push({
            "id": 0,
            "item": "",
            "type": "Material",
            "qty": 0,
            "unitprice": 0,
            "amount": 0
        })
        setTest(test + 1)
    }

    const addMoreLaborRow = (e) => {
        e.preventDefault();
        if (EstimatesDetails.estimatedata != undefined && EstimatesDetails.estimatedata != null) {
            var countRecords = EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length;
            if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
                var lastIndexTitle = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].item;
                var lastIndexQty = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].qty;
                var lastIndexUnitPrice = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].unitprice;
                var lastIndexAmount = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].amount;
                if (lastIndexTitle == "" || lastIndexTitle == null) {
                    return toast.error("Please Enter Labor Title.");
                }
                else if (lastIndexQty == "" || lastIndexQty == 0) {
                    return toast.error("Please Enter Labor Qty.");
                }
                else if (lastIndexUnitPrice == "" || lastIndexUnitPrice == 0) {
                    return toast.error("Please Enter Labor Unit Price.");
                }
                else if (lastIndexAmount == "" || lastIndexAmount == 0) {
                    return toast.error("Please Enter Labor Amount.");
                }
            }
        }
        if (EstimatesDetails.estimatedata == null) {
            EstimatesDetails.estimatedata = [];
        }

        EstimatesDetails.estimatedata.push({
            "id": 0,
            "item": "",
            "type": "Labor",
            "qty": 0,
            "unitprice": 0,
            "amount": 0
        })
        setTest(test + 1)
    }


    const finalizeInvoice = (e) => {
        e.preventDefault();
        if (InvoicesDetails.invoicedata.length == 0)
            return toast.error('Please add atleast one row for material or labor');

        var countRecords = InvoicesDetails.invoicedata.filter(x => x.type === "Material").length;
        if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
            var lastIndexTitle_material = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].item;
            var lastIndexQty_material = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].qty;
            var lastIndexUnitPrice_material = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].unitprice;
            var lastIndexAmount_material = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].amount;
            if (lastIndexTitle_material == "" || lastIndexTitle_material == null) {
                return toast.error("Please Enter Material Title.");
            }
            else if (lastIndexQty_material == "" || lastIndexQty_material == 0) {
                return toast.error("Please Enter Material Qty.");
            }
            else if (lastIndexUnitPrice_material == "" || lastIndexUnitPrice_material == 0) {
                return toast.error("Please Enter Material Unit Price.");
            }
            else if (lastIndexAmount_material == "" || lastIndexAmount_material == 0) {
                return toast.error("Please Enter Material Amount.");
            }
        }

        countRecords = InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length;
        if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
            var lastIndexTitle_labor = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].item;
            var lastIndexQty_labor = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].qty;
            var lastIndexUnitPrice_labor = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].unitprice;
            var lastIndexAmount_labor = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].amount;
            if (lastIndexTitle_labor == "" || lastIndexTitle_labor == null) {
                return toast.error("Please Enter Labor Title.");
            }
            else if (lastIndexQty_labor == "" || lastIndexQty_labor == 0) {
                return toast.error("Please Enter Labor Qty.");
            }
            else if (lastIndexUnitPrice_labor == "" || lastIndexUnitPrice_labor == 0) {
                return toast.error("Please Enter Labor Unit Price.");
            }
            else if (lastIndexAmount_labor == "" || lastIndexAmount_labor == 0) {
                return toast.error("Please Enter Labor Amount.");
            }
        }

        var invoicedata = [];
        for (var i = 0; i < InvoicesDetails.invoicedata.length; i++) {
            //  if (InvoicesDetails.invoicedata[i].type != null && InvoicesDetails.invoicedata[i].type != "" && InvoicesDetails.invoicedata[i].type != "null") {
            invoicedata.push({
                "id": InvoicesDetails.invoicedata[i].id,
                "type": InvoicesDetails.invoicedata[i].type,
                "item": InvoicesDetails.invoicedata[i].item,
                "qty": String(InvoicesDetails.invoicedata[i].qty),
                "unitprice": Number(InvoicesDetails.invoicedata[i].unitprice),
                "amount": Number(InvoicesDetails.invoicedata[i].amount)
            })
            //}
        }
        var req = {
            "ticketid": ticketid,
            "activityid": activiyid,
            "invoicedata": invoicedata,
            "amountMaterials": Number(InvoicesDetails.amountMaterial),
            "amountLabour": Number(InvoicesDetails.amountLabour),
            "amountTotal": Number(InvoicesDetails.amountMaterial) + Number(InvoicesDetails.amountLabour),
            "amountDiscount": !isNaN(Number(InvoicesDetails.amountDiscount)) ? Number(InvoicesDetails.amountDiscount) : 0,
            "amountTax": Number(InvoicesDetails.amountTax),
            "discountPercentage": InvoicesDetails.discountpercentage == null ? 0 : Number(InvoicesDetails.discountpercentage),
            "amountTaxMaterialRate": Number(InvoicesDetails.amountTaxMaterialRate),
            "amountTaxMaterial": Number(InvoicesDetails.amountTaxMaterial),
            "amountTaxLabourRate": Number(InvoicesDetails.amountTaxLabourRate),
            "amountTaxLabour": Number(InvoicesDetails.amountTaxLabour),
            "amountPayable": Number(InvoicesDetails.amountPayable),
            "ponumber": poNumber_invoice,
            "notes": InvoicesDetails.notes,
            "updatedby": userid

        }

        FinalizeInvoice_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                loadTicketDetails();
                loadInvoice(activiyid);
            }
        })
            .catch();
    }

    const draftInvoice = (e) => {
        e.preventDefault();

        if (InvoicesDetails.invoicedata.length == 0)
            return toast.error('Please add atleast one row for material or labor');

        var countRecords = InvoicesDetails.invoicedata.filter(x => x.type === "Material").length;
        if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
            var lastIndexTitle_material = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].item;
            var lastIndexQty_material = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].qty;
            var lastIndexUnitPrice_material = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].unitprice;
            var lastIndexAmount_material = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].amount;
            if (lastIndexTitle_material == "" || lastIndexTitle_material == null) {
                return toast.error("Please Enter Material Title.");
            }
            else if (lastIndexQty_material == "" || lastIndexQty_material == 0) {
                return toast.error("Please Enter Material Qty.");
            }
            else if (lastIndexUnitPrice_material == "" || lastIndexUnitPrice_material == 0) {
                return toast.error("Please Enter Material Unit Price.");
            }
            else if (lastIndexAmount_material == "" || lastIndexAmount_material == 0) {
                return toast.error("Please Enter Material Amount.");
            }
        }

        countRecords = InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length;
        if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
            var lastIndexTitle_labor = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].item;
            var lastIndexQty_labor = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].qty;
            var lastIndexUnitPrice_labor = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].unitprice;
            var lastIndexAmount_labor = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].amount;
            if (lastIndexTitle_labor == "" || lastIndexTitle_labor == null) {
                return toast.error("Please Enter Labor Title.");
            }
            else if (lastIndexQty_labor == "" || lastIndexQty_labor == 0) {
                return toast.error("Please Enter Labor Qty.");
            }
            else if (lastIndexUnitPrice_labor == "" || lastIndexUnitPrice_labor == 0) {
                return toast.error("Please Enter Labor Unit Price.");
            }
            else if (lastIndexAmount_labor == "" || lastIndexAmount_labor == 0) {
                return toast.error("Please Enter Labor Amount.");
            }
        }

        var invoicedata = [];
        for (var i = 0; i < InvoicesDetails.invoicedata.length; i++) {
            //if (InvoicesDetails.invoicedata[i].type != null && InvoicesDetails.invoicedata[i].type != "" && InvoicesDetails.invoicedata[i].type != "null") {
            invoicedata.push({
                "type": InvoicesDetails.invoicedata[i].type,
                "item": InvoicesDetails.invoicedata[i].item,
                "qty": String(InvoicesDetails.invoicedata[i].qty),
                "unitprice": Number(InvoicesDetails.invoicedata[i].unitprice),
                "amount": Number(InvoicesDetails.invoicedata[i].amount)
            })
            //}
        }
        var req = {
            "ticketid": ticketid,
            "invoicedata": invoicedata,
            "amountMaterials": 0,
            "amountLabour": 0,
            "amountTotal": 0,
            "amountDiscount": 0,
            "amountTax": 0,
            "amountTaxMaterialRate": 0,
            "amountTaxMaterial": 0,
            "amountTaxLabourRate": 0,
            "amountTaxLabour": 0,
            "amountPayable": 0,
            "customername": "",
            "comment": "",
            "addedby": userid,
            "latitude": "",
            "longitude": "",
            "signature": "",
            "notes": InvoicesDetails.notes,

        }
        DraftInvoice_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                if (ticketType === "normal") {
                    loadTicketDetailsAfterDraft("invoice");
                }
                loadActions(ticketid);

            }
        })
            .catch();
    }

    const updateInvoice = (e) => {
        e.preventDefault();
        if (InvoicesDetails.invoicedata.length == 0)
            return toast.error('Please add atleast one row for material or labor');

        var countRecords = InvoicesDetails.invoicedata.filter(x => x.type === "Material").length;
        if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
            var lastIndexTitle_material = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].item;
            var lastIndexQty_material = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].qty;
            var lastIndexUnitPrice_material = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].unitprice;
            var lastIndexAmount_material = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].amount;
            if (lastIndexTitle_material == "" || lastIndexTitle_material == null) {
                return toast.error("Please Enter Material Title.");
            }
            else if (lastIndexQty_material == "" || lastIndexQty_material == 0) {
                return toast.error("Please Enter Material Qty.");
            }
            else if (lastIndexUnitPrice_material == "" || lastIndexUnitPrice_material == 0) {
                return toast.error("Please Enter Material Unit Price.");
            }
            else if (lastIndexAmount_material == "" || lastIndexAmount_material == 0) {
                return toast.error("Please Enter Material Amount.");
            }
        }

        countRecords = InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length;
        if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
            var lastIndexTitle_labor = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].item;
            var lastIndexQty_labor = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].qty;
            var lastIndexUnitPrice_labor = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].unitprice;
            var lastIndexAmount_labor = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].amount;
            if (lastIndexTitle_labor == "" || lastIndexTitle_labor == null) {
                return toast.error("Please Enter Labor Title.");
            }
            else if (lastIndexQty_labor == "" || lastIndexQty_labor == 0) {
                return toast.error("Please Enter Labor Qty.");
            }
            else if (lastIndexUnitPrice_labor == "" || lastIndexUnitPrice_labor == 0) {
                return toast.error("Please Enter Labor Unit Price.");
            }
            else if (lastIndexAmount_labor == "" || lastIndexAmount_labor == 0) {
                return toast.error("Please Enter Labor Amount.");
            }
        }
        var invoicedata = [];
        for (var i = 0; i < InvoicesDetails.invoicedata.length; i++) {
            //  if (InvoicesDetails.invoicedata[i].type != null && InvoicesDetails.invoicedata[i].type != "" && InvoicesDetails.invoicedata[i].type != "null") {
            invoicedata.push({
                "id": InvoicesDetails.invoicedata[i].id,
                "type": InvoicesDetails.invoicedata[i].type,
                "item": InvoicesDetails.invoicedata[i].item,
                "qty": String(InvoicesDetails.invoicedata[i].qty),
                "unitprice": Number(InvoicesDetails.invoicedata[i].unitprice),
                "amount": Number(InvoicesDetails.invoicedata[i].amount)
            })
            //}
        }
        var req = {
            "ticketid": ticketid,
            "activityid": activiyid,
            "invoicedata": invoicedata,
            "amountMaterials": Number(InvoicesDetails.amountMaterial),
            "amountLabour": Number(InvoicesDetails.amountLabour),
            "amountTotal": Number(InvoicesDetails.amountMaterial) + Number(InvoicesDetails.amountLabour),
            "amountDiscount": !isNaN(Number(InvoicesDetails.amountDiscount)) ? Number(InvoicesDetails.amountDiscount) : 0,
            "amountTax": Number(InvoicesDetails.amountTax),
            "discountPercentage": InvoicesDetails.discountpercentage == null ? 0 : Number(InvoicesDetails.discountpercentage),
            "amountTaxMaterialRate": Number(InvoicesDetails.amountTaxMaterialRate),
            "amountTaxMaterial": Number(InvoicesDetails.amountTaxMaterial),
            "amountTaxLabourRate": Number(InvoicesDetails.amountTaxLabourRate),
            "amountTaxLabour": Number(InvoicesDetails.amountTaxLabour),
            "amountPayable": Number(InvoicesDetails.amountPayable),
            "ponumber": poNumber_invoice,
            "updatedfrom": "web",
            "notes": InvoicesDetails.notes,
            "updatedby": userid

        }

        UpdateInvoice_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                loadTicketDetails();
                loadInvoice(activiyid);
            }
        })
            .catch();
    }

    const addMoreMaterialRow_invoice = (e) => {
        e.preventDefault();
        if (InvoicesDetails.invoicedata != undefined && InvoicesDetails.invoicedata != null) {
            var countRecords = InvoicesDetails.invoicedata.filter(x => x.type === "Material").length;
            if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
                var lastIndexTitle = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].item;
                var lastIndexQty = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].qty;
                var lastIndexUnitPrice = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].unitprice;
                var lastIndexAmount = InvoicesDetails.invoicedata.filter(x => x.type === "Material")[InvoicesDetails.invoicedata.filter(x => x.type === "Material").length - 1].amount;
                if (lastIndexTitle == "" || lastIndexTitle == null) {
                    return toast.error("Please Enter Material Title.");
                }
                else if (lastIndexQty == "" || lastIndexQty == 0) {
                    return toast.error("Please Enter Material Qty.");
                }
                else if (lastIndexUnitPrice == "" || lastIndexUnitPrice == 0) {
                    return toast.error("Please Enter Material Unit Price.");
                }
                else if (lastIndexAmount == "" || lastIndexAmount == 0) {
                    return toast.error("Please Enter Material Amount.");
                }
            }
        }
        if (InvoicesDetails.invoicedata == null) {
            InvoicesDetails.invoicedata = [];
        }
        InvoicesDetails.invoicedata.push({
            "id": 0,
            "item": "",
            "type": "Material",
            "qty": 0,
            "unitprice": 0,
            "amount": 0
        })
        setTest(test + 1)
    }

    const addMoreLaborRow_invoice = (e) => {
        e.preventDefault();
        if (InvoicesDetails.invoicedata != undefined && InvoicesDetails.invoicedata != null) {
            var countRecords = InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length;
            if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
                var lastIndexTitle = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].item;
                var lastIndexQty = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].qty;
                var lastIndexUnitPrice = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].unitprice;
                var lastIndexAmount = InvoicesDetails.invoicedata.filter(x => x.type === "Labor")[InvoicesDetails.invoicedata.filter(x => x.type === "Labor").length - 1].amount;
                if (lastIndexTitle == "" || lastIndexTitle == null) {
                    return toast.error("Please Enter Labor Title.");
                }
                else if (lastIndexQty == "" || lastIndexQty == 0) {
                    return toast.error("Please Enter Labor Qty.");
                }
                else if (lastIndexUnitPrice == "" || lastIndexUnitPrice == 0) {
                    return toast.error("Please Enter Labor Unit Price.");
                }
                else if (lastIndexAmount == "" || lastIndexAmount == 0) {
                    return toast.error("Please Enter Labor Amount.");
                }
            }
        }
        if (InvoicesDetails.invoicedata == null) {
            InvoicesDetails.invoicedata = [];
        }
        InvoicesDetails.invoicedata.push({
            "id": 0,
            "item": "",
            "type": "Labor",
            "qty": 0,
            "unitprice": 0,
            "amount": 0
        })
        setTest(test + 1)
    }

    const addNewEstimate = () => {
        setIsAddNewEstimate(true);
        setIsAddNewInvoice(false);
        setOpenSection("Estimates_draft")
        window.$("#addnotesdiv").hide();

        setEstimatesDetails({ amountDiscount: 0, amountTaxLabour: 0, amountLabour: 0, amountMaterial: 0, amountPayable: 0, amountTaxMaterial: 0, amountTaxMaterialRate: 0, amountTaxLabour: 0, amountTaxLabourRate: 0, amountTax: 0, estimatedata: [{ "type": "Material", "item": "", "qty": 0, "unitprice": 0, "amount": 0 }, { "type": "Labor", "item": "", "qty": 0, "unitprice": 0, "amount": 0 }] });
        setTest(test + 1)
    }

    const addNewInvoice = () => {
        setIsAddNewEstimate(false);
        setIsAddNewInvoice(true);
        setOpenSection("Invoices_draft")
        window.$("#addnotesdiv").hide();
        setInvoicesDetails({ amountDiscount: 0, amountTax: 0, amountLabour: 0, amountMaterial: 0, amountPayable: 0, amountTaxMaterial: 0, amountTaxMaterialRate: 0, amountTaxLabour: 0, amountTaxLabourRate: 0, amountTax: 0, invoicedata: [{ "type": "Material", "item": "", "qty": 0, "unitprice": 0, "amount": 0 }, { "type": "Labor", "item": "", "qty": 0, "unitprice": 0, "amount": 0 }] });
        // InvoicesDetails.invoicedata.push({"item" : "", "qty" : "" , "amount" : 0})
        setTest(test + 1)
    }

    const OpenOrderTypeEdit = () => {
        setSelectedOrderType(ticketDetails.ordertypeid);
        setOrderDetail(ticketDetails.orderdetail)
        setServiceAddress(ticketDetails.address)
        setJobName(ticketDetails.jobname)
        setJobContactName(ticketDetails.jobcontactname)
        setJobContactEmail(ticketDetails.jobcontactemail)
        setJobContactPhone(ticketDetails.jobcontactphone)
        //setPoDoc(ticketDetails.pofilepath)
        //setPoRequired(ticketDetails.poavailable)      
        window.$("#OrderType").modal('show');
    }

    const OpenPOWorkOrderEdit = () => {
        //console.log("ticket" , ticketDetails)
        setWorkOrderNumber(ticketDetails.workordernumber)
        setPoNumber(ticketDetails.ponumber)
        setPoDoc(ticketDetails.pofilepath)
        setPoRequired(ticketDetails.poavailable)
        window.$("#POWorkOrder").modal('show');
    }


    const showAssigneeUpdate = () => {
        setTechnicianID(ticketDetails.assignedtoUserID);
        setTicketDate(moment(ticketDetails.scheduleddate).format("yyyy-MM-DD"))
        setTicketTime(getTwentyFourHourTime(ticketDetails.scheduledtime))

        window.$("#AssigneeUpdate").modal('show');
    }

    const showStatusUpdate = () => {
        setSelectedStatusId(ticketDetails.currentstatusid);
        window.$("#StatusPop").modal('show');
    }

    const UpdateAssignee = (e) => {
        e.preventDefault();
        if (technicianId == 0 || technicianId == null)
            return toast.error("Please choose technician");
        else if (ticketDate == "" || ticketDate == null)
            return toast.error("Please enter date.");
        else if (ticketTime == "" || ticketTime == null)
            return toast.error("Please enter time.");
        else {
            var req = {
                "ticketid": ticketid,
                "scheduledate": ticketDate,
                "technicianid": Number(technicianId),
                "scheduletime": ticketTime,
                "updatedby": loggeduserid
            }
            UpdateTicketAssinee_api(req).then(res => {
                if (res != undefined) {
                    toast.success(res.message);
                    window.$("#AssigneeUpdate").modal('hide');
                    loadTicketDetails();
                }
            })
                .catch();
        }

    }



    const UpdateOrderType = (e) => {
        e.preventDefault();
        if (selectedOrderType == "" || selectedOrderType == null)
            return toast.error("Please choose order type.");
        else if (orderDetail == "" || orderDetail == null)
            return toast.error("Please enter order details.");
        else {
            var req = {
                "ticketid": Number(ticketid),
                //"customername": ticketTitle,
                "ordertypeid": Number(selectedOrderType),
                "orderdetails": orderDetail,
                "address": serviceAddress,
                "state": state,
                "city": city,
                "zipcode": zipcode,
                "latitude": String(latitude),
                "longitude": String(longitude),
                "jobname": jobname,
                "jobcontactname": jobcontactname,
                "jobcontactemail": jobcontactemail,
                "jobcontactphone": jobcontactphone,
                "updatedby": loggeduserid
            }
            UpdateTicketOrderDetails_api(req).then(res => {
                if (res != undefined) {
                    toast.success(res.message);
                    window.$("#OrderType").modal('hide');
                    loadTicketDetails();
                }
            })
                .catch();
        }

    }

    const UpdatePOWorkOrder = (e) => {
        e.preventDefault();

        if (poNumber == "" || poNumber == null)
            return toast.error("Please enter po number.");
        else if (workOrderNumber == "" || workOrderNumber == null)
            return toast.error("Please enter work order number.");
        else {
            var req = {
                "ticketid": Number(ticketid),
                "pofilepath": poDocument,
                "ponumber": poNumber,
                "workordernumber": workOrderNumber,
                "updatedby": loggeduserid
            }
            UpdateTicketPOWorkOrder_api(req).then(res => {
                if (res != undefined) {
                    toast.success(res.message);
                    window.$("#POWorkOrder").modal('hide');
                    loadTicketDetails();
                }
            })
                .catch();
        }

    }

    const UpdateStatus = (e) => {
        e.preventDefault();
        if (selectedStatusId == 0 || selectedStatusId == null)
            return toast.error("Please choose status.");

        else {
            var req = {
                "ticketid": Number(ticketid),
                "statusID": Number(selectedStatusId),
                "updatedby": loggeduserid
            }
            UpdateTicketStatus_api(req).then(res => {
                if (res != undefined) {
                    toast.success(res.message);
                    window.$("#StatusPop").modal('hide');
                    loadTicketDetails();
                    loadAgain();
                }
            })
                .catch();
        }

    }



    const UploadDocument = async (e) => {

        try {

            let multifile = [];
            const selectedFile = e.target.files;
            //for (let i = 0; i < selectedFile.length; i++) {
            const formData = new FormData();
            var today = new Date();
            var doctype = "";
            const ext = selectedFile[0].name.split('.').pop();
            const datevalue = today.getDate() + today.getMonth() + today.getFullYear() + today.getHours() + today.getMinutes() + today.getSeconds();
            const filename = "file_" + datevalue + "." + ext;
            if (ext == "png" || ext == "jpeg" || ext == "svg" || ext == "wpeg" || ext == "PNG" || ext == "JPEG" || ext == "JPG" || ext == "jpg" || ext == "SVG" || ext == "WPEG") {
                doctype = "Image";
            }
            else if (ext == "pdf" || ext == "PDF") {
                doctype = "PDF";
            }
            else if (ext == "docx" || ext == "DOCX" || ext == "doc" || ext == "DOC" || ext == "txt" || ext == "TXT") {
                doctype = "Document";
            }
            else if (ext == "pptx" || ext == "PPTX" || ext == "ppt" || ext == "PPT") {
                doctype = "Presentation";
            }
            else if (ext == "xlsx" || ext == "XLSX" || ext == "xls" || ext == "XLS") {
                doctype = "Workbook";
            }
            else {
                return toast.error('Please Select Valid file');
            }
            // Update the formData object 
            formData.append("uploadedby", loggeduserid);
            formData.append("file", selectedFile[0], filename);
            const Request = {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + AccessToken
                },
                body: formData
            }
            const response = await fetch(API_Base_Url + "upload", Request)
            const result = await response.json();
            if (response.ok) {
                // multifile = multifile.concat(result)
                setPoDoc(result.filepath)
                setTest(test + 1)
                setPoDocument(result.filepath)
                //$("#file-picker1").hide();
            }
            else if (response.status === 400) {
                if (result.errors == undefined) { }
                else {
                    toast.error(result.errors)
                }
            }
            else if (response.status === 401) {
                toast.error('Your Session has been expired, Please login again.');
                window.setTimeout(function () {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }, 2000);
            }
            else {
                //setisLoaded(true)
            }
            //}
        } catch (error) {
            toast.error('Something went wrong , Please try again later.')
        }


    }



    const handleChange_TextValue_qty_material_estimate = (item, event) => {
        var index = EstimatesDetails.estimatedata.indexOf(item);
        EstimatesDetails.estimatedata[index].qty = event.target.value;
        // var num = event.target.value.replace(/[^0-9\.]+/g,"");
        var num = parseFloat(event.target.value.match(/[\d\.]+/))
        EstimatesDetails.estimatedata[index].qtyno = num;
        EstimatesDetails.estimatedata[index].amount = calculateAmount(item);
        sumPayable()
        sumEstimateAmt()
        setTest(test + 1)
    }

    const handleChange_TextValue_qty_labor_estimate = (item, event) => {
        var index = EstimatesDetails.estimatedata.indexOf(item);
        EstimatesDetails.estimatedata[index].qty = event.target.value;
        var num = parseFloat(event.target.value.match(/[\d\.]+/));
        EstimatesDetails.estimatedata[index].qtyno = num;
        //EstimatesDetails.estimatedata[index].amount = EstimatesDetails.estimatedata[index].unitprice * EstimatesDetails.estimatedata[index].qtyno;
        EstimatesDetails.estimatedata[index].amount = calculateAmount(item);
        sumPayable()
        sumEstimateAmt()
        setTest(test + 1)
    }
    const handleChange_TextValue_UnitPrice_material_estimate = (item, event) => {
        var index = EstimatesDetails.estimatedata.indexOf(item);
        var num = parseFloat(item.qty.match(/[\d\.]+/));
        EstimatesDetails.estimatedata[index].qtyno = num;

        EstimatesDetails.estimatedata[index].unitprice = event.target.value;
        EstimatesDetails.estimatedata[index].amount = calculateAmount(item);
        sumPayable()
        sumEstimateAmt()
        setTest(test + 1)
    }

    const handleChange_TextValue_UnitPrice_labor_estimate = (item, event) => {
        var index = EstimatesDetails.estimatedata.indexOf(item);

        var num = parseFloat(item.qty.match(/[\d\.]+/));
        EstimatesDetails.estimatedata[index].qtyno = num;

        EstimatesDetails.estimatedata[index].unitprice = event.target.value;
        EstimatesDetails.estimatedata[index].amount = calculateAmount(item);
        sumPayable()
        sumEstimateAmt()
        setTest(test + 1)
    }



    const handleChange_TextValue_qty_material_invoice = (item, event) => {
        var index = InvoicesDetails.invoicedata.indexOf(item);
        InvoicesDetails.invoicedata[index].qty = event.target.value;
        var num = parseFloat(event.target.value.match(/[\d\.]+/));
        InvoicesDetails.invoicedata[index].qtyno = num;
        InvoicesDetails.invoicedata[index].amount = calculateAmount(item);
        sumPayable_invoice()
        sum_invoice()
        setTest(test + 1)
    }

    const handleChange_TextValue_qty_labor_invoice = (item, event) => {
        var index = InvoicesDetails.invoicedata.indexOf(item);

        InvoicesDetails.invoicedata[index].qty = event.target.value;
        var num = parseFloat(event.target.value.match(/[\d\.]+/));
        InvoicesDetails.invoicedata[index].qtyno = num;
        InvoicesDetails.invoicedata[index].amount = calculateAmount(item);
        sumPayable_invoice()
        sum_invoice()
        setTest(test + 1)
    }
    const handleChange_TextValue_UnitPrice_material_invoice = (item, event) => {
        var index = InvoicesDetails.invoicedata.indexOf(item);

        var num = parseFloat(item.qty.match(/[\d\.]+/));
        InvoicesDetails.invoicedata[index].qtyno = num;

        InvoicesDetails.invoicedata[index].unitprice = event.target.value;
        InvoicesDetails.invoicedata[index].amount = calculateAmount(item);
        sumPayable_invoice()
        sum_invoice()
        setTest(test + 1)
    }

    const handleChange_TextValue_UnitPrice_labor_invoice = (item, event) => {
        var index = InvoicesDetails.invoicedata.indexOf(item);

        var num = parseFloat(item.qty.match(/[\d\.]+/));
        InvoicesDetails.invoicedata[index].qtyno = num;

        InvoicesDetails.invoicedata[index].unitprice = event.target.value;
        InvoicesDetails.invoicedata[index].amount = calculateAmount(item);
        sumPayable_invoice()
        sum_invoice()
        setTest(test + 1)
    }

    function initMap_ticket() {
        var input = document.getElementById('searchMapInputTicket');
        setServiceAddress(input.value)
        var autocomplete = new window.google.maps.places.Autocomplete(input);

        autocomplete.addListener('place_changed', function () {
            var place = autocomplete.getPlace();
            let div = document.createElement('div');
            div.innerHTML = place.adr_address;
            let cityname = div.getElementsByClassName('locality')[0] != undefined ? div.getElementsByClassName('locality')[0].innerHTML : "";
            let statename = div.getElementsByClassName('region')[0] != undefined ? div.getElementsByClassName('region')[0].innerHTML : "";
            let lat = place.geometry.location.lat();
            let long = place.geometry.location.lng();
            setCity(cityname)
            setState(statename)
            setLatitude(lat)
            setLongitude(long)
            setServiceAddress(place.formatted_address)
        });
    }
    const onfocusSearch = () => {
        window.$("#searchMapInputTicket").attr("autocomplete", "new-password");
    }

    const removeRow_estimate = (item) => {
        EstimatesDetails.estimatedata = EstimatesDetails.estimatedata.filter(x => x !== item);
        sumEstimateAmt()
        setTest(test + 1)
    }

    const removeRow_invoice = (item) => {
        InvoicesDetails.invoicedata = InvoicesDetails.invoicedata.filter(x => x !== item);
        sum_invoice()
        setTest(test + 1)
    }

    const sendMail = (type) => {
        var photos = [];
        var selectedphotos = [];
        if (type == "estimate") {
            if (EstimatesDetails.photos != undefined && EstimatesDetails.photos.length > 0) {
                selectedphotos = EstimatesDetails?.photos?.filter(x => x.selected == true);
                if (selectedphotos.length > 0) {
                    for (var i = 0; i < selectedphotos.length; i++) {
                        photos.push({
                            "photoid": selectedphotos[i].photoid
                        })
                    }
                }
            }
        }

        if (type == "invoice") {
            if (InvoicesDetails.photos != undefined && InvoicesDetails.photos.length > 0) {
                selectedphotos = InvoicesDetails.photos.filter(x => x.selected == true);
                if (selectedphotos.length > 0) {
                    for (var i = 0; i < selectedphotos.length; i++) {
                        photos.push({
                            "photoid": selectedphotos[i].photoid
                        })
                    }
                }
            }
        }


        var req = {
            "ticketid": ticketid,
            "activityid": activiyid,
            "photos": photos,
            "sentby": userid

        }
        SendEmail_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                if (type == "estimate")
                    setIsMailSent(true)
                if (type == "invoice")
                    setIsMailSent_invoice(true)
                loadTicketDetails();
                // loadInvoice(activiyid);
                // loadEstimate(activiyid)
            }
        })
            .catch();
    }

    const reSendMail = (type) => {
        var photos = [];
        var selectedphotos = [];
        if (type == "estimate") {
            if (EstimatesDetails.photos != undefined && EstimatesDetails.photos.length > 0) {
                selectedphotos = EstimatesDetails.photos.filter(x => x.selected == true);
                if (selectedphotos.length > 0) {
                    for (var i = 0; i < selectedphotos.length; i++) {
                        photos.push({
                            "photoid": selectedphotos[i].photoid
                        })
                    }
                }
            }
        }

        if (type == "invoice") {
            if (InvoicesDetails.photos != undefined && InvoicesDetails.photos.length > 0) {
                selectedphotos = InvoicesDetails.photos.filter(x => x.selected == true);
                if (selectedphotos.length > 0) {
                    for (var i = 0; i < selectedphotos.length; i++) {
                        photos.push({
                            "photoid": selectedphotos[i].photoid
                        })
                    }
                }
            }
        }
        var req = {
            "ticketid": ticketid,
            "activityid": activiyid,
            "photos": photos,
            "sentby": userid

        }
        ResendEmail_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                loadTicketDetails();
                // loadInvoice(activiyid);
                // loadEstimate(activiyid)
            }
        })
            .catch();

    }

    const handlenotes_estimate = (text) => {
        EstimatesDetails.notes = text;
        setTest(test + 1)

    }
    const handlenotes_invoice = (text) => {
        InvoicesDetails.notes = text;
        setTest(test + 1)
    }

    const SelectImage_estimate = (item) => {
        var index = EstimatesDetails.photos.indexOf(item);
        EstimatesDetails.photos[index].selected = EstimatesDetails.photos[index].selected ? false : true;
        setTest(test + 1)
    }
    const SelectImage_invoice = (item) => {
        var index = InvoicesDetails.photos.indexOf(item);
        InvoicesDetails.photos[index].selected = InvoicesDetails.photos[index].selected ? false : true;
        setTest(test + 1)
    }
    const handleChange_disc_percentage_estimate = (event) => {
        const amount = event.target.value;
        if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
            EstimatesDetails.discountpercentage = amount;
        }
        sumPayable();
        setTest(test + 1)
    }
    const handleChange_disc_percentage_invoice = (event) => {
        const amount = event.target.value;
        if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
            InvoicesDetails.discountpercentage = amount;
        }
        sumPayable_invoice();
        setTest(test + 1)
    }

    const editinvoiceduedate = (date, date2) => {
        setInvoiceduedate(format(new Date(date), "MM/dd/yyyy"))
        setInvoicedate(format(new Date(date2), "MM/dd/yyyy"))
        window.$("#duedatepopup").modal('show');
    }

    const editinvoicedate = (date, date2) => {
        setInvoiceduedate(format(new Date(date2), "MM/dd/yyyy"))
        setInvoicedate(format(new Date(date), "MM/dd/yyyy"))
        window.$("#datepopup").modal('show');
    }

    const editinvoiceno = (date, date2, invoiceno) => {
        setInvoiceduedate(format(new Date(date), "MM/dd/yyyy"))
        setInvoicedate(format(new Date(date2), "MM/dd/yyyy"))
        setInvoiceno(invoiceno)
        window.$("#invoiceno").modal('show');
    }

    const showCreateTicketPopUp = () => {
        if (cid == 0)
            return toast.error('Please select customer')
        else {
            setTestId(testId + 1)
            window.$("#CreateTicket").modal('show');
        }

    }

    const refreshDashboard = () => {
        history.push('/tickets')
    }

    //#region  Delete Function                            added by sonali  31/03/22 feedbacks

    const deleteTicketPopUp = () => {
        window.$("#DeletePopUp").modal('show');
    }

    const DeleteTicket_submit = (id) => {
        // window.$("#DeletePopUp").modal('hide');
        // window.$("#TicketDetails").modal('hide');
        DeleteTicket_api(id).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                window.$("#DeletePopUp").modal('hide');
                window.$("#TicketDetails").modal('hide');
                loadAgain();
            }
        })
            .catch();

    }

    //#endregion


    const RestoreTicket = () => {
        RestoreTicket_api(ticketid).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                window.$("#TicketDetails").modal('hide');
                loadAgain();
            }
        }).catch();
    }



    return (
        <>
            <div className="modal fade modal-fullscreen fsm-popup popticketdetails" id="TicketDetails" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <a className="close" data-dismiss="modal" aria-hidden="true"> <img src="images/close.png" /></a>
                            <h5 className="modal-title" id="myModalLabel" style={{ display: 'inline' }}>#{ticketid} - Ticket Details</h5>
                            <button onClick={() => refresh()} className="btn btn-default btn-xs" style={{ marginLeft: '10px' }} >Reload</button>

                            {ticketType === "normal" ?
                                ((ticketDetails.currentstatus === "Assigned" || ticketDetails.currentstatus === "Created" || ticketDetails.currentstatus === "In-Progress" || ticketDetails.currentstatus === "Awaited" || ticketDetails.currentstatus === "Cancelled") && (!ticketDetails.currentstatusactivity.includes('Estimate') && !ticketDetails.currentstatusactivity.includes('Invoice') && !ticketDetails.currentstatusactivity.includes('Paid') )) &&
                                <button className="btn btn-danger btn-xs" style={{ marginLeft: '10px' }} onClick={(e) => deleteTicketPopUp()} >Delete</button>
                                : <button className="btn btn-theme btn-xs" style={{ marginLeft: '10px' }} onClick={(e) => RestoreTicket()} >Restore</button>
                            }

                            {ticketDetails.assignedtoName && <h5 id="myModalLabel" style={{ display: 'inline', marginLeft: "170px" }}>Job Scheduled at  {ticketDetails.scheduleddate + " " + ticketDetails.scheduledtime}</h5>}

                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="box box-solid ">
                                        <div className="box-body">
                                            <div className="order-details">
                                                <div className="row">
                                                    <div className="col-md-3  col-sm-12">
                                                        <div className="od-col4">
                                                            <div className="ed-top">
                                                                <h4 className="ed-title"><span className="mr-15">{ticketDetails.ordertype}</span>
                                                                    {ticketType === "normal" &&
                                                                        <a style={{ cursor: 'pointer' }} onClick={() => OpenOrderTypeEdit()} className="od-edit"><i className="fa fa-pencil"></i></a>}
                                                                </h4>
                                                                <p className="rpc">{ticketDetails.orderdetail}</p>
                                                                <p className="rpc">{ticketDetails.address}</p>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2  col-sm-5">
                                                        <div className="od-col4">
                                                            <div className="ed-top">
                                                                <h4 className="ed-title"><span className="mr-15">Job Contact</span></h4>
                                                                <h5 className="ed-title"><span className="mr-15">{ticketDetails.jobname !== null ? ticketDetails.jobname : ""}</span></h5>
                                                                <p>{ticketDetails.jobcontactname !== null ? ticketDetails.jobcontactname : ""}</p>
                                                                <p>{ticketDetails.jobcontactemail !== null ? ticketDetails.jobcontactemail : ""}</p>
                                                                <p>{ticketDetails.jobcontactphone !== null ? ticketDetails.jobcontactphone : ""}</p>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2  col-sm-5 ">
                                                        <div className="od-col4">
                                                            <div className="ed-top">
                                                                <h4 className="ed-title"><span className="mr-15">PO / Work Order</span>
                                                                    {ticketType === "normal" &&
                                                                        <a style={{ cursor: 'pointer' }} onClick={() => OpenPOWorkOrderEdit()} className="od-edit"><i className="fa fa-pencil"></i></a>
                                                                    }
                                                                </h4>
                                                                <div className="ord-assigned">
                                                                    <p className="rpc">PO No. {ticketDetails.ponumber}</p>
                                                                    <p className="rpc"> Work Order No.   {ticketDetails.workordernumber}</p>
                                                                </div>
                                                            </div>
                                                            <div className="purchase-order" >
                                                                {
                                                                    (ticketDetails.pofilepath != "" && ticketDetails.pofilepath != "NA" && ticketDetails.poavailable)
                                                                        ?
                                                                        <a href={ticketDetails.pofilepath} target="_blank">
                                                                            <span className="" >  <img src="images/download.png" width="17" className="" /> </span>

                                                                            <span className="ed-title"><span className="mr-15">Purchase Order </span>
                                                                            </span>
                                                                        </a>
                                                                        :
                                                                        "No Purchase order available."

                                                                }

                                                            </div>


                                                        </div>
                                                    </div>


                                                    <div className="col-md-2  col-sm-5 ">
                                                        <div className="od-col4">
                                                            <div className="ed-top">
                                                                <h4 className="ed-title"><span className="mr-15">Assigned to</span>
                                                                    {ticketType === "normal" &&
                                                                        <a style={{ cursor: 'pointer' }} onClick={() => showAssigneeUpdate()} className="od-edit"><i className="fa fa-pencil"></i></a>
                                                                    }
                                                                </h4>
                                                                <div className="ord-assigned">
                                                                    {/* {
                                                                        ticketDetails.assignedtoProfilePic != "" && ticketDetails.assignedtoProfilePic != "NA"
                                                                        &&
                                                                        <img src={ticketDetails.assignedtoProfilePic} className="user-icon" />
                                                                    } */}

                                                                    <div className="user-detl">
                                                                        <p className="name">{ticketDetails.assignedtoName} </p>
                                                                        <span className="phone">{ticketDetails.assignedtoPhone}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="asmt-status-bar ">
                                                                <span className="ass-to mr-15">Status </span>
                                                                <br />
                                                                <span className="blue-dot"><i className="fa fa-circle"></i></span>
                                                                <span className="dot-status ed-title"><span className="mr-15">{ticketDetails.currentstatus} ({ticketDetails.currentstatusactivity})</span>
                                                                    {ticketType === "normal" &&
                                                                        <a style={{ cursor: 'pointer' }} onClick={() => showStatusUpdate()} className="od-edit"><i className="fa fa-pencil"></i></a>
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3  col-sm-7">
                                                        <div className="">
                                                            <div className="order-box-desc">
                                                                <h4 className="mr-15">{ticketDetails.customername} </h4>
                                                                <a className="btn btn-gray btn-sm">{ticketDetails.customertype}</a>
                                                                <h5>{ticketDetails.billingaddress}</h5>
                                                                <p><img src="images/person.png" />{ticketDetails.contactname} </p>
                                                                <p><img src="images/phone.png" /> {ticketDetails.contactphone} </p>
                                                                <p><img src="images/email.png" /> {ticketDetails.contactemail} </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="activity-title">
                                        <h4 className="act-heading">Activities</h4>
                                        {ticketType === "normal" &&
                                            <div className="dropdown actddlbtn">
                                                <a className="btn btn-theme dropdown-toggle" type="button" data-toggle="dropdown"><i className="fa fa-plus"></i> &nbsp;New
                                                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="caret"></span></a>
                                                <ul className="dropdown-menu act-dropdown">
                                                    {
                                                        actionList.length > 0
                                                        &&
                                                        actionList.map(item => (
                                                            <>
                                                                {
                                                                    item.action == "Notes"
                                                                    &&
                                                                    <li><a style={{ cursor: 'pointer' }} onClick={() => openNotesAdd()}>Notes</a></li>
                                                                }
                                                                {
                                                                    item.action == "Estimate"
                                                                    &&
                                                                    <li><a style={{ cursor: 'pointer' }} onClick={() => addNewEstimate()}>Estimate</a></li>
                                                                }
                                                                {
                                                                    item.action == "Invoice"
                                                                    &&
                                                                    <li><a style={{ cursor: 'pointer' }} onClick={() => addNewInvoice()}>Invoice</a></li>
                                                                }
                                                            </>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                    <div className="box box-solid activitycontainer">
                                        <div className="box-body">
                                            <div className="line">
                                                <div className="line-content">
                                                    {
                                                        ticketDetails.activities != null && ticketDetails.activities != undefined
                                                            &&
                                                            ticketDetails.activities.length == 0
                                                            ?
                                                            "No Record Found."
                                                            :
                                                            ticketDetails.activities != null
                                                            &&
                                                            ticketDetails.activities.map(item => (
                                                                <>
                                                                    <div className="timeline-content">
                                                                        {item.activitytype == "Notes" &&
                                                                            <div className="timeline-text actionable" onClick={() => loadNotesDetails(item.activityid)}>
                                                                                <h1>{item.activity}</h1>
                                                                                <p>{item.activityday}, {item.activitydate} {item.activitytime} <img src="images/right-arrow.svg" alt="" className="actionimg" /></p>
                                                                            </div>
                                                                        }

                                                                        {item.activitytype == "Photos" &&
                                                                            <div className="timeline-text actionable" onClick={() => loadPhotos(item.activityid)}>
                                                                                <h1>{item.activity}</h1>
                                                                                <p>{item.activityday}, {item.activitydate} {item.activitytime} <img src="images/right-arrow.svg" alt="" className="actionimg" /></p>
                                                                            </div>
                                                                        }

                                                                        {item.activitytype == "Estimate" &&
                                                                            <div className="timeline-text actionable" onClick={() => loadBoth(item.activityid)}>
                                                                                <h1>{item.activity}</h1>
                                                                                <p>{item.activityday}, {item.activitydate} {item.activitytime} <img src="images/right-arrow.svg" alt="" className="actionimg" /></p>
                                                                            </div>
                                                                        }

                                                                        {item.activitytype == "Invoice" &&
                                                                            <div className="timeline-text actionable" onClick={() => loadInvoice(item.activityid)}>
                                                                                <h1>{item.activity}</h1>
                                                                                <p>{item.activityday}, {item.activitydate} {item.activitytime}  <img src="images/right-arrow.svg" alt="" className="actionimg" /></p>
                                                                            </div>
                                                                        }

                                                                        {item.activitytype != "Invoice" && item.activitytype != "Estimate" && item.activitytype != "Photos" && item.activitytype != "Notes" &&
                                                                            <div className="timeline-text">
                                                                                <h1>{item.activity}</h1>
                                                                                <p>{item.activityday}, {item.activitydate} {item.activitytime}</p>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </>

                                                            ))
                                                    }


                                                </div>
                                            </div>
                                            {/* <div className="actaddbtn">
                                            <a href="">ADD</a>
                                        </div> */}

                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-9">
                                    {invoicestatus === "Invoice Drafted" ? <button className="btn btn-theme pull-right" onClick={() => showCreateTicketPopUp()}>Re-create</button> : ""}

                                    {/* Add Notes */}
                                    <div className="box box-solid act-rightpnl" id="addnotesdiv">
                                        <div className="box-body act-body">
                                            <div className="act-box-heading">

                                                {/* <h4 className="act-title">Notes </h4> */}

                                            </div>
                                            <div className="od-notes">
                                                <h4 className="ed-title"><img src="images/notes.png" /><span className="mr-15">Notes </span></h4>

                                                <textarea rows="4" cols="64" value={txtnotes} onChange={(e) => setTxtNotes(e.target.value)}   ></textarea>
                                                <br />
                                                <button className="btn btn-primary" onClick={(e) => submitNotes(e)}>Create</button>
                                                <button className="btn btn-default pull-right" onClick={() => window.$("#addnotesdiv").hide()}>Cancel</button>
                                            </div>

                                        </div>
                                    </div>
                                    {
                                        openSection == "Notes"
                                        &&
                                        <div className="box box-solid act-rightpnl">
                                            <div className="box-body act-body">
                                                <div className="act-box-heading">

                                                    <h4 className="act-title">Notes </h4>
                                                    <p className="act-time">{notesdetails.activityday}, {notesdetails.activitydate} {notesdetails.activitytime}</p>
                                                    <p className="act-by">by: {notesdetails.activitybyName}</p>
                                                </div>
                                                <div className="od-notes">
                                                    <h4 className="ed-title"><img src="images/notes.png" /><span className="mr-15">Notes </span>
                                                        {/* <a href="" className="od-edit"><i className="fa fa-pencil"></i></a> */}
                                                    </h4>

                                                    <p>{notesdetails.notes} </p>
                                                </div>

                                                {/* <div className="act-box-heading" hidden={(notesdetails.activitybyRole == "Admin" && notesdetails.activitybyRole == "Supervisor ") ? true : false}>
                                                    <h4 className="act-title">Signature</h4>
                                                    <p className="act-time">{notesdetails.activityday}, {notesdetails.activitydate} {notesdetails.activitytime}</p>
                                                    <p className="act-by">by: {notesdetails.activitybyName}</p>
                                                </div>
                                                <div className="">
                                                    <img src={notesdetails.signature} />
                                                </div> */}

                                            </div>

                                            {/* Signature */}

                                        </div>

                                    }

                                    {
                                        openSection == "Photos"
                                        &&
                                        <div className="box box-solid act-rightpnl">
                                            <div className="box-body act-body">
                                                <div className="od-photos">
                                                    <h4 className=""><img src="images/photo-camera.png" />Photos </h4>
                                                    <div className="od-photos-list">

                                                        {
                                                            photosList.length == 0 ?
                                                                "No Photos Available."
                                                                :
                                                                // photosList != null &&
                                                                photosList.map(item => (
                                                                    <a href={item.photopath} target="_blank">
                                                                        <img src={item.photopath} />
                                                                    </a>
                                                                ))
                                                        }


                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    }

                                    {
                                        openSection == "Estimates_draft"
                                        &&
                                        <div className="box box-solid">
                                            <div className="box-body act-body">
                                                <div className="act-box-heading">
                                                    <h4 className="act-title">Estimate</h4>
                                                    {
                                                        ((EstimatesDetails.status == "Finalized" || EstimatesDetails.status == "Submitted") && isMailSent == false)
                                                        &&
                                                        <a onClick={() => sendMail("estimate")} className="btn btn-default pull-right" >Send Mail</a>
                                                    }
                                                    {
                                                        ((EstimatesDetails.status == "Finalized" || EstimatesDetails.status == "Submitted") && isMailSent == true)
                                                        &&
                                                        <a onClick={() => reSendMail("estimate")} className="btn btn-default pull-right" >Resend Mail</a>
                                                    }



                                                </div>
                                                <div className="mt30">
                                                    <div className="row estinvform">
                                                        <div className="col-md-6">
                                                            <div className="estsub-title">
                                                                <h4 className="esttitle mt0">Material</h4>
                                                                {
                                                                    // EstimatesDetails.currentstatusactivity != "Estimate Approved"
                                                                    // &&
                                                                    <div className="est-addbtn">
                                                                        <a style={{ cursor: 'pointer' }} onClick={(e) => addMoreMaterialRow(e)} className="btn btn-gray btn-sm"><i className="fa fa-plus"></i> &nbsp; Add</a>
                                                                    </div>
                                                                }

                                                            </div>
                                                            <div className="row">
                                                                <div>
                                                                    <div className="col-md-5 pright0">
                                                                        <div className="form-group">
                                                                            Title
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3 pright0">
                                                                        <div className="form-group">
                                                                            Qty
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <div className="form-group">
                                                                            Unit Price
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <div className="form-group">
                                                                            Amount
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    EstimatesDetails.estimatedata != null &&
                                                                    EstimatesDetails.estimatedata.length > 0
                                                                    &&
                                                                    EstimatesDetails != null &&
                                                                    EstimatesDetails.estimatedata.filter(x => x.type == "Material").map(item => (
                                                                        <div>
                                                                            <div className="col-md-5 pright0">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control" value={item.item} placeholder="Material" onChange={(e) => handleChange_TextValue_item(item, e)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 pright0">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control" value={item.qty} placeholder="Quantity" onChange={(e) => handleChange_TextValue_qty_material_estimate(item, e)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-2 pright0">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control" value={item.unitprice} placeholder="Unit Price" onChange={(e) => handleChange_TextValue_UnitPrice_material_estimate(item, e)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <div className="form-group itemamount">
                                                                                    <input type="text" className="form-control" disabled value={item.amount} placeholder="Total Amt" onChange={(e) => handleChange_Material(item, e)} />
                                                                                    <a className="removeitem" onClick={() => removeRow_estimate(item)} ><img src="images/remove.svg" alt="" /></a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }


                                                                <div className="col-md-6 pright0">

                                                                </div>
                                                                {
                                                                    EstimatesDetails.estimatedata != null && EstimatesDetails.estimatedata != undefined &&
                                                                    EstimatesDetails?.estimatedata?.filter(x => x.type == "Material").length > 0
                                                                    &&

                                                                    <>
                                                                        <div className="col-md-3 pright0">
                                                                            <h4 className="total-right">Total</h4>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <div className="form-group">
                                                                                <input type="text" className="form-control right" value={EstimatesDetails.amountMaterial} disabled placeholder="" />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="estsub-title">
                                                                <h4 className="esttitle mt0">Labor </h4>
                                                                {
                                                                    // EstimatesDetails.currentstatusactivity != "Estimate Approved"
                                                                    // && 
                                                                    <div className="est-addbtn" >
                                                                        <a style={{ cursor: 'pointer' }} onClick={(e) => addMoreLaborRow(e)} className="btn btn-gray btn-sm"><i className="fa fa-plus"></i> &nbsp; Add</a>
                                                                    </div>
                                                                }

                                                            </div>
                                                            <div className="row">
                                                                <div>
                                                                    <div className="col-md-5 pright0">
                                                                        <div className="form-group">
                                                                            Title
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3 pright0">
                                                                        <div className="form-group">
                                                                            Qty
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <div className="form-group">
                                                                            Unit Price
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <div className="form-group">
                                                                            Amount
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    EstimatesDetails.estimatedata != null &&
                                                                    EstimatesDetails.estimatedata.length > 0
                                                                    &&
                                                                    EstimatesDetails.estimatedata.filter(x => x.type == "Labor").map(item => (
                                                                        <div>
                                                                            <div className="col-md-5">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control" value={item.item} placeholder="Title" onChange={(e) => handleChange_TextValue_item(item, e)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control" value={item.qty} placeholder="Quantity" onChange={(e) => handleChange_TextValue_qty_labor_estimate(item, e)} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-2">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control" value={item.unitprice} placeholder="Unit Price" onChange={(e) => handleChange_TextValue_UnitPrice_labor_estimate(item, e)} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-2">
                                                                                <div className="form-group itemamount">
                                                                                    <input type="text" className="form-control" disabled value={item.amount} placeholder="" onChange={(e) => handleChange_Labor(item, e)} />
                                                                                    <a className="removeitem" onClick={() => removeRow_estimate(item)} ><img src="images/remove.svg" alt="" /></a>
                                                                                </div>
                                                                            </div>





                                                                        </div>
                                                                    ))
                                                                }


                                                                <div className="col-md-6 pright0">

                                                                </div>
                                                                {
                                                                    EstimatesDetails?.estimatedata?.filter(x => x.type == "Labor").length > 0
                                                                    &&
                                                                    <>
                                                                        <div className="col-md-3 pright0">
                                                                            <h4 className="total-right">Total</h4>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <div className="form-group">
                                                                                <input type="text" className="form-control right" value={EstimatesDetails.amountLabour} disabled placeholder="" />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-8">
                                                        </div>
                                                        <div className="col-md-4">

                                                            {
                                                                ticketDetails.currentstatusactivity !== "Estimate Approved"
                                                                &&
                                                                <div className="topbtn-right mb-20">
                                                                    {
                                                                        (EstimatesDetails.status == "" || EstimatesDetails.status == null || EstimatesDetails.status == undefined)
                                                                        &&
                                                                        <a style={{ cursor: 'pointer' }} onClick={(e) => draftEstimate(e)} className="btn btn-theme" >Save</a>
                                                                    }
                                                                    {
                                                                        (EstimatesDetails.status == "Draft")
                                                                        &&
                                                                        <a style={{ cursor: 'pointer' }} onClick={(e) => finalizeEstimate(e)} className="btn btn-theme" >Save</a>
                                                                    }

                                                                    {
                                                                        (EstimatesDetails.status == "Finalized")
                                                                        &&
                                                                        <a style={{ cursor: 'pointer' }} onClick={(e) => updateEstimate(e)} className="btn btn-theme" >Save</a>
                                                                    }

                                                                    {
                                                                        (EstimatesDetails.status == "Submitted")
                                                                        &&
                                                                        <a style={{ cursor: 'pointer' }} onClick={(e) => updateEstimate(e)} className="btn btn-theme" >Save</a>
                                                                        // <a style={{ cursor: 'pointer' }} onClick={(e) => finalizeEstimate(e)} className="btn btn-theme" >Submit</a>
                                                                    }
                                                                    {
                                                                        (EstimatesDetails.status == "Approved")
                                                                        &&
                                                                        null
                                                                        // <a style={{ cursor: 'pointer' }} onClick={(e) => updateEstimate(e)} className="btn btn-theme" >Save</a>
                                                                        // <a style={{ cursor: 'pointer' }} onClick={(e) => finalizeEstimate(e)} className="btn btn-theme" >Submit</a>
                                                                    }

                                                                </div>

                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    }
                                    {
                                        openSection == "Estimates"
                                        &&
                                        <div className="box box-solid">
                                            <div className="box-body act-body">
                                                <div className="act-box-heading">
                                                    <h4 className="act-title">Estimate</h4>
                                                    {
                                                        ((EstimatesDetails.status == "Finalized" || EstimatesDetails.status == "Submitted") && isMailSent == false)
                                                        &&
                                                        <a onClick={() => sendMail("estimate")} className="btn btn-default pull-right" >Send Mail</a>
                                                    }
                                                    {
                                                        ((EstimatesDetails.status == "Finalized" || EstimatesDetails.status == "Submitted") && isMailSent == true)
                                                        &&
                                                        <a onClick={() => reSendMail("estimate")} className="btn btn-default pull-right" >Resend Mail</a>
                                                    }
                                                    {
                                                        EstimatesDetails.downloadpath != ""
                                                        &&
                                                        <a className="btn btn-default pull-right" href={EstimatesDetails.downloadpath} target="_blank" >Download</a>
                                                    }

                                                    <p className="act-time"> {EstimatesDetails.creationdate} {EstimatesDetails.creationtime}</p>
                                                    {
                                                        isAddNewEstimate == false
                                                        &&
                                                        <p className="act-by">by: {EstimatesDetails.createdbyName}</p>
                                                    }

                                                </div>
                                                <div className="mt30">
                                                    <div className="row estinvform">
                                                        <div className="col-md-6">
                                                            <div className="estsub-title">
                                                                <h4 className="esttitle mt0">Material</h4>
                                                                {
                                                                    // EstimatesDetails.currentstatusactivity != "Estimate Approved"
                                                                    // &&
                                                                    <div className="est-addbtn">
                                                                        {EstimatesDetails.status != "Approved" &&
                                                                            <a style={{ cursor: 'pointer' }} onClick={(e) => addMoreMaterialRow(e)} className="btn btn-gray btn-sm"><i className="fa fa-plus"></i> &nbsp; Add</a>
                                                                        }
                                                                    </div>
                                                                }

                                                            </div>
                                                            <div className="row">
                                                                <div>
                                                                    <div className="col-md-5 pright0">
                                                                        <div className="form-group">
                                                                            Title
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3 pright0">
                                                                        <div className="form-group">
                                                                            Qty
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <div className="form-group">
                                                                            Unit Price
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <div className="form-group">
                                                                            Amount
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    EstimatesDetails.estimatedata != null &&
                                                                    EstimatesDetails.estimatedata.length > 0
                                                                    &&
                                                                    EstimatesDetails != null &&
                                                                    EstimatesDetails.estimatedata.filter(x => x.type == "Material").map(item => (
                                                                        <div>
                                                                            <div className="col-md-5 pright0">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control" value={item.item} placeholder="Material" onChange={(e) => handleChange_TextValue_item(item, e)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 pright0">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control" value={item.qty} placeholder="Quantity" onChange={(e) => handleChange_TextValue_qty_material_estimate(item, e)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-2 pright0">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control" value={item.unitprice} placeholder="Unit Price" onChange={(e) => handleChange_TextValue_UnitPrice_material_estimate(item, e)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <div className="form-group itemamount">
                                                                                    <input type="text" className="form-control" disabled value={item.amount} placeholder="Total Amt" onChange={(e) => handleChange_Material(item, e)} />
                                                                                    {EstimatesDetails.status != "Approved" &&
                                                                                        <a className="removeitem" onClick={() => removeRow_estimate(item)} ><img src="images/remove.svg" alt="" /></a>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }


                                                                <div className="col-md-6 pright0">

                                                                </div>
                                                                {
                                                                    EstimatesDetails.estimatedata != null && EstimatesDetails.estimatedata != undefined &&
                                                                    EstimatesDetails?.estimatedata?.filter(x => x.type == "Material").length > 0
                                                                    &&

                                                                    <>
                                                                        <div className="col-md-3 pright0">
                                                                            <h4 className="total-right">Total</h4>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <div className="form-group">
                                                                                <input type="text" className="form-control right" value={EstimatesDetails.amountMaterial} disabled placeholder="" />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="estsub-title">
                                                                <h4 className="esttitle mt0">Labor </h4>
                                                                {
                                                                    // EstimatesDetails.currentstatusactivity != "Estimate Approved"
                                                                    // && 
                                                                    <div className="est-addbtn" >
                                                                        {EstimatesDetails.status != "Approved" &&
                                                                            <a style={{ cursor: 'pointer' }} onClick={(e) => addMoreLaborRow(e)} className="btn btn-gray btn-sm"><i className="fa fa-plus"></i> &nbsp; Add</a>
                                                                        }
                                                                    </div>
                                                                }

                                                            </div>
                                                            <div className="row">
                                                                <div>
                                                                    <div className="col-md-5 pright0">
                                                                        <div className="form-group">
                                                                            Title
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3 pright0">
                                                                        <div className="form-group">
                                                                            Qty
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <div className="form-group">
                                                                            Unit Price
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <div className="form-group">
                                                                            Amount
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    EstimatesDetails.estimatedata != null &&
                                                                    EstimatesDetails.estimatedata.length > 0
                                                                    &&
                                                                    EstimatesDetails.estimatedata.filter(x => x.type == "Labor").map(item => (
                                                                        <div>
                                                                            <div className="col-md-5">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control" value={item.item} placeholder="Title" onChange={(e) => handleChange_TextValue_item(item, e)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control" value={item.qty} placeholder="Quantity" onChange={(e) => handleChange_TextValue_qty_labor_estimate(item, e)} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-2">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control" value={item.unitprice} placeholder="Unit Price" onChange={(e) => handleChange_TextValue_UnitPrice_labor_estimate(item, e)} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-2">
                                                                                <div className="form-group itemamount">
                                                                                    <input type="text" className="form-control" disabled value={item.amount} placeholder="" onChange={(e) => handleChange_Labor(item, e)} />
                                                                                    {EstimatesDetails.status != "Approved" &&
                                                                                        <a className="removeitem" onClick={() => removeRow_estimate(item)} ><img src="images/remove.svg" alt="" /></a>
                                                                                    }
                                                                                </div>
                                                                            </div>





                                                                        </div>
                                                                    ))
                                                                }


                                                                <div className="col-md-6 pright0">

                                                                </div>
                                                                {
                                                                    EstimatesDetails?.estimatedata?.filter(x => x.type == "Labor").length > 0
                                                                    &&
                                                                    <>
                                                                        <div className="col-md-3 pright0">
                                                                            <h4 className="total-right">Total</h4>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <div className="form-group">
                                                                                <input type="text" className="form-control right" value={EstimatesDetails.amountLabour} disabled placeholder="" />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-8">
                                                            <div>
                                                                <div className="od-notes">
                                                                    <h4 className="ed-title"><img src="images/notes.png" /><span className="mr-15">Notes </span></h4>

                                                                    <textarea rows="4" cols="64" value={EstimatesDetails.notes} onChange={(e) => handlenotes_estimate(e.target.value)}   ></textarea>
                                                                    <br />
                                                                    <div className="od-photos">
                                                                        <h4 className=""><img src="images/photo-camera.png" />Photos </h4>
                                                                        <div className="od-photos-list">

                                                                            {
                                                                                EstimatesDetails?.photos?.length == 0 ?
                                                                                    "No Photos Available."
                                                                                    :
                                                                                    // photosList != null &&
                                                                                    EstimatesDetails?.photos?.map(item => (
                                                                                        <a onClick={() => SelectImage_estimate(item)} style={{ cursor: 'pointer' }} >
                                                                                            <img src={item.photopath} className={item.selected ? "selectedphoto" : ""} />
                                                                                        </a>
                                                                                    ))
                                                                            }


                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="total-count">
                                                                {/* <div className="total-count-list">
                                                                    <h5 className="list-name">PO Number</h5>
                                                                    <input type="text" className="list-amount" style={{ width: '100px' }} value={poNumber_estimate} onChange={(e) => setPoNumber_estimate(e.target.value)} />
                                                                </div> */}
                                                                <div className="total-count-list">
                                                                    <h5 className="list-name">Total Material</h5>
                                                                    <h5 className="list-amount">{EstimatesDetails.amountMaterial}</h5>
                                                                </div>
                                                                <div className="total-count-list">
                                                                    <h5 className="list-name">Total Labor</h5>
                                                                    <h5 className="list-amount">{EstimatesDetails.amountLabour}</h5>
                                                                </div>

                                                                <div className="total-count-list">
                                                                    <h5 className="list-name tax">Material Taxes</h5>
                                                                    {/* <h5 className="list-amount">{EstimatesDetails.amountTax}</h5> */}
                                                                    <input type="text" className="list-amount" style={{ width: '50px' }} value={EstimatesDetails.amountTaxMaterial} disabled />
                                                                    <input type="text" disabled={EstimatesDetails.status == "Approved" ? true : false} className="list-amount nofloat" max="100" placeholder="%" style={{ width: '50px' }} value={EstimatesDetails.amountTaxMaterialRate} onChange={(e) => handleChange_tax_material(e)} />%

                                                                </div>
                                                                <div className="total-count-list">
                                                                    <h5 className="list-name tax">Labor Taxes</h5>
                                                                    {/* <h5 className="list-amount">{EstimatesDetails.amountTax}</h5> */}
                                                                    <input type="text" className="list-amount" style={{ width: '50px' }} value={EstimatesDetails.amountTaxLabour} disabled />
                                                                    <input type="text" disabled={EstimatesDetails.status == "Approved" ? true : false} className="list-amount nofloat" placeholder="%" max="100" style={{ width: '50px' }} value={EstimatesDetails.amountTaxLabourRate} onChange={(e) => handleChange_tax_labor(e)} />%

                                                                </div>
                                                                <div className="total-count-list">
                                                                    <h5 className="list-name tax">Discounts </h5>
                                                                    <input type="text" className="list-amount" style={{ width: '50px' }} disabled value={EstimatesDetails.amountDiscount} onChange={(e) => handleChange_disc(e)} />
                                                                    {EstimatesDetails.status == "Approved" ?
                                                                        <><input type="text" className="list-amount nofloat txtdisable" disabled max="100" placeholder="%" style={{ width: '50px' }} value={EstimatesDetails.discountpercentage} onChange={(e) => handleChange_disc_percentage_estimate(e)} />%</>
                                                                        :
                                                                        <><input type="text" className="list-amount nofloat txtdisable" disabled={ticketDetails.customertype == "Preferred" ? false : true} max="100" placeholder="%" style={{ width: '50px' }} value={EstimatesDetails.discountpercentage} onChange={(e) => handleChange_disc_percentage_estimate(e)} />%</>
                                                                    }
                                                                </div>
                                                                <div className="total-count-list">
                                                                    <h5 className="list-name"><b>Total Due</b></h5>
                                                                    <h5 className="list-amount"><b>{EstimatesDetails.amountPayable}</b></h5>
                                                                </div>
                                                            </div>
                                                            {
                                                                userType != "Accounts"
                                                                &&
                                                                <div className="topbtn-right mb-20">

                                                                    {
                                                                        (EstimatesDetails.status == "" || EstimatesDetails.status == null || EstimatesDetails.status == undefined)
                                                                        &&
                                                                        <a style={{ cursor: 'pointer' }} onClick={(e) => draftEstimate(e)} className="btn btn-theme" >Save</a>
                                                                    }
                                                                    {
                                                                        (EstimatesDetails.status == "Draft")
                                                                        &&
                                                                        <a style={{ cursor: 'pointer' }} onClick={(e) => finalizeEstimate(e)} className="btn btn-theme" >Save</a>
                                                                    }

                                                                    {
                                                                        (EstimatesDetails.status == "Finalized")
                                                                        &&
                                                                        <a style={{ cursor: 'pointer' }} onClick={(e) => updateEstimate(e)} className="btn btn-theme" >Save</a>
                                                                    }

                                                                    {
                                                                        (EstimatesDetails.status == "Submitted")
                                                                        &&
                                                                        <a style={{ cursor: 'pointer' }} onClick={(e) => updateEstimate(e)} className="btn btn-theme" >Save</a>
                                                                        // <a style={{ cursor: 'pointer' }} onClick={(e) => finalizeEstimate(e)} className="btn btn-theme" >Submit</a>
                                                                    }
                                                                    {
                                                                        (EstimatesDetails.status == "Approved")
                                                                        &&
                                                                        null
                                                                        // <a style={{ cursor: 'pointer' }} onClick={(e) => updateEstimate(e)} className="btn btn-theme" >Save</a>
                                                                        // <a style={{ cursor: 'pointer' }} onClick={(e) => finalizeEstimate(e)} className="btn btn-theme" >Submit</a>
                                                                    }

                                                                </div>

                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }


                                    {
                                        openSection == "Invoices_draft"
                                        &&
                                        <div className="box box-solid ">
                                            <div className="box-body act-body">
                                                <div className="act-box-heading">
                                                    <h4 className="act-title">Invoice</h4>
                                                    {
                                                        ((InvoicesDetails.status == "Finalized" || InvoicesDetails.status == "Submitted") && isMailSent_invoice == false)
                                                        &&
                                                        <a onClick={() => sendMail("invoice")} className="btn btn-default pull-right" >Send Mail</a>
                                                    }
                                                    {
                                                        ((InvoicesDetails.status == "Finalized" || InvoicesDetails.status == "Submitted") && isMailSent_invoice == true)
                                                        &&
                                                        <a onClick={() => reSendMail("invoice")} className="btn btn-default pull-right" >Resend Mail</a>
                                                    }

                                                </div>
                                                <div className="mt30">
                                                    <div className="row estinvform">
                                                        <div className="col-md-6">
                                                            <div className="estsub-title">
                                                                <h4 className="esttitle mt0">Material</h4>
                                                                <div className="est-addbtn">
                                                                    <a style={{ cursor: 'pointer' }} onClick={(e) => addMoreMaterialRow_invoice(e)} className="btn btn-gray btn-sm"><i className="fa fa-plus"></i> &nbsp; Add</a>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div>
                                                                    <div className="col-md-5 pright0">
                                                                        <div className="form-group">
                                                                            Title
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3 pright0">
                                                                        <div className="form-group">
                                                                            Qty
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <div className="form-group">
                                                                            Unit Price
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <div className="form-group">
                                                                            Amount
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    InvoicesDetails.invoicedata != null &&
                                                                    InvoicesDetails.invoicedata.length > 0
                                                                    &&
                                                                    InvoicesDetails != null &&
                                                                    InvoicesDetails.invoicedata.filter(x => x.type == "Material").map(item => (
                                                                        <div>
                                                                            <div className="col-md-5">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control" value={item.item} placeholder="" onChange={(e) => handleChange_TextValue_item_invoice(item, e)} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-3 pright0">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control" value={item.qty} placeholder="Quantity" onChange={(e) => handleChange_TextValue_qty_material_invoice(item, e)} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-2 pright0">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control right" value={item.unitprice} placeholder="Unit Price" onChange={(e) => handleChange_TextValue_UnitPrice_material_invoice(item, e)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <div className="form-group itemamount">
                                                                                    <input type="text" className="form-control right" disabled value={item.amount} placeholder="Total Amt" />
                                                                                    <a className="removeitem" onClick={() => removeRow_invoice(item)} ><img src="images/remove.svg" alt="" /></a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }


                                                                <div className="col-md-6 pright0">

                                                                </div>
                                                                <div className="col-md-3 pright0">
                                                                    <h4 className="total-right">Total</h4>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <input type="text" className="form-control right" disabled value={InvoicesDetails.amountMaterial} placeholder="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="estsub-title">
                                                                <h4 className="esttitle mt0">Labor</h4>
                                                                <div className="est-addbtn">
                                                                    <a style={{ cursor: 'pointer' }} onClick={(e) => addMoreLaborRow_invoice(e)} className="btn btn-gray btn-sm"><i className="fa fa-plus"></i> &nbsp; Add</a>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div>
                                                                    <div className="col-md-5 pright0">
                                                                        <div className="form-group">
                                                                            Title
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3 pright0">
                                                                        <div className="form-group">
                                                                            Qty
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <div className="form-group">
                                                                            Unit Price
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <div className="form-group">
                                                                            Amount
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    InvoicesDetails.invoicedata != null &&
                                                                    InvoicesDetails.invoicedata.length > 0
                                                                    &&
                                                                    InvoicesDetails.invoicedata.filter(x => x.type == "Labor").map(item => (
                                                                        <div>
                                                                            <div className="col-md-5">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control" value={item.item} placeholder="" onChange={(e) => handleChange_TextValue_item_invoice(item, e)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 pright0">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control" value={item.qty} placeholder="" onChange={(e) => handleChange_TextValue_qty_labor_invoice(item, e)} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-2 pright0">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control right" value={item.unitprice} placeholder="Unit Price" onChange={(e) => handleChange_TextValue_UnitPrice_labor_invoice(item, e)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <div className="form-group itemamount">
                                                                                    <input type="text" className="form-control right" disabled value={item.amount} placeholder="" />
                                                                                    <a className="removeitem" onClick={() => removeRow_invoice(item)} ><img src="images/remove.svg" alt="" /></a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }

                                                                <div className="col-md-6 pright0">

                                                                </div>
                                                                <div className="col-md-3 pright0">
                                                                    <h4 className="total-right">Total</h4>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <input type="text" className="form-control right" value={InvoicesDetails.amountLabour} disabled placeholder="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-8">

                                                        </div>
                                                        <div className="col-md-4">
                                                            {
                                                                userType != "Accounts"
                                                                &&

                                                                <div className="topbtn-right mb-20">
                                                                    {
                                                                        (InvoicesDetails.status == "" || InvoicesDetails.status == null || InvoicesDetails.status == undefined)
                                                                        &&
                                                                        <a style={{ cursor: 'pointer' }} onClick={(e) => draftInvoice(e)} className="btn btn-theme" >Save</a>
                                                                    }
                                                                    {
                                                                        InvoicesDetails.status == "Draft"
                                                                        &&
                                                                        <a style={{ cursor: 'pointer' }} onClick={(e) => finalizeInvoice(e)} className="btn btn-theme" >Save</a>
                                                                    }

                                                                    {
                                                                        InvoicesDetails.status == "Finalized"
                                                                        &&
                                                                        <a style={{ cursor: 'pointer' }} onClick={(e) => updateInvoice(e)} className="btn btn-theme" >Save</a>
                                                                    }

                                                                    {
                                                                        InvoicesDetails.status == "Submitted"
                                                                        &&
                                                                        <a style={{ cursor: 'pointer' }} onClick={(e) => updateInvoice(e)} className="btn btn-theme" >Save</a>
                                                                        // <a style={{ cursor: 'pointer' }} onClick={(e) => finalizeInvoice(e)} className="btn btn-theme" >Submit</a>
                                                                    }
                                                                    {
                                                                        InvoicesDetails.status == "Paid"
                                                                        &&
                                                                        null
                                                                        // <a style={{ cursor: 'pointer' }} onClick={(e) => updateInvoice(e)} className="btn btn-theme" >Save</a>
                                                                        // <a style={{ cursor: 'pointer' }} onClick={(e) => finalizeInvoice(e)} className="btn btn-theme" >Submit</a>
                                                                    }

                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        openSection == "Invoices"
                                        &&
                                        <div className="box box-solid ">
                                            <div className="box-body act-body">
                                                <div className="act-box-heading">
                                                    <h4 className="act-title">Invoice Details</h4>
                                                    <div className="invoice-box-desc">
                                                        <div className="col-md-3">
                                                            <h5>Invoice Date</h5>
                                                            <p className="act-time">
                                                                <span className="dot-status ed-title">
                                                                    <span className="mr-15">
                                                                        <div style={{ display: "inline" }}>{InvoicesDetails.invoicedate}</div>
                                                                    </span>
                                                                    <a style={((userType === "Accounts" || userType === "Admin")) ? { display: "inline", cursor: 'pointer', marginLeft: "5px" } : { display: "none" }} onClick={(e) => editinvoicedate(InvoicesDetails.invoicedate, InvoicesDetails.invoiceduedate, InvoicesDetails.invoicenumber)} className="od-edit"><i className="fa fa-pencil"></i></a>
                                                                </span>
                                                            </p>

                                                        </div>
                                                        <div className="col-md-3">
                                                            <h5>Due Date</h5>
                                                            <div className="act-time">
                                                                <span className="dot-status ed-title">
                                                                    <span className="mr-15">
                                                                        <div style={{ display: "inline" }}>{InvoicesDetails.invoiceduedate}</div>
                                                                    </span>
                                                                    <a style={(userType === "Accounts" || userType === "Admin") ? { display: "inline", cursor: 'pointer', marginLeft: "5px" } : { display: "none" }} onClick={(e) => editinvoiceduedate(InvoicesDetails.invoiceduedate, InvoicesDetails.invoicedate, InvoicesDetails.invoicenumber)} className="od-edit"><i className="fa fa-pencil"></i></a>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <h5>Invoice No.</h5>
                                                            <div className="act-time">
                                                                <span className="dot-status ed-title">
                                                                    <span className="mr-15">
                                                                        <div style={{ display: "inline" }}>{InvoicesDetails.invoicenumber}</div>
                                                                    </span>
                                                                    <a style={(userType === "Accounts" || userType === "Admin") ? { display: "inline", cursor: 'pointer', marginLeft: "5px" } : { display: "none" }} onClick={(e) => editinvoiceno(InvoicesDetails.invoiceduedate, InvoicesDetails.invoicedate, InvoicesDetails.invoicenumber)} className="od-edit"><i className="fa fa-pencil"></i></a>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">

                                                            {
                                                                ((InvoicesDetails.status == "Finalized" || InvoicesDetails.status == "Submitted") && isMailSent_invoice == false && (userType === "Accounts" || userType === "Admin"))
                                                                &&
                                                                <a onClick={() => sendMail()} className="btn btn-default" style={{ margin: "0px 10px 0px 0px" }}>Send Mail</a>
                                                            }
                                                            {
                                                                ((InvoicesDetails.status == "Finalized" || InvoicesDetails.status == "Submitted") && isMailSent_invoice == true && (userType === "Accounts" || userType === "Admin"))
                                                                &&
                                                                <a onClick={() => reSendMail()} className="btn btn-default" style={{ margin: "0px 10px 0px 0px" }}>Resend Mail</a>
                                                            }
                                                            {
                                                                InvoicesDetails.downloadpath != ""
                                                                &&
                                                                <a className="btn btn-default" href={InvoicesDetails.downloadpath} target="_blank" style={{ margin: "0px 10px 0px 0px" }}>Download</a>
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="mt30">
                                                    <div className="row estinvform">
                                                        <div className="col-md-6">
                                                            <div className="estsub-title">
                                                                <h4 className="esttitle mt0">Material</h4>
                                                                <div className="est-addbtn">
                                                                    {InvoicesDetails.status != "Paid" &&
                                                                        <a style={{ cursor: 'pointer' }} onClick={(e) => addMoreMaterialRow_invoice(e)} className="btn btn-gray btn-sm"><i className="fa fa-plus"></i> &nbsp; Add</a>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div>
                                                                    <div className="col-md-5 pright0">
                                                                        <div className="form-group">
                                                                            Title
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3 pright0">
                                                                        <div className="form-group">
                                                                            Qty
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <div className="form-group">
                                                                            Unit Price
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <div className="form-group">
                                                                            Amount
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    InvoicesDetails.invoicedata != null &&
                                                                    InvoicesDetails.invoicedata.length > 0
                                                                    &&
                                                                    InvoicesDetails != null &&
                                                                    InvoicesDetails.invoicedata.filter(x => x.type == "Material").map(item => (
                                                                        <div>
                                                                            <div className="col-md-5">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control" value={item.item} placeholder="" onChange={(e) => handleChange_TextValue_item_invoice(item, e)} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-3 pright0">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control" value={item.qty} placeholder="Quantity" onChange={(e) => handleChange_TextValue_qty_material_invoice(item, e)} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-2 pright0">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control right" value={item.unitprice} placeholder="Unit Price" onChange={(e) => handleChange_TextValue_UnitPrice_material_invoice(item, e)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <div className="form-group itemamount">
                                                                                    <input type="text" className="form-control right" disabled value={item.amount} placeholder="Total Amt" />
                                                                                    {InvoicesDetails.status != "Paid" &&
                                                                                        <a className="removeitem" onClick={() => removeRow_invoice(item)} ><img src="images/remove.svg" alt="" /></a>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }


                                                                <div className="col-md-6 pright0">

                                                                </div>
                                                                <div className="col-md-3 pright0">
                                                                    <h4 className="total-right">Total</h4>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <input type="text" className="form-control right" disabled value={InvoicesDetails.amountMaterial} placeholder="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="estsub-title">
                                                                <h4 className="esttitle mt0">Labor</h4>
                                                                <div className="est-addbtn">
                                                                    {InvoicesDetails.status != "Paid" &&
                                                                        <a style={{ cursor: 'pointer' }} onClick={(e) => addMoreLaborRow_invoice(e)} className="btn btn-gray btn-sm"><i className="fa fa-plus"></i> &nbsp; Add</a>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div>
                                                                    <div className="col-md-5 pright0">
                                                                        <div className="form-group">
                                                                            Title
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3 pright0">
                                                                        <div className="form-group">
                                                                            Qty
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <div className="form-group">
                                                                            Unit Price
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <div className="form-group">
                                                                            Amount
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    InvoicesDetails.invoicedata != null &&
                                                                    InvoicesDetails.invoicedata.length > 0
                                                                    &&
                                                                    InvoicesDetails.invoicedata.filter(x => x.type == "Labor").map(item => (
                                                                        <div>
                                                                            <div className="col-md-5">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control" value={item.item} placeholder="" onChange={(e) => handleChange_TextValue_item_invoice(item, e)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 pright0">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control" value={item.qty} placeholder="" onChange={(e) => handleChange_TextValue_qty_labor_invoice(item, e)} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-2 pright0">
                                                                                <div className="form-group">
                                                                                    <input type="text" className="form-control right" value={item.unitprice} placeholder="Unit Price" onChange={(e) => handleChange_TextValue_UnitPrice_labor_invoice(item, e)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <div className="form-group itemamount">
                                                                                    <input type="text" className="form-control right" disabled value={item.amount} placeholder="" />
                                                                                    {InvoicesDetails.status != "Paid" &&
                                                                                        <a className="removeitem" onClick={() => removeRow_invoice(item)} ><img src="images/remove.svg" alt="" /></a>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }


                                                                <div className="col-md-6 pright0">

                                                                </div>
                                                                <div className="col-md-3 pright0">
                                                                    <h4 className="total-right">Total</h4>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <input type="text" className="form-control right" value={InvoicesDetails.amountLabour} disabled placeholder="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-8">
                                                            <div>
                                                                <div className="od-notes">
                                                                    <h4 className="ed-title"><img src="images/notes.png" /><span className="mr-15">Notes </span></h4>

                                                                    <textarea rows="4" cols="64" value={InvoicesDetails.notes} onChange={(e) => handlenotes_invoice(e.target.value)}   ></textarea>
                                                                    <br />
                                                                    <div className="od-photos">
                                                                        <h4 className=""><img src="images/photo-camera.png" />Photos </h4>
                                                                        <div className="od-photos-list">

                                                                            {
                                                                                InvoicesDetails?.photos?.length == 0 ?
                                                                                    "No Photos Available."
                                                                                    :
                                                                                    // photosList != null &&
                                                                                    InvoicesDetails?.photos?.map(item => (
                                                                                        <a onClick={() => SelectImage_invoice(item)} style={{ cursor: 'pointer' }} >
                                                                                            <img src={item.photopath} className={item.selected ? "selectedphoto" : ""} />
                                                                                        </a>
                                                                                    ))
                                                                            }


                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="total-count">
                                                                {/* <div className="total-count-list">
                                                                    <h5 className="list-name">PO Number</h5>
                                                                    <input type="text" className="list-amount" style={{ width: '100px' }} value={poNumber_invoice} onChange={(e) => setPoNumber_invoice(e.target.value)} />
                                                                </div> */}
                                                                <div className="total-count-list">
                                                                    <h5 className="list-name">Total Material</h5>
                                                                    <h5 className="list-amount">{InvoicesDetails.amountMaterial}</h5>
                                                                </div>
                                                                <div className="total-count-list">
                                                                    <h5 className="list-name">Total Labor</h5>
                                                                    <h5 className="list-amount">{InvoicesDetails.amountLabour}</h5>
                                                                </div>

                                                                <div className="total-count-list">
                                                                    <h5 className="list-name tax">Material Taxes</h5>
                                                                    {/* <h5 className="list-amount">{EstimatesDetails.amountTax}</h5> */}
                                                                    <input type="text" className="list-amount" style={{ width: '50px' }} value={InvoicesDetails.amountTaxMaterial} disabled />
                                                                    <input type="text" disabled={InvoicesDetails.status == "Paid" ? true : false} className="list-amount nofloat" max="100" placeholder="%" style={{ width: '50px' }} value={InvoicesDetails.amountTaxMaterialRate} onChange={(e) => handleChange_tax_material_invoice(e)} />%

                                                                </div>
                                                                <div className="total-count-list">
                                                                    <h5 className="list-name tax">Labor Taxes</h5>
                                                                    {/* <h5 className="list-amount">{EstimatesDetails.amountTax}</h5> */}
                                                                    <input type="text" className="list-amount" style={{ width: '50px' }} value={InvoicesDetails.amountTaxLabour} disabled />
                                                                    <input type="text" disabled={InvoicesDetails.status == "Paid" ? true : false} className="list-amount nofloat" placeholder="%" max="100" style={{ width: '50px' }} value={InvoicesDetails.amountTaxLabourRate} onChange={(e) => handleChange_tax_labor_invoice(e)} />%

                                                                </div>
                                                                <div className="total-count-list">
                                                                    <h5 className="list-name tax">Discounts </h5>
                                                                    <input type="text" className="list-amount" style={{ width: '50px' }} disabled value={InvoicesDetails.amountDiscount} />
                                                                    {InvoicesDetails.status == "Paid" ?
                                                                        <><input type="text" className="list-amount nofloat txtdisable" disabled max="100" placeholder="%" style={{ width: '50px' }} value={InvoicesDetails.discountpercentage} onChange={(e) => handleChange_disc_percentage_invoice(e)} />%</>
                                                                        :
                                                                        <><input type="text" className="list-amount nofloat txtdisable" disabled={ticketDetails.customertype == "Preferred" ? false : true} max="100" placeholder="%" style={{ width: '50px' }} value={InvoicesDetails.discountpercentage} onChange={(e) => handleChange_disc_percentage_invoice(e)} />%</>
                                                                    }
                                                                </div>

                                                                <div className="total-count-list">
                                                                    <h5 className="list-name"><b>Total Due</b></h5>
                                                                    <h5 className="list-amount"><b>{InvoicesDetails.amountPayable}</b></h5>
                                                                </div>
                                                            </div>
                                                            {/* stutus {InvoicesDetails.status} */}
                                                            {
                                                                userType != "Accounts"
                                                                &&

                                                                <div className="topbtn-right mb-20">

                                                                    {
                                                                        (InvoicesDetails.status == "" || InvoicesDetails.status == null || InvoicesDetails.status == undefined)
                                                                        &&
                                                                        <a style={{ cursor: 'pointer' }} onClick={(e) => draftInvoice(e)} className="btn btn-theme" >Save</a>
                                                                    }
                                                                    {
                                                                        InvoicesDetails.status == "Draft"
                                                                        &&
                                                                        <a style={{ cursor: 'pointer' }} onClick={(e) => finalizeInvoice(e)} className="btn btn-theme" >Save</a>
                                                                    }

                                                                    {
                                                                        InvoicesDetails.status == "Finalized"
                                                                        &&
                                                                        <a style={{ cursor: 'pointer' }} onClick={(e) => updateInvoice(e)} className="btn btn-theme" >Save</a>
                                                                    }

                                                                    {
                                                                        InvoicesDetails.status == "Submitted"
                                                                        &&
                                                                        <a style={{ cursor: 'pointer' }} onClick={(e) => updateInvoice(e)} className="btn btn-theme" >Save</a>
                                                                        // <a style={{ cursor: 'pointer' }} onClick={(e) => finalizeInvoice(e)} className="btn btn-theme" >Submit</a>
                                                                    }
                                                                    {
                                                                        InvoicesDetails.status == "Paid"
                                                                        &&
                                                                        null
                                                                        // <a style={{ cursor: 'pointer' }} onClick={(e) => updateInvoice(e)} className="btn btn-theme" >Save</a>
                                                                        // <a style={{ cursor: 'pointer' }} onClick={(e) => finalizeInvoice(e)} className="btn btn-theme" >Submit</a>
                                                                    }

                                                                </div>

                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                ticketDetails.ordertypeid != undefined
                &&
                <OrderTypeUpdate orderType={ticketDetails.ordertypeid} orderDetail_prop={ticketDetails.orderdetail} ticketid={ticketid} />
            }

            {/* <TicketAssigneeUpdate technicianid={ticketDetails.assignedtoUserID} date={ticketDetails.scheduleddate} time={ticketDetails.scheduledtime} ticketid={ticketid} /> */}


            <div className="modal fade fsm-popup" id="AssigneeUpdate">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <a type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <img src="images/close.png" /></a>
                            <h5 className="modal-title">Update Assignee</h5>
                        </div>
                        <div className="modal-body">
                            <div className="add-custmer-form ">
                                <div className="row">


                                    <div className="col-md-6">
                                        <div className="form-group">

                                            <input type="date" className="form-control" placeholder="Date" value={ticketDate} onChange={(e) => setTicketDate(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">

                                            <input type="time" className="form-control" placeholder="Time" value={ticketTime} onChange={(e) => setTicketTime(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">

                                            <select className="form-control" value={technicianId} onChange={(e) => setTechnicianID(e.target.value)}>
                                                <option>Technician</option>
                                                {
                                                    technicianList.map(item => (
                                                        <option value={item.userId}>{item.name}</option>
                                                    ))
                                                }

                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6 pull-right">
                                        <a type="submit" className="btn btn-theme pull-right" style={{ cursor: 'pointer' }} onClick={(e) => UpdateAssignee(e)}>Update </a>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog --> */}
            </div>

            <div className="modal fade fsm-popup" id="OrderType">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <a type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <img src="images/close.png" /></a>
                            <h5 className="modal-title">Update Order Type</h5>
                        </div>
                        <div className="modal-body">
                            <div className="add-custmer-form ">
                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <select className="form-control" value={selectedOrderType} onChange={(e) => setSelectedOrderType(e.target.value)}>
                                                <option value="0">Order Type</option>
                                                {
                                                    orderTypeList.map(item => (
                                                        <option value={item.ordertypeid}>{item.ordertype}</option>
                                                    ))
                                                }


                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <div className="">
                                                <textarea className="form-control" rows="3" placeholder="Order Details" value={orderDetail} onChange={(e) => setOrderDetail(e.target.value)}></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">

                                            <textarea className="form-control" autoComplete="false" id="searchMapInputTicket" onFocus={() => onfocusSearch()} onChange={(e) => initMap_ticket()} rows="2" placeholder="Service Address" value={serviceAddress} ></textarea>
                                        </div>
                                    </div>



                                    <div className="col-md-6 pull-right">
                                        <a type="submit" className="btn btn-theme pull-right" style={{ cursor: 'pointer' }} onClick={(e) => UpdateOrderType(e)}>Update </a>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog --> */}
            </div>

            <div className="modal fade fsm-popup" id="POWorkOrder">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <a type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <img src="images/close.png" /></a>
                            <h5 className="modal-title">Update PO/Work Order </h5>
                        </div>
                        <div className="modal-body">
                            <div className="add-custmer-form ">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="textbox" className="form-control" autoComplete="false" placeholder="PO Number" value={poNumber} onChange={(e) => setPoNumber(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="textbox" className="form-control" placeholder="Work Order Number" value={workOrderNumber} onChange={(e) => setWorkOrderNumber(e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-6 pright0" >
                                        <input type="file" onChange={(e) => UploadDocument(e)} className="form-control tic-file" placeholder="" />
                                    </div>

                                    <div className="col-md-6 pull-right">
                                        <a type="submit" className="btn btn-theme pull-right" style={{ cursor: 'pointer' }} onClick={(e) => UpdatePOWorkOrder(e)}>Update </a>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog --> */}
            </div>

            <div className="modal fade fsm-popup" id="StatusPop">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <a type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <img src="images/close.png" /></a>
                            <h5 className="modal-title">Update Status </h5>
                        </div>
                        <div className="modal-body">
                            <div className="add-custmer-form ">
                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <select className="form-control" value={selectedStatusId} onChange={(e) => setSelectedStatusId(e.target.value)}>
                                                <option value="0">Status</option>
                                                {
                                                    statusList.map(item => (
                                                        <option value={item.statusid}>{item.status}</option>
                                                    ))
                                                }


                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6 pull-right">
                                        <a type="submit" className="btn btn-theme pull-right" style={{ cursor: 'pointer' }} onClick={(e) => UpdateStatus(e)}>Update </a>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog --> */}
            </div>
            {cid !== 0 &&
                <CreateTicket
                    customerid={cid}
                    refreshDashboard={refreshDashboard}
                    testId={testId}
                    ticketid={ticketid}
                    ticketType={ticketType}
                />}
            <DateUpdate ticketid={ticketid} activityid={activiyid} loadInvoice={loadInvoice} invoicedate1={invoicedate} invoiceduedate1={invoiceduedate} invoiceno={invoiceno} />
            <DueDateUpdate ticketid={ticketid} activityid={activiyid} loadInvoice={loadInvoice} invoicedate1={invoicedate} invoiceduedate1={invoiceduedate} invoiceno={invoiceno} />
            <Invoicenoupdate ticketid={ticketid} activityid={activiyid} loadInvoice={loadInvoice} invoicedate1={invoicedate} invoiceduedate1={invoiceduedate} invoiceno1={invoiceno} />

            <DeletePopUp
                DeleteUser_submit={DeleteTicket_submit}
                id={ticketid}
                getreqFrom="Ticket" />



        </>
    )
}

export default TicketDetails

window.$(window).on('popstate', function () {
    window.$('.modal').click();
});