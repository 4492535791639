import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Header from '../nav/Header'
import Sidebar from '../nav/Sidebar'
import { Link } from 'react-router-dom'
import { GetReport_TechPerformance_api } from '../../functions/reports'

function TechPerformance() {

    const [period, setPeriod] = useState("");
    const [selectedCustomerID, setSelectedCustomerID] = useState(0);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [customerList, setCustomerList] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [List, setList] = useState([]);

    const [paginationarr, setPaginationarr] = useState([]);
    const [selectedPageNo, setSelectedPageNo] = useState(1);
    let recordshowperpage = 30;

    useEffect(() => {
        loadReport();
    }, [])

    useEffect(() => {
        if (period == "Custom") {
            window.$("#dateSelection").modal('show')
        }
    }, [period])




    const loadReport = () => {
        var aa = "0 Hr".replace(" Hr", '');
        var _period = period;
        var req = {
            "periodtype": _period
        }

        setIsLoaded(false);
        GetReport_TechPerformance_api(req).then(res => {
            const result = res;
            if (result != undefined) {
                if (result == null) {
                    setList([])
                }
                else {
                    setList(result)
                    var data = result.data.filter(x => x.timedata != undefined);
                    if (data.length > recordshowperpage) {
                        var totalpage = data.length / recordshowperpage;
                        var reminder = data.length % recordshowperpage;
                        var pages = [];
                        for (var i = 1; i <= totalpage; i++) {
                            pages.push(i);
                        }
                        if (reminder == 0) {
                            //pages = pages;
                        }
                        else {
                            pages.push(pages.length + 1)
                        }
                        setPaginationarr(pages);
                    }
                }
            }
            else {
                setPaginationarr([]);
            }
            setIsLoaded(true);
        }).catch();
    }


    return (
        <div>
            <div className="hold-transition skin-black sidebar-mini" hidden>
                <div className="wrapper">
                    <Header />
                    <Sidebar />
                    <div className="content-wrapper">
                        {/* <section className="content content-body"> */}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="reports">
                                    <ul>
                                        <li><Link to="revenuebytech">Revenue by Tech</Link></li>
                                        <li><Link to="revenuebycustomer">Revenue by Customer</Link></li>
                                        <li ><Link to="collectionreport">Collection Report</Link></li>
                                        <li className="active_report"><Link to="techperformance">Tech Performance</Link></li>
                                        <li><Link to="techutilization">Tech Utilization</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* </section> */}
                        <div className="filterbox">
                            <div className="">
                                <div className="boxmargin">
                                    <div className="box box-solid box-height">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group row">
                                                    <label for="colFormLabel" className="col-sm-4 col-form-label">Period</label>
                                                    <div className="col-sm-8">
                                                        <select className="form-control" onChange={(e) => setPeriod(e.target.value)}>
                                                            <option>This Month</option>
                                                            <option>Prev Month</option>
                                                            <option>This Year</option>
                                                            <option>Prev Year</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <div className="form-group row">
                                                    <div className="col-sm-3">
                                                        <button className="btn btn-primary" onClick={() => loadReport()}>View</button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-md-3"></div> */}
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div className="rptcontainer">

                            {
                                isLoaded == false ? <div className="loader"><img src="images/loader.gif" alt="" /></div>
                                    :
                                    <>

                                        <table className="table table-striped customertbl">
                                            <thead>
                                                <tr>
                                                    <th>Order Type</th>
                                                    {
                                                        List.technicians != undefined &&
                                                        <>
                                                            {
                                                                List.technicians.map(tech => (
                                                                    <th>{tech.technicianname}</th>
                                                                ))
                                                            }
                                                        </>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    List.data != undefined &&
                                                    <>
                                                        {
                                                            List.data.length == 0
                                                                ?
                                                                "No Record Found."
                                                                :
                                                                List.data.filter(x => x.timedata != undefined).slice(selectedPageNo * recordshowperpage - recordshowperpage, selectedPageNo * recordshowperpage).map((item, index) => (
                                                                    <>
                                                                        {item.timedata != undefined &&
                                                                            <tr>
                                                                                <td>{item.ordertype}</td>
                                                                                {
                                                                                    item.timedata != undefined &&
                                                                                    <>
                                                                                        {
                                                                                            item.timedata.map(time => (
                                                                                                <td>{(Number((item.mintime).replace(" Hr", '')) <= Number((time.avgtime).replace(" Hr", '')) && Number((time.avgtime).replace(" Hr", '')) > 0) ? <><b>{time.avgtime}</b> </> : time.avgtime} </td>
                                                                                            ))
                                                                                        }
                                                                                    </>
                                                                                }
                                                                            </tr>
                                                                        }
                                                                    </>
                                                                ))
                                                        }
                                                    </>
                                                }
                                            </tbody>
                                        </table>

                                    </>
                            }
                            <ul className="pagination pull-right mt0">
                                {
                                    paginationarr.length > 0 &&
                                    paginationarr.map(item => (
                                        <li class={`paginate_button ${item == selectedPageNo && "active"}`}><a style={{ cursor: 'pointer' }} onClick={() => setSelectedPageNo(item)}>{item}</a></li>
                                    ))
                                }
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default TechPerformance
