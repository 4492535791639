import React from 'react'

function TicketFilters({ handleSearch ,statuslist, assigneelist, fromdate, setFromdate, todate , setTodate, status, setStatus, assigntoId, setAssigntoId}) {
    return (
        <div className="modal fade fsm-popup" id="Filters">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <a type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <img src="images/close.png" /></a>
                        <h5 className="modal-title">Filters</h5>
                    </div>
                    <div className="modal-body">
                        <div className="add-custmer-form">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <select className="form-control" value={status} onChange={(e)=>setStatus(e.target.value)}>
                                            <option value="All">All</option>
                                            <option value="Assigned">Assigned</option>
                                            <option value="live">Live</option>
                                            <option value="Not Assigned">Not Assigned</option>
                                            <option value="Draft Estimate">Draft Estimate</option>
                                            <option value="Finalized Estimate">Finalized Estimate</option>
                                            <option value="Submitted Estimate">Submitted Estimate</option>
                                            <option value="Approved Estimate">Approved Estimate</option>
                                            <option value="Draft Invoice">Draft Invoice</option>
                                            <option value="Finalized Invoice">Finalized Invoice</option>
                                            <option value="Submitted Invoice">Submitted Invoice</option>
                                            <option value="Paid Invoice">Paid Invoice</option>
                                            
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <select className="form-control" value={assigntoId} onChange={(e)=>setAssigntoId(Number(e.target.value))}>
                                            <option value="0">Assigned To </option>
                                            {
                                                assigneelist.length > 0 &&
                                                assigneelist.map(item=>(
                                                <option value={item.userId}>{item.name}</option>
                                                ))
                                            }  
                                        </select>
                                    </div>
                                </div>


                                <div className="col-md-6 pright0">
                                    <div className="form-group">
                                        <div className="">
                                            <input type="date" className="form-control" placeholder="Period From" value={fromdate} onChange={(e)=>setFromdate(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="date" className="form-control"placeholder="Period To" value={todate} onChange={(e)=>setTodate(e.target.value)} />
                                    </div>
                                </div>


                                <div className="col-md-12">
                                    <a type="submit" className="btn btn-theme pull-right" onClick={()=>handleSearch()}>Apply </a>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                {/* <!-- /.modal-content --> */}
            </div>
            {/* <!-- /.modal-dialog --> */}
        </div>

    )
}

export default TicketFilters
