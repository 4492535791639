import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../components/nav/Header'
import Sidebar from '../../components/nav/Sidebar'
import CreateUpdateService from '../../forms/CreateUpdateService'
import DeletePopUp from '../../forms/DeletePopUp'
import { toast } from 'react-toastify'
import { CreateService_api, Loadservice_api, Updateservice_api, ChangeserviceStatus_api, Deleteservice_api } from '../../functions/service'

const Services = () => {

    const [isLoaded, setIsLoaded] = useState(false);
    const [screentype, setScreenType] = useState("Create");
    const [serviceId, setserviceId] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [serviceList, setserviceList] = useState([]);
    const [service, setservice] = useState("");
    const [serviceamount, setserviceamount] = useState(0);
    const loggeduserid = Number(localStorage.getItem("userid"));


    useEffect(() => {
        loadservices();
    }, [searchText])

    const loadservices = () => {
        setIsLoaded(false);
        Loadservice_api(searchText).then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {
                    setserviceList([])
                }
                else
                    setserviceList(result.data);
            }
            setIsLoaded(true);
        }).catch();
    }

    const showServiceCreationPopUp = () => {
        setservice("");
        setserviceId("");
        setScreenType("Create");
        window.$("#Addservice").modal('show');
    }

    const CreateService_submit = (e) => {
        console.log(localStorage.getItem("clientid"), service, serviceamount, loggeduserid)
        e.preventDefault();
        if (service.trim() === "")
            return toast.error("Please enter service name.");
        else if (serviceamount.trim() == "")
            return toast.error("Please enter service amount.");


        var req = {
            "clientid": Number(localStorage.getItem("clientid")),
            "servicename": service,
            "serviceamount": Number(serviceamount),
            "addedby": Number(loggeduserid)
        }
        CreateService_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                window.$("#Addservice").modal('hide');
                setserviceamount(0);
                setservice("");
                loadservices();
            }
        })
            .catch();

    }

    const Updateservice_submit = (e) => {
        e.preventDefault();
        if (service.trim() == "")
            return toast.error("Please enter order type.");


        var req = {
            "serviceid": Number(serviceId),
            "servicename": service,
            "serviceamount": Number(serviceamount),
            "updatedby": Number(loggeduserid)
        }
        Updateservice_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                window.$("#Addservice").modal('hide');
                setservice("");
                loadservices();
            }
        })
            .catch();

    }

    const Updateservice_Status_submit = (serviceid, newstatus) => {

        ChangeserviceStatus_api(serviceid, newstatus).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                loadservices();
            }
        })
            .catch();

    }

    const loadserviceDetails = (item) => {
        setservice(item.serviceName);
        setserviceId(item.serviceID)
        setserviceamount(item.serviceAmount)
        setScreenType("Update");
        window.$("#Addservice").modal('show');
    }

    const deleteservicePopUp = (serviceid) => {
        setserviceId(serviceid)
        window.$("#DeletePopUp").modal('show');
    }

    const Deleteservice_submit = (serviceid) => {
        Deleteservice_api(serviceid).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                window.$("#DeletePopUp").modal('hide');
                loadservices();
            }
        })
            .catch();

    }

    return (
        <div className="hold-transition skin-black sidebar-mini">
            <div className="wrapper" >

                {/* SideBar */}
                <Header />
                <Sidebar />
                {/* <!-- Content Wrapper. Contains page content --> */}
                <div className="content-wrapper db-background">

                    {/* <!-- Main content --> */}
                    <section className="content content-body">
                        {/* <!-- Main row --> */}
                        <div className="row">
                            <div className="col-md-6">
                                <div className="top-btn-group estimatestab">
                                    <div className="btn-group ">
                                        <Link to="/ordertype" className="btn btn-default">Order Type</Link>
                                        <Link to="/services" className="btn btn-theme">Services</Link>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">

                            </div>
                            <div className="col-md-12">
                                <div className="content-header ">
                                    <h1 className="">
                                        Services  </h1>
                                    <div className="topbtn-right">
                                        <a type="" className="btn btn-theme filterbtn" data-toggle="modal" onClick={() => showServiceCreationPopUp()}>Add Service</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="top-btn-group ">

                                    {/* <div className="pull-right">
                                    <div className="pull-left serch-fild top-serching">

                                        <input type="text" className="form-control ih36" placeholder="" value={searchText} onChange={e => setSearchText(e.target.value)} />
                                        <i className="fa fa-search"></i>

                                    </div>
                                </div> */}
                                </div>

                                <div className="">
                                    <div className="box box-solid">
                                        {/* <!-- /.box-header --> */}
                                        {
                                            isLoaded == false ? <div className="loader"><img src="images/loader.gif" alt="" /></div>
                                                :
                                                <div className="box-body table-responsive no-padding userlist">
                                                    <table className="table ">
                                                        <tbody>
                                                            <tr>

                                                                <th>Service Name</th>
                                                                <th>Amount</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                            {
                                                                (serviceList != undefined && serviceList != null && serviceList != "null") &&
                                                                    serviceList.length == 0 ?
                                                                    <tr><td colSpan="4">No Record Found.</td></tr>
                                                                    :
                                                                    serviceList.map(item => (
                                                                        <tr>

                                                                            <td>{item.serviceName}</td>
                                                                            <td>{item.serviceAmount}</td>
                                                                            <td className="">
                                                                                {
                                                                                    item.status == "Active"
                                                                                        ?
                                                                                        <a style={{ cursor: 'pointer' }} className="stsbtn sts-active " onClick={() => Updateservice_Status_submit(item.serviceID, 0)} >Active</a>
                                                                                        :
                                                                                        <a style={{ cursor: 'pointer' }} className="stsbtn sts-inactive" onClick={() => Updateservice_Status_submit(item.serviceID, 1)}>Inactive</a>
                                                                                }

                                                                            </td>

                                                                            <td className="">
                                                                                {item.userType != "Admin" &&
                                                                                    <a style={{ cursor: 'pointer' }} className="stsbtn sts-inactive" onClick={() => loadserviceDetails(item)}>Edit</a>
                                                                                }
                                                                                &nbsp;&nbsp;
                                                                                {item.userType != "Admin" &&
                                                                                    <a style={{ cursor: 'pointer' }} className="stsbtn sts-inactive" onClick={() => deleteservicePopUp(item.serviceID)}>Delete</a>
                                                                                }


                                                                            </td>

                                                                        </tr>
                                                                    ))

                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>

                                        }{/* <!-- /.box-body --> */}
                                    </div>
                                </div>


                            </div>
                            {/* <!-- /.row (main row) --> */}
                        </div>
                        {/* <!-- /.content --> */}
                    </section>
                    {/* <!-- /.content-wrapper -->       */}
                </div>

                {/* Create Form */}
                <CreateUpdateService
                    screentype={screentype}
                    service={service}
                    setservice={setservice}
                    serviceamount={serviceamount}
                    setserviceamount={setserviceamount}
                    CreateService_submit={CreateService_submit}
                    Updateservice_submit={Updateservice_submit}
                    serviceId={serviceId}
                />

                <DeletePopUp
                    DeleteUser_submit={Deleteservice_submit}
                    id={serviceId}
                    getreqFrom="Service" />



                {/* <!-- /.modal --> */}

            </div>
        </div>

    )
}

export default Services
