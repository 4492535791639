import React from 'react'


const CreateUpdateOrderType = ({ screentype, CreateOrderType_submit, UpdateOrderType_submit, orderType, setOrderType }) => {
    return <div className="modal fade fsm-popup" id="AddOrderType">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <a type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <img src="images/close.png" /></a>
                    <h5 className="modal-title">{screentype == "Create" ? "Add" : "Update"} Order Type</h5>
                </div>
                <div className="modal-body">
                    <div className="add-custmer-form ">
                        <div className="row">

                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="">
                                        <input type="text" className="form-control" placeholder="Order Type" maxLength="50" value={orderType} onChange={e => setOrderType(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 pull-right">
                                <a type="button" className="btn btn-danger pull-right" data-dismiss="modal" aria-label="Close">Cancel</a>
                            </div>

                            {
                                screentype == "Create"
                                    ?
                                    <div className="col-md-10">
                                        <a type="submit" className="btn btn-theme pull-right" onClick={CreateOrderType_submit} >SAVE </a>
                                    </div>

                                    :
                                    <div className="col-md-10">
                                        <a type="submit" className="btn btn-theme pull-right" onClick={UpdateOrderType_submit} >SAVE </a>
                                    </div>
                            }

                        </div>
                    </div>

                </div>

            </div>
            {/* <!-- /.modal-content --> */}
        </div>
        {/* <!-- /.modal-dialog --> */}
    </div>

}

export default CreateUpdateOrderType
