
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'


const API_Base_Url = process.env.REACT_APP_API_BASE_URL;
const AccessToken = localStorage.getItem("AccessToken");
const loggeduserid = Number(localStorage.getItem("userid"));



export const GetReport_RevenueByTech_api = async (req) => {
    try {
        const response = await fetch(API_Base_Url + "reports/revenueByTech", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify(req)
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

export const GetReport_CollectionReport_api = async (req) => {
        try {       
        const response = await fetch(API_Base_Url + "reports/collection", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify(req)
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

export const GetReport_TechUtilizationReport_api = async (req) => {
    try {       
    const response = await fetch(API_Base_Url + "reports/techUtilization", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
        },
        body: JSON.stringify(req)
    })
    if (response.status == 401) {
        toast.error('Your Session has been expired, Please login again.');
        return window.setTimeout(function () {
            localStorage.clear();
            window.location.href = "/#/";
        }, 1000);
    }
    const result = await response.json();
    if (response.ok) {
        return result;
    }
    else if (response.status === 400) {
        if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
    }
    else {

    }
} catch (error) {
    toast.error('Something went wrong , Please try again later.')
}
};

export const GetReport_RevenueByCustomer_api = async (req) => {
    try {
        var data = [];
        const response = await fetch(API_Base_Url + "reports/revenueByCustomer", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify(req)
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};
export const GetReport_TechPerformance_api = async (req) => {


    try {     
        const response = await fetch(API_Base_Url + "reports/techPerformance", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify(req)
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

export const GetReport_AvgTicketResolutionTime_api=async(req)=>{
    try {   
        const response = await fetch(API_Base_Url + "analytics/avgticketresolutiontime", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
               // 'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify(req)
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

export const GetReport_TechOccupancy_api=async(req)=>{
    try {   
        const response = await fetch(API_Base_Url + "analytics/techoccupancy", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
               // 'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify(req)
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

export const GetReport_AvgRevenuePerTech_api=async(req)=>{
    try {   
        const response = await fetch(API_Base_Url + "analytics/avgrevenuepertech", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
               // 'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify(req)
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

export const GetReport_AvgResponseTime_api=async(filterval)=>{
    try {   
        const response = await fetch(API_Base_Url + "analytics/avgresponsetime?period="+filterval, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
               // 'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};


export const GetReport_AvgTicketLifeCycle_api=async(filterval)=>{
    try {   
        const response = await fetch(API_Base_Url + "analytics/avgticketlifecycle?period="+filterval, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
               // 'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};



export const GetReport_EstimatedActual_api=async(filterval)=>{
    try {   
        const response = await fetch(API_Base_Url + "analytics/estimatedactual?period="+filterval, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
               // 'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

