import React from 'react';
import { useHistory } from 'react-router-dom'

function ResetPassword4() {
    const history = useHistory();

    const gotologin = () => {
        history.push("/")
    }
    return (
        <div className="hold-transition login-page login2">
            <div className="login2-body">
                <div className="login-left-pnl " >
                    <div className="logo1">
                        <img src="images/logo-1.png" className="" alt=""/>
                        <h4>Reset Password</h4>
                    </div>
                    <form>
                        <div className="spacetologo new-password-msg">
                            You have successfully created a new Password!
                    </div>
                        <div className="spacetologo">
                            <button type="submit" className="btn btn-green btn-block" onClick={() => gotologin()}>LOGIN TO FSM</button>
                        </div>
                    </form>
                </div>

                <div className="login-right-pnl">
                    <h4 className="text-center">Field Service Management System</h4>
                    <img src="images/login-bnr.png" alt=""/>
                </div>

                <div className="clearfix"></div>
            </div>

        </div>
    )
}

export default ResetPassword4