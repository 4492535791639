import { toast } from 'react-toastify'
const API_Base_Url = process.env.REACT_APP_API_BASE_URL;


export const LoadNotificationList_api = async (userid) => {
    try {
        const response = await fetch(`${API_Base_Url}notification/web?userid=${userid}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        //toast.error('Something went wrong , Please try again later.')
    }
}