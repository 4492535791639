import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { LoadOrderType_api } from '../functions/masters'

const OrderTypeUpdate = ({ orderType, orderDetail_prop }) => {

    const loggeduserid = Number(localStorage.getItem("userid"));
    const [orderTypeList, setOrderTypeList] = useState([]);
    const [selectedOrderType, setSelectedOrderType] = useState(orderType);
    const [orderDetail, setOrderDetail] = useState(orderDetail_prop);

    useEffect(() => {
        loadOrderType();
    }, [])
    const loadOrderType = () => {
        LoadOrderType_api().then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {
                    setOrderTypeList([])
                }
                else
                    setOrderTypeList(result.data);
            }

        }).catch();
    }

    const UpdateOrderType = (e) => {
        e.preventDefault();
        if (selectedOrderType == "" || selectedOrderType == null)
            return toast.error("Please choose order type.");
        else if (orderDetail == "" || orderDetail == null)
            return toast.error("Please enter order details.");
        else {
            var req = {
                // "customerid": customerid,
                // //"customername": ticketTitle,
                // "ordertypeid": Number(orderType),
                // "orderdetails": orderDetail,
                // "scheduledate": ticketDate,
                // "scheduletime": ticketTime,
                // "technicianid": Number(technicianId),
                // "porequired": poRequired,
                // "pofilepath": poDOc,
                // "addedby": loggeduserid
            }
            // UpdateOrderType_api(req).then(res => {
            //     if (res != undefined) {
            //         //toast.success(res.message);
            //         window.$("#OrderType").modal('hide');
            //         //refreshDashboard();
            //     }
            // })
            //     .catch();
        }

    }



    return (
        <>
        </>
    )
}

export default OrderTypeUpdate
