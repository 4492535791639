import React from 'react';
import { useState } from 'react';
import Chart from 'react-apexcharts';

export default function PieChartResponseTime(props) {


// Making Series Array to show in Graph

const series = [props.data.part1,props.data.part2]
    return (

        <>
            <div id="SimplePieChart">
             
                <Chart
                    id="chart"
                    options={
                        {
                            chart: {
                               // width: 300,
                                type: 'pie',
                                offsetY:20,
                                offsetX: 50,

                            },
                            labels: props.lable,
                            colors: [ "#8B9DC3","#3B5998"],
                            plotOptions: {
                                radialBar: {
                                  startAngle: -30,
                                  endAngle: 90,
                                  offsetX: 0,
                                  offsetY: 0,
                                  hollow: {
                                    size: '60%',
                                    position: 'front',
                                  },
                                  
                                  dataLabels: {
                                    position: 'bottom',
                                    name: {
                                      fontSize: "16px",
                                      show: true,
                                      color: '#8B9DC3',
                                      fontWeight: 500,
                                    },
                                    value: {
                                      offsetY: -5,
                                      offsetX: 0,
                                      fontSize: "25px",
                                      show: true,
                                      color: '#3B5998',
                                      fontWeight: 400
                                    },
                  
                                  }
                  
                                },
                  
                              },
                            responsive: [{
                                breakpoint: 480,
                                options: {
                                    chart: {
                                        width: 60
                                    },
                                    legend: {
                                      show: false,
                                        position: 'bottom'

                                    }
                                }
                            }]
                        }
                    }
                    series={series}
                    type="pie"
                    height={350} width={350} />
            </div>
        </>


    );
}
