
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'


const API_Base_Url = process.env.REACT_APP_API_BASE_URL;
const AccessToken = localStorage.getItem("AccessToken");
const loggeduserid = Number(localStorage.getItem("userid"));




//Add OrderType
export const CreateService_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}service/add`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify(reqdata)
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        console.log("now",result)
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

//Update OrderType
export const Updateservice_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}service/update`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify(reqdata)
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }

};

//Active Not Active status update
export const ChangeserviceStatus_api = async (ordertypeid, statusid) => {
    try {
        const response = await fetch(`${API_Base_Url}service/status`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "serviceid": ordertypeid,
                "status": statusid,
                "updatedby": Number(localStorage.getItem("userid"))
            })
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }

};

//Delete OrderType
export const Deleteservice_api = async (ordertypeid) => {
    try {
        const response = await fetch(`${API_Base_Url}service/delete`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "serviceid": ordertypeid,               
                "deletedby": Number(localStorage.getItem("userid"))
            })
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }

};

//User List
export const Loadservice_api = async (searchtext) => {
    //  const { user } = useSelector(state => ({ ...state }))
    // var header = { 'Authorization': 'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJGU01TZXJ2aWNlQWNjZXNzVG9rZW4iLCJqdGkiOiIwMGJlMGRmNC03YTBhLTRlNjQtYTdhMS0wMWZkYTQ5ZWY4ZDYiLCJpYXQiOiI0LzgvMjAyMSA0OjE0OjUxIEFNIiwiSWQiOiIyIiwiTmFtZSI6IktyaXMgSGFycGVyIiwiUGhvbmUiOiI5OTk4ODg3NzcyIiwiVXNlclR5cGUiOiJTdXBlcnZpc29yIiwiRW1haWwiOiJrcmlzaGFycGVyQGdtYWlsLmNvbSIsImV4cCI6MTYxNzk0MTY5MSwiaXNzIjoiRlNNQXV0aGVudGljYXRpb25TZXJ2ZXIiLCJhdWQiOiJGU01TZXJ2aWNlQXBwQ2xpZW50In0.keoUNJmc1eU5VXWZDTNYyULYudfyhrY7BLilk_wlG7E' }
    // return await axios.get(`${API_Base_Url}users/list?type=${tab}&search=${searchtext}`, header);

    try {
        var data = [];
        const response = await fetch(`${API_Base_Url}service/list?clientid=${Number(localStorage.getItem("clientid"))}&search=${searchtext}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }

}