import React, { useEffect, useState } from 'react'
import { LoadNotificationList_api } from '../../functions/notifications'
import ChangePassword_pop from '../../pages/auth/ChangePassword'
import Notifications from '../popups/Notifications'
import { ReadNotificationApi } from '../../functions/dashboard'

function Header() {

    const profilepic = localStorage.getItem("profilepic");
    const username = localStorage.getItem("username");
    const usertype = localStorage.getItem("usertype");
    const client = localStorage.getItem("client");
    const [refresh, setRefresh] = useState(0)
    const userid = localStorage.getItem("userid");
    const [notificationList, setNotificationList] = useState([]);
    const [notcount, setNotcount] = useState(localStorage.getItem("notificationcount"))

    useEffect(() => {
        refreshNotificationCount();
    }, []);
   
    var intervaltime;
    useEffect(() => {
        intervaltime = setInterval(refreshNotificationCount, 5000)
    }, [refresh])

    const refreshNotificationCount = () => {
        clearInterval(intervaltime)
        setRefresh(refresh + 1)
        setNotcount(localStorage.getItem("notificationcount"));
    }

    const signOut = () => {
        localStorage.clear();
        window.location.href = '#';
        window.location.reload();
    }

    const ChangePassword = () => {
        window.$("#ChangePWD").modal('show');
    }    

    const openNotification = () => {
        
        ReadNotificationApi().then(res => {           
            const result = res;
            if (result != undefined) {
                setNotcount("0");
                localStorage.setItem("notificationcount", "0");         
            }
        }).catch();

        window.$("#Notification").modal('show')
    }
    return (
        <>
            <header class="main-header">
                {/* <!-- Logo --> */}
                <a href="" class="logo">
                    {/* <!-- mini logo for sidebar mini 50x50 pixels --> */}
                    <span class="logo-mini"><b>HE</b></span>
                    {/* <!-- logo for regular state and mobile devices --> */}
                    <span class="logo-lg"><img src="images/mepcologo2.svg" alt="" height="40" style={{marginTop:"10px"}} /></span>
                </a>
                {/* <!-- Header Navbar: style can be found in header.less --> */}
                <nav class="navbar navbar-static-top">
                    {/* <!-- Sidebar toggle button--> */}

                    <a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button">
                        <span class="sr-only">Toggle navigation</span>
                    </a>
                    <div class="top-logo">{client} - FSM</div>
                    <div class="navbar-custom-menu">
                        <ul class="nav navbar-nav">
                            <li class="dropdown notifications-menu">
                                <a style={{ cursor: 'pointer' }} onClick={() => openNotification()} class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                    <i class="fa fa-bell"></i>
                                    { notcount!="0" ? <span class="label label-danger">{notcount}</span> : <span></span> }
                                </a>
                                 </li>


                            {/* <!-- User Account: style can be found in dropdown.less --> */}
                            <li class="dropdown user user-menu">
                                <a href="" class="dropdown-toggle" data-toggle="dropdown">
                                    <img src="dist/img/avtar.png" class="user-image" alt="User Image" />
                                    <span class="hidden-xs"> <i class="fa fa-angle-down"></i></span>
                                </a>
                                <ul class="dropdown-menu">

                                    {/* <!-- User image --> */}
                                    <li class="user-header">
                                        <img src="dist/img/avtar.png" class="img-circle" alt="User Image" />
                                        <p>
                                            {usertype}
                                            <small>{username}</small>
                                        </p>
                                    </li>
                                    {/* <!-- Menu Body --> */}

                                    {/* <!-- Menu Footer--> */}
                                    <li class="user-footer">
                                        <div class="pull-left">
                                            <a style={{ cursor: 'pointer' }} class="btn btn-default btn-flat" onClick={() => ChangePassword()}>Change Password</a>
                                        </div>
                                        <div class="pull-right">
                                            <a style={{ cursor: 'pointer' }} onClick={() => signOut()} class="btn btn-default btn-flat">Sign out</a>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header >
            <ChangePassword_pop />
            <Notifications />
        </>
    )
}

export default Header
