import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Header from '../nav/Header'
import Sidebar from '../nav/Sidebar'
import { Link } from 'react-router-dom'
import { LoadTechnicians_api } from '../../functions/users'
import { GetReport_TechUtilizationReport_api } from '../../functions/reports'
import moment from 'moment'
import ReactExport from "react-data-export";

function TechUtilization() {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    const [excelDataSet, setExcelDataSet] = useState([]);

    const [period, setPeriod] = useState("This Week");
    const [selectedTechnicianID, setSelectedTechnicianID] = useState(0);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [technicianList, setTechnicianlist] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [List, setList] = useState([]);

    const [paginationarr, setPaginationarr] = useState([]);
    const [selectedPageNo, setSelectedPageNo] = useState(1);
    let recordshowperpage = 30;

    useEffect(() => {
        loadTechnicians();
        loadReport();
    }, [])

    // useEffect(() => {
    //     if (period == "Custom") {
    //         window.$("#dateSelection").modal('show')
    //     }
    // }, [period])

    const selectPeriod = (period) => {
        setPeriod(period)
        if (period == "Custom") {
            window.$("#dateSelection").modal('show')
        }
    }

    const loadTechnicians = () => {
        LoadTechnicians_api().then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {
                    setTechnicianlist([])
                }
                else
                    setTechnicianlist(result.data);
            }

        }).catch();
    }


    const loadReport = () => {
        var _period = period;
        var _fromdate = fromDate;
        var _todate = toDate;
        var _technicianID = selectedTechnicianID;

        if (period === "Custom") {
            if (_fromdate == "" || _fromdate == null) {
                return toast.error("Please select from date.")
            }
            else {
                _fromdate = moment(_fromdate).format("YYYY-MM-DD")
            }
            if (_todate == "" || _todate == null) {
                return toast.error("Please select to date.")
            }
            else {
                _todate = moment(_todate).format("YYYY-MM-DD")
            }
        }
        setIsLoaded(false);
        var req = {
            "periodtype": _period,
            "fromdate": _fromdate,
            "todate": _todate,
            "techid": Number(_technicianID)
        }
        GetReport_TechUtilizationReport_api(req).then(res => {
            const result = res;
            if (result != undefined) {
                if (result == null) {
                    setList([])
                }
                else {
                    setList(result);
                    console.log(result,"tech")

                    let data = result.data;
                    let dataset = [];
                    if (data.length > 0) {
                        for (var i = 0; i < data.length; i++) {
                            if (data[i].tickets != undefined && data[i].tickets != null) {
                                for (var j = 0; j < data[i].tickets.length; j++) {
                                    dataset.push(
                                        {
                                            TechnicianID: data[i].technicianID,
                                            Technician: data[i].technicianname,
                                            Revenue: data[i].revenue,
                                            Customer: data[i].tickets[j].customername,
                                            ticketid: data[i].tickets[j].ticketid,
                                            ponumber: data[i].tickets[j].ponumber,
                                            ordertype: data[i].tickets[j].ordertype,
                                            ordertocashtime: data[i].tickets[j].ordertocashtime,
                                            activityPerformed: data[i].tickets[j].activityPerformed,
                                            draftedon: data[i].tickets[j].draftedon,
                                            checkin: data[i].tickets[j].checkin,
                                            checkout: data[i].tickets[j].checkout,
                                            timespent: data[i].tickets[j].timespent,
                                            timespentminutes: data[i].tickets[j].timespentminutes
                                        }
                                    )
                                }
                            }
                        }
                    }
                    {
                        dataset.push({
                            TechnicianID: 0,
                            Technician: "",
                            Revenue: "",
                            Customer: "",
                            ponumber: "",
                            ticketid: 0,
                            ordertype: "",
                            ordertocashtime:"",
                            activityPerformed: "",
                            draftedon: "",
                            checkin: "",
                            checkout: "",
                            timespent: "",
                            timespentminutes: ""
                        })
                    }
                    setExcelDataSet(dataset);

                    // var data = result.data;
                    if (data.length > recordshowperpage) {
                        var totalpage = data.length / recordshowperpage;
                        var reminder = data.length % recordshowperpage;
                        var pages = [];
                        for (var i = 1; i <= totalpage; i++) {
                            pages.push(i);
                        }
                        if (reminder == 0) {
                            //pages = pages;
                        }
                        else {
                            pages.push(pages.length + 1)
                        }
                        setPaginationarr(pages);
                    }
                }
            }
            else {
                setPaginationarr([]);
            }
            setIsLoaded(true);
        }).catch();
    }

    const AccordianChange = (item) => {
        var accordionitem = item;
        window.$("#" + accordionitem).slideToggle().parent().siblings().find(".accordion-content").slideUp();
        window.$("#" + accordionitem).toggleClass("active-title");
        window.$("#" + "item_" + accordionitem).toggleClass("active-title");
        window.$("#" + accordionitem).parent().siblings().find(".accordion-title").removeClass("active-title");
        window.$("i.fa-chevron-down", "#" + item).toggleClass("chevron-top");
        window.$("#" + accordionitem).parent().siblings().find(".accordion-title i.fa-chevron-down").removeClass("chevron-top");
    }


    return (
        <div>
            <div className="hold-transition skin-black sidebar-mini">
                <div className="wrapper">
                    <Header />
                    <Sidebar />
                    <div className="content-wrapper">
                        {/* <section className="content content-body"> */}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="reports">
                                    <ul>
                                        <li><Link to="revenuebytech">Revenue by Tech</Link></li>
                                        <li><Link to="revenuebycustomer">Revenue by Customer</Link></li>
                                        <li><Link to="collectionreport">Collection Report</Link></li>
                                        {/* <li><Link to="techperformance">Tech Performance</Link></li> */}
                                        <li className="active_report"><Link to="techutilization">Tech Utilization</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* </section> */}
                        <div className="filterbox">
                            <div className="">
                                <div className="boxmargin">
                                    <div className="box box-solid box-height">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group row">
                                                    <label for="colFormLabel" className="col-sm-4 col-form-label">Period</label>
                                                    <div className="col-sm-8">
                                                        <select className="form-control" onChange={(e) => selectPeriod(e.target.value)}>
                                                            <option>Today</option>
                                                            <option>This Week</option>
                                                            <option>This Month</option>
                                                            <option>Prev Month</option>
                                                            <option>This Year</option>
                                                            <option>Prev Year</option>
                                                            <option>Custom</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group row">
                                                    <label for="colFormLabel" className="col-sm-4 col-form-label">Technician</label>
                                                    <div className="col-sm-8">
                                                        <select className="form-control" value={selectedTechnicianID} onChange={(e) => setSelectedTechnicianID(e.target.value)}>
                                                            <option value="0">All</option>
                                                            {
                                                                technicianList.map(item => (
                                                                    <option value={item.userId}>{item.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <div className="form-group row">
                                                    <div className="col-sm-3">
                                                        <button className="btn btn-primary" onClick={() => loadReport()}>View</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <div className="form-group row">
                                                    <div className="col-sm-3">
                                                        <ExcelFile filename="Tech Utilization" element={<button className="btn btn-primary">Export to Excel</button>} >
                                                            <ExcelSheet data={excelDataSet} name="customer revenue" >
                                                                <ExcelColumn label="Technician" value="Technician" />
                                                                <ExcelColumn label="Ticket ID" value="ticketid" />
                                                                <ExcelColumn label="Customer" value="Customer" />
                                                                <ExcelColumn label="PO Number" value="ponumber" />
                                                                <ExcelColumn label="Order Type" value="ordertype" />
                                                                <ExcelColumn label="Activity Performed" value="activityPerformed" />
                                                                <ExcelColumn label="Drafted On" value="draftedon" />
                                                                <ExcelColumn label="Check In" value="checkin" />
                                                                <ExcelColumn label="Check Out" value="checkout" />
                                                                <ExcelColumn label="Time Spent" value="timespent" />
                                                                <ExcelColumn label="Time Spent Minutes" value="timespentminutes" />
                                                            </ExcelSheet>
                                                        </ExcelFile>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            period == "Custom"
                                            &&
                                            <div>
                                                <b>From - </b> {fromDate != "" && moment(fromDate).format("DD MMM YYYY")} &nbsp;&nbsp; <b>To -  </b>{toDate != "" && moment(toDate).format("DD MMM YYYY")}
                                                &nbsp; &nbsp;<i className="fa fa-pencil" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => window.$("#dateSelection").modal('show')}></i>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="rptcontainer">
                            {
                                isLoaded == false ? <div className="loader"><img src="images/loader.gif" alt="" /></div>
                                    :
                                    <div className="accordions">
                                        {
                                            List.data != undefined &&
                                            <>
                                                {
                                                    List.data.length == 0
                                                        ?
                                                        "No Record Found."
                                                        :
                                                        List.data.slice(selectedPageNo * recordshowperpage - recordshowperpage, selectedPageNo * recordshowperpage).map((item, index) => (
                                                            <>
                                                                <div className="accordion-item ">
                                                                    <div className="accordion-title" data-tab={`item-${index}`} onClick={() => AccordianChange(`item-${index}`)}>
                                                                        <div className="row">
                                                                            <div className="col-md-10">
                                                                                <p className="name">{item.technicianname} </p>
                                                                            </div>
                                                                            <div className="col-md-2 alignright">
                                                                                <div className="amount">{item.timespent}</div>
                                                                                <i className="fa fa-angle-down"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="accordion-content" id={`item-${index}`}>
                                                                        <div className="customertbl">
                                                                        <table className="table table-striped">
                                                                            <thead>
                                                                                <tr>

                                                                                    <th>ticketid</th>
                                                                                    <th>Customer</th>
                                                                                    <th>Order Type</th>
                                                                                    <th>Activity Performed</th>
                                                                                    <th>PO Number</th>
                                                                                    <th>Check In</th>
                                                                                    <th>Check Out</th>
                                                                                    <th>Order to Cash Time</th>
                                                                                    <th className="alignright">Time Spent</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>

                                                                                {

                                                                                    item.tickets != undefined &&

                                                                                        item.tickets.length == 0
                                                                                        ?
                                                                                        "No Record Found."
                                                                                        :
                                                                                        item.tickets != null &&
                                                                                        item.tickets.map(inneritem => (
                                                                                            <tr>

                                                                                                <td>{inneritem.ticketid}</td>
                                                                                                <td >{inneritem.customername}</td>
                                                                                                <td>{inneritem.ordertype}</td>
                                                                                                <td>{inneritem.activityPerformed}</td>
                                                                                                <td>{inneritem.ponumber}</td>
                                                                                                <td>{inneritem.checkin}</td>
                                                                                                <td>{inneritem.checkout}</td>
                                                                                                <td>{inneritem.ordertocashtime}</td>
                                                                                                <td className="alignright">{inneritem.timespent}</td>
                                                                                            </tr>
                                                                                        ))
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))
                                                }
                                            </>
                                        }
                                    </div>
                            }
                            <ul className="pagination pull-right mt0">
                                {
                                    paginationarr.length > 0 &&
                                    paginationarr.map(item => (
                                        <li class={`paginate_button ${item == selectedPageNo && "active"}`}><a style={{ cursor: 'pointer' }} onClick={() => setSelectedPageNo(item)}>{item}</a></li>
                                    ))
                                }

                            </ul>
                        </div>

                    </div>
                </div>
            </div>

            <div className="modal fade bd-example-modal-sm" id="dateSelection" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <div className="dateselectionModal">
                            <div className="form-group row">
                                <label for="colFormLabel" className="col-sm-4 col-form-label">From </label>
                                <div className="col-sm-8">
                                    <input type="date" className="form-control" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="colFormLabel" className="col-sm-4 col-form-label">To </label>
                                <div className="col-sm-8">
                                    <input type="date" className="form-control" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                                </div>
                            </div>
                            <div className="row">
                                <button className="btn btn-primary" onClick={() => window.$("#dateSelection").modal('hide')}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TechUtilization
