import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { CreateTicket_api, SearchDuplicateTicket_api } from '../functions/tickets'
import { LoadOrderType_api } from '../functions/masters'
import { LoadUsers_api, LoadTechnicians_api } from '../functions/users'
import CreateUpdateOrderType from '../forms/CreateUpdateOrderType'
import { CreateOrderType_api } from '../functions/orderType'
import { Customers_Profile_api } from '../functions/customers'
import { LoadTicketDetails_api } from '../functions/tickets'

function CreateTicket({ customerid, refreshDashboard, testId, ticketid,ticketType=""}) {

    const API_Base_Url = process.env.REACT_APP_API_BASE_URL;
    const AccessToken = localStorage.getItem("AccessToken");

    const [ticketTitle, setTicketTitle] = useState("");
    const [orderType, setOrderType] = useState("");
    const [orderDetail, setOrderDetail] = useState("");
    const [ticketDate, setTicketDate] = useState("");
    const [ticketTime, setTicketTime] = useState("");
    const [poRequired, setPoRequired] = useState(false);
    const [poDOc, setPoDoc] = useState("");
    const [technicianid, setTechnicianid] = useState(0);
    const loggeduserid = Number(localStorage.getItem("userid"));
    const [orderTypeList, setOrderTypeList] = useState([]);
    const [technicianList, setTechnicianlist] = useState([]);
    const [technicianId, setTechnicianID] = useState(0);
    const [txtsearchOrderType, setTxtSearchOrderType] = useState("");
    const [allOrderTypes, setAllOrderTypes] = useState([]);
    const [screentype, setScreenType] = useState("Create");
    const [orderTypeId, setOrderTypeId] = useState(0);
    const [test, setText] = useState(0);
    const [showsearch, setshowsearch] = useState(false);
    const [poNumber, setPoNumber] = useState("");
    const [workOrderNumber, setWorkOrderNumber] = useState("");
    const [serviceAddress, setServiceAddress] = useState("");
    const [sameAsBilling, setSameAsBilling] = useState(false);
    const [customerAddress, setCustomerAddress] = useState("");
    const [state, setState] = useState("")
    const [city, setCity] = useState("")
    const [zipcode, setZipCode] = useState("")
    const [latitude, setLatitude] = useState("")
    const [longitude, setLongitude] = useState("")
    const [customer_state, setcustomer_State] = useState("")
    const [customer_city, setcustomer_City] = useState("")
    const [customer_zipcode, setcustomer_ZipCode] = useState("")
    const [customer_latitude, setcustomer_Latitude] = useState("")
    const [customer_longitude, setcustomer_Longitude] = useState("")
    const usertype = localStorage.getItem("usertype");
    const [jobname, setJobName] = useState("");
    const [jobcontactname, setJobContactName] = useState("");
    const [jobcontactemail, setJobContactEmail] = useState("");
    const [jobcontactphone, setJobContactPhone] = useState("");
    const [expectedtime, setExpectedtime] = useState("0")
    const [ticketDetails, setTicketDetails] = useState({});
    const [priorityId, setPriorityID] = useState(0)
    const [isLoaded, setIsLoaded] = useState(false)
    const [selectedcustomerid,setCustomerId]=useState(0)



    useEffect(() => {
        console.log("idmain",customerid)
        loadOrderType();
        loadTechnicians();
        window.$("#searchOrderType").hide();
    }, []);


    useEffect(() => {
        console.log("id",customerid)
        setTxtSearchOrderType("")
        setOrderTypeId(0);
        setOrderType("")
        setOrderDetail("");
        setTicketDate("");
        setTicketTime("");
        setTechnicianID("")
        setPoDoc("");
        setPoRequired(false);
        setSameAsBilling(false)
        if (customerid !== undefined && customerid !== 0 && customerid !== null){
            setCustomerId(customerid)
            loadCustomerProfile();
            console.log("id2",customerid)
        }

        if (ticketid !== undefined && ticketid !== 0 && ticketid !== null && ticketid !== "") {
           if(ticketType==="normal"){ loadTicketDetails()}
                   }
    }, [testId])

    useEffect(() => {
        console.log("idsame1",customerid)
        if (sameAsBilling) {
            console.log("idsame2",customerid)
            setServiceAddress(customerAddress)
            setCity(customer_city)
            setState(customer_state)
            setLatitude(customer_latitude)
            setLongitude(customer_longitude)
            setZipCode(customer_zipcode)
        }
        else
            setServiceAddress("");
    }, [sameAsBilling])

    const loadCustomerProfile = () => {
    
        Customers_Profile_api(customerid).then(res => {
            const result = res;
            if (result != undefined) {
                if (result == null) {
                    //SetUserList([])
                }
                else {
                    setCustomerAddress(result.address)
                    setcustomer_State(result.state)
                    setcustomer_City(result.city)
                    setcustomer_Latitude(result.latitude)
                    setcustomer_Longitude(result.longitude)
                    setcustomer_ZipCode(result.zipcode)
                }
                console.log(result, "customerprofile")

                //SetUserList(result.data);
            }
        }).catch();
    }


    const loadOrderType = () => {
        console.log("idordertype",customerid)
        LoadOrderType_api().then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {
                    setOrderTypeList([])
                    setAllOrderTypes([])
                }
                else {
                    setOrderTypeList(result.data);
                    setAllOrderTypes(result.data);
                }
            }

        }).catch();
    }
    const loadTechnicians = () => {
        LoadTechnicians_api().then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {
                    setTechnicianlist([])
                }
                else
                    setTechnicianlist(result.data);
            }

        }).catch();
    }

    const CreateOrderType_submit = (e) => {
        e.preventDefault();
        if (orderType.trim() == "")
            return toast.error("Please enter order type.");

        var req = {
            "ordertype": orderType,
            "addedby": loggeduserid
        }
        CreateOrderType_api(req).then(res => {
            if (res != undefined) {
                //toast.success(res.message);
                window.$("#AddOrderType").modal('hide');
                setOrderType("");
                loadOrderType();
                SelectNewOrderType(res.ordertypeid)
            }
        })
            .catch();

    }



    const submitTicket = (e) => {
       
        e.preventDefault();
       
        if (orderTypeId == 0 || orderTypeId == null)
            return toast.error("Please choose order type.");
        else if ((ticketDate == "" || ticketDate == null) && usertype !== "Dispatcher")
            return toast.error("Please enter ticket date.");
        else if ((ticketTime == "" || ticketTime == null) && usertype !== "Dispatcher")
            return toast.error("Please enter ticket time.");
        else if (expectedtime === "0")
            return toast.error("Please enter expected time.");
        else if ((latitude == "" || latitude == null))
            return toast.error("Please select complete address.");
        else if (longitude == "" || longitude == null)
            return toast.error("Please select complete address.");

        else {

            var req = {
                "ponumber": poNumber,
                "workordernumber": workOrderNumber,
                "jobname": jobname,
            }

            SearchDuplicateTicket_api(req).then(result => {
                console.log(result, "duplicateticket")
                if (result.message === "Ticket exists") {
                    window.$("#SearchDuplicateTicket").modal('show');
                }
                else {
                    window.$("#SearchDuplicateTicket").modal('hide');
                    CreateTicket(e);
                }
            })
        }

    }

    const CreateTicket = (e) => {
        var req = {
            "customerid": selectedcustomerid,
            //"customername": ticketTitle,
            "ordertypeid": Number(orderTypeId),
            "orderdetails": orderDetail,
            "scheduledate": ticketDate,
            "scheduletime": ticketTime,
            "expectedtime": expectedtime,
            "technicianid": Number(technicianId),
            "ponumber": poNumber,
            "workordernumber": workOrderNumber,
            "address": serviceAddress,
            "state": state,
            "city": city,
            "zipcode": zipcode,
            "latitude": String(latitude),
            "longitude": String(longitude),
            "jobname": jobname,
            "jobcontactname": jobcontactname,
            "jobcontactemail": jobcontactemail,
            "jobcontactphone": jobcontactphone,
            "pofilepath": poDOc,
            "addedby": loggeduserid,
            "priority": Number(priorityId)
        }
        console.log(req, "ticketadd")
        CreateTicket_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                window.$("#SearchDuplicateTicket").modal('hide');
                window.$("#CreateTicket").modal('hide');
                //loadCustomers();
                refreshDashboard();
                clearFields();

            }
        })
            .catch();

    }

    const HandleClose = (e) => {
        window.$("#SearchDuplicateTicket").modal('hide');
        window.$("#CreateTicket").modal('hide');
        refreshDashboard();
        clearFields();
    }

    const UploadDocument = async (e) => {

        try {
            let multifile = [];
            const selectedFile = e.target.files;
            //for (let i = 0; i < selectedFile.length; i++) {
            const formData = new FormData();
            var today = new Date();
            var doctype = "";
            const ext = selectedFile[0].name.split('.').pop();
            const datevalue = today.getDate() + today.getMonth() + today.getFullYear() + today.getHours() + today.getMinutes() + today.getSeconds();
            const filename = "file_" + datevalue + "." + ext;
            if (ext == "png" || ext == "jpeg" || ext == "svg" || ext == "wpeg" || ext == "PNG" || ext == "JPEG" || ext == "JPG" || ext == "jpg" || ext == "SVG" || ext == "WPEG") {
                doctype = "Image";
            }
            else if (ext == "pdf" || ext == "PDF") {
                doctype = "PDF";
            }
            else if (ext == "docx" || ext == "DOCX" || ext == "doc" || ext == "DOC" || ext == "txt" || ext == "TXT") {
                doctype = "Document";
            }
            else if (ext == "pptx" || ext == "PPTX" || ext == "ppt" || ext == "PPT") {
                doctype = "Presentation";
            }
            else if (ext == "xlsx" || ext == "XLSX" || ext == "xls" || ext == "XLS") {
                doctype = "Workbook";
            }
            else {
                return toast.error('Please Select Valid file');
            }
            // Update the formData object 
            formData.append("uploadedby", loggeduserid);
            formData.append("file", selectedFile[0], filename);
            const Request = {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + AccessToken
                },
                body: formData
            }
            const response = await fetch(API_Base_Url + "upload", Request)
            const result = await response.json();
            if (response.ok) {
                // multifile = multifile.concat(result)
                setPoDoc(result.filepath)
                //$("#file-picker1").hide();
            }
            else if (response.status === 400) {
                if (result.errors == undefined) { }
                else {
                    toast.error(result.errors)
                }
            }
            else if (response.status === 401) {
                toast.error('Your Session has been expired, Please login again.');
                window.setTimeout(function () {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }, 2000);
            }
            else {
                //setisLoaded(true)
            }
            //}
        } catch (error) {
            toast.error('Something went wrong , Please try again later.')
        }


    }

    const clearFields = () => {
        setOrderType("");
        setOrderDetail("");
        setTechnicianid(0);
        setTicketDate("");
        setTicketTime("");
        setPriorityID(0)
        setWorkOrderNumber("")
        setPoNumber("")
        setJobName("")
        setExpectedtime(0)


    }

    const showOrderTypeCreationPopUp = () => {
        //setCustomername("");
        window.$("#AddOrderType").modal('show');
    }

    // useEffect(() => {

    // }, [txtsearchOrderType])

    const search_fn = (e) => {
        var searchText = e.target.value;
        setTxtSearchOrderType(e.target.value)
        if (searchText.length > 1) {
            window.$("#searchOrderType").show()
            setOrderTypeList(allOrderTypes.filter(x => x.ordertype.toLowerCase().includes(searchText.toLowerCase())))
        }
        else {
            window.$("#searchOrderType").hide()
        }
    }

    const SelectNewOrderType = (ordertypeid) => {
        LoadOrderType_api().then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {

                }
                else {
                    var ordertype = result.data.filter(x => x.ordertypeid == ordertypeid)[0];
                    selectOrderType_fn(ordertype);
                }
            }

        }).catch();
    }

    const selectOrderType_fn = (item) => {
        setText(1)
        window.$("#searchOrderType").hide();
        setOrderTypeId(item.ordertypeid);
        window.$("#searchOrderType").hide();
        setTxtSearchOrderType(item.ordertype)
    }

    function initMap_create() {
        var input = document.getElementById('searchMapInput_create');
        setServiceAddress(input.value)
        var autocomplete = new window.google.maps.places.Autocomplete(input);

        autocomplete.addListener('place_changed', function () {
            var place = autocomplete.getPlace();
            let div = document.createElement('div');
            div.innerHTML = place.adr_address;
            let cityname = div.getElementsByClassName('locality')[0] != undefined ? div.getElementsByClassName('locality')[0].innerHTML : "";
            let statename = div.getElementsByClassName('region')[0] != undefined ? div.getElementsByClassName('region')[0].innerHTML : "";
            let lat = place.geometry.location.lat();
            let long = place.geometry.location.lng();
            setCity(cityname)
            setState(statename)
            setLatitude(lat)
            setLongitude(long)
            setServiceAddress(place.formatted_address)
        });
    }

    const onfocusSearch = () => {
        window.$("#searchMapInput_create").attr("autocomplete", "new-password");
    }

    const loadTicketDetails = () => {
        LoadTicketDetails_api(ticketid, loggeduserid).then(res => {
            const result = res;
            if (result != undefined) {
                if (result == null) {
                    setTicketDetails([])
                }
                else {
                    setTicketDetails(result);
                    setOrderTypeId(result.ordertypeid)
                    setOrderType(result.ordertype)
                    setTxtSearchOrderType(result.ordertype)
                    setOrderDetail(result.orderdetail)
                    setJobName(result.jobname)
                    setServiceAddress(result.address)
                    setState(result.state)
                    setCity(result.city)
                    setZipCode(result.zipcode)
                    setLatitude(result.latitude)
                    setLongitude(result.longitude)
                    setJobContactName(result.jobcontactname)
                    setJobContactEmail(result.jobcontactemail)
                    setJobContactPhone(result.jobcontactphone)
                    setPriorityID(result.priority)
                }

            }
        }).catch();
    }

    return (
        // <!-- Create Ticket -->
        <>
            <div className="modal fade fsm-popup" id="CreateTicket">
                <div className="modal-dialog" style={{ width: "600px" }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <a type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <img src="images/close.png" /></a>
                            <h5 className="modal-title">Add New Ticket</h5>
                        </div>
                        <div className="modal-body">
                            <div className="add-custmer-form ">
                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="text" className="form-control" autoComplete="false" placeholder="Job Type" maxLength="20" value={txtsearchOrderType} onChange={(e) => search_fn(e)} />
                                            {/* <button onClick={() => showOrderTypeCreationPopUp()}>Add New</button> */}
                                        </div>

                                        <div className="search-suggestion" id="searchOrderType" style={{ zIndex: '9999', border: '1px solid' }}>
                                            <div className="searchdiv" >
                                                {
                                                    orderTypeList.length == 0
                                                        ?
                                                        null
                                                        :
                                                        orderTypeList.map(item => (
                                                            <div className="search-list" style={{ cursor: 'pointer' }} onClick={() => selectOrderType_fn(item)} >
                                                                <h5>{item.ordertype}</h5>
                                                            </div>
                                                        ))
                                                }
                                            </div>
                                            <div className="addnewbtn">
                                                <a style={{ cursor: 'pointer' }} onClick={() => showOrderTypeCreationPopUp()}><img src="images/plus.png" />&nbsp;&nbsp; Add New Order Type</a>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <div className="">
                                                <textarea className="form-control" autoComplete="false" rows="3" placeholder="Job Details" value={orderDetail} onChange={(e) => setOrderDetail(e.target.value)}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="text" className="form-control" autoComplete="false" placeholder="Job Name" maxLength="20" value={jobname} onChange={(e) => setJobName(e.target.value)} />
                                            {/* <button onClick={() => showOrderTypeCreationPopUp()}>Add New</button> */}
                                        </div>
                                    </div>

                                    {/* Service Address */}
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            {/* <textarea className="form-control" autocomplete="off"  name="searchgoogle" id="searchMapInput_create" onChange={(e) => initMap_create()} rows="2" placeholder="Service Address" value={serviceAddress} ></textarea> */}
                                            <textarea className="form-control" autoComplete="false" id="searchMapInput_create" onFocus={() => onfocusSearch()} onChange={(e) => initMap_create()} rows="2" placeholder="Job Address" value={serviceAddress} ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="checkbox" id="sameasbilling" name="sameasbilling" value={sameAsBilling} checked={sameAsBilling ? true : false} onChange={() => setSameAsBilling(!sameAsBilling)} />
                                            &nbsp;<label for="sameasbilling"> Same as Billing Address </label>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label for="email">Job Contact</label>
                                            <input type="text" className="form-control" placeholder="Contact Name" maxLength="20" value={jobcontactname} onChange={e => setJobContactName(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-7 pright0">
                                        <div className="form-group">
                                            <input type="email" className="form-control" placeholder="Contact Email" maxLength="50" value={jobcontactemail} onChange={e => setJobContactEmail(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Contact Phone" maxLength="20" value={jobcontactphone} onChange={e => setJobContactPhone(e.target.value.replace(/\D/, ''))} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <input type="date" className="form-control" autoComplete="false" pattern="(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))" placeholder="Date" value={ticketDate} onChange={(e) => setTicketDate(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <input type="time" className="form-control" autoComplete="false" placeholder="Time" value={ticketTime} onChange={(e) => setTicketTime(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <select className="form-control" value={expectedtime} onChange={(e) => setExpectedtime(e.target.value)}>
                                                <option vlaue="0">Estimated Hours</option>
                                                <option value="00:30">00:30</option>
                                                <option value="01:00">01:00</option>
                                                <option value="01:30">01:30</option>
                                                <option value="02:00">02:00</option>
                                                <option value="02:30">02:30</option>
                                                <option value="03:00">03:00</option>
                                                <option value="03:30">03:30</option>
                                                <option value="04:00">04:00</option>
                                                <option value="04:30">04:30</option>
                                                <option value="05:00">05:00</option>
                                                <option value="05:30">05:30</option>
                                                <option value="06:00">06:00</option>
                                                <option value="06:30">06:30</option>
                                                <option value="07:00">07:00</option>
                                                <option value="07:30">07:30</option>
                                                <option value="08:00">08:00</option>
                                                <option value="08:30">08:30</option>
                                                <option value="09:00">09:00</option>
                                                <option value="09:30">09:30</option>
                                                <option value="10:00">10:00</option>
                                                <option value="10:30">10:30</option>

                                            </select>
                                        </div>
                                    </div>
                                    {usertype !== "Dispatcher" &&
                                        <>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <select className="form-control" value={technicianId} onChange={(e) => setTechnicianID(e.target.value)}>
                                                        <option>Technician</option>
                                                        {
                                                            technicianList.map(item => (
                                                                <option value={item.userId}>{item.name}</option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>
                                            </div>


                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <select className="form-control" value={priorityId} onChange={(e) => setPriorityID(e.target.value)}>
                                                        <option value="0">Priority</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                    </select>
                                                </div>
                                            </div>


                                            {/* <div className="col-md-12" hidden>
                                        <div className="form-group">
                                            <div className="topradio mt-30">
                                                <p><span style={{ fontWeight: '600' }}>PO Required</span>
                                                    <span className="pl-40">
                                                        <span className="rb" autosize="false">
                                                            <input id="rbparent" type="radio" value="rbparent" checked={poRequired == true} onChange={() => setPoRequired(true)} /></span>
                                                        <span >&nbsp;&nbsp;Yes </span>
                                                    </span>
                                                    <span className="pl-40">
                                                        <span className="rb" autosize="false">
                                                            <input id="rbstudent" type="radio" checked={poRequired == false} onChange={() => setPoRequired(false)} /></span>
                                                        <span >&nbsp;&nbsp;No</span>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div> */}

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <input type="textbox" className="form-control" autoComplete="false" placeholder="PO Number" value={poNumber} onChange={(e) => setPoNumber(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <input type="textbox" className="form-control" placeholder="Work Order Number" value={workOrderNumber} onChange={(e) => setWorkOrderNumber(e.target.value)} />
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {usertype !== "Dispatcher" ?
                                        <div className="col-md-6 pright0" >
                                            <input type="file" onChange={(e) => UploadDocument(e)} className="form-control tic-file" placeholder="PO File" />
                                        </div>
                                        :
                                        <div className="col-md-6 pright0" >
                                            &nbsp;
                                        </div>
                                    }
                                    <div className="col-md-2 pull-right">
                                        <a type="button" className="btn btn-danger pull-right" data-dismiss="modal" aria-label="Close">Cancel</a>
                                    </div>
                                    <div className="col-md-2 pull-right">
                                        <a type="submit" className="btn btn-theme pull-right" style={{ cursor: 'pointer' }} onClick={(e) => submitTicket(e)}>Create </a>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog --> */}
            </div>


            <div className="modal fade fsm-popup" id="SearchDuplicateTicket">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <a type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <img src="images/close.png" /></a>
                            <h5 className="modal-title"> </h5>
                        </div>
                        <div className="modal-body">
                            <div className="add-custmer-form ">
                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <p>The record already exists, do you want to proceed?</p>
                                        </div>
                                    </div>

                                    <div className="col-md-2 pull-right">
                                        <a type="button" className="btn btn-danger pull-right" onClick={(e) => HandleClose(e)}>NO</a>
                                    </div>
                                    <div className="col-md-2 pull-right">
                                        <a type="submit" className="btn btn-theme pull-right" style={{ cursor: 'pointer' }} onClick={(e) => CreateTicket(e)}>YES </a>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    {/* <!-- /.modal-content --> */}
                </div>
                {/* <!-- /.modal-dialog --> */}
            </div>



            <CreateUpdateOrderType
                screentype={screentype}
                orderType={orderType}
                setOrderType={setOrderType}
                CreateOrderType_submit={CreateOrderType_submit}
                orderTypeId={orderTypeId}
            />
        </>

    )
}

export default CreateTicket

