import React from "react";
import { Redirect, Route } from "react-router-dom";
import MyContext from '../contextapi/MyContext'

const PrivateRoute = ({
    component: Component,
    ...rest
}) => {
    //  const history = useHistory();  
    //   const accessToken = localStorage.getItem("token");
    //   const decodedToken = jwt_decode(accessToken);

    //    if (decodedToken.userData.isAdmin) {
    //     history.push("/admin-panel");
    //    }
    const { Contextuser } = React.useContext(MyContext);
    const IsUserBlank = Object.keys(Contextuser).length;
    return (
        <Route
            {...rest}
            render={(props) => {
                // logic for authenticated user to access /app part goes here.
                // e.g. check if user is logged-in logic.
                // const isLoggedIn = true;

                return IsUserBlank > 0 ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={"/"} />
                );
            }}
        />
    );
};
export default PrivateRoute