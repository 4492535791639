import React, { useState, useEffect } from 'react'
import Header from '../../components/nav/Header'
import Sidebar from '../../components/nav/Sidebar'
import DeletePopUp from '../../forms/DeletePopUp'
import { toast } from 'react-toastify'
import CustomerAddUpdateForm from '../../forms/CustomerAddUpdateForm'
import MyContext from '../../contextapi/MyContext'

import { LoadCustomers_api, DeleteCustomer_api, ChangeCustomerStatus_api, Customers_Profile_api } from '../../functions/customers'

function Customers() {
    const { Contextuser } = React.useContext(MyContext);
    const [customerList, setCustomerList] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedTab, setSelectedTab] = useState("All");
    const [searchText, setSearchText] = useState("");
    const [screentype, setScreenType] = useState("Create");
    const [customerinfo, setCustomerinfo] = useState({});
    const [customerId, setCustomerId] = useState(0);
    const [testId, setTestId] = useState(0);
    const usertype = localStorage.getItem("usertype");

    useEffect(() => {       
        loadCustomers();
    }, [selectedTab]);

    useEffect(() => {
        loadCustomers();
    }, [searchText])

    const loadCustomers = () => {
        setIsLoaded(false);
        LoadCustomers_api(selectedTab, searchText).then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {
                    setCustomerList([])
                }
                else
                    setCustomerList(result.data);
            }
            else {
                setCustomerList([])
            }
            setIsLoaded(true);
        }).catch();
    }

    const showCustomerCreationPopUp = () => {
        setScreenType("Create");
        setTestId(testId + 1)
        window.$("#CustomerFormPop").modal('show');
    }



    const UpdateCustomer_Status_submit = (userid, newstatus) => {

        ChangeCustomerStatus_api(userid, newstatus).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                loadCustomers();
            }
        })
            .catch();

    }

    const deleteCustomerPopUp = (customerid) => {
        setCustomerId(customerid)
        window.$("#DeletePopUp").modal('show');
    }

    const DeleteCustomer_submit = (customerid) => {
        DeleteCustomer_api(customerid).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                window.$("#DeletePopUp").modal('hide');
                loadCustomers();
            }
        })
            .catch();
    }

    const loadCustomerDetails = (item) => {
        setScreenType("Update");
        setCustomerinfo(item);
    }

    const SelectNewAddedCustomer = () => {

    }


    return (
        <div className="hold-transition skin-black sidebar-mini">
            <div className="wrapper">

                <Header />
                <Sidebar />

                {/* <!-- Content Wrapper. Contains page content --> */}
                <div className="content-wrapper db-background">

                    {/* <!-- Main content --> */}
                    <section className="content content-body">
                        {/* <!-- Main row --> */}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="content-header xsmrgintb">
                                    <h1 className="">
                                        Customers
                                    </h1>
                                    <div className="addbtn-right">
                                        <a className="btn btn-theme filterbtn" data-toggle="modal" onClick={() => showCustomerCreationPopUp()}>Add Customer</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="top-btn-group ">
                                    <div className="btn-group ">
                                        <a type="button" class={selectedTab == "All" ? "btn btn-theme" : "btn btn-default"} onClick={() => setSelectedTab("All")}>All</a>
                                        <a type="button" class={selectedTab == "Regular" ? "btn btn-theme" : "btn btn-default"} onClick={() => setSelectedTab("Regular")}>Regular</a>
                                        <a type="button" class={selectedTab == "Preferred" ? "btn btn-theme" : "btn btn-default"} onClick={() => setSelectedTab("Preferred")}>Preferred</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="top-btn-group ">
                                    <div className="search-right">
                                        <div className="serch-fild top-serching">
                                            <input type="text" className="form-control ih36" placeholder="Search.." value={searchText} onChange={e => setSearchText(e.target.value)} />
                                            <i className="fa fa-search"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="box box-solid">
                                    {/* <!-- /.box-header --> */}
                                    {
                                        isLoaded == false ? <div className="loader"><img src="images/loader.gif" alt="" /></div>
                                            :
                                            <div className="box-body table-responsive no-padding userlist">
                                                <table className="table ">
                                                    <tbody>
                                                        <tr>
                                                            <th>Customer Name</th>
                                                            {/* <th>Field Contact</th> */}
                                                            <th>Billing Contact</th>
                                                            <th>Billing Address</th>
                                                            <th>Type </th>
                                                            <th>Status</th>
                                                            <th>Tickets</th>
                                                            <th>Action</th>
                                                        </tr>
                                                        {
                                                            customerList.length == 0 ?
                                                                "No Record Found."
                                                                :
                                                                customerList.map(item => (
                                                                    <tr>
                                                                        <td style={(usertype==="Admin" || usertype==="Accounts")?{ cursor: 'pointer' }:{cursor: 'default'}} onClick={() => (usertype==="Admin" || usertype==="Accounts")&& loadCustomerDetails(item)}
                                                                        >{item.customername}</td>
                                                                        {/* <td>{item.fieldcontactname}<br />{item.fieldcontactphone}<br />{item.fieldcontactemail}</td> */}
                                                                        <td>{item.accountingcontactname}<br />{item.accountingcontactphone}<br />{item.accountingcontactemail}</td>
                                                                        <td>{item.address}</td>
                                                                        <td>{item.customertype} </td>
                                                                        <td className="">
                                                                            {
                                                                                item.status == "Active"
                                                                                    ?
                                                                                    <a style={{ cursor: 'pointer' }} className="stsbtn sts-active " onClick={() => UpdateCustomer_Status_submit(item.customerid, 0)} >Active</a>
                                                                                    :
                                                                                    <a style={{ cursor: 'pointer' }} className="stsbtn sts-inactive" onClick={() => UpdateCustomer_Status_submit(item.customerid, 1)}>Inactive</a>
                                                                            }

                                                                        </td>
                                                                        <td style={{ textAlign: 'center' }}>{item.ticketcounts}</td>
                                                                        <td className="">
                                                                            {(item.userType != "Admin" && item.ticketcounts == 0) &&
                                                                                <a style={{ cursor: 'pointer' }} className="stsbtn sts-inactive" onClick={() => deleteCustomerPopUp(item.customerid)}>Delete</a>
                                                                            }

                                                                        </td>
                                                                    </tr>
                                                                ))
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>


                                    }
                                    {/* <!-- /.box-body --> */}
                                </div>
                            </div>


                        </div>
                        {/* <!-- /.row (main row) --> */}
                    </section>
                    {/* <!-- /.content --> */}

                </div>

                <CustomerAddUpdateForm
                    //customerId={customerId}
                    screentype={screentype}
                    loadCustomers={loadCustomers}
                    customerinfo={customerinfo}
                    SelectNewAddedCustomer={SelectNewAddedCustomer}
                    testId={testId}
                // CreateCustomer_submit = {CreateCustomer_submit}
                // UpdateCustomer_submit = {UpdateCustomer_submit}


                />
                <DeletePopUp
                    DeleteUser_submit={DeleteCustomer_submit}
                    id={customerId}
                    getreqFrom="Customer" />
            </div>
        </div>
    )
}

export default Customers
