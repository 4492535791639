import React, { useEffect, useState } from 'react'
import Header from '../../components/nav/Header'
import Sidebar from '../../components/nav/Sidebar'
import { LoadCustomers_api } from '../../functions/customers'
import CustomerAddUpdateForm from '../../forms/CustomerAddUpdateForm'
import CreateTicket from '../../forms/CreateTicket'
import { toast } from 'react-toastify'
import { GetAssignmentsApi, GetMapdataApi } from '../../functions/dashboard'
import { LoadTechnicians_api } from '../../functions/users'          //        added by Sonali 05/04/22
import { LoadOrderType_api } from '../../functions/masters'             //        added by Sonali 05/04/22
import Moment from 'moment';
import { Link } from 'react-router-dom'
import MyMapComponent from '../../googleMap/MyMapComponent'
import TicketDetails from '../../components/popups/TicketDetails'
import KeyPoints from '../../commonComponents/KeyPoints'
import axios from 'axios';

import Multiselect from 'multiselect-react-dropdown';

const API_Base_Url = process.env.REACT_APP_API_BASE_URL;


function Dashboard() {
    var date_lo = new Date();
    var formateddate = Moment(date_lo).format("yyyy-MM-DD");
    const [txtcustomername, setCustomername] = useState("");
    const [selectedCustomerID, setSelectedCustomerID] = useState("");
    const [selectedCustomerName, setSelectedCustomerName] = useState("");
    const [customerList, setCustomerList] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [screentype, setScreenType] = useState("Create");
    const [customerinfo, setCustomerinfo] = useState({});
    const [assignmentList, setAssignmentList] = useState([]);
    const [date, setDate] = useState(formateddate);
    const [ticketMapData, setTicketMapData] = useState([]);
    const [technicianMapData, setTechnicialMapData] = useState([]);
    const [ticketId, setTicketId] = useState(0);
    const [testId, setTestId] = useState(0);
    const [keypointRefresh, setKeyPointsRefresh] = useState(0);
    const [txtSearchInMap, setTxtSearchInMap] = useState("");
    const [txtSearchInMaptemp, setTxtSearchInMaptemp] = useState("");
    const [refreshComponetAfterSearch, setRefreshComponentAfterSearch] = useState(0)

    const [technicianList, setTechnicianlist] = useState([]);       //        added by Sonali 05/04/22
    const [searchOrderTypelist, setSearchOrderTypelist] = useState([]);         //        added by Sonali 05/04/22
    const [searchTechnicianlist, setSearchTechnicianlist] = useState([]);         //        added by Sonali 05/04/22
    const [allOrderTypes, setAllOrderTypes] = useState([]);               //        added by Sonali 05/04/22
    const [txtSearchDateval, setTxtSearchDateVal] = useState("")                  //        added by Sonali 05/04/22
    const [searchData, setSearchData] = useState(0);
    const multiselectRef = React.createRef();
    const multiselectRef1 = React.createRef();
    //        added by Sonali 05/04/22

    useEffect(() => {

        window.$("#searchOrderTypeval").hide();      // added by Sonali 05/04/22
        window.$("#searchresultarea").hide();
        window.$("#msgdiv").hide();

        loadTechnicians();                       // added by Sonali 05/04/22
        loadOrderType();                        // added by Sonali 05/04/22

        loadAssignments();
        loadMapData();

        let res;
        let evt;
        let evtfinal;
        const clientid = localStorage.getItem("clientid");
        try {
            axios.get(API_Base_Url + "calendar/summary?clientid=" + clientid, {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    //'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    console.log(response.data.technicians)
                    res = response.data.technicians.map(item => {
                        return {
                            id: item.technicianID,
                            name: item.technicianname,
                            avatar: item.profileimage === "" ? "images/avtar.png" : item.profileimage
                        }

                    })
                    // evt = response.data.data.map((item, i) => {
                    //     return {
                    //         id: i + 1,
                    //         bgColor: item.unavailableTiming === "" ? "#A2D2FF" : "#dfdfdf",
                    //         resourceId: item.techncianID,
                    //         title: item.unavailableTiming === "" ? item.totalTickets + " tickets " + " (" + item.totalHours + " Hours)" : "Unavailable (" + (item.unavailableTiming) + ")",
                    //         start: item.date + ' 09:00:00',
                    //         end: item.date + ' 11:00:00'
                    //     }
                    // })

                    //evtfinal.push(evt)
                    // console.log("evt1", evt)
                    evt = response.data.data.map((item, i) => {
                        if (item.totalTickets > 0) {
                            return {
                                id: i * 100 + 1,
                                bgColor: "#A2D2FF",
                                resourceId: item.techncianID,
                                title: item.totalTickets + " tickets " + " (" + item.totalHours + " Hours)",
                                start: item.date + ' 09:00:00',
                                end: item.date + ' 11:00:00'
                            }
                        }
                        else {
                            return {
                                id: 0,
                                bgColor: "#ffffff",
                                resourceId: '',
                                title: '',
                                start: '',
                                end: ''
                            }
                        }


                    })
                    evtfinal = response.data.data.map((item, i) => {
                        if (item.unavailableTiming !== "") {

                            evt.push({
                                id: i * 200 + 1,
                                bgColor: "#dfdfdf",
                                resourceId: item.techncianID,
                                title: "Block (" + (item.unavailableTiming) + ")",
                                start: item.date + ' 09:00:00',
                                end: item.date + ' 11:00:00'
                            })
                        }


                    })
                    console.log("evt2", evt)
                    localStorage.setItem("evt", JSON.stringify(evt))
                    localStorage.setItem("res", JSON.stringify(res))

                });
        }
        catch (err) {
            //console.log(err)
        }


    }, []);

    //      loadTechnicians()  added by Sonali 05/04/22
    const loadTechnicians = () => {
        LoadTechnicians_api().then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {
                    setTechnicianlist([])
                }
                else
                    var list = [];
                result.data.forEach(item => {
                    list.push({ name: item.name, id: item.userId })
                });
                setTechnicianlist(list);
            }

        }).catch();
    }

    const loadOrderType = () => {
        LoadOrderType_api().then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {
                    setAllOrderTypes([])
                }
                else {
                    var list = [];
                    result.data.forEach(item => {
                        list.push({ name: item.ordertype, id: item.ordertype })
                    });
                    setAllOrderTypes(list);
                }
            }

        }).catch();
    }


    const refreshDashboard = () => {
        setKeyPointsRefresh(keypointRefresh + 1);
        loadAssignments();
        loadMapData();
        setCustomername("");
        setSelectedCustomerID(0);
        window.$("#msgdiv").show();
    }
    //Load Change date value
    useEffect(() => {
        loadAssignments();
    }, [date]);


    const loadCustomers = (txtsearch) => {
        setIsLoaded(false);
        LoadCustomers_api("All", txtsearch).then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {
                    setCustomerList([])
                }
                else {
                    setCustomerList(result.data);
                }
            }
            setIsLoaded(true);
        }).catch();
    }



    //load Map Data
    const loadMapData = () => {
        GetMapdataApi().then(res => {
            const result = res;
            if (result != undefined) {
                setTechnicialMapData(result.technicians)
                setTicketMapData(result.tickets)
            }
        }).catch();
    }

    //Assignments
    const loadAssignments = () => {
        GetAssignmentsApi(date).then(res => {
            const result = res;
            if (result != undefined) {
                setAssignmentList(result.assignments);
            }
        }).catch();
    }

    const SearchCustomer = (e) => {
        setCustomername(e.target.value);
        if (e.target.value.length < 2) {
            window.$("#searchresultarea").hide();

        }
        else {
            window.$("#searchresultarea").show();
            loadCustomers(e.target.value);
        }

    }

    const SelectNewAddedCustomer = (customerid) => {
        LoadCustomers_api("All", txtcustomername).then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {

                }
                else {
                    var cust = result.data.filter(x => x.customerid == customerid)[0];
                    ChooseCustomer(cust);
                }
            }
            setIsLoaded(true);
        }).catch();
    }

    const ChooseCustomer = (item) => {
        setSelectedCustomerName(item.customername)
        setSelectedCustomerID(item.customerid)
        //alert(item.customerid)
        window.$("#searchresultarea").hide();
        setCustomername(item.customername)
    }

    const showCustomerCreationPopUp = () => {
        setCustomername("");
        setTestId(testId + 1)
        window.$("#CustomerFormPop").modal('show');
        window.$("#searchresultarea").hide();
    }

    const loadAgain = () => {

    }

    const showCreateTicketPopUp = () => {
        //alert(selectedCustomerID)
        if (selectedCustomerID == 0)
            return toast.error('Please select customer')

        setTestId(testId + 1)
        window.$("#CreateTicket").modal('show');

    }

    const addDays = () => {
        var aa = Moment(date).add(1, 'days');
        setDate(aa.format("yyyy-MM-DD"));
    }

    const addminusDays = () => {
        var aa = Moment(date).add(-1, 'days');
        setDate(aa.format("yyyy-MM-DD"));
    }


    const assignmentjs = (item) => {
        var accordionitem = item;
        window.$("#" + accordionitem).slideToggle().parent().siblings().find(".accordion-content").slideUp();
        window.$("#" + accordionitem).toggleClass("active-title");
        window.$("#" + "item_" + accordionitem).toggleClass("active-title");
        window.$("#" + accordionitem).parent().siblings().find(".accordion-title").removeClass("active-title");
        window.$("i.fa-chevron-down", "#" + item).toggleClass("chevron-top");
        window.$("#" + accordionitem).parent().siblings().find(".accordion-title i.fa-chevron-down").removeClass("chevron-top");
    }

    const doSearch = (e) => {
        setSearchData(searchData + 1)
    }       // updated by Sonali on 05/04/22


    const onSelectOrder = (selectedList, selectedItem) => {
        var obj = [];
        selectedList.forEach(x => {
            obj.push(x.name);
        })
        setSearchOrderTypelist(obj)
    }

    const onRemoveOrder = (selectedList, removedItem) => {
        var obj = [];
        selectedList.forEach(x => {
            obj.push(x.name);
        })
        setSearchOrderTypelist(selectedList)

    }

    const onSelectTechnician = (selectedList, selectedItem) => {
        var obj = [];
        selectedList.forEach(x => {
            obj.push(Number(x.id));
        })
        setSearchTechnicianlist(obj)
    }

    const onRemoveTechnician = (selectedList, removedItem) => {
        var obj = [];
        selectedList.forEach(x => {
            obj.push(Number(x.id));
        })
        setSearchTechnicianlist(obj)

    }
    const onClearOrder = () => {
        setSearchOrderTypelist([])

        // By calling the belowe method will reset the selected values programatically
        multiselectRef.current.resetSelectedValues();

    }
    const onClearTechnician = () => {
        setSearchTechnicianlist([])

        // By calling the belowe method will reset the selected values programatically
        multiselectRef1.current.resetSelectedValues();

    }


    return (
        <div className="hold-transition skin-black sidebar-mini">
            <div className="wrapper">
                <Header />
                <Sidebar />

                <TicketDetails
                    ticketid={ticketId}
                    loadAgain={loadAgain}
                    ticketType={"normal"}
                />

                <CreateTicket
                    customerid={selectedCustomerID}
                    refreshDashboard={refreshDashboard}
                    testId={testId}
                    ticketid=""
                />
                <CustomerAddUpdateForm
                    screentype={screentype}
                    loadCustomers={loadCustomers}
                    customerinfo={customerinfo}
                    SelectNewAddedCustomer={SelectNewAddedCustomer}
                    testId={testId}
                />
                <div className="content-wrapper db-background">
                    {/* <!-- Main content --> */}
                    <section className="content content-body">
                        <div className="row" id="msgdiv" style={{ position: 'relative' }}>
                            <div className="col-lg-3"></div>
                            <div className="col-lg-6">
                            </div>
                            <div className="col-lg-3"></div>
                        </div>
                        {/* <!-- Main row --> */}
                        <div className="row">
                            {/* <!-- Left col 8--> */}
                            <div className="col-lg-9 ">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4 className="box-title">Overview</h4>
                                        <div className="">
                                            <div className="db-Overview">
                                                <KeyPoints whichScreen={"Dashboard"} refresh={keypointRefresh} changeStatus={() => { }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">

                                            <div className="col-md-9">
                                                <h4 className="box-title">Tickets & Techs</h4>
                                            </div>

                                        </div>
                                        <div className='row' style={{ marginBottom: "10px" }}>
                                            {/* <form onSubmit={(e) => doSearch(e)}> */}
                                            <div className="col-md-3">
                                                <input type="date" format="mm/dd/yyyy" placeholder="Search Date" className="form-control col-md-3 pull-right" value={txtSearchDateval} onChange={e => setTxtSearchDateVal(e.target.value)} />
                                            </div>

                                            <div className="col-md-3">
                                                <Multiselect

                                                    options={allOrderTypes} // Options to display in the dropdown
                                                    //  selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                                    onSelect={e => onSelectOrder(e)} // Function will trigger on select event
                                                    onRemove={e => onRemoveOrder(e)} // Function will trigger on remove event
                                                    displayValue="name" // Property name to display in the dropdown options
                                                    placeholder={searchOrderTypelist.length <= 0 ? "Select Order Type" : searchOrderTypelist.length.toString() + " Selected"}
                                                    showCheckbox={true}
                                                    avoidHighlightFirstOption={true}
                                                    ref={multiselectRef}
                                                />
                                                {searchOrderTypelist.length > 0 ?
                                                    <a onClick={(e) => onClearOrder()} className="multiselectclearbutton">clear</a>
                                                    : ""}
                                            </div>
                                            <div className="col-md-3">

                                                <Multiselect
                                                    options={technicianList} // Options to display in the dropdown
                                                    //  selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                                    onSelect={e => onSelectTechnician(e)} // Function will trigger on select event
                                                    onRemove={e => onRemoveTechnician(e)} // Function will trigger on remove event
                                                    displayValue="name" // Property name to display in the dropdown options
                                                    placeholder={searchTechnicianlist.length <= 0 ? "Select Technician" : searchTechnicianlist.length.toString() + " Selected"}
                                                    showCheckbox={true}
                                                    avoidHighlightFirstOption={true}
                                                    ref={multiselectRef1}
                                                />
                                                {searchTechnicianlist.length > 0 ?
                                                    <a onClick={(e) => onClearTechnician()} className="multiselectclearbutton">clear</a>
                                                    : ""}
                                                {/* <select className="form-control col-md-3 pull-right" value={technicianID} onChange={e => setTechnicianID(e.target.value)}>
                                                    <option value="0">All Technician</option>
                                                    {technicianList.length > 0 &&
                                                        technicianList.map(item => (
                                                            <option value={item.userId}>{item.name}</option>
                                                        ))
                                                    }
                                                </select> */}
                                            </div>
                                            <div className="col-md-2">
                                                <input type="text" placeholder="Search Ticket ID" className="form-control col-md-3 pull-right" value={txtSearchInMaptemp} onChange={(e) => setTxtSearchInMaptemp(e.target.value.replace(/[^0-9]/g, ""))} />
                                            </div>
                                            <div className="col-md-1">
                                                <a className="btn btn-theme filterbtn" onClick={(e) => doSearch(e)}>
                                                    <i className="fa fa-search"></i></a>
                                            </div>

                                            {/* </form> */}
                                        </div>

                                        <div className="map">
                                            <MyMapComponent setRefreshComponentAfterSearch={setRefreshComponentAfterSearch} isMarkerShown ticketid={ticketId} setTicketId={setTicketId} searchValue={txtSearchInMaptemp} searchDateVal={txtSearchDateval} searchTechnicianList={searchTechnicianlist} searchOrderTypeList={searchOrderTypelist} searchData={searchData} />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* <!-- right col 4--> */}
                            <div className="col-lg-3">
                                <h4 className="box-title" style={{ visibility: 'hidden' }}>Overview</h4>
                                <div className="box box-solid ">
                                    <div className="form-group csbox">
                                        <div className="custmer-search">
                                            <input type="text" autoComplete="off" value={txtcustomername} onChange={(e) => SearchCustomer(e)} className="form-control" placeholder="Select Customer" />
                                            <i className="fa fa-search"></i>
                                        </div>
                                        <div className="search-suggestion " id="searchresultarea" style={{ zIndex: '9999' }}>
                                            <div className="searchdiv" >
                                                {
                                                    customerList.length == 0
                                                        ?
                                                        null
                                                        :
                                                        customerList.map(item => (
                                                            <div className="search-list" style={{ cursor: 'pointer' }} onClick={() => ChooseCustomer(item)}>
                                                                <h5>{item.customername}</h5>
                                                                <p>{item.contactname}</p>
                                                            </div>
                                                        ))
                                                }
                                            </div>
                                            <div className="addnewbtn">
                                                <a onClick={() => showCustomerCreationPopUp()} style={{ cursor: 'pointer' }}><img src="images/plus.png" />&nbsp;&nbsp; Add New Customer</a>
                                            </div>

                                        </div>
                                        <div className="text-right">
                                            <a className="btn btn-theme mt15" data-toggle="modal" onClick={() => showCreateTicketPopUp()}>Create Ticket &nbsp;&nbsp;<i className="fa fa-angle-right"></i></a>
                                        </div>

                                    </div>
                                </div>

                                <div className="asmt-right">
                                    <h4 className="asmt-top-title ">Assignments</h4>
                                    <div className="asmt-date"><i className="fa fa-angle-left" style={{ cursor: 'pointer' }} onClick={() => addminusDays()}></i>&nbsp;&nbsp;{Moment(date).format("DD-MMM-YY")} &nbsp;&nbsp;<i className="fa fa-angle-right" style={{ cursor: 'pointer' }} onClick={() => addDays()}></i></div>
                                </div>
                                <div className="box box-solid ">
                                    {/* <!-- Our Experties  --> */}
                                    <div className="assignments">
                                        <div className="">
                                            <div className="accordions">
                                                {
                                                    assignmentList != undefined &&
                                                    <>
                                                        {
                                                            assignmentList.length == 0
                                                                ?
                                                                "No Record Found."
                                                                :
                                                                assignmentList.map((item, index) => (
                                                                    <div className="accordion-item ">
                                                                        <div className="accordion-title " data-tab={`item-${index}`} onClick={() => assignmentjs(`item-${index}`)}>
                                                                            <div className="title-bar">

                                                                                {
                                                                                    item.profilepic == "NA" || item.profilepic == ""
                                                                                        ?
                                                                                        <div className="profile-img">
                                                                                            <div className="user-blank" title={item.name}>{item.name.slice(0, 1)} </div>
                                                                                        </div>
                                                                                        :
                                                                                        <img src={item.profilepic} className="asmt-user" />

                                                                                }
                                                                                <div className="asmt-detl">
                                                                                    <p className="name">{item.name} </p>
                                                                                    <span className="phone">{item.phone}</span>
                                                                                </div>
                                                                                <div className="lbl-angl">
                                                                                    <div className="asmt-label pull-left">{item.countTickets}</div>
                                                                                    <i className="fa fa-angle-down"></i>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="accordion-content" id={`item-${index}`}>
                                                                            {/* <p>{JSON.stringify(item.tickets)}</p> */}
                                                                            {

                                                                                item.tickets != undefined &&

                                                                                    item.tickets.length == 0
                                                                                    ?
                                                                                    "No Record Found."
                                                                                    :
                                                                                    item.tickets != null &&
                                                                                    item.tickets.map(inneritem => (
                                                                                        <div className="asmt-list">
                                                                                            <div className="asmtIDTime">
                                                                                                <div className="asmtID">
                                                                                                    <img src="images/tickets1.png" className="" />


                                                                                                    {inneritem.ticketid}</div>
                                                                                                <div className="asmtTime">{inneritem.scheduletime}</div>
                                                                                            </div>
                                                                                            <div className="asmtDetail">
                                                                                                <h3>{inneritem.customername}</h3>
                                                                                                <h4 className="fst-h4">{inneritem.address}</h4>
                                                                                                <h4 className="scnd-h4">{inneritem.ordertype}</h4>
                                                                                                <div className="asmt-status-bar">
                                                                                                    {
                                                                                                        inneritem.status == "Scheduled"
                                                                                                        &&
                                                                                                        <span className="blue-dot"><i className="fa fa-circle"></i></span>
                                                                                                    }
                                                                                                    {
                                                                                                        inneritem.status == "Completed"
                                                                                                        &&
                                                                                                        <span className="green-dot"><i className="fa fa-circle"></i></span>
                                                                                                    }
                                                                                                    {
                                                                                                        inneritem.status == "Open"
                                                                                                        &&
                                                                                                        <span className="red-dot"><i className="fa fa-circle"></i></span>
                                                                                                    }
                                                                                                    {
                                                                                                        inneritem.status == "In-Progress"
                                                                                                        &&
                                                                                                        <span className="blue-dot"><i className="fa fa-circle"></i></span>
                                                                                                    }
                                                                                                    {
                                                                                                        inneritem.status == "Closed"
                                                                                                        &&
                                                                                                        <span className="green-dot"><i className="fa fa-circle"></i></span>
                                                                                                    }
                                                                                                    {
                                                                                                        inneritem.status == "Cancelled"
                                                                                                        &&
                                                                                                        <span className="gray-dot"><i className="fa fa-circle"></i></span>
                                                                                                    }
                                                                                                    {
                                                                                                        inneritem.status == "Awaited"
                                                                                                        &&
                                                                                                        <span className="gray-dot"><i className="fa fa-circle"></i></span>
                                                                                                    }
                                                                                                    <span className="dot-status">{inneritem.status}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    ))
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                ))
                                                        }
                                                    </>
                                                }


                                            </div>
                                        </div>



                                    </div>



                                </div>
                            </div>


                        </div>
                        {/* <!-- /.row (main row) --> */}




                    </section>
                    {/* <!-- /.content --> */}


                </div>

            </div>


        </div>
    )
}

export default Dashboard

