import React, { useState } from 'react';
import { ResetPassord_api_2 } from '../../functions/auth'
import { toast } from 'react-toastify'
import OtpInput from 'react-otp-input';
import { useHistory } from 'react-router-dom'

function ResetPassword2() {
    const history = useHistory();
    const [otp, setOtp] = useState("")

    const NextClick = (e) => {
        e.preventDefault();
        if (otp === "" || otp === null) {
            return toast.error("Please Enter otp.")
        }
        next();
    }
    const next = async () => {
        try {
            var req = {
                username: localStorage.getItem("ChagePasswordUserName"),
                code: otp
            }

            ResetPassord_api_2(req).then(res => {
                if (res !== undefined) {
                    toast.success(res.message);
                    history.push("/resetpassword3");
                    localStorage.setItem("ResetPasswordOTP", otp)
                }
            })
                .catch()
        } catch (error) {

        }
    }

    const handleOtp = otp => setOtp(otp);
    return (
        <div className="hold-transition login-page login2">
            <div className="login2-body">
                <div className="login-left-pnl " >
                    <div className="logo1">
                        <img src="images/logo-1.png" className="" alt=""/>
                        <h4>Reset Password</h4>
                    </div>
                    <form onSubmit={(e) => NextClick(e)} >
                        <div className="form-group spacetologo">
                            <div className="otp-input ">
                                <p>Enter 4 digit code received in your registere email address</p>
                                <OtpInput

                                    value={otp}
                                    onChange={handleOtp}
                                    numInputs={4}
                                />
                            </div>
                        </div>
                        <div className="spacetotext">
                            <button type="submit" className="btn btn-green btn-block">NEXT</button>
                        </div>

                    </form>
                </div>

                <div className="login-right-pnl">
                    <h4 className="text-center">Field Service Management System</h4>
                    <img src="images/login-bnr.png" alt=""/>
                </div>

                <div className="clearfix"></div>
            </div>

        </div>
    )
}

export default ResetPassword2