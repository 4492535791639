import React, { useState, useEffect } from 'react';
import { LoginApi } from '../../functions/auth'
import { GetProfileApi } from '../../functions/users'
import { toast } from 'react-toastify'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import MyContext from '../../contextapi/MyContext'
import { Redirect, Route } from "react-router-dom";

function Login() {
    const { setContextUser } = React.useContext(MyContext);

    const { Contextuser } = React.useContext(MyContext);
    const IsUserBlank = Object.keys(Contextuser).length;

    let history = useHistory();
    let dispatch = useDispatch();
    const [mobileno, setMobileno] = useState("");
    const [password, setPassword] = useState("");
    const [iskeepsignIn, setKeepSignIn] = useState(true);



    useEffect(() => {
        if (localStorage.getItem("usertype") === "Accounts" && (localStorage.getItem("ISREMEMBERED") === true || localStorage.getItem("ISREMEMBERED") === "true")) {
            history.push('/customers');
        }
        else if(localStorage.getItem("usertype") === "Dispatcher" && (localStorage.getItem("ISREMEMBERED") === true || localStorage.getItem("ISREMEMBERED") === "true")){
            history.push('/tickets');
        }
        else if (IsUserBlank > 0 && (localStorage.getItem("ISREMEMBERED") === true || localStorage.getItem("ISREMEMBERED") === "true")) {
            history.push("/dashboard");
        }
    }, [])

    const LoginBtnClick = (e) => {
        e.preventDefault();
        if (mobileno === "" || mobileno === null) {
            return toast.error("Please enter username")
        }
        else if (password === "" || password === null) {
            return toast.error("Please enter password")
        }
        getLogin();
    }
    const getLogin = async () => {

        try {

            var req = {};
            req = {
                mobileno: mobileno,
                password: password
            }
            LoginApi(req).then(res => {
                if (res !== undefined) {
                    localStorage.setItem("AccessToken", res.accesstoken);
                    GetProfileApi(mobileno, res.accesstoken).then(result => {

                        if (result !== undefined) {

                            if (iskeepsignIn) {
                                localStorage.setItem("ISREMEMBERED", true)
                            }
                            else {
                                localStorage.setItem("ISREMEMBERED", false)
                            }
                            setContextUser(result)
                            //store in localstorage                            
                            localStorage.setItem("userinfo", JSON.stringify(result));
                            localStorage.setItem("userid", result.userId);
                            localStorage.setItem("usertype", result.userType);
                            localStorage.setItem("profilepic", result.profilePic);
                            localStorage.setItem("username", result.name);
                            localStorage.setItem("clientid", result.clientID);
                            localStorage.setItem("client", result.client);
                            if (result.userType === "Accounts") {
                                history.push('/customers');
                            }
                            else if(result.userType === "Dispatcher"){
                                history.push('/tickets');
                            }
                            else
                                history.push('/dashboard');
                            //  Store in redux   
                            dispatch({
                                type: 'LOGGED_IN_USER',
                                payload: {
                                    "accesstoken": res.accesstoken,
                                    "userId": result.userId,
                                    "userType": result.userType,
                                    "clientid": result.clientID,
                                    "client": result.client,
                                    "name": result.name,
                                    "email": result.email,
                                    "phone": result.phone,
                                    "timeZoneId": result.timeZoneId,
                                    "timeZone": result.timeZone,
                                    "profilePic": result.profilePic,
                                    "status": result.status,
                                    "createdOn": result.createdOn
                                }
                            })
                        }
                    })

                }
            })
                .catch()
        } catch (error) {

        }
    }

    return (
        <div class="hold-transition login-page login2">
        <div class="login2-body">
            <div class="login-left-pnl " >
                <div class="logo1">
                    <img src="images/mepcologo.svg" class="" alt="" height="70" />
                    <h4>Login</h4>
                </div>
                <form onSubmit={(e) => LoginBtnClick(e)} >
                    <div class="form-group ">
                         <input type="text" class="form-control loignh" autoComplete="off" placeholder="Username" value={mobileno} maxLength="100" onChange={(e) => { setMobileno(e.target.value) }} /> {/*.replace(/\D/, '') */}
                    </div>
                    <div class="form-group ">
                        <input type="password" class="form-control loignh" autoComplete="off" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div class="form-checkbox">
                        <input type="checkbox" id="Signin" checked={iskeepsignIn ? true : false} onChange={() => setKeepSignIn(iskeepsignIn ? false : true)} />
                        <label for="Signin" className="lblname">Keep me Signed in</label>
                    </div>
                    <div class="">
                        <div class="">
                            <button type="submit" class="btn btn-green btn-block">LOGIN</button>
                        </div>
                        <div class="login-footer-link">
                            {/* <Link to="/resetpassword1">Reset Password</Link>&nbsp;|&nbsp;<a>Contact Tech Support</a> */}
                            Forgot Password?<Link to="/resetpassword1">Click here</Link>  to reset
                        </div>

                    </div>
                </form>
            </div>

            <div class="login-right-pnl">
                <h4 class="text-center" style={{fontSize:"24px"}}>Mepco</h4>
                <h4 class="text-center" style={{fontSize:"18px"}}>Field Service Management</h4>
                <img src="images/login-bnr.png" alt="" />
            </div>

            <div className="clearfix"></div>
        </div>

    </div>
    )
}

export default Login
