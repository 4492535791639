import React, { useState } from 'react'
import { ChangePassword } from '../../functions/auth'
import { toast } from 'react-toastify'

const ChangePassword_pop = () => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");

    const ChangePassword_submit = (e) => {
        //e.preventDefault();
        e.preventDefault();
        if (newPassword == "" || newPassword == null)
            return toast.error("Please enter new password.");
        else if (confirmNewPassword == "" || confirmNewPassword == null)
            return toast.error("Please enter comfirm password.");
        else if (newPassword != confirmNewPassword)
            return toast.error("New password and confirm password should be same.");
        else {
            var req = {
                "userid": Number(localStorage.getItem("userid")),
                "password": newPassword
            }
            ChangePassword(req).then(res => {
                if (res != undefined) {
                    toast.success(res.message);
                    window.$("#ChangePWD").modal('hide');
                    //refreshDashboard();
                    //localStorage.clear();
                   // window.location.href = '#';
                   // window.location.reload();
                }
            })
                .catch();
        }

    }

    const Showhidepassword = () => {
        var pwd = document.getElementById("pwd");
        var icon = document.getElementById("icon");
        if (pwd.getAttribute("type") == "password") {
            pwd.setAttribute("type", "text");
            window.$('.icon').removeClass('fa fa-eye').addClass('fa fa-eye-slash');

        } else {
            pwd.setAttribute("type", "password");
            window.$('.icon').removeClass('fa fa-eye-slash').addClass('fa fa-eye');
        }
    }

    return (


        <div className="modal fade modal-fullscreen fsm-popup" id="ChangePWD" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <a className="close" data-dismiss="modal" aria-hidden="true"> <img src="images/close.png" /></a>
                        <h5 className="modal-title" id="myModalLabel">Change Password</h5>
                    </div>
                    <div className="modal-body">
                        <div className="row mt30">
                            <div className="col-md-3"></div>
                            <div className="col-md-6">
                                <div className="pwdchange-box">
                                    <h4>Create a new Password</h4>
                                    <div className="form-group">
                                        <input type="password" className="form-control" maxLength="50" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder="New Password" />
                                    </div>
                                    <div className="form-group">
                                        <input type="password" className="form-control" id="pwd" maxLength="50" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} placeholder="Confirm New Password" />
                                        <span className="sowhide-eye">
                                            <a onClick={() => Showhidepassword()}><span className="fa fa-eye icon btneye icon" id="icon" ></span></a>
                                        </span>
                                    </div>
                                    <div className="text-right">
                                        <button type="submit" className="btn btn-theme" onClick={(e) => ChangePassword_submit(e)}>Submit</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword_pop
