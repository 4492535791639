import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Header from '../nav/Header'
import Sidebar from '../nav/Sidebar'
import { Link } from 'react-router-dom'
import { LoadCustomers_api } from '../../functions/customers'
import { GetReport_RevenueByCustomer_api } from '../../functions/reports'
import ReactExport from "react-data-export";

function RevenueByCustomer() {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    const [excelDataSet, setExcelDataSet] = useState([]);

    const [period, setPeriod] = useState("");
    const [selectedCustomerID, setSelectedCustomerID] = useState(0);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [customerList, setCustomerList] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [List, setList] = useState([]);
    const [paginationarr, setPaginationarr] = useState([]);
    const [selectedPageNo, setSelectedPageNo] = useState(1);
    let recordshowperpage = 30;

    useEffect(() => {
        loadCustomers();
        loadReport();
    }, [])

    const loadCustomers = () => {
        LoadCustomers_api('All', '').then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {
                    setCustomerList([])
                }
                else
                    setCustomerList(result.data);
            }

        }).catch();
    }



    const loadReport = () => {
        var _period = period;
        var _fromdate = fromDate;
        var _todate = toDate;
        var _customerID = selectedCustomerID;
        setIsLoaded(false);
        var req = {
            "periodtype": _period,
            "customerid": Number(_customerID)
        }
        GetReport_RevenueByCustomer_api(req).then(res => {
            const result = res;
            if (result != undefined) {
                if (result == null) {
                    setList([])
                }
                else {
                    setList(result)
                    let data = result.data;
                    let dataset = [];
                    if (data.length > 0) {
                        for (var i = 0; i < data.length; i++) {
                            dataset.push({
                                Month: data[i].month,
                                Revenue: data[i].revenue
                            })
                        }

                    }
                    {
                        dataset.push({
                            Month: "",
                            Revenue: ""
                        })
                    }
                    setExcelDataSet(dataset)
                    // var data = result.data;
                    if (data.length > recordshowperpage) {
                        var totalpage = data.length / recordshowperpage;
                        var reminder = data.length % recordshowperpage;
                        var pages = [];
                        for (var i = 1; i <= totalpage; i++) {
                            pages.push(i);
                        }
                        if (reminder == 0) {
                            //pages = pages;
                        }
                        else {
                            pages.push(pages.length + 1)
                        }
                        setPaginationarr(pages);
                    }
                }
            }
            else {
                setPaginationarr([]);
            }
            setIsLoaded(true);
        }).catch();
    }


    return (
        <div>
            <div className="hold-transition skin-black sidebar-mini">
                <div className="wrapper">
                    <Header />
                    <Sidebar />
                    <div className="content-wrapper">
                        {/* <section className="content content-body"> */}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="reports">
                                    <ul>
                                        <li><Link to="revenuebytech">Revenue by Tech</Link></li>
                                        <li className="active_report"><Link to="revenuebycustomer">Revenue by Customer</Link></li>
                                        <li><Link to="collectionreport">Collection Report</Link></li>
                                        {/* <li><Link to="techperformance">Tech Performance</Link></li> */}
                                        <li><Link to="techutilization">Tech Utilization</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* </section> */}
                        <div className="filterbox">
                            <div className="">
                                <div className="boxmargin">
                                    <div className="box box-solid box-height">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group row">
                                                    <label for="colFormLabel" className="col-sm-4 col-form-label">Period</label>
                                                    <div className="col-sm-8">
                                                        <select className="form-control" onChange={(e) => setPeriod(e.target.value)}>
                                                            <option>This Year</option>
                                                            <option>Prev Year</option>
                                                        </select>
                                                    </div>
                                                </div>


                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group row">
                                                    <label for="colFormLabel" className="col-sm-4 col-form-label">Customer</label>
                                                    <div className="col-sm-8">
                                                        <select className="form-control" onChange={(e) => setSelectedCustomerID(e.target.value)}>
                                                            <option value="0">All</option>
                                                            {
                                                                customerList.map(item => (
                                                                    <option value={item.customerid}>{item.customername}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <div className="form-group row">
                                                    <div className="col-sm-3">
                                                        <button className="btn btn-primary" onClick={() => loadReport()}>View</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <div className="form-group row">
                                                    <div className="col-sm-3">
                                                        <ExcelFile filename="Revenue by Customer" element={<button className="btn btn-primary">Export to Excel</button>} >
                                                            <ExcelSheet data={excelDataSet} name="customer revenue" >
                                                                <ExcelColumn label="Month" value="Month" />
                                                                <ExcelColumn label="Revenue" value="Revenue" />
                                                            </ExcelSheet>
                                                        </ExcelFile>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div className="rptcontainer">
                            {
                                isLoaded == false ? <div className="loader"><img src="images/loader.gif" alt="" /></div>
                                    :
                                    <>
                                        <div className="customertbl">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>{List.customername}</th>
                                                    <th className="alignright">${List.totalrevenue}</th>
                                                </tr>
                                                <tr>
                                                    <th>Month</th>
                                                    <th className="alignright">Revenue</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    List.data != undefined &&
                                                    <>
                                                        {
                                                            List.data.length == 0
                                                                ?
                                                                "No Record Found."
                                                                :
                                                                List.data.slice(selectedPageNo * recordshowperpage - recordshowperpage, selectedPageNo * recordshowperpage).map((item, index) => (
                                                                    <tr>
                                                                        <td>{item.month}</td>
                                                                        <td className="alignright">${item.revenue}</td>
                                                                    </tr>
                                                                ))
                                                        }
                                                    </>
                                                }
                                            </tbody>
                                        </table>
                                        </div>
                                    </>
                            }
                            <div>
                                <ul className="pagination pull-right mt0">
                                    {
                                        paginationarr.length > 0 &&
                                        paginationarr.map(item => (
                                            <li class={`paginate_button ${item == selectedPageNo && "active"}`}><a style={{ cursor: 'pointer' }} onClick={() => setSelectedPageNo(item)}>{item}</a></li>
                                        ))
                                    }
                                </ul>    
                                <div className="clearfix"></div>                            
                            </div>
                        </div>
                        <div className="clearfix"></div>                         
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RevenueByCustomer
