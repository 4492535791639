import { toast } from 'react-toastify'


const API_Base_Url = process.env.REACT_APP_API_BASE_URL;
const AccessToken = localStorage.getItem("AccessToken");
const loggeduserid = Number(localStorage.getItem("userid"));

//Key Points
export const GetKeyPointsApi = async () => {


    try {
        const response = await fetch(API_Base_Url + "keypoints?userid=" + Number(localStorage.getItem("userid")), {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

//Map Web
export const GetMapdataApi = async () => {


    try {
        const response = await fetch(API_Base_Url + "ticket/mapweb?userid=" + Number(localStorage.getItem("userid")), {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

//Unread Notifications
export const GetUnreadNotificationApi = async () => {
    try {
        const response = await fetch(API_Base_Url + "notification/unreadweb?userid=" + Number(localStorage.getItem("userid")), {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        //toast.error('Something went wrong , Please try again later.')
    }
};

//Unread Notifications
export const ReadNotificationApi = async () => {
    try {
        const response = await fetch(API_Base_Url + "notification/readweb?userid=" + Number(localStorage.getItem("userid")), {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

//Assignments
export const GetAssignmentsApi = async (date) => {


    try {
        const response = await fetch(API_Base_Url + "ticket/assignments?userid=" + Number(localStorage.getItem("userid")) + "&date=" + date, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};