import React, { useState } from 'react';
import { ResetPassord_api_1 } from '../../functions/auth'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

function ResetPassword1() {
    const history = useHistory();
    const [mobileno, setMobileno] = useState("");

    const NextClick = (e) => {
        e.preventDefault();
        if (mobileno === "" || mobileno === null) {
            return toast.error("Please enter mobile number")
        }
        next();
    }
    const next = async () => {
        try {
            var req = {
                username: mobileno
            }

            ResetPassord_api_1(req).then(res => {
                if (res !== undefined) {
                    toast.success(res.message);
                    history.push("/resetpassword2");
                    localStorage.setItem("ChagePasswordUserName", mobileno)
                }
            })
                .catch()
        } catch (error) {

        }
    }
    return (
        <div className="hold-transition login-page login2">
            <div className="login2-body">
                <div className="login-left-pnl " >
                    <div className="logo1">
                        <img src="images/logo-1.png" className="" alt=""/>
                        <h4>Reset Password</h4>
                    </div>
                    <form onSubmit={(e) => NextClick(e)} >
                        <div className="form-group spacetologo">
                            <input type="text" className="form-control loignh" autoComplete="off" placeholder="Mobile Number/Email" value={mobileno} maxLength="10" onChange={(e) => { setMobileno(e.target.value.replace(/\D/, '')) }} />
                        </div>
                        <div className="spacetotext">
                            <div className="">
                                <button type="submit" className="btn btn-green btn-block">NEXT</button>
                            </div>

                        </div>
                    </form>
                </div>

                <div className="login-right-pnl">
                    <h4 className="text-center">Field Service Management System</h4>
                    <img src="images/login-bnr.png" alt=""/>
                </div>

                <div className="clearfix"></div>
            </div>

        </div>
    )
}

export default ResetPassword1