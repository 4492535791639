import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { FinalizeEstimate_api, DraftEstimate_api, SubmitEstimate_api, ApproveEstimate_api, UpdateEstimate_api } from '../../functions/estimates'
import { LoadEstimates_api, LoadTicketDetails_api, SendEmail_api, ResendEmail_api } from '../../functions/tickets'
import StatusUpdate from '../popups/StatusUpdate'


const EstimatesDetails = ({ estimateid, ticketid, activityid, loadEstimates }) => {
    var userid = Number(localStorage.getItem("userid"));
    const [EstimatesDetails, setEstimateDetails] = useState({});
    const [test, setTest] = useState(0);
    const [selectedStatusId, setSelectedStatusId] = useState(0);
    const [ticketDetails, setTicketDetails] = useState({});
    const [poNumber, setPoNumber] = useState("");
    const [isMailSent, setIsMailSent] = useState(true);
    useEffect(() => {
        loadEstimate()
        setIsMailSent(false);
    }, [activityid])

    useEffect(() => {
        loadTicketDetails();
    }, [])

    useEffect(() => {
        if (Object.keys(EstimatesDetails).length > 0)
            sum();
    }, [EstimatesDetails])

    const loadTicketDetails = () => {
        // setIsLoaded(false);
        LoadTicketDetails_api(ticketid, userid).then(res => {
            const result = res;
            if (result != undefined) {
                if (result == null) {
                    setTicketDetails([])
                }
                else

                    setTicketDetails(result);
            }
            //setIsLoaded(true);
        }).catch();
    }

    const sumPayable = () => {
        var amtMaterial = 0;
        var amtLabour = 0;
        var amtTaxMaterial = 0;
        var amtTaxLabour = 0;
        var amtDiscount = 0;
        var amtNetPayable = 0;

        if (!(isNaN(EstimatesDetails.amountMaterial) || EstimatesDetails.amountMaterial === null)) {
            amtMaterial = Number(EstimatesDetails.amountMaterial);
            if (!(isNaN(EstimatesDetails.amountTaxMaterialRate) || EstimatesDetails.amountTaxMaterialRate === null)) {
                amtTaxMaterial = EstimatesDetails.amountTaxMaterial = parseFloat(((Number(EstimatesDetails.amountMaterial) * Number(EstimatesDetails.amountTaxMaterialRate)) / 100)).toFixed(2);
            }
        }

        if (!(isNaN(EstimatesDetails.amountLabour) || EstimatesDetails.amountLabour === null)) {
            amtLabour = Number(EstimatesDetails.amountLabour);
            if (!(isNaN(EstimatesDetails.amountTaxLabourRate) || EstimatesDetails.amountTaxLabourRate === null)) {
                amtTaxLabour = EstimatesDetails.amountTaxLabour = parseFloat(((Number(EstimatesDetails.amountLabour) * Number(EstimatesDetails.amountTaxLabourRate)) / 100)).toFixed(2);
            }
        }

        if (!(isNaN(EstimatesDetails.amountDiscount) || EstimatesDetails.amountDiscount === null)) {
            if (!(isNaN(EstimatesDetails.discountpercentage) || EstimatesDetails.discountpercentage === null)) {
                amtDiscount = EstimatesDetails.amountDiscount = parseFloat((Number(amtMaterial) + Number(amtLabour)) * Number(EstimatesDetails.discountpercentage) / 100).toFixed(2);

            }
        }
        EstimatesDetails.amountPayable = parseFloat((Number(amtMaterial) + Number(amtLabour) + Number(amtTaxMaterial) + Number(amtTaxLabour)) - Number(amtDiscount)).toFixed(2);
        setTest(test + 1)
    }

    const sum = () => {
        var totalmaterialamt = 0;
        var totallaboramt = 0;
        if (EstimatesDetails.estimatedata !== null) {
            for (var i = 0; i < EstimatesDetails.estimatedata.filter(x => x.type == "Labor").length; i++) {
                if (!(isNaN(EstimatesDetails.estimatedata.filter(x => x.type == "Labor")[i].amount) || EstimatesDetails.estimatedata.filter(x => x.type == "Labor")[i].amount === null || EstimatesDetails.estimatedata.filter(x => x.type == "Labor")[i].amount === 0)) {
                    totallaboramt = Number(totallaboramt) + Number(EstimatesDetails.estimatedata.filter(x => x.type == "Labor")[i].amount);
                }
            }

            for (var i = 0; i < EstimatesDetails.estimatedata.filter(x => x.type == "Material").length; i++) {
                if (!(isNaN(EstimatesDetails.estimatedata.filter(x => x.type == "Material")[i].amount) || EstimatesDetails.estimatedata.filter(x => x.type == "Material")[i].amount === null || EstimatesDetails.estimatedata.filter(x => x.type == "Material")[i].amount === 0)) {
                    totalmaterialamt = Number(totalmaterialamt) + Number(EstimatesDetails.estimatedata.filter(x => x.type == "Material")[i].amount);
                }
            }
        }

        EstimatesDetails.amountLabour = parseFloat(totallaboramt).toFixed(2);
        EstimatesDetails.amountMaterial = parseFloat(totalmaterialamt).toFixed(2);
        sumPayable()
    }

    const loadEstimate = () => {
        LoadEstimates_api(ticketid, userid, activityid).then(res => {
            const result = res;
            if (result != undefined) {
                if (result == null) {
                    setEstimateDetails({});
                }
                else {
                    console.log("estimateDetails", result)
                    setEstimateDetails(result);
                    setPoNumber(result.ponumber)
                }
            }

        }).catch();
    }

    const handleChange_Material = (item, event) => {
        var index = EstimatesDetails.estimatedata.indexOf(item);
        EstimatesDetails.estimatedata[index].amount = event.target.value.replace(/\D/, ''); // Update it with the modified email.      
        var totalmaterialamt = 0;

        if (item.type == "Material") {
            for (var i = 0; i < EstimatesDetails.estimatedata.filter(x => x.type == "Material").length; i++) {

                totalmaterialamt = Number(totalmaterialamt) + Number(EstimatesDetails.estimatedata.filter(x => x.type == "Material")[i].amount);
                EstimatesDetails.amountMaterial = totalmaterialamt;

            }
        }
        sumPayable()
        setTest(test + 1)
    }
    const handleChange_Labor = (item, event) => {
        var index = EstimatesDetails.estimatedata.indexOf(item);
        EstimatesDetails.estimatedata[index].amount = event.target.value.replace(/\D/, ''); // Update it with the modified email.


        var totallaboramt = 0;

        if (item.type == "Labor") {
            for (var i = 0; i < EstimatesDetails.estimatedata.filter(x => x.type == "Labor").length; i++) {
                totallaboramt = Number(totallaboramt) + Number(EstimatesDetails.estimatedata.filter(x => x.type == "Labor")[i].amount);

                EstimatesDetails.amountLabour = totallaboramt;
            }
        }
        sumPayable()
        setTest(test + 1)
    }

    const handleChange_TextValue_qty_material = (item, event) => {
        var index = EstimatesDetails.estimatedata.indexOf(item);
        EstimatesDetails.estimatedata[index].qty = event.target.value;
        var num = parseFloat(event.target.value.match(/[\d\.]+/));
        EstimatesDetails.estimatedata[index].qtyno = num;
        EstimatesDetails.estimatedata[index].amount = calculateAmount(item);
        sumPayable()
        sum()
        setTest(test + 1)
    }

    const handleChange_TextValue_qty_labor = (item, event) => {
        var index = EstimatesDetails.estimatedata.indexOf(item);

        EstimatesDetails.estimatedata[index].qty = event.target.value;
        var num = parseFloat(event.target.value.match(/[\d\.]+/));
        EstimatesDetails.estimatedata[index].qtyno = num;
        EstimatesDetails.estimatedata[index].amount = calculateAmount(item);
        sumPayable()
        sum()
        setTest(test + 1)
    }
    const handleChange_TextValue_UnitPrice_material = (item, event) => {
        var index = EstimatesDetails.estimatedata.indexOf(item);

        var num = parseFloat(item.qty.match(/[\d\.]+/));
        EstimatesDetails.estimatedata[index].qtyno = num;

        EstimatesDetails.estimatedata[index].unitprice = event.target.value;
        EstimatesDetails.estimatedata[index].amount = calculateAmount(item);
        sumPayable()
        sum()
        setTest(test + 1)
    }

    const handleChange_TextValue_UnitPrice_labor = (item, event) => {
        var index = EstimatesDetails.estimatedata.indexOf(item);

        var num = parseFloat(item.qty.match(/[\d\.]+/));
        EstimatesDetails.estimatedata[index].qtyno = num;

        EstimatesDetails.estimatedata[index].unitprice = event.target.value;
        EstimatesDetails.estimatedata[index].amount = calculateAmount(item);
        sumPayable()
        sum()
        setTest(test + 1)
    }

    const handleChange_TextValue_item = (item, event) => {
        var index = EstimatesDetails.estimatedata.indexOf(item);
        EstimatesDetails.estimatedata[index].item = event.target.value;
        setTest(test + 1)
    }

    const handleChange_disc = (event) => {
        EstimatesDetails.amountDiscount = event.target.value.replace(/\D/, '');
        sumPayable();
        setTest(test + 1)
    }
    const handleChange_disc_percentage_estimate = (event) => {
        const amount = event.target.value;
        if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
            EstimatesDetails.discountpercentage = amount;
        }
        //EstimatesDetails.discountpercentage = event.target.value.replace(/\D/, '');
        sumPayable();
        setTest(test + 1)
    }
    const handleChange_tax_material = (event) => {
        const amount = event.target.value;
        if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
            EstimatesDetails.amountTaxMaterialRate = amount;
        }
        EstimatesDetails.amountTaxMaterial = ((EstimatesDetails.amountMaterial * event.target.value) / 100)
        sumPayable();
        setTest(test + 1)
    }

    const handleChange_tax_labor = (event) => {
        const amount = event.target.value;
        if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
            EstimatesDetails.amountTaxLabourRate = amount;
        }
        EstimatesDetails.amountTaxLabour = ((EstimatesDetails.amountLabour * event.target.value) / 100);
        sumPayable();
        setTest(test + 1)
    }

    const finalizeEstimate = (e) => {
        e.preventDefault();
        if (EstimatesDetails.estimatedata.length == 0)
            return toast.error('Please add atleast one row for material or labor');

        var countRecords = EstimatesDetails.estimatedata.filter(x => x.type === "Material").length;
        if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
            var lastIndexTitle_material = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].item;
            var lastIndexQty_material = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].qty;
            var lastIndexUnitPrice_material = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].unitprice;
            var lastIndexAmount_material = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].amount;
            if (lastIndexTitle_material == "" || lastIndexTitle_material == null) {
                return toast.error("Please Enter Material Title.");
            }
            else if (lastIndexQty_material == "" || lastIndexQty_material == 0) {
                return toast.error("Please Enter Material Qty.");
            }
            else if (lastIndexUnitPrice_material == "" || lastIndexUnitPrice_material == 0) {
                return toast.error("Please Enter Material Unit Price.");
            }
            else if (lastIndexAmount_material == "" || lastIndexAmount_material == 0) {
                return toast.error("Please Enter Material Amount.");
            }
        }

        countRecords = EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length;
        if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
            var lastIndexTitle_labor = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].item;
            var lastIndexQty_labor = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].qty;
            var lastIndexUnitPrice_labor = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].unitprice;
            var lastIndexAmount_labor = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].amount;
            if (lastIndexTitle_labor == "" || lastIndexTitle_labor == null) {
                return toast.error("Please Enter Labor Title.");
            }
            else if (lastIndexQty_labor == "" || lastIndexQty_labor == 0) {
                return toast.error("Please Enter Labor Qty.");
            }
            else if (lastIndexUnitPrice_labor == "" || lastIndexUnitPrice_labor == 0) {
                return toast.error("Please Enter Labor Unit Price.");
            }
            else if (lastIndexAmount_labor == "" || lastIndexAmount_labor == 0) {
                return toast.error("Please Enter Labor Amount.");
            }
        }

        var estimatedata = [];
        for (var i = 0; i < EstimatesDetails.estimatedata.length; i++) {
            estimatedata.push({
                "id": EstimatesDetails.estimatedata[i].id,
                "type": EstimatesDetails.estimatedata[i].type,
                "item": EstimatesDetails.estimatedata[i].item,
                "qty": String(EstimatesDetails.estimatedata[i].qty),
                "unitprice": Number(EstimatesDetails.estimatedata[i].unitprice),
                "amount": Number(EstimatesDetails.estimatedata[i].amount)
            })
        }
        var req = {
            "ticketid": ticketid,
            "activityid": activityid,
            "estimatedata": estimatedata,
            "amountMaterials": Number(EstimatesDetails.amountMaterial),
            "amountLabour": Number(EstimatesDetails.amountLabour),
            "amountTotal": Number(EstimatesDetails.amountMaterial) + Number(EstimatesDetails.amountLabour),
            "amountDiscount": !isNaN(Number(EstimatesDetails.amountDiscount)) ? Number(EstimatesDetails.amountDiscount) : 0,
            "amountTax": Number(EstimatesDetails.amountTax),
            "discountPercentage": Number(EstimatesDetails.discountpercentage),
            "amountTaxMaterialRate": Number(EstimatesDetails.amountTaxMaterialRate),
            "amountTaxMaterial": Number(EstimatesDetails.amountTaxMaterial),
            "amountTaxLabourRate": Number(EstimatesDetails.amountTaxLabourRate),
            "amountTaxLabour": Number(EstimatesDetails.amountTaxLabour),
            "amountPayable": Number(EstimatesDetails.amountPayable),
            "ponumber": poNumber,
            "notes": EstimatesDetails.notes,
            "updatedby": userid

        }
        FinalizeEstimate_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                loadEstimate();
            }
        })
            .catch();
    }

    const draftEstimate = (e) => {
        e.preventDefault();
        if (EstimatesDetails.estimatedata.length == 0)
            return toast.error('Please add atleast one row for material or labor');

        var countRecords = EstimatesDetails.estimatedata.filter(x => x.type === "Material").length;
        if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
            var lastIndexTitle_material = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].item;
            var lastIndexQty_material = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].qty;
            var lastIndexUnitPrice_material = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].unitprice;
            var lastIndexAmount_material = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].amount;
            if (lastIndexTitle_material == "" || lastIndexTitle_material == null) {
                return toast.error("Please Enter Material Title.");
            }
            else if (lastIndexQty_material == "" || lastIndexQty_material == 0) {
                return toast.error("Please Enter Material Qty.");
            }
            else if (lastIndexUnitPrice_material == "" || lastIndexUnitPrice_material == 0) {
                return toast.error("Please Enter Material Unit Price.");
            }
            else if (lastIndexAmount_material == "" || lastIndexAmount_material == 0) {
                return toast.error("Please Enter Material Amount.");
            }
        }

        countRecords = EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length;
        if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
            var lastIndexTitle_labor = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].item;
            var lastIndexQty_labor = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].qty;
            var lastIndexUnitPrice_labor = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].unitprice;
            var lastIndexAmount_labor = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].amount;
            if (lastIndexTitle_labor == "" || lastIndexTitle_labor == null) {
                return toast.error("Please Enter Labor Title.");
            }
            else if (lastIndexQty_labor == "" || lastIndexQty_labor == 0) {
                return toast.error("Please Enter Labor Qty.");
            }
            else if (lastIndexUnitPrice_labor == "" || lastIndexUnitPrice_labor == 0) {
                return toast.error("Please Enter Labor Unit Price.");
            }
            else if (lastIndexAmount_labor == "" || lastIndexAmount_labor == 0) {
                return toast.error("Please Enter Labor Amount.");
            }
        }

        var estimatedata = [];
        for (var i = 0; i < EstimatesDetails.estimatedata.length; i++) {
            estimatedata.push({
                "type": EstimatesDetails.estimatedata[i].type,
                "item": EstimatesDetails.estimatedata[i].item,
                "qty": String(EstimatesDetails.estimatedata[i].qty),
                "unitprice": Number(EstimatesDetails.estimatedata[i].unitprice),
                "amount": Number(EstimatesDetails.estimatedata[i].amount)
            })
        }
        var req = {
            "ticketid": ticketid,
            "estimatedata": estimatedata,
            "amountMaterials": 0,
            "amountLabour": 0,
            "amountTotal": 0,
            "amountDiscount": 0,
            "amountTax": 0,
            "amountTaxMaterialRate": 0,
            "amountTaxMaterial": 0,
            "amountTaxLabourRate": 0,
            "amountTaxLabour": 0,
            "amountPayable": 0,
            "customername": "",
            "comment": "",
            "addedby": userid,
            "latitude": "",
            "longitude": "",
            "signature": "",
            "notes": EstimatesDetails.notes

        }
        DraftEstimate_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                //  loadTicketDetails();
            }
        })
            .catch();
    }

    const updateEstimate = (e) => {
        e.preventDefault();
        if (EstimatesDetails.estimatedata.length == 0)
            return toast.error('Please add atleast one row for material or labor');

        var countRecords = EstimatesDetails.estimatedata.filter(x => x.type === "Material").length;
        if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
            var lastIndexTitle_material = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].item;
            var lastIndexQty_material = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].qty;
            var lastIndexUnitPrice_material = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].unitprice;
            var lastIndexAmount_material = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].amount;
            if (lastIndexTitle_material == "" || lastIndexTitle_material == null) {
                return toast.error("Please Enter Material Title.");
            }
            else if (lastIndexQty_material == "" || lastIndexQty_material == 0) {
                return toast.error("Please Enter Material Qty.");
            }
            else if (lastIndexUnitPrice_material == "" || lastIndexUnitPrice_material == 0) {
                return toast.error("Please Enter Material Unit Price.");
            }
            else if (lastIndexAmount_material == "" || lastIndexAmount_material == 0) {
                return toast.error("Please Enter Material Amount.");
            }
        }

        countRecords = EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length;
        if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
            var lastIndexTitle_labor = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].item;
            var lastIndexQty_labor = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].qty;
            var lastIndexUnitPrice_labor = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].unitprice;
            var lastIndexAmount_labor = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].amount;
            if (lastIndexTitle_labor == "" || lastIndexTitle_labor == null) {
                return toast.error("Please Enter Labor Title.");
            }
            else if (lastIndexQty_labor == "" || lastIndexQty_labor == 0) {
                return toast.error("Please Enter Labor Qty.");
            }
            else if (lastIndexUnitPrice_labor == "" || lastIndexUnitPrice_labor == 0) {
                return toast.error("Please Enter Labor Unit Price.");
            }
            else if (lastIndexAmount_labor == "" || lastIndexAmount_labor == 0) {
                return toast.error("Please Enter Labor Amount.");
            }
        }

        var estimatedata = [];
        for (var i = 0; i < EstimatesDetails.estimatedata.length; i++) {
            estimatedata.push({
                "id": EstimatesDetails.estimatedata[i].id,
                "type": EstimatesDetails.estimatedata[i].type,
                "item": EstimatesDetails.estimatedata[i].item,
                "qty": String(EstimatesDetails.estimatedata[i].qty),
                "unitprice": Number(EstimatesDetails.estimatedata[i].unitprice),
                "amount": Number(EstimatesDetails.estimatedata[i].amount)
            })
        }
        var req = {
            "ticketid": ticketid,
            "activityid": activityid,
            "estimatedata": estimatedata,
            "amountMaterials": Number(EstimatesDetails.amountMaterial),
            "amountLabour": Number(EstimatesDetails.amountLabour),
            "amountTotal": Number(EstimatesDetails.amountMaterial) + Number(EstimatesDetails.amountLabour),
            "amountDiscount": !isNaN(Number(EstimatesDetails.amountDiscount)) ? Number(EstimatesDetails.amountDiscount) : 0,
            "amountTax": Number(EstimatesDetails.amountTax),
            "discountPercentage": EstimatesDetails.discountpercentage == null ? 0 : Number(EstimatesDetails.discountpercentage),
            "amountTaxMaterialRate": Number(EstimatesDetails.amountTaxMaterialRate),
            "amountTaxMaterial": Number(EstimatesDetails.amountTaxMaterial),
            "amountTaxLabourRate": Number(EstimatesDetails.amountTaxLabourRate),
            "amountTaxLabour": Number(EstimatesDetails.amountTaxLabour),
            "amountPayable": Number(EstimatesDetails.amountPayable),
            "ponumber": poNumber,
            "updatedfrom": "web",
            "notes": EstimatesDetails.notes,
            "updatedby": userid

        }
        UpdateEstimate_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                loadEstimate();
            }
        })
            .catch();
    }

    const addMoreMaterialRow = (e) => {
        e.preventDefault();
        if (EstimatesDetails.estimatedata != undefined && EstimatesDetails.estimatedata != null) {
            var countRecords = EstimatesDetails.estimatedata.filter(x => x.type === "Material").length;
            if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
                var lastIndexTitle = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].item;
                var lastIndexQty = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].qty;
                var lastIndexUnitPrice = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].unitprice;
                var lastIndexAmount = EstimatesDetails.estimatedata.filter(x => x.type === "Material")[EstimatesDetails.estimatedata.filter(x => x.type === "Material").length - 1].amount;
                if (lastIndexTitle == "" || lastIndexTitle == null) {
                    return toast.error("Please Enter Material Title.");
                }
                else if (lastIndexQty == "" || lastIndexQty == 0) {
                    return toast.error("Please Enter Material Qty.");
                }
                else if (lastIndexUnitPrice == "" || lastIndexUnitPrice == 0) {
                    return toast.error("Please Enter Material Unit Price.");
                }
                else if (lastIndexAmount == "" || lastIndexAmount == 0) {
                    return toast.error("Please Enter Material Amount.");
                }
            }
        }
        if (EstimatesDetails.estimatedata == null) {
            EstimatesDetails.estimatedata = [];
        }
        EstimatesDetails.estimatedata.push({
            "id": 0,
            "item": "",
            "type": "Material",
            "qty": 0,
            "unitprice": 0,
            "amount": 0
        })
        setTest(test + 1)
    }

    const addMoreLaborRow = (e) => {
        e.preventDefault();
        if (EstimatesDetails.estimatedata != undefined && EstimatesDetails.estimatedata != null) {
            var countRecords = EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length;
            if (!(isNaN(countRecords) || countRecords === null || countRecords === 0)) {
                var lastIndexTitle = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].item;
                var lastIndexQty = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].qty;
                var lastIndexUnitPrice = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].unitprice;
                var lastIndexAmount = EstimatesDetails.estimatedata.filter(x => x.type === "Labor")[EstimatesDetails.estimatedata.filter(x => x.type === "Labor").length - 1].amount;
                if (lastIndexTitle == "" || lastIndexTitle == null) {
                    return toast.error("Please Enter Labor Title.");
                }
                else if (lastIndexQty == "" || lastIndexQty == 0) {
                    return toast.error("Please Enter Labor Qty.");
                }
                else if (lastIndexUnitPrice == "" || lastIndexUnitPrice == 0) {
                    return toast.error("Please Enter Labor Unit Price.");
                }
                else if (lastIndexAmount == "" || lastIndexAmount == 0) {
                    return toast.error("Please Enter Labor Amount.");
                }
            }
        }
        if (EstimatesDetails.estimatedata == null) {
            EstimatesDetails.estimatedata = [];
        }
        EstimatesDetails.estimatedata.push({
            "id": 0,
            "item": "",
            "type": "Labor",
            "qty": 0,
            "unitprice": 0,
            "amount": 0
        })
        setTest(test + 1)
    }

    const showStatusUpdate = () => {
        setSelectedStatusId(EstimatesDetails.currentstatusid);
        window.$("#StatusPop").modal('show');
    }

    const calculateAmount = (_item) => {
        var _qty = 0;
        var _unitprice = 0;
        var _total = 0;

        try {
            _qty = _item.qtyno //.replace(/\D/g, '');
        } catch (error) {

        }
        try {
            _unitprice = _item.unitprice;//.replace(/\D/g, '');
        } catch (error) {

        }

        if (!(isNaN(_qty) || _qty === null || _qty === 0)) {
            if (!(isNaN(_unitprice) || _unitprice === null || _unitprice === 0)) {
                _total = _qty * _unitprice;
            }
        }
        return _total;
    }

    const removeRow = (item) => {
        EstimatesDetails.estimatedata = EstimatesDetails.estimatedata.filter(x => x !== item);
        sum()
        setTest(test + 1)
    }

    const sendMail = () => {
        var photos = [];
        var selectedphotos = [];
        if (EstimatesDetails.photos != undefined && EstimatesDetails.photos.length > 0) {
            selectedphotos = EstimatesDetails.photos.filter(x => x.selected == true);
            if (selectedphotos.length > 0) {
                for (var i = 0; i < selectedphotos.length; i++) {
                    photos.push({
                        "photoid": selectedphotos[i].photoid
                    })
                }
            }
        }
        var req = {
            "ticketid": ticketid,
            "activityid": activityid,
            "photos": photos,
            "sentby": userid

        }
        SendEmail_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                loadTicketDetails()
                setIsMailSent(true)
            }
        })
            .catch();
    }

    const reSendMail = () => {
        var photos = [];
        var selectedphotos = [];
        if (EstimatesDetails.photos != undefined && EstimatesDetails.photos.length > 0) {
            selectedphotos = EstimatesDetails.photos.filter(x => x.selected == true);
            if (selectedphotos.length > 0) {
                for (var i = 0; i < selectedphotos.length; i++) {
                    photos.push({
                        "photoid": selectedphotos[i].photoid
                    })
                }
            }
        }
        var req = {
            "ticketid": ticketid,
            "activityid": activityid,
            "photos": photos,
            "sentby": userid

        }
        ResendEmail_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                loadTicketDetails()
            }
        })
            .catch();
    }
    const handlenotes_estimate = (text) => {
        EstimatesDetails.notes = text;
        setTest(test + 1)
    }
    const SelectImage_estimate = (item) => {
        var index = EstimatesDetails.photos.indexOf(item);
        EstimatesDetails.photos[index].selected = EstimatesDetails.photos[index].selected ? false : true;
        setTest(test + 1)
    }

    return (
        <>
            <div className="modal fade modal-fullscreen fsm-popup" id="EstimatesDetail" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <a className="close" data-dismiss="modal" aria-hidden="true"> <img src="images/close.png" /></a>
                            <h5 className="modal-title" id="myModalLabel">
                                {/* #{estimateid} - Estimate Details  */}
                                #{ticketid} - Ticket Details</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="box box-solid ">
                                        <div className="box-body">
                                            <div className="order-details">
                                                <div className="row">
                                                    <div className="col-md-3  col-sm-12 ">
                                                        <div className="od-col4">
                                                            <div className="ed-top">
                                                                <h4 className="ed-title"><span className="mr-15">{ticketDetails.ordertype}</span><a href="" className="od-edit">
                                                                    {/* <i className="fa fa-pencil"></i> */}
                                                                </a></h4>
                                                                <p className="rpc">{ticketDetails.orderdetail}</p>
                                                                <p className="rpc">{ticketDetails.address}</p>
                                                            </div>


                                                        </div>
                                                    </div>

                                                    <div className="col-md-3  col-sm-5 ">
                                                        <div className="od-col4">
                                                            <div className="ed-top">
                                                                <h4 className="ed-title"><span className="mr-15">PO / Work Order</span></h4>
                                                                <div className="ord-assigned">
                                                                    <p className="rpc"> PO No. {ticketDetails.ponumber}</p>
                                                                    <p className="rpc"> Work Order No.  {ticketDetails.workordernumber}</p>
                                                                </div>
                                                            </div>
                                                            <div className="purchase-order" >
                                                                {
                                                                    (ticketDetails.pofilepath != "" && ticketDetails.pofilepath != "NA" && ticketDetails.poavailable)
                                                                        ?
                                                                        <a href={ticketDetails.pofilepath} target="_blank">
                                                                            <span className="" >  <img src="images/download.png" width="17" className="" /> </span>

                                                                            <span className="ed-title"><span className="mr-15">Purchase Order </span>
                                                                            </span>
                                                                        </a>
                                                                        :
                                                                        "No Purchase order available."

                                                                }

                                                            </div>


                                                        </div>
                                                    </div>

                                                    <div className="col-md-3  col-sm-5 ">
                                                        <div className="od-col4">
                                                            <div className="ed-top">
                                                                <h4 className="ed-title"><span className="mr-15">Assigned to</span><a href="" className="od-edit">
                                                                    {/* <i className="fa fa-pencil"></i> */}
                                                                </a></h4>
                                                                <div className="ord-assigned">
                                                                    {
                                                                        EstimatesDetails.assignedtoProfilePic != "" && EstimatesDetails.assignedtoProfilePic != "NA"
                                                                        &&
                                                                        <img src={EstimatesDetails.assignedtoProfilePic} className="user-icon" />
                                                                    }

                                                                    <div className="user-detl">
                                                                        <p className="name">{EstimatesDetails.assignedtoName} </p>
                                                                        <span className="phone">{EstimatesDetails.assignedtoPhone}</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="asmt-status-bar ">
                                                                <span className="ass-to mr-15">Status </span>
                                                                <br />
                                                                <span className="blue-dot"><i className="fa fa-circle"></i></span>
                                                                {/* <span className="dot-status ed-title"><span className="mr-15">{EstimatesDetails.currentstatus}</span> <a href="" className="od-edit"> */}
                                                                {/* <i className="fa fa-pencil"></i> */}
                                                                <span className="dot-status ed-title"><span className="mr-15">{EstimatesDetails.currentstatus} ({EstimatesDetails.currentstatusactivity})</span> <a style={{ cursor: 'pointer' }} onClick={() => showStatusUpdate()} className="od-edit"><i className="fa fa-pencil"></i></a></span>


                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-3  col-sm-7">
                                                        <div className="">
                                                            <div className="order-box-desc">
                                                                <h4 className="mr-15">{EstimatesDetails.customername} </h4>
                                                                <a className="btn btn-gray btn-sm">{EstimatesDetails.customertype}</a>
                                                                <h5>{EstimatesDetails.address}</h5>
                                                                <p><img src="images/person.png" />{EstimatesDetails.contactname} </p>
                                                                <p><img src="images/phone.png" /> {EstimatesDetails.contactphone} </p>
                                                                <p><img src="images/email.png" /> {EstimatesDetails.contactemail} </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>



                                </div>

                                <div className="col-md-3">
                                    <div className="activity-title">
                                        <h4 className="act-heading">Activities</h4>
                                        {/* <div className="dropdown actddlbtn" hidden>
                                            <a className="btn btn-theme dropdown-toggle" type="button" data-toggle="dropdown"><i className="fa fa-plus"></i> &nbsp;New
				  &nbsp;&nbsp;&nbsp;&nbsp;<span className="caret"></span></a>
                                            <ul className="dropdown-menu act-dropdown">
                                                <li><a style={{ cursor: 'pointer' }} onClick={() => openNotesAdd()}>Notes</a></li>
                                                <li><a style={{ cursor: 'pointer' }} onClick={() => addNewEstimate()}>Estimate</a></li>
                                                <li><a style={{ cursor: 'pointer' }} onClick={() => addNewInvoice()}>Invoice</a></li>
                                            </ul>
                                        </div> */}
                                    </div>
                                    <div className="box box-solid activitycontainer">
                                        <div className="box-body">
                                            <div className="line">
                                                <div className="line-content">
                                                    {
                                                        ticketDetails.activities != null && ticketDetails.activities != undefined
                                                            &&
                                                            ticketDetails.activities.length == 0
                                                            ?
                                                            "No Record Found."
                                                            :
                                                            ticketDetails.activities != null
                                                            &&
                                                            ticketDetails.activities.map(item => (

                                                                <div className="timeline-content">
                                                                    {item.activitytype == "Notes" &&
                                                                        <div className="timeline-text">
                                                                            <h1>{item.activity}</h1>
                                                                            <p>{item.activityday}, {item.activitydate} {item.activitytime} </p>
                                                                        </div>
                                                                    }

                                                                    {item.activitytype == "Photos" &&
                                                                        <div className="timeline-text " >
                                                                            <h1>{item.activity}</h1>
                                                                            <p>{item.activityday}, {item.activitydate} {item.activitytime} </p>
                                                                        </div>
                                                                    }

                                                                    {item.activitytype == "Estimate" &&
                                                                        <div className="timeline-text " >
                                                                            <h1>{item.activity}</h1>
                                                                            <p>{item.activityday}, {item.activitydate} {item.activitytime}</p>
                                                                        </div>
                                                                    }

                                                                    {item.activitytype == "Invoice" &&
                                                                        <div className="timeline-text " >
                                                                            <h1>{item.activity}</h1>
                                                                            <p>{item.activityday}, {item.activitydate} {item.activitytime}  </p>
                                                                        </div>
                                                                    }

                                                                    {item.activitytype != "Invoice" && item.activitytype != "Estimate" && item.activitytype != "Photos" && item.activitytype != "Notes" &&
                                                                        <div className="timeline-text">
                                                                            <h1>{item.activity}</h1>
                                                                            <p>{item.activityday}, {item.activitydate} {item.activitytime}</p>
                                                                        </div>
                                                                    }
                                                                </div>


                                                            ))
                                                    }


                                                </div>
                                            </div>
                                            {/* <div className="actaddbtn">
                                            <a href="">ADD</a>
                                        </div> */}

                                        </div>
                                    </div>
                                </div>



                                <div className="col-md-9">
                                    <div className="box box-solid ">
                                        <div className="box-body act-body">
                                            <div className="act-box-heading">
                                                <h4 className="act-title">Estimate Details</h4>
                                                {
                                                    ((EstimatesDetails.status == "Finalized" || EstimatesDetails.status == "Submitted") && isMailSent == false)
                                                    &&
                                                    <a onClick={() => sendMail()} className="btn btn-default pull-right" >Send Mail</a>
                                                }
                                                {
                                                    ((EstimatesDetails.status == "Finalized" || EstimatesDetails.status == "Submitted") && isMailSent == true)
                                                    &&
                                                    <a onClick={() => reSendMail()} className="btn btn-default pull-right" >Resend Mail</a>
                                                }
                                                {
                                                    EstimatesDetails.downloadpath != ""
                                                    &&
                                                    <a className="btn btn-default pull-right" href={EstimatesDetails.downloadpath} target="_blank" >Download</a>
                                                }

                                                <p className="act-time"> {EstimatesDetails.creationdate} {EstimatesDetails.creationtime}</p>

                                                <p className="act-by">by: {EstimatesDetails.createdbyName}</p>

                                            </div>
                                            <div className="mt30">
                                                <div className="row estinvform">
                                                    <div className="col-md-6">
                                                        <div className="estsub-title">
                                                            <h4 className="esttitle mt0">Material</h4>
                                                            {
                                                                EstimatesDetails.currentstatusactivity != "Estimate Approved"
                                                                &&
                                                                <div className="est-addbtn">
                                                                    {EstimatesDetails.status != "Approved" &&
                                                                        <a style={{ cursor: 'pointer' }} onClick={(e) => addMoreMaterialRow(e)} className="btn btn-gray btn-sm"><i className="fa fa-plus"></i> &nbsp; Add</a>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="row">

                                                            <div>
                                                                <div className="col-md-5 pright0">
                                                                    <div className="form-group">
                                                                        Title
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 pright0">
                                                                    <div className="form-group">
                                                                        Qty
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        Unit Price
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        Amount
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                EstimatesDetails.estimatedata != null &&
                                                                EstimatesDetails.estimatedata.length > 0
                                                                &&
                                                                EstimatesDetails != null &&
                                                                EstimatesDetails.estimatedata.filter(x => x.type == "Material").map(item => (
                                                                    <div>
                                                                        <div className="col-md-5 pright0">
                                                                            <div className="form-group">
                                                                                <input type="text" className="form-control" value={item.item} placeholder="Material" onChange={(e) => handleChange_TextValue_item(item, e)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3 pright0">
                                                                            <div className="form-group">
                                                                                <input type="text" className="form-control" value={item.qty} placeholder="Quantity" onChange={(e) => handleChange_TextValue_qty_material(item, e)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-2 pright0">
                                                                            <div className="form-group">
                                                                                <input type="text" className="form-control right" value={item.unitprice} placeholder="Unit Price" onChange={(e) => handleChange_TextValue_UnitPrice_material(item, e)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <div className="form-group itemamount">
                                                                                <input type="text" className="form-control right" disabled value={item.amount} placeholder="Total Amt" onChange={(e) => handleChange_Material(item, e)} />
                                                                                {EstimatesDetails.status != "Approved" &&
                                                                                    <a className="removeitem" onClick={() => removeRow(item)} ><img src="images/remove.svg" alt="" /></a>
                                                                                }
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }


                                                            <div className="col-md-6 pright0">

                                                            </div>
                                                            {
                                                                EstimatesDetails.estimatedata != null && EstimatesDetails.estimatedata != undefined &&
                                                                EstimatesDetails?.estimatedata?.filter(x => x.type == "Material").length > 0
                                                                &&

                                                                <>
                                                                    <div className="col-md-3 pright0">
                                                                        <h4 className="total-right">Total</h4>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <div className="form-group">
                                                                            <input type="text" className="form-control right" value={EstimatesDetails.amountMaterial} disabled placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="estsub-title">
                                                            <h4 className="esttitle mt0">Labor</h4>
                                                            {
                                                                EstimatesDetails.currentstatusactivity != "Estimate Approved"
                                                                &&
                                                                <div className="est-addbtn">
                                                                    {
                                                                        EstimatesDetails.status != "Approved" &&

                                                                        <a style={{ cursor: 'pointer' }} onClick={(e) => addMoreLaborRow(e)} className="btn btn-gray btn-sm"><i className="fa fa-plus"></i> &nbsp; Add</a>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="row">
                                                            <div>
                                                                <div className="col-md-5 pright0">
                                                                    <div className="form-group">
                                                                        Title
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 pright0">
                                                                    <div className="form-group">
                                                                        Qty
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        Unit Price
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        Amount
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                EstimatesDetails.estimatedata != null &&
                                                                EstimatesDetails.estimatedata.length > 0
                                                                &&
                                                                EstimatesDetails.estimatedata.filter(x => x.type == "Labor").map(item => (
                                                                    <div>
                                                                        <div className="col-md-5">
                                                                            <div className="form-group">
                                                                                <input type="text" className="form-control" value={item.item} placeholder="Title" onChange={(e) => handleChange_TextValue_item(item, e)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <div className="form-group">
                                                                                <input type="text" className="form-control" value={item.qty} placeholder="Quantity" onChange={(e) => handleChange_TextValue_qty_labor(item, e)} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-2">
                                                                            <div className="form-group">
                                                                                <input type="text" className="form-control right" value={item.unitprice} placeholder="Unit Price" onChange={(e) => handleChange_TextValue_UnitPrice_labor(item, e)} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-2">
                                                                            <div className="form-group itemamount">
                                                                                <input type="text" className="form-control right" disabled value={item.amount} placeholder="" onChange={(e) => handleChange_Labor(item, e)} />
                                                                                {EstimatesDetails.status != "Approved" &&
                                                                                    <a className="removeitem" onClick={() => removeRow(item)} ><img src="images/remove.svg" alt="" /></a>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }


                                                            <div className="col-md-6 pright0">

                                                            </div>
                                                            {
                                                                EstimatesDetails?.estimatedata?.filter(x => x.type == "Labor").length > 0
                                                                &&
                                                                <>
                                                                    <div className="col-md-3 pright0">
                                                                        <h4 className="total-right">Total</h4>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <div className="form-group">
                                                                            <input type="text" className="form-control right" value={EstimatesDetails.amountLabour} disabled placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div>
                                                            <div className="od-notes">
                                                                <h4 className="ed-title"><img src="images/notes.png" /><span className="mr-15">Notes </span></h4>

                                                                <textarea rows="4" cols="64" value={EstimatesDetails.notes} onChange={(e) => handlenotes_estimate(e.target.value)}   ></textarea>
                                                                <br />
                                                                <div className="od-photos">
                                                                    <h4 className=""><img src="images/photo-camera.png" />Photos </h4>
                                                                    <div className="od-photos-list">

                                                                        {
                                                                            EstimatesDetails?.photos?.length == 0 ?
                                                                                "No Photos Available."
                                                                                :
                                                                                // photosList != null &&
                                                                                EstimatesDetails?.photos?.map(item => (
                                                                                    <a onClick={() => SelectImage_estimate(item)} style={{ cursor: 'pointer' }} >
                                                                                        <img src={item.photopath} className={item.selected ? "selectedphoto" : ""} />
                                                                                    </a>
                                                                                ))
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="total-count">

                                                            {/* <div className="total-count-list">
                                                                <h5 className="list-name">PO Number</h5>
                                                                <input type="text" className="list-amount" style={{ width: '100px' }} value={poNumber} onChange={(e) => setPoNumber(e.target.value)} />
                                                            </div> */}

                                                            <div className="total-count-list">
                                                                <h5 className="list-name">Total Material</h5>
                                                                <h5 className="list-amount">{EstimatesDetails.amountMaterial}</h5>
                                                            </div>
                                                            <div className="total-count-list">
                                                                <h5 className="list-name">Total Labor</h5>
                                                                <h5 className="list-amount">{EstimatesDetails.amountLabour}</h5>
                                                            </div>

                                                            <div className="total-count-list">
                                                                <h5 className="list-name tax">Material Taxes</h5>
                                                                {/* <h5 className="list-amount">{EstimatesDetails.amountTax}</h5> */}
                                                                <input type="text" className="list-amount" style={{ width: '50px' }} value={EstimatesDetails.amountTaxMaterial} disabled />
                                                                <input type="text" disabled={EstimatesDetails.status == "Approved" ? true : false} className="list-amount nofloat" max="100" placeholder="%" style={{ width: '50px' }} value={EstimatesDetails.amountTaxMaterialRate} onChange={(e) => handleChange_tax_material(e)} />%

                                                            </div>
                                                            <div className="total-count-list">
                                                                <h5 className="list-name tax">Labor Taxes</h5>
                                                                {/* <h5 className="list-amount">{EstimatesDetails.amountTax}</h5> */}
                                                                <input type="text" className="list-amount" style={{ width: '50px' }} value={EstimatesDetails.amountTaxLabour} disabled />
                                                                <input type="text" disabled={EstimatesDetails.status == "Approved" ? true : false} className="list-amount nofloat" placeholder="%" max="100" style={{ width: '50px' }} value={EstimatesDetails.amountTaxLabourRate} onChange={(e) => handleChange_tax_labor(e)} />%

                                                            </div>

                                                            <div className="total-count-list">
                                                                <h5 className="list-name tax">Discounts  </h5>
                                                                <input type="text" className="list-amount" style={{ width: '50px' }} value={EstimatesDetails.amountDiscount} disabled onChange={(e) => handleChange_disc(e)} />
                                                                {EstimatesDetails.status == "Approved"
                                                                    ?
                                                                    <><input type="text" className="list-amount nofloat txtdisable" disabled max="100" placeholder="%" style={{ width: '50px' }} value={EstimatesDetails.discountpercentage} onChange={(e) => handleChange_disc_percentage_estimate(e)} />%</>
                                                                    :
                                                                    <><input type="text" className="list-amount nofloat txtdisable" disabled={ticketDetails.customertype == "Preferred" ? false : true} max="100" placeholder="%" style={{ width: '50px' }} value={EstimatesDetails.discountpercentage} onChange={(e) => handleChange_disc_percentage_estimate(e)} />%</>
                                                                }
                                                            </div>

                                                            <div className="total-count-list">
                                                                <h5 className="list-name"><b>Total Due</b></h5>
                                                                <h5 className="list-amount"><b>{EstimatesDetails.amountPayable}</b></h5>
                                                            </div>
                                                        </div>
                                                        <div className="topbtn-right mb-20">

                                                            {
                                                                (EstimatesDetails.status == "" || EstimatesDetails.status == null || EstimatesDetails.status == undefined)
                                                                &&
                                                                <a style={{ cursor: 'pointer' }} onClick={(e) => draftEstimate(e)} className="btn btn-theme" >Save</a>
                                                            }
                                                            {
                                                                (EstimatesDetails.status == "Draft")
                                                                &&
                                                                <a style={{ cursor: 'pointer' }} onClick={(e) => finalizeEstimate(e)} className="btn btn-theme" >Save</a>
                                                            }

                                                            {
                                                                (EstimatesDetails.status == "Finalized")
                                                                &&
                                                                <a style={{ cursor: 'pointer' }} onClick={(e) => updateEstimate(e)} className="btn btn-theme" >Save</a>
                                                            }

                                                            {
                                                                (EstimatesDetails.status == "Submitted")
                                                                &&
                                                                <a style={{ cursor: 'pointer' }} onClick={(e) => updateEstimate(e)} className="btn btn-theme" >Save</a>
                                                                // <a style={{ cursor: 'pointer' }} onClick={(e) => finalizeEstimate(e)} className="btn btn-theme" >Submit</a>
                                                            }
                                                            {
                                                                (EstimatesDetails.status == "Approved")
                                                                &&
                                                                null
                                                                // <a style={{ cursor: 'pointer' }} onClick={(e) => updateEstimate(e)} className="btn btn-theme" >Save</a>
                                                                // <a style={{ cursor: 'pointer' }} onClick={(e) => finalizeEstimate(e)} className="btn btn-theme" >Submit</a>
                                                            }


                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <StatusUpdate ticketid={ticketid} selectedStatusId_prop={selectedStatusId} loadagain={loadEstimate} loadTicketDetails={loadTicketDetails} whichPage="Estimate" />
        </>
    )
}

export default EstimatesDetails
