import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Header from '../../components/nav/Header'
import Sidebar from '../../components/nav/Sidebar'
import { Link } from 'react-router-dom'
import { LoadTechnicians_api } from '../../functions/users'
import { GetReport_TechOccupancy_api } from '../../functions/reports'
import moment from 'moment'
import ReactExport from "react-data-export";
import DoubleLineBasic from '../../Charts/DoubleLineChart'


function TechOccupancy() {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    const [excelDataSet, setExcelDataSet] = useState([]);

    const [paginationarr, setPaginationarr] = useState([]);
    const [selectedPageNo, setSelectedPageNo] = useState(1);

    const [period, setPeriod] = useState("This Year");
    const [filterid, setFilterid] = useState(0);

    const [technicianList, setTechnicianlist] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const [headingList, setHeadingList] = useState([])
    const [List, setList] = useState([]);
    const [xAsisData, setxAxisData] = useState([])
    const [lineChartData, setLineChartData] = useState({ currentdata: [], prevdata: [], label1: "", label2: "" })
    const [lineChartTitle, setLineChartTitle] = useState({ xAxis: "", yAxis: "" })
    const [total, setTotal] = useState({ currentTotal: 0, prevTotal: 0 })

    let recordshowperpage = 30;

    useEffect(() => {
        loadTechnicians();
        LoadDataList();
    }, [])

    const loadReport = () => {
        LoadDataList();
    }

    const LoadDataList = () => {
        setIsLoaded(false)
        var req = {
            "period": period,
            "id": Number(filterid),
            "fromdate": "",
            "todate": ""
        }
        GetReport_TechOccupancy_api(req).then(res => {
            if (res !== undefined) {
                if (res.tbldata.length > 0) {
                    setHeadingList(res.tbldata[0].details)
                    setList(res.tbldata)

                }
                else {
                    setList([])
                }
                if (res.chartdata.length > 0) {

                    var dt = new Date();
                    var currentyear = dt.getFullYear();
                    var prevyear = currentyear - 1;
                    var data = res.chartdata;
                    var label_data = [];
                    var thisyear_data = [];
                    var prevyear_data = [];
                    var currenttotal = 0;
                    var prevtotal = 0;
                    data.forEach(item => {
                        currenttotal = 0;
                        prevtotal = 0;
                        label_data.push(item.series1label)
                        thisyear_data.push(item.series1value)
                        prevyear_data.push(item.series2value)
                    });
                    setTotal({ currentTotal: currenttotal, prevTotal: prevtotal })
                    setxAxisData(label_data)
                    setLineChartData({ currentdata: thisyear_data, prevdata: prevyear_data, label2: prevyear + " - " + prevtotal, label1: currentyear + " - " + currenttotal })
                }
                else {
                    var dt = new Date();
                    var currentyear = dt.getFullYear();
                    var prevyear = currentyear - 1;
                    var data = res.chartdata;
                    var label_data = [];
                    var thisyear_data = [];
                    var prevyear_data = [];
                    var currenttotal = 0;
                    var prevtotal = 0;

                    setTotal({ currentTotal: currenttotal, prevTotal: prevtotal })
                    setxAxisData(label_data)
                    setLineChartData({ currentdata: thisyear_data, prevdata: prevyear_data, label2: prevyear + " - " + prevtotal, label1: currentyear + " - " + currenttotal })
                }

                setIsLoaded(true)
            }
        })
    }



    const loadTechnicians = () => {
        LoadTechnicians_api().then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {
                    setTechnicianlist([])
                }
                else
                    setTechnicianlist(result.data);
            }

        }).catch();
    }


    const AccordianChange = (item) => {
        var accordionitem = item;
        window.$("#" + accordionitem).slideToggle().parent().siblings().find(".accordion-content").slideUp();
        window.$("#" + accordionitem).toggleClass("active-title");
        window.$("#" + "item_" + accordionitem).toggleClass("active-title");
        window.$("#" + accordionitem).parent().siblings().find(".accordion-title").removeClass("active-title");
        window.$("i.fa-chevron-down", "#" + item).toggleClass("chevron-top");
        window.$("#" + accordionitem).parent().siblings().find(".accordion-title i.fa-chevron-down").removeClass("chevron-top");
    }

    return (
        <div>
            <div className="hold-transition skin-black sidebar-mini">
                <div className="wrapper">
                    <Header />
                    <Sidebar />
                    <div className="content-wrapper db-background">
                        <div className="">
                            <div style={{ paddingTop: 30 }}>
                                <div className="filterbox">
                                    <div className="boxmargin">
                                        <div className="box box-solid box-height">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3">
                                                    <div className="form-group row">
                                                        <label for="colFormLabel" className="col-lg-3 col-sm-3 col-form-label">Period</label>
                                                        <div className="col-lg-9 col-sm-9">
                                                            <select className="form-control" value={period} onChange={(e) => setPeriod(e.target.value)}>
                                                                <option value="This Year">This Year</option>
                                                                <option value="Previous Year">Previous Year</option>
                                                                <option value="This Month">This Month</option>
                                                                <option value="Previous Month">Previous Month</option>
                                                                <option value="This Week">This Week</option>
                                                                <option value="Previous Week">Previous Week</option>

                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-3">
                                                    <div className="form-group row">
                                                        <label for="colFormLabel" className="col-lg-5 col-sm-5 col-form-label">Technician</label>
                                                        <div className="col-lg-7 col-sm-7">
                                                            <select className="form-control" value={filterid} onChange={(e) => setFilterid(e.target.value)}>
                                                                <option value="0">All</option>
                                                                {
                                                                    technicianList.map(item => (
                                                                        <option value={item.userId}>{item.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="fliterbtn">
                                                        <div className="inlinebtn">
                                                            <button className="btn btn-theme" onClick={() => loadReport()}>View</button>
                                                        </div>
                                                        <div className="inlinebtn">
                                                            <ExcelFile filename="Revenue by Technician" element={<button className="btn btn-theme">Export to Excel</button>} >
                                                                <ExcelSheet data={excelDataSet} name="customer revenue" >
                                                                    <ExcelColumn label="Technician" value="Technician" />
                                                                    <ExcelColumn label="Customer" value="Customer" />
                                                                    <ExcelColumn label="PO Number" value="ponumber" />
                                                                    <ExcelColumn label="Amount" value="amount" />
                                                                </ExcelSheet>
                                                            </ExcelFile>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="chartcontainer">
                            <h4 className="box-title">Tech Occupancy for this year is 60%</h4>
                            <div className="box box-solid">
                                <DoubleLineBasic color={["#3B5998", "#8B9DC3"]} lable={lineChartTitle} data={lineChartData} xAsisData={xAsisData} />
                            </div>
                        </div>

                        <div className="rptcontainer">
                            {
                                isLoaded == false ? <div className="loader"><img src="images/loader.gif" alt="" /></div>
                                    :
                                    <div className="box-body table-responsive no-padding userlist">
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th>Technician</th>
                                                    {headingList.map(item => (
                                                        <th>{item.label}</th>

                                                    ))}
                                                </tr>
                                                {List.map(item => (
                                                    <tr>
                                                        <td>{item.name}</td>
                                                        {item.details.map(listitem => (
                                                            <td>{listitem.value}</td>
                                                        ))}

                                                    </tr>

                                                ))}
                                            </tbody>
                                        </table>

                                    </div>
                            }
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default TechOccupancy
