import React from 'react'


const CreateUpdateService = ({ screentype, CreateService_submit, Updateservice_submit, service, setservice, serviceamount, setserviceamount }) => {
    return <div className="modal fade fsm-popup" id="Addservice">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <a type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <img src="images/close.png" /></a>
                    <h5 className="modal-title">{screentype == "Create" ? "Add" : "Update"} Service</h5>
                </div>
                <div className="modal-body">
                    <div className="add-custmer-form ">
                        <div className="row">

                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="">
                                        <input type="text" className="form-control" placeholder="Service Name" maxLength="50" value={service} onChange={e => setservice(e.target.value)} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="">
                                        <input type="text" className="form-control" placeholder="Amount" maxLength="4" value={serviceamount} onChange={e => setserviceamount(e.target.value.replace(/\D/, ''))} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-2 pull-right">
                                <a type="button" className="btn btn-danger pull-right" data-dismiss="modal" aria-label="Close">Cancel</a>
                            </div>
                            {
                                screentype == "Create"
                                    ?
                                    <div className="col-md-10">
                                        <a type="submit" className="btn btn-theme pull-right" onClick={CreateService_submit} >SAVE </a>
                                    </div>

                                    :
                                    <div className="col-md-10">
                                        <a type="submit" className="btn btn-theme pull-right" onClick={Updateservice_submit} >SAVE </a>
                                    </div>
                            }

                        </div>
                    </div>

                </div>

            </div>
            {/* <!-- /.modal-content --> */}
        </div>
        {/* <!-- /.modal-dialog --> */}
    </div>

}

export default CreateUpdateService
