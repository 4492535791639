import React from 'react'


function DeletePopUp({ DeleteUser_submit, id, getreqFrom }) {
    return (
        <div className="modal fade fsm-popup" id="DeletePopUp">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <a type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <img src="images/close.png" /></a>
                        {/* <h5 className="modal-title">{screentype == "Create" ? "Add" : "Update"} User</h5> */}
                    </div>
                    <div className="modal-body">

                        <div className="add-custmer-form ">
                            <div>
                                <img src="images/deleteImg.png" style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block', height: '76px' }} />
                                <h5 className="text-center">Are you sure to delete this <br /> {getreqFrom} ?</h5>
                            </div>
                            <div className="row">

                                <div className="col-md-12">

                                </div>


                                <div className="col-md-6">
                                    <a type="submit" className="btn btn-theme pull-right" onClick={() => DeleteUser_submit(id)} >Delete </a>
                                </div>

                                <div className="col-md-6">
                                    <a type="submit" className="btn btn-default pull-left" onClick={() => window.$("#DeletePopUp").modal('hide')} >Cancel </a>
                                </div>


                            </div>
                        </div>

                    </div>

                </div>
                {/* <!-- /.modal-content --> */}
            </div>
            {/* <!-- /.modal-dialog --> */}
        </div>
    )
}

export default DeletePopUp
