import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../components/nav/Header'
import Sidebar from '../../components/nav/Sidebar'
import CreateUpdateOrderType from '../../forms/CreateUpdateOrderType'
import DeletePopUp from '../../forms/DeletePopUp'
import { toast } from 'react-toastify'
import { CreateOrderType_api, LoadOrderType_api, UpdateOrderType_api, ChangeOrderTypeStatus_api, DeleteOrderType_api } from '../../functions/orderType'

const OrderType = () => {

    const [isLoaded, setIsLoaded] = useState(false);
    const [screentype, setScreenType] = useState("Create");
    const [orderTypeId, setOrderTypeId] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [orderTypeList, setOrderTypeList] = useState([]);
    const [orderType, setOrderType] = useState("");
    const loggeduserid = Number(localStorage.getItem("userid"));
    const [userType]  = useState(localStorage.getItem("usertype"));


    useEffect(() => {
        loadOrderTypes();
    }, [searchText])

    const loadOrderTypes = () => {
        setIsLoaded(false);
        LoadOrderType_api(searchText).then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {
                    setOrderTypeList([])
                }
                else
                    setOrderTypeList(result.data);
            }
            setIsLoaded(true);
        }).catch();
    }

    const showOrderTypeCreationPopUp = () => {
        setOrderType("");
        setOrderTypeId("");
        setScreenType("Create");
        window.$("#AddOrderType").modal('show');
    }

    const CreateOrderType_submit = (e) => {
        e.preventDefault();
        if (orderType.trim() == "")
            return toast.error("Please enter order type.");


        var req = {
            "ordertype": orderType,
            "addedby": loggeduserid
        }
        CreateOrderType_api(req).then(res => {
            if (res != undefined) {
                toast.success("Order type added successfully!");
                window.$("#AddOrderType").modal('hide');
                setOrderType("");
                loadOrderTypes();
            }
        })
            .catch();

    }

    const UpdateOrderType_submit = (e) => {
        e.preventDefault();
        if (orderType.trim() == "")
            return toast.error("Please enter order type.");


        var req = {
            "ordertypeid": orderTypeId,
            "ordertype": orderType,
            "updatedby": loggeduserid
        }
        UpdateOrderType_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                window.$("#AddOrderType").modal('hide');
                setOrderType("");
                loadOrderTypes();
            }
        })
            .catch();

    }

    const UpdateOrderType_Status_submit = (userid, newstatus) => {

        ChangeOrderTypeStatus_api(userid, newstatus).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                loadOrderTypes();
            }
        })
            .catch();

    }

    const loadOrderTypeDetails = (item) => {
        setOrderType(item.ordertype);
        setOrderTypeId(item.ordertypeid)
        setScreenType("Update");
        window.$("#AddOrderType").modal('show');
    }

    const deleteOrderTypePopUp = (ordertypeid) => {
        setOrderTypeId(ordertypeid)
        window.$("#DeletePopUp").modal('show');
    }

    const DeleteOrderType_submit = (ordertypeid) => {
        DeleteOrderType_api(orderTypeId).then(res => {
            if (res != undefined) {
                toast.success("Order type deleted successfully!");
                window.$("#DeletePopUp").modal('hide');
                loadOrderTypes();
            }
        })
            .catch();

    }

    return (
        <div className="hold-transition skin-black sidebar-mini">
            <div className="wrapper" >

                {/* SideBar */}
                <Header />
                <Sidebar />
                {/* <!-- Content Wrapper. Contains page content --> */}
                <div className="content-wrapper db-background">

                    {/* <!-- Main content --> */}
                    <section className="content content-body">
                        {/* <!-- Main row --> */}
                        <div className="row">
                            <div className="col-md-6">
                                <div className="top-btn-group estimatestab">
                                {userType!=="Dispatcher"&& <div className="btn-group ">
                                    <Link to="/ordertype" className="btn btn-theme">Order Type</Link>
                                        <Link to="/services" className="btn btn-default">Services</Link>

                                    </div>}
                                </div>
                            </div>
                            <div className="col-md-6">

                            </div>
                            <div className="col-md-12">
                                <div className="content-header ">
                                    <h1 className="">
                                        Order Type  </h1>
                                    <div className="topbtn-right">
                                        <a type="" className="btn btn-theme filterbtn" data-toggle="modal" onClick={() => showOrderTypeCreationPopUp()}>Add Order Type</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="top-btn-group ">

                                    {/* <div className="pull-right">
                                    <div className="pull-left serch-fild top-serching">

                                        <input type="text" className="form-control ih36" placeholder="" value={searchText} onChange={e => setSearchText(e.target.value)} />
                                        <i className="fa fa-search"></i>

                                    </div>
                                </div> */}
                                </div>

                                <div className="">
                                    <div className="box box-solid">
                                        {/* <!-- /.box-header --> */}
                                        {
                                            isLoaded == false ? <div className="loader"><img src="images/loader.gif" alt="" /></div>
                                                :
                                                <div className="box-body table-responsive no-padding userlist">
                                                    <table className="table ">
                                                        <tbody>
                                                            <tr>

                                                                <th>Order Type</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                            {
                                                                (orderTypeList != undefined && orderTypeList != null && orderTypeList != "null") &&
                                                                    orderTypeList.length == 0 ?
                                                                    <tr><td colSpan="3">No Record Found.</td></tr>
                                                                    :
                                                                    orderTypeList.map(item => (
                                                                        <tr>

                                                                            <td>{item.ordertype}</td>
                                                                            <td className="">
                                                                                {
                                                                                    item.status == "Active"
                                                                                        ?
                                                                                        <a style={{ cursor: 'pointer' }} className="stsbtn sts-active " onClick={() => UpdateOrderType_Status_submit(item.ordertypeid, 0)} >Active</a>
                                                                                        :
                                                                                        <a style={{ cursor: 'pointer' }} className="stsbtn sts-inactive" onClick={() => UpdateOrderType_Status_submit(item.ordertypeid, 1)}>Inactive</a>
                                                                                }

                                                                            </td>

                                                                            <td className="">
                                                                                {item.userType != "Admin" &&
                                                                                    <a style={{ cursor: 'pointer' }} className="stsbtn sts-inactive" onClick={() => loadOrderTypeDetails(item)}>Edit</a>
                                                                                }
                                                                                &nbsp;&nbsp;
                                                                                {item.userType != "Admin" &&
                                                                                    <a style={{ cursor: 'pointer' }} className="stsbtn sts-inactive" onClick={() => deleteOrderTypePopUp(item.ordertypeid)}>Delete</a>
                                                                                }


                                                                            </td>

                                                                        </tr>
                                                                    ))

                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>

                                        }{/* <!-- /.box-body --> */}
                                    </div>
                                </div>


                            </div>
                            {/* <!-- /.row (main row) --> */}
                        </div>
                        {/* <!-- /.content --> */}
                    </section>
                    {/* <!-- /.content-wrapper -->       */}
                </div>

                {/* Create Form */}
                <CreateUpdateOrderType
                    screentype={screentype}
                    orderType={orderType}
                    setOrderType={setOrderType}
                    CreateOrderType_submit={CreateOrderType_submit}
                    UpdateOrderType_submit={UpdateOrderType_submit}
                    orderTypeId={orderTypeId}
                />

                <DeletePopUp
                    DeleteUser_submit={DeleteOrderType_submit}
                    id={orderTypeId}
                    getreqFrom="Order Type" />



                {/* <!-- /.modal --> */}

            </div>
        </div>

    )
}

export default OrderType
