import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../components/nav/Header'
import Sidebar from '../../components/nav/Sidebar'
import DoubleLineBasic from '../../Charts/DoubleLineChart'
import SimplePieChart from '../../Charts/SimplePieChart'
import AnalyticsCard from '../../components/analyticsCard/Card'
import { GetAnalyticsSummary_api, GetRevenueTrend_api } from '../../functions/analytics'
import {GetReport_AvgTicketLifeCycle_api} from '../../functions/reports'
const API_Base_Url = process.env.REACT_APP_API_BASE_URL;

/*
Date:-11-01-22
CodeBy:-Mayur
Description:-Dashboard of Analitics
*/
function DashboardAnalytics() {

    const [filterval, setFilterval] = useState("This Year")
    const [graphfilterval, setGraphFilterVal] = useState("year")
    const [summaryResult, setSummaryResult] = useState([])

    const [xAsisData, setxAxisData] = useState([])
    const [lineChartData, setLineChartData] = useState({ currentdata: [], prevdata: [], label1: "", label2: "" })
    const [lineChartTitle, setLineChartTitle] = useState({ xAxis: "Months", yAxis: "Revenue" })

    const[isLoaded,setisLoaded]=useState(false)

    const [PieChartData, setPieChartData] = useState({ part1: 0, part2: 0, part3: 0, part4: 0 })

    

    useEffect(() => {
        setisLoaded(false)
        loadAnalyticsSummary();
        loadRevenueTrend();
        loadpiechartData();
        setisLoaded(true)

    }, [filterval]);

    const loadAnalyticsSummary = () => {
        GetAnalyticsSummary_api(filterval).then(res => {
            if (res !== undefined) {
                setSummaryResult(res)
            }
        }).catch();
    }


    const loadRevenueTrend = () => {
        var val="This Year";
        if (filterval === "This Year" || filterval === "This Month") {
            val = "This";
        }
        else {
            val = "Previous";
        }
        const obj_filter = val + " " + graphfilterval;
        GetRevenueTrend_api(obj_filter).then(res => {
            if (res !== undefined) {
                var dt = new Date(res[0].series1FromDate);
                var currentyear = dt.getFullYear();
                var prevyear = currentyear - 1;
                var data = res;
                var label_data = [];
                var thisyear_data = [];
                var prevyear_data = [];
                data.forEach(item => {
                    const val1 = item.series1Revenue !== null ? item.series1Revenue.toFixed(2) : 0
                    const val2 = item.series2Revenue !== null ? item.series2Revenue.toFixed(2) : 0
                    label_data.push(item.series1Label)
                    thisyear_data.push(val1)
                    prevyear_data.push(val2)
                });
                setxAxisData(label_data)
                setLineChartData({ currentdata: thisyear_data, prevdata: prevyear_data, label2: prevyear, label1: currentyear })
            }
        }).catch();
    }

    const loadpiechartData=()=>{
        GetReport_AvgTicketLifeCycle_api(filterval).then(res => {
            if (res !== undefined) {
                setPieChartData({ part1: res.responseTime, part2: res.working, part3: res.estimateApproval, part4: res.invoicePaid })
            }
            console.log(res,"piechart")
            setisLoaded(true)
        }).catch();
    }
    const LoadLineChartData=(name)=>{
         setGraphFilterVal(name)
        var val="This";
        if (filterval === "This Year" || filterval === "This Month") {
            val = "This";
        }
        else {
            val = "Previous";
        }
        const obj_filter = val + " " + name;
        GetRevenueTrend_api(obj_filter).then(res => {
            if (res !== undefined) {
                var dt = new Date(res[0].series1FromDate);
                var currentyear = dt.getFullYear();
                var prevyear = currentyear - 1;
                var data = res;
                var label_data = [];
                var thisyear_data = [];
                var prevyear_data = [];
                data.forEach(item => {
                    const val1 = item.series1Revenue !== null ? item.series1Revenue.toFixed(2) : 0
                    const val2 = item.series2Revenue !== null ? item.series2Revenue.toFixed(2) : 0
                    label_data.push(item.series1Label)
                    thisyear_data.push(val1)
                    prevyear_data.push(val2)
                });
                setxAxisData(label_data)
                setLineChartData({ currentdata: thisyear_data, prevdata: prevyear_data, label2: prevyear, label1: currentyear })
            }
        }).catch();
    }

    return (
        <div className="hold-transition skin-black sidebar-mini">
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper db-background">
                    {/* <!-- Main content --> */}
                    <section className="content content-body">
                        <div className="row" id="msgdiv" style={{ position: 'relative' }}>
                            <div className="col-lg-3"></div>
                            <div className="col-lg-6">
                            </div>
                            <div className="col-lg-3">

                            </div>
                        </div>
                        {/* <!-- Main row --> */}
                        <div className="row">
                            {/* Timelines */}
                            <div className="col-md-12">
                                <div className="title-row">
                                    <h4 className="box-title">Timelines</h4>
                                    <div className="form-ddl">
                                        <select className="form-control" value={filterval} onChange={e => setFilterval(e.target.value)}>
                                            <option value="This Year">This Year</option>
                                            <option value="Previous Year">Previous Year</option>
                                            <option value="This Month">This Month</option>
                                            <option value="Previous mMnth">Previous Month</option>
                                        </select>
                                    </div>
                                </div>
                                {isLoaded?
                                <div className="db-Overview">
                                    <ul>
                                        <AnalyticsCard shareIcon={false} title={summaryResult.firstTimeFixRate + "%"} subTitle="First Time Fix Rate" url="#" />
                                        <AnalyticsCard shareIcon={true} title={summaryResult.averageTicketResolutionTime} subTitle="Average Ticket Resolution Time" url="avg-ticket-resolution-time" />
                                        <AnalyticsCard shareIcon={true} title={summaryResult.averageResponseTime} subTitle="Average Response Time" url="average-response-time" />
                                        <AnalyticsCard shareIcon={true} title={summaryResult.estimatedTime + "/" + summaryResult.actualTime} subTitle="Estimated V/s. Actual Time" url="estimate-actual-time" />
                                        <AnalyticsCard shareIcon={true} title={summaryResult.techOccupancy + "%"} subTitle="Tech Occupancy" url="tech-occupancy" />
                                    </ul>
                                </div>
                                 :<div className="loader"><img src="images/loader.gif" alt="" /></div>}
                            </div>
                            {/* Financials */}
                            {isLoaded?
                            <>
                            <div className="col-md-12">
                                <h4 className="box-title">Financials</h4>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className='box box-solid'>
                                            <div className='chart-title'>
                                                <h4 className='titlename'>Revenue Trend</h4>
                                                <div className='tabsname'>
                                                <Link to="#" className={graphfilterval === "month" ? "active" : ""} onClick={() => LoadLineChartData("month")}>Month</Link>
                                                <Link to="#" className={graphfilterval === "year" ? "active" : ""} onClick={() => LoadLineChartData("year")}>Year</Link>
                                                                                    
                                                   
                                                </div>
                                            </div>
                                            <DoubleLineBasic color={["#3B5998", "#8B9DC3"]} lable={lineChartTitle} data={lineChartData} xAsisData={xAsisData} />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="db-Overview financials-cards">
                                            <ul>
                                                <AnalyticsCard shareIcon={false} title={summaryResult.totalInvoices} subTitle="Total Invoices" url="#" />
                                                <AnalyticsCard shareIcon={true} title={"$" + summaryResult.totalRevenue} subTitle="Revenue" url="revenue" />
                                            </ul>
                                            <ul>
                                                <AnalyticsCard shareIcon={true} title={"$" + summaryResult.avgRevenuePerTicket} subTitle="Average Revenue Per Ticket" url="average-ticket-life-cycle" />
                                                <AnalyticsCard shareIcon={true} title={"$" + summaryResult.avgRevenuePerTech} subTitle="Average Revenue Per Tech" url="average-revenue-per-tech" />
                                            </ul>
                                            <ul>
                                                <AnalyticsCard shareIcon={false} title={summaryResult.totalCustomers} subTitle="Total Customer" url="#" />
                                                <AnalyticsCard shareIcon={true} title={"$" + summaryResult.avgRevenuePerCustomer} subTitle="Average Revenue Per Customer" url="average-response-time" />
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Average Ticket Life Cycle */}
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="box-title">Average Ticket Life Cycle</h4>
                                        <div className='box box-solid height310'>
                                        <SimplePieChart lable={["Response", "Working", "Estimate Approval", "Invoice Paid"]} data={PieChartData} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <h4 className="box-title">Reports</h4>
                                        <div className='box box-solid height310'>
                                            <ul className='Reportscard'>
                                                <li><a href='#/revenuebytech'>Revenue By Tech</a></li>
                                                <li><a href='#/revenuebycustomer'>Revenue By Customer</a></li>
                                                <li><a href='#/collectionreport'>Collection Report</a></li>
                                                <li><a href='#/techutilization'>Tech Utilization</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </>
                             :<div className="loader"><img src="images/loader.gif" alt="" /></div>}
                        </div>

                    </section>

                </div>

            </div>
            {/* wrapper */}
        </div>
    )
}

export default DashboardAnalytics