import React, { Component, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from '../../components/nav/Header'
import Sidebar from '../../components/nav/Sidebar'
import FullCalendarWidget2 from "./Fullcalender2";
import axios from 'axios';
import Scheduler, {
  SchedulerData,
  ViewTypes,
  DemoData,
  DATE_FORMAT
} from "react-big-scheduler";
import withDragDropContext from "./withDnDContext";
import "react-big-scheduler/lib/css/style.css";
import moment from "moment";
const API_Base_Url = process.env.REACT_APP_API_BASE_URL;

class FullCalendarWidget extends Component {
  constructor(props) {
    super(props);

    //let schedulerData = new SchedulerData(new moment("2017-12-18").format(DATE_FORMAT), ViewTypes.Week);
    let schedulerData = new SchedulerData(
      new moment().format(DATE_FORMAT),
      ViewTypes.Week,
      false,
      false,
      {
        selectedAreaColor: "#616e7c",
        nonWorkingTimeHeadBgColor: "#fff",
        nonWorkingTimeBodyBgColor: "#fff",
        startResizable: false,
        endResizable: false,
        headerEnabled: true,
        resourceName: "Technician",
        //nonAgendaDayCellHeaderFormat: "ddd D |HH:mm",
        views: []

      },

    )
    schedulerData.localeMoment.locale("en");
    schedulerData.setResources(JSON.parse(localStorage.getItem("res")));
    schedulerData.setEvents(JSON.parse(localStorage.getItem("evt")));
    this.state = {
      viewModel: schedulerData,
      slot_id: 0
    };
  }

  render() {
    const { viewModel } = this.state;
    return (
      <div className="hold-transition skin-black sidebar-mini">
        <div className="wrapper">
          <Header />
          <Sidebar />
          <div className="content-wrapper db-background">
            {/* <!-- Main content --> */}
            <section className="content ">
            <div className="table-responsive">
              <div className="row">
                {/* <!-- Left col 8--> */}
                <div className="">
                <div className="col-lg-12">
              
                  <Scheduler
                    schedulerData={viewModel}
                    prevClick={this.prevClick}
                    nextClick={this.nextClick}
                    onSelectDate={this.onSelectDate}
                    //onViewChange={onViewChange}
                    eventItemClick={this.eventClicked}
                    //updateEventStart={updateEventStart}
                    //updateEventEnd={updateEventEnd}
                    //moveEvent={moveEvent}
                    newEvent={this.newEvent}
                    //onScrollLeft={onScrollLeft}
                    //onScrollRight={onScrollRight}
                    //onScrollTop={onScrollTop}
                    //onScrollBottom={onScrollBottom}
                    style={{ height: "1000px" }}
                  />
                   </div>
                </div>
              </div>
              </div>
            </section>

          </div>
        </div>
        <FullCalendarWidget2 slotid={this.state.slot_id} />
      </div>
    );
  }

  prevClick = schedulerData => {
    schedulerData.prev();
    schedulerData.setEvents(JSON.parse(localStorage.getItem("evt")));
    this.setState({
      viewModel: schedulerData
    });
  };

  nextClick = schedulerData => {
    schedulerData.next();
    schedulerData.setEvents(JSON.parse(localStorage.getItem("evt")));
    this.setState({
      viewModel: schedulerData
    });
  };

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    schedulerData.setEvents(JSON.parse(localStorage.getItem("evt")));
    this.setState({
      viewModel: schedulerData
    });
  };

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    schedulerData.setEvents(JSON.parse(localStorage.getItem("evt")));
    this.setState({
      viewModel: schedulerData
    });
  };

  eventClicked = (schedulerData, event) => {
    this.setState({
      slot_id: event.resourceId
    });
    window.$("#calendarpopup").modal('show');
  };

  ops1 = (schedulerData, event) => {

  };

  ops2 = (schedulerData, event) => {

  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    this.setState({
      slot_id: slotId
    });
    window.$("#calendarpopup").modal('show');
  };

  updateEventStart = (schedulerData, event, newStart) => {

  };

  updateEventEnd = (schedulerData, event, newEnd) => {

  };

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {

  };

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {

  };

  onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {

  };

  onScrollTop = (schedulerData, schedulerContent, maxScrollTop) => {
    console.log("onScrollTop");
  };

  onScrollBottom = (schedulerData, schedulerContent, maxScrollTop) => {
    console.log("onScrollBottom");
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData
    });
  };
}

export default withDragDropContext(FullCalendarWidget);
