import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import {UpdateInvoicedate_api } from '../../functions/invoices'
import { format } from 'date-fns'


const Invoicenoupdate = ({ticketid, activityid, loadInvoice, invoicedate1,invoiceduedate1,invoiceno1}) => {

    var userid = Number(localStorage.getItem("userid"));
    const [invoiceno, setInvoiceno] = useState(invoiceno1);


    const updateinvoicedate = () => {
        
        var req = {
            "ticketid": ticketid,
            "activityid": activityid,
            "invoicedate": format(new Date(invoicedate1), 'yyyy-MM-dd'),
            "invoiceduedate": format(new Date(invoiceduedate1),"yyyy-MM-dd"),
            "invoicenumber":invoiceno,
            "updatedby": userid
        }
        
        UpdateInvoicedate_api(req).then(res => {

            if (res != undefined) {
                toast.success(res.message);
                if(window.location.hash==="#/tickets")
                    loadInvoice(activityid)
                else
                    loadInvoice();
                window.$("#invoiceno").modal('hide');
            }
        })
            .catch();
    }

    return (
        <div className="modal fade fsm-popup" id="invoiceno">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <a type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <img src="images/close.png" /></a>
                        <h5 className="modal-title">Update Invoice No. </h5>
                    </div>
                    <div className="modal-body">
                        <div className="add-custmer-form ">
                            <div className="row">

                                <div className="col-md-12">
                                    <div className="form-group">
                                    <input type="text" style={{ width: "auto" }} className="form-control" value={invoiceno} onChange={(e) => setInvoiceno(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6 pull-right">
                                    <a type="submit" className="btn btn-theme pull-right" style={{ cursor: 'pointer' }} onClick={(e) => updateinvoicedate()}>Update </a>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                {/* <!-- /.modal-content --> */}
            </div>
            {/* <!-- /.modal-dialog --> */}
        </div>

    )
}

export default Invoicenoupdate
