
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'


const API_Base_Url = process.env.REACT_APP_API_BASE_URL;
const AccessToken = localStorage.getItem("AccessToken");
const loggeduserid = Number(localStorage.getItem("userid"));



export const GetProfileApi = async (mobilenumber, accesstoken) => {
    try {

        var data = [];
        const response = await fetch(API_Base_Url + "users/profile?phone=" + mobilenumber, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + accesstoken
            }
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

//Add User
export const CreateUser_api = async (reqdata) => {
    try {
        var clientid = localStorage.getItem("clientid")
        const response = await fetch(`${API_Base_Url}users/add`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "clientid":Number(clientid),
                "name": reqdata.name, 
                "usertype": reqdata.usertype,
                "email": reqdata.email,
                "phone": reqdata.phone,
                "profilepic":reqdata.profilepic,
                "password": reqdata.password,
                "allowapplogin" : reqdata.allowapplogin,
                "timezoneid": 8,
                "addedby": Number(localStorage.getItem("userid")),
                "colorcode":reqdata.colorcode
            })
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

//Update User
export const UpdateUser_api = async (reqdata) => {
    try {
        const response = await fetch(`${API_Base_Url}users/update`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "name": reqdata.name,
                "usertype": reqdata.usertype,
                "email": reqdata.email,
                "phone": reqdata.phone,
                "profilepic":reqdata.profilepic,
                "password": reqdata.password,
                "userid": reqdata.userid,
                "allowapplogin" : reqdata.allowapplogin,
                "timezoneid": 8,
                "updatedby": Number(localStorage.getItem("userid")),
                "colorcode":reqdata.colorcode
            })
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }

};

//Active Not Active status update
export const ChangeUserStatus_api = async (userid, statusid) => {
    try {
        const response = await fetch(`${API_Base_Url}users/status`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "userid": userid,
                "status": statusid,
                "updatedby": Number(localStorage.getItem("userid"))
            })
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }

};

//Delete user
export const DeleteUser_api = async (userid) => {
    try {
        const response = await fetch(`${API_Base_Url}users/delete`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "userid": userid,
                "deletedby": Number(localStorage.getItem("userid"))
            })
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }

};

//User List
export const LoadUsers_api = async (tab, searchtext) => {
    //  const { user } = useSelector(state => ({ ...state }))
    // var header = { 'Authorization': 'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJGU01TZXJ2aWNlQWNjZXNzVG9rZW4iLCJqdGkiOiIwMGJlMGRmNC03YTBhLTRlNjQtYTdhMS0wMWZkYTQ5ZWY4ZDYiLCJpYXQiOiI0LzgvMjAyMSA0OjE0OjUxIEFNIiwiSWQiOiIyIiwiTmFtZSI6IktyaXMgSGFycGVyIiwiUGhvbmUiOiI5OTk4ODg3NzcyIiwiVXNlclR5cGUiOiJTdXBlcnZpc29yIiwiRW1haWwiOiJrcmlzaGFycGVyQGdtYWlsLmNvbSIsImV4cCI6MTYxNzk0MTY5MSwiaXNzIjoiRlNNQXV0aGVudGljYXRpb25TZXJ2ZXIiLCJhdWQiOiJGU01TZXJ2aWNlQXBwQ2xpZW50In0.keoUNJmc1eU5VXWZDTNYyULYudfyhrY7BLilk_wlG7E' }
    // return await axios.get(`${API_Base_Url}users/list?type=${tab}&search=${searchtext}`, header);

    try {
        var data = [];
        var clientid = localStorage.getItem("clientid")
        const response = await fetch(`${API_Base_Url}users/list?clientid=${clientid}&type=${tab}&search=${searchtext}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }

}


export const LoadTechnicians_api = async () => {   
    try {
        var clientid = localStorage.getItem("clientid")
        var data = [];
        const response = await fetch(`${API_Base_Url}users/technicians?clientid=${clientid}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status == 401) {
            toast.error('Your Session has been expired, Please login again.');
            return window.setTimeout(function () {
                localStorage.clear();
                window.location.href = "/#/";
            }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if(result.errors != undefined)
            {
                toast.error(result.errors[0])
            }
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }

}