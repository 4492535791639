import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Header from '../../components/nav/Header'
import Sidebar from '../../components/nav/Sidebar'
import { Link } from 'react-router-dom'
import { LoadTechnicians_api } from '../../functions/users'
import { GetReport_AvgRevenuePerTech_api } from '../../functions/reports'
import moment from 'moment'
import ReactExport from "react-data-export";


function AverageRevenuePertech() {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    const [excelDataSet, setExcelDataSet] = useState([]);

    const [paginationarr, setPaginationarr] = useState([]);
    const [selectedPageNo, setSelectedPageNo] = useState(1);

    const [period, setPeriod] = useState("This Year");
    const [filterid, setFilterid] = useState(0);

    const [technicianList, setTechnicianlist] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const [List, setList] = useState([]);
    let recordshowperpage = 30;

    useEffect(() => {
        loadTechnicians();
        LoadDataList();
    }, [])


    const loadReport = () => {
        LoadDataList();
    }

    const LoadDataList = () => {
        setIsLoaded(false)
        var req = {
            "period": period,
            "id": Number(filterid),
            "fromdate": "",
            "todate": ""
        }
        GetReport_AvgRevenuePerTech_api(req).then(res => {
            if (res !== undefined) {
                if (res.length > 0) {
                    setList(res)

                }
                else {
                    setList([])
                }

                setIsLoaded(true)
            }
        })
    }

    const loadTechnicians = () => {
        LoadTechnicians_api().then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {
                    setTechnicianlist([])
                }
                else
                    setTechnicianlist(result.data);
            }

        }).catch();
    }


    const AccordianChange = (item) => {
        var accordionitem = item;
        window.$("#" + accordionitem).slideToggle().parent().siblings().find(".accordion-content").slideUp();
        window.$("#" + accordionitem).toggleClass("active-title");
        window.$("#" + "item_" + accordionitem).toggleClass("active-title");
        window.$("#" + accordionitem).parent().siblings().find(".accordion-title").removeClass("active-title");
        window.$("i.fa-chevron-down", "#" + item).toggleClass("chevron-top");
        window.$("#" + accordionitem).parent().siblings().find(".accordion-title i.fa-chevron-down").removeClass("chevron-top");
    }

    // const loadReport = () => {
    //     LoadDataList();
    // var _period = period;
    // var _fromdate = fromDate;
    // var _todate = toDate;
    // var _technicialID = (selectedTechnicianID == undefined || selectedTechnicianID == null) ? 0 : selectedTechnicianID;
    // if (period === "Custom") {
    //     if (_fromdate == "" || _fromdate == null) {
    //         return toast.error("Please select from date.")
    //     }
    //     else {
    //         _fromdate = moment(_fromdate).format("YYYY-MM-DD")
    //     }
    //     if (_todate == "" || _todate == null) {
    //         return toast.error("Please select to date.")
    //     }
    //     else {
    //         _todate = moment(_todate).format("YYYY-MM-DD")
    //     }
    // }
    // setIsLoaded(false);
    // var req = {
    //     "periodtype": _period,
    //     "fromdate": _fromdate,
    //     "todate": _todate,
    //     "techid": Number(_technicialID)
    // }
    // GetReport_AvgRevenuePerTech_api(req).then(res => {
    //     const result = res;
    //     if (result != undefined) {
    //         if (result == null) {
    //             setList([])
    //         }
    //         else {
    //             setList(result)
    //             let data = result.data;
    //             let dataset = [];
    //             if (data.length > 0) {
    //                 for (var i = 0; i < data.length; i++) {
    //                     if (data[i].invoices != undefined && data[i].invoices != null) {
    //                         for (var j = 0; j < data[i].invoices.length; j++) {
    //                             dataset.push(
    //                                 {
    //                                     TechnicianID: data[i].technicianID,
    //                                     Technician: data[i].technicianname,
    //                                     Revenue: data[i].revenue,
    //                                     Customer: data[i].invoices[j].customername,
    //                                     ponumber: data[i].invoices[j].ponumber,
    //                                     amount: data[i].invoices[j].invoiceamt
    //                                 }
    //                             )
    //                         }
    //                     }
    //                 }
    //             }
    //             {
    //                 dataset.push({
    //                     TechnicianID: 0,
    //                     Technician: "",
    //                     Revenue: "",
    //                     Customer: "",
    //                     ponumber: "",
    //                     amount: 0
    //                 })
    //             }
    //             setExcelDataSet(dataset);
    //             console.log("Dataset", dataset)
    //             //var data = result.data;
    //             if (data.length > recordshowperpage) {
    //                 var totalpage = data.length / recordshowperpage;
    //                 var reminder = data.length % recordshowperpage;
    //                 var pages = [];
    //                 for (var i = 1; i <= totalpage; i++) {
    //                     pages.push(i);
    //                 }
    //                 if (reminder == 0) {
    //                     //pages = pages;
    //                 }
    //                 else {
    //                     pages.push(pages.length + 1)
    //                 }
    //                 setPaginationarr(pages);
    //             }
    //         }
    //     }
    //     else {
    //         setPaginationarr([]);
    //     }

    //     setIsLoaded(true);
    // }).catch();
    //}

    return (
        <div>
            <div className="hold-transition skin-black sidebar-mini">
                <div className="wrapper">
                    <Header />
                    <Sidebar />
                    <div className="content-wrapper db-background">

                        <div className="">
                            <div style={{ paddingTop: 30 }}>
                                <div className="filterbox">
                                    <div className="boxmargin">
                                        <div className="box box-solid box-height">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3">
                                                    <div className="form-group row">
                                                        <label for="colFormLabel" className="col-lg-3 col-sm-3 col-form-label">Period</label>
                                                        <div className="col-lg-9 col-sm-9">
                                                            <select className="form-control" value={period} onChange={(e) => setPeriod(e.target.value)}>
                                                                <option value="This Year">This Year</option>
                                                                <option value="Previous Year">Previous Year</option>
                                                                <option value="This Month">This Month</option>
                                                                <option value="Previous Month">Previous Month</option>
                                                                <option value="This Week">This Week</option>
                                                                <option value="Previous Week">Previous Week</option>

                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-3">
                                                    <div className="form-group row">
                                                        <label for="colFormLabel" className="col-lg-5 col-sm-5 col-form-label">Technician</label>
                                                        <div className="col-lg-7 col-sm-7">
                                                            <select className="form-control" value={filterid} onChange={(e) => setFilterid(e.target.value)}>
                                                                <option value="0">All</option>
                                                                {
                                                                    technicianList.map(item => (
                                                                        <option value={item.userId}>{item.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="fliterbtn">
                                                        <div className="inlinebtn">
                                                            <button className="btn btn-theme" onClick={() => loadReport()}>View</button>
                                                        </div>
                                                        <div className="inlinebtn">
                                                            <ExcelFile filename="Revenue by Technician" element={<button className="btn btn-theme">Export to Excel</button>} >
                                                                <ExcelSheet data={excelDataSet} name="customer revenue" >
                                                                    <ExcelColumn label="Technician" value="Technician" />
                                                                    <ExcelColumn label="Customer" value="Customer" />
                                                                    <ExcelColumn label="PO Number" value="ponumber" />
                                                                    <ExcelColumn label="Amount" value="amount" />
                                                                </ExcelSheet>
                                                            </ExcelFile>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='chartcontainer '>
                            <div className=''><h4 className="box-title">Average Revenue Per Tech</h4></div>
                        </div>
                        <div className="chartcontainer row">
                            <div className="col-md-6">
                                <h5 className="name">Technician</h5>
                            </div>
                            <div className="col-md-3">
                                <h5>Average Revenue</h5>
                            </div>
                            <div className="col-md-3">
                                <h5 className="amount">Total Revenue</h5>
                            </div>
                        </div>

                        <div className="rptcontainer">

                            {
                                isLoaded == false ? <div className="loader"><img src="images/loader.gif" alt="" /></div>
                                    :
                                    <div className="accordions">
                                        {
                                            List != undefined &&
                                            <>
                                                {
                                                    List.length == 0
                                                        ?
                                                        "No Record Found."
                                                        :
                                                        List.slice(selectedPageNo * recordshowperpage - recordshowperpage, selectedPageNo * recordshowperpage).map((item, index) => (
                                                            <>

                                                                <div className="accordion-item ">
                                                                    <div className="accordion-title" data-tab={`item-${index}`} onClick={() => AccordianChange(`item-${index}`)}>
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <p className="name">{item.name}</p>
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <p>{item.avgRevenue!==0?"$" +item.avgRevenue:"0"}</p>
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <div className="amount">{item.totalRevenue}</div>
                                                                                <i className="fa fa-angle-down"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="accordion-content" id={`item-${index}`}>
                                                                        <div className="customertbl">
                                                                            <table className="table table-striped">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Ticket No.</th>
                                                                                        <th>Customer</th>
                                                                                        <th >PO Number</th>
                                                                                        <th >Ticket Date</th>
                                                                                        <th >Amount</th>
                                                                                    </tr>
                                                                                </thead>

                                                                                <tbody>

                                                                                    {

                                                                                        item.details != undefined &&

                                                                                            item.details.length == 0
                                                                                            ?
                                                                                            "No Record Found."
                                                                                            :
                                                                                            item.details != null &&
                                                                                            item.details.map(inneritem => (
                                                                                                <tr>
                                                                                                    <td >{inneritem.ticketno}</td>
                                                                                                    <td>{inneritem.customername}</td>
                                                                                                    <td className=" rpt1">{inneritem.ponumber}</td>
                                                                                                    <td className=" rpt1">{inneritem.ticketdate}</td>
                                                                                                    <td className=" rpt1">{inneritem.revenue!==0?"$"+ inneritem.revenue:"0"}</td>
                                                                                                </tr>
                                                                                            ))
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))
                                                }
                                            </>
                                        }
                                    </div>
                            }
                            <ul className="pagination pull-right mt0">
                                {
                                    paginationarr.length > 0 &&
                                    paginationarr.map(item => (
                                        <li class={`paginate_button ${item == selectedPageNo && "active"}`}><a style={{ cursor: 'pointer' }} onClick={() => setSelectedPageNo(item)}>{item}</a></li>
                                    ))
                                }

                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AverageRevenuePertech
