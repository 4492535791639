import { Link } from "react-router-dom";

/*
Date:-11-01-22
CodeBy:-Mayur
Description:- Analitics Card Components
*/
const AnalyticsCard = ({ shareIcon, title, subTitle, url }) => {
    return (
        <>
            <li className="box box-solid card1">
                <Link to={url} className="" style={{cursor:url==="#"?"default":"pointer"}}>
                    {shareIcon === true &&
                        <div className="share-icon go-corner">
                            <div className="go-arrow">
                                <i className="fa fa-share"></i>
                            </div>
                        </div>
                    }
                    <div className="ovr-card">
                        <h4>{title}</h4>
                        <p>{subTitle}</p>
                    </div>
                </Link>
            </li>
        </>
    )
}
export default AnalyticsCard;
