import React, { useEffect, useState } from 'react'
import { LoadNotificationList_api } from '../../functions/notifications'
import { GetUnreadNotificationApi } from '../../functions/dashboard'

const Notifications = () => {

    const [notificationList, setNotificationList] = useState([]);
    const userid = localStorage.getItem("userid");
    const [refresh, setRefresh] = useState(0)
    const [notcount, setNotcount] = useState(0)

    useEffect(() => {
        LoadNotficationList();
        loadNotificationCount();
    }, [])
  
    var intervaltime;
    useEffect(() => {
        intervaltime = setInterval(refreshNotificationCount, 5000)
    }, [refresh])

    const refreshNotificationCount = () => {
        clearInterval(intervaltime)
        setRefresh(refresh + 1)
        loadNotificationCount();
    }

    const loadNotificationCount = () => {
        GetUnreadNotificationApi().then(res => {           
            const result = res;
            if (result != undefined) {
                setNotcount(result.message);
                localStorage.setItem("notificationcount", result.message);                
            }
        }).catch();
    }


    const LoadNotficationList = () => {
        LoadNotificationList_api(userid).then(res => {
            const result = res;            
            if (result != undefined) {
                if (result.data == null) {
                    setNotificationList([])
                }
                else
                    setNotificationList(result.data);
            }
            console.log(result)
        }).catch();
    }   


    return (
        <div className="modal right fade Notification" id="Notification" >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img src="images/close.png" /></button>
                        <h4 className="modal-title" id="myModalLabel2">Notification</h4>
                    </div>

                    <div className="modal-body">
                        <div className="notilist">
                            {
                                notificationList.length == 0 ?
                                    <div className="NotificationsEmpty text-center">
                                        <img src="images/alarm-1.png" width="50 " />
                                        <h4 className="mt30">All caught up!</h4>
                                    </div>
                                    :
                                    notificationList.map(item => (

                                        <div className="notiuserlist">

                                            <div className="notidtl">
                                                <p>{item.notification}</p>
                                                <div className="notidate"> {item.timestampvalue}</div>
                                            </div>
                                        </div>
                                    ))
                            }</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notifications
