import React, { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import { validateEmail } from '../functions/commonfunction'
import { CreateCustomer_api, UpdateCustomer_api } from '../functions/customers'

const CustomerAddUpdateForm = ({ screentype, loadCustomers, customerinfo, SelectNewAddedCustomer, testId }) => {
        
    const [customerName, setCustomerName] = useState("");
    const [customerType, setCustomerType] = useState("");
    const [streetAddress, setStreetAddress] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [marginpercentage, setmarginpercentage] = useState(0);
    //for api provide by google places
    const [location, setLocation] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");

    const [contactName, setContactName] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [email, setEmail] = useState("");
    const [acontactName, setAContactName] = useState("");
    const [acontactNumber, setAContactNumber] = useState("");
    const [aemail, setAEmail] = useState("");
    const [customerId, setCustomerId] = useState(0);
    const [discountPercentage, setDiscountPercentage] = useState();



    function initMap_customer() {
        var input = document.getElementById('searchMapInputCustomer');

        setStreetAddress(input.value)

        var autocomplete = new window.google.maps.places.Autocomplete(input);

        autocomplete.addListener('place_changed', function () {
            var place = autocomplete.getPlace();
            let div = document.createElement('div');
            div.innerHTML = place.adr_address;
            let cityname = div.getElementsByClassName('locality')[0].innerHTML;
            let statename = div.getElementsByClassName('region')[0].innerHTML;
            let zipCode=""
            if(div.getElementsByClassName('postal-code').length>0)
                zipCode=div.getElementsByClassName('postal-code')[0].innerHTML;
            let lat = place.geometry.location.lat();
            let long = place.geometry.location.lng();

            console.log(place)
            setState(statename)
            setCity(cityname)
            setZipCode(zipCode)
            setLatitude(String(lat))
            setLongitude(String(long))
            setStreetAddress(place.formatted_address)

            //   document.getElementById('location-snap').innerHTML = place.formatted_address;
            //   document.getElementById('lat-span').innerHTML = place.geometry.location.lat();
            //   document.getElementById('lon-span').innerHTML = place.geometry.location.lng();
        });
    }




    useEffect(() => {

        if (screentype != "Create") {
            //loadCustomerDetails(customerinfo)
            loadCustomerDetails(customerinfo)
        }


    }, [customerinfo])
    useEffect(() => {
        if (screentype == "Create") {
            setCustomerName("");
            setCustomerType("");
            setStreetAddress("");
            setState("");
            setCity("");
            setZipCode("");
            setContactName("");
            setContactNumber("");
            setEmail("");
            setAContactName("");
            setAContactNumber("");
            setAEmail("");
            setCustomerId("");
        }
    }, [screentype]);

    useEffect(() => {
        if (screentype == "Create") {
            setCustomerName("");
            setCustomerType("");
            setStreetAddress("");
            setState("");
            setCity("");
            setZipCode("");
            setContactName("");
            setContactNumber("");
            setEmail("");
            setAContactName("");
            setAContactNumber("");
            setAEmail("");
            setCustomerId("");
            window.$("#searchMapInput").val('')
        }
    }, [testId]);




    const CreateCustomer_submit = (e) => {
        e.preventDefault();
        if (customerName == "" && customerType == "" && email == "" && streetAddress == "")
            return toast.error("Please fill all fields.");
        else if (customerName.trim() == "")
            return toast.error("please enter customer name.");
        else if (customerType.trim() == "" || customerType == null)
            return toast.error("Please select customer type.");
        else if (streetAddress.trim() == "")
            return toast.error("Please enter address.");
        else if (state.trim() == "" || state == null)
            return toast.error("Please enter state.");
        else if (city.trim() == "" || city == null)
            return toast.error("Please enter city.");
        // else if (zipCode.trim() == "")
        //     return toast.error("Please enter zip code.");
        else if (acontactName.trim() == "")
            return toast.error("Please enter contact name.");
        else if (aemail != "" && validateEmail(aemail.trim()) == false)
            return toast.error("Please enter valid email id.");
        else if (acontactNumber.trim() === "")
            return toast.error("Please enter phone number");

        var req = {
            "customername": customerName,
            "customertype": customerType,
            "address": streetAddress,
            "state": state,
            "city": city,
            "location": location,
            "zipcode": zipCode,
            "latitude": latitude,
            "longitude": longitude,
            "fieldcontactname": contactName,
            "fieldcontactemail": email,
            "fieldcontactphone": contactNumber,
            "accountingcontactname": acontactName,
            "accountingcontactemail": aemail,
            "accountingcontactphone": acontactNumber,
            "marginpercentage": marginpercentage,
            "discountpercentage": Number(discountPercentage)
        }
        CreateCustomer_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                window.$("#CustomerFormPop").modal('hide');
                loadCustomers();
                SelectNewAddedCustomer(res.customerid)
            }
        })
            .catch();

    }

    const UpdateCustomer_submit = (e) => {
        e.preventDefault();
        if (customerName == "" && customerType == "" && email == "" && streetAddress == "")
            return toast.error("Please fill all fields.");
        else if (customerName.trim() == "")
            return toast.error("please enter customer name.");
        else if (customerType.trim() == "" || customerType == null)
            return toast.error("Please select customer type.");
        else if (streetAddress.trim() == "")
            return toast.error("Please enter address.");
        else if (state.trim() == "" || state == null)
            return toast.error("Please enter state.");
        else if (city.trim() == "" || city == null)
            return toast.error("Please enter city.");
        // else if (zipCode.trim() == "")
        //     return toast.error("Please enter zip code.");
        else if (acontactName.trim() == "")
            return toast.error("Please enter contact name.");
        else if (aemail != "" && validateEmail(aemail.trim()) == false)
            return toast.error("Please enter valid email id.");
        else if (acontactNumber.trim() === "")
            return toast.error("Please enter phone number");

        var req = {
            "customerid": customerId,
            "customername": customerName,
            "customertype": customerType,
            "address": streetAddress,
            "state": state,
            "city": city,
            "location": location,
            "zipcode": zipCode,
            "latitude": latitude,
            "longitude": longitude,
            "fieldcontactname": contactName,
            "fieldcontactemail": email,
            "fieldcontactphone": contactNumber,
            "accountingcontactname": acontactName,
            "accountingcontactemail": aemail,
            "accountingcontactphone": acontactNumber,
            "marginpercentage": marginpercentage,
            "discountpercentage": Number(discountPercentage)
        }
        UpdateCustomer_api(req).then(res => {
            if (res != undefined) {
                toast.success(res.message);
                window.$("#CustomerFormPop").modal('hide');
                loadCustomers();
            }
        })
            .catch();

    }

    const loadCustomerDetails = (item) => {
        setCustomerName(item.customername);
        setCustomerType(item.customertype);
        setStreetAddress(item.address);
        setState(item.state);
        setCity(item.city);
        setLocation(item.location);
        setZipCode(item.zipcode);
        setLatitude(item.latitude);
        setLongitude(item.longitude);
        setContactName(item.fieldcontactname);
        setContactNumber(item.fieldcontactphone);
        setEmail(item.fieldcontactemail);
        setAContactName(item.accountingcontactname);
        setAContactNumber(item.accountingcontactphone);
        setAEmail(item.accountingcontactemail);
        setmarginpercentage(item.marginpercentage)
        setCustomerId(item.customerid);
        setDiscountPercentage(item.discountpercentage)
        //setScreenType("Update");
        window.$("#CustomerFormPop").modal('show');
    }

    return <div className="modal fade fsm-popup" id="CustomerFormPop">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <a type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <img src="images/close.png" /></a>
                    <h5 className="modal-title">Add New Customer</h5>
                </div>

                <div className="modal-body">
                    <div className="add-custmer-form ">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="">
                                        <input type="text" className="form-control" placeholder="Customer Name" maxLength="100" value={customerName} onChange={(e) => setCustomerName(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <select className="form-control" value={customerType} onChange={(e) => setCustomerType(e.target.value)}>
                                        <option value="">Type</option>
                                        <option value="Preferred">Preferred</option>
                                        <option value="Regular">Regular</option>

                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12" hidden={customerType == "Preferred" ? false : true}>
                                <div className="form-group">
                                    <div className="">
                                        <input type="text" className="form-control" placeholder="Discount Percentage" maxLength="20" value={discountPercentage} onChange={(e) => setDiscountPercentage(isNaN(e.target.value) == false ? e.target.value : discountPercentage)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="">
                                        <input type="text" autoComplete="nope" className="form-control" id="searchMapInputCustomer" value={streetAddress} placeholder="Billing Address" maxLength="50" onChange={(e) => initMap_customer()} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="">
                                        <input type="text" autoComplete="nope" className="form-control" placeholder="State" maxLength="20" value={state} onChange={(e) => setState(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <input type="text" autoComplete="nope" className="form-control" placeholder="City" maxLength="20" value={city} onChange={(e) => setCity(e.target.value)} />
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Zip Code" maxLength="6" value={zipCode} onChange={(e) => setZipCode(e.target.value.replace(/\D/, ''))} />
                                </div>
                            </div>
                            {/* <div className="col-md-12">
                                <div className="form-group">
                                    <label for="email">Field Contact</label>
                                    <input type="text" className="form-control" placeholder="Contact Name" maxLength="20" value={contactName} onChange={e => setContactName(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-md-7 pright0">
                                <div className="form-group">
                                    <input type="email" className="form-control" placeholder="Email" maxLength="50" value={email} onChange={e => setEmail(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Contact Phone" maxLength="20" value={contactNumber} onChange={e => setContactNumber(e.target.value.replace(/\D/, ''))} />
                                </div>
                            </div> */}

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label for="email">Billing Contact</label>
                                    <input type="text" className="form-control" placeholder="Contact Name" maxLength="20" value={acontactName} onChange={e => setAContactName(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-md-7 pright0">
                                <div className="form-group">
                                    <input type="email" className="form-control" placeholder="Email" maxLength="50" value={aemail} onChange={e => setAEmail(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Contact Phone" maxLength="20" value={acontactNumber} onChange={e => setAContactNumber(e.target.value.replace(/\D/, ''))} />
                                </div>
                            </div>
                            {
                                screentype == "Create"
                                    ?
                                    <div className="col-md-10">
                                        <a type="submit" onClick={CreateCustomer_submit} className="btn btn-theme pull-right" style={{ cursor: 'pointer' }}>SAVE </a>
                                    </div>
                                    :
                                    //Update
                                    <div className="col-md-10">
                                        <a type="submit" onClick={UpdateCustomer_submit} className="btn btn-theme pull-right" style={{ cursor: 'pointer' }}>Update </a>
                                    </div>
                            }
                            <div className="col-md-2">
                                    <a type="button" className="btn btn-danger pull-right" data-dismiss="modal" aria-label="Close">Cancel</a>
                                </div>
                            {/* <h1>Autocomplete Address Search Box using Google Maps Javascript API</h1> */}

                            {/* <input  className="mapControls"  onChange={initMap_nn} type="text" placeholder="Enter a location" />
                                <ul id="geoData">
                                    <li>Full Address: <span id="location-snap"></span></li>
                                    <li>Latitude: <span id="lat-span"></span></li>
                                    <li>Longitude: <span id="lon-span"></span></li>
                                </ul> */}
                        </div>
                    </div>

                </div>

            </div>
            {/* <!-- /.modal-content --> */}
        </div>
        {/* <!-- /.modal-dialog --> */}
    </div>

}
export default CustomerAddUpdateForm;



