import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Header from '../../components/nav/Header'
import Sidebar from '../../components/nav/Sidebar'
import TicketDetails from '../../components/popups/TicketDetails'
import TicketFilters from '../../components/popups/TicketFilters'
import { filterslist_api, deletedticketList_api, RestoreTicket_api } from '../../functions/tickets'
import { useHistory } from 'react-router-dom'
import Moment from 'moment';
import { LoadCustomers_api } from '../../functions/customers'
import CustomerAddUpdateForm from '../../forms/CustomerAddUpdateForm'
import CreateTicket from '../../forms/CreateTicket'

import localeCompare from "locale-compare";

function DeletedTickets(props) {

    const history = useHistory();
    var urlstatus = props.match.params.status;
    var date_lo = new Date();
    var formateddate = Moment(date_lo).format("yyyy-MM-DD");
    const usertype = localStorage.getItem("usertype");
    const [statuslist, setstatuslist] = useState([]);
    const [assigneelist, setassigneelist] = useState([]);
    const [fromdate, setFromdate] = useState("");
    const [todate, setTodate] = useState("");
    const [status, setStatus] = useState(urlstatus !== undefined ? urlstatus : "all");
    const [statusid, setStatusid] = useState(urlstatus);
    const [assigntoId, setAssigntoId] = useState(0);
    const [ticketList, setTicketList] = useState([]);
    const [selectedTicketId, setSelectedTicketId] = useState(0);
    var userid = Number(localStorage.getItem("userid"));
    const [paginationarr, setPaginationarr] = useState([]);
    const [selectedPageNo, setSelectedPageNo] = useState(1);
    const [totaltickets, setTotalTickets] = useState("");
    const [txtsearch, setTxtsearch] = useState("");
    const [ticketid, setTicketId] = useState(0);
    const [isapplyFilter_status, setIsApplyfilter_status] = useState(urlstatus != undefined ? true : false);
    const [isapplyFilter_assignto, setIsApplyfilter_assignto] = useState(false);
    const [isapplyFilter_date, setIsApplyfilter_date] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [keypointRefresh, setKeyPointsRefresh] = useState(0);
    const [keyStatus, setKeyStatus] = useState("all");
    const [selectedCustomerID, setSelectedCustomerID] = useState("");
    const [screentype, setScreenType] = useState("Create");
    const [customerinfo, setCustomerinfo] = useState({});
    const [testId, setTestId] = useState(0);
    const [txtcustomername, setCustomername] = useState("");
    const [customerList, setCustomerList] = useState([]);
    const [selectedCustomerName, setSelectedCustomerName] = useState("");


    const [sortingDirection, setSortingDirection] = useState("asc")

    let recordshowperpage = 10;

    useEffect(() => {
        handleSearch();
        window.$("#searchresultarea").hide();
        window.$("#msgdiv").hide();
    }, []);


    useEffect(() => {
        handleSearch();
    }, [txtsearch]);



    const handleSearch = () => {
        setSelectedPageNo(1)
        setPaginationarr([])
        setIsLoaded(false);
        var req = {
            "userid": userid,
            "fromdate": fromdate,
            "todate": todate,
            "status": "all",
            "searchkeyword": txtsearch,
            "assigntouserid": assigntoId
        }
        deletedticketList_api(req).then(res => {
            setIsLoaded(true)
            if (res != undefined) {
                //toast.success(res.message);
                window.$("#Filters").modal('hide');
                if (fromdate != "" && todate != "")
                    setIsApplyfilter_date(true)
                if (status != "")
                    setIsApplyfilter_status(true)
                if (assigntoId != 0)
                    setIsApplyfilter_assignto(true)

                setTotalTickets(res.result);
                var ticklist = res.tickets;
                if (ticklist != undefined) {
                    setTicketList(res.tickets);
                    console.log("ticketList", res.tickets)
                    if (ticklist.length > recordshowperpage) {
                        var totalpage = ticklist.length / recordshowperpage;
                        var reminder = ticklist.length % recordshowperpage;
                        var pages = [];
                        for (var i = 1; i <= totalpage; i++) {
                            pages.push(i);
                        }
                        if (reminder == 0) {
                            //pages = pages;
                        }
                        else {
                            pages.push(pages.length + 1)
                        }
                        setPaginationarr(pages);
                        //setPaginationarr(reminder == 0 ? totalpage  : totalpage + (totalpage + 1))
                    }
                    else {
                        setPaginationarr([]);
                        //setTicketList([]);
                    }
                }
                else {
                    setPaginationarr([]);
                    setTicketList([]);
                }

            }

        })
            .catch(error => setIsLoaded(true));

    }

    const loadCustomers = (txtsearch) => {
        setIsLoaded(false);
        LoadCustomers_api("All", txtsearch).then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {
                    setCustomerList([])
                }
                else {
                    setCustomerList(result.data);
                }
            }
            setIsLoaded(true);
        }).catch();
    }

    const SelectNewAddedCustomer = (customerid) => {
        LoadCustomers_api("All", txtcustomername).then(res => {
            const result = res;
            if (result != undefined) {
                if (result.data == null) {

                }
                else {
                    var cust = result.data.filter(x => x.customerid == customerid)[0];
                    ChooseCustomer(cust);
                }
            }
            setIsLoaded(true);
        }).catch();
    }

    const ChooseCustomer = (item) => {
        setSelectedCustomerName(item.customername)
        setSelectedCustomerID(item.customerid)
        window.$("#searchresultarea").hide();
        setCustomername(item.customername)
    }

    const showCustomerCreationPopUp = () => {
        setCustomername("");
        setTestId(testId + 1)
        window.$("#CustomerFormPop").modal('show');
        window.$("#searchresultarea").hide();
    }

    const refreshDashboard = () => {
        setKeyPointsRefresh(keypointRefresh + 1);
        handleSearch()
        setCustomername("");
        setSelectedCustomerID(0);
        window.$("#msgdiv").show();
    }

    const SearchCustomer = (e) => {
        setCustomername(e.target.value);
        if (e.target.value.length < 2) {
            window.$("#searchresultarea").hide();

        }
        else {
            window.$("#searchresultarea").show();
            loadCustomers(e.target.value);
        }

    }

    const showCreateTicketPopUp = () => {
        if (selectedCustomerID == 0)
            return toast.error('Please select customer')

        setTestId(testId + 1)
        window.$("#CreateTicket").modal('show');

    }



    var tickid = 0;
    const OpenTicketDetails = (ticketid) => {
        setSelectedTicketId(ticketid);
        tickid = ticketid;
        localStorage.setItem("ticketid", ticketid)
        window.$("#TicketDetails").modal('show');
    }

    const compareBy = (key, direction) => {
        if (direction === "asc") {
            setSortingDirection("desc")
            return function (a, b) {
                if (a[key] < b[key]) return -1;
                if (a[key] > b[key]) return 1;
                return 0;
            };
        }
        else if (direction === "desc") {
            setSortingDirection("asc")
            return function (a, b) {
                if (a[key] < b[key]) return 1;
                if (a[key] > b[key]) return -1;
                return 0;
            };
        }
    }

    const sortBy = (key, direction) => {
        let array = [...ticketList];
        array.sort(compareBy(key, direction));
        setTicketList(array);
    }


    const RestoreTicket = (id) => {
        RestoreTicket_api(id).then(res => {
            if (res != undefined) {
                handleSearch();
                return toast.success(res.message);               
            }
        }).catch();
    }

    return (
        <div className="hold-transition skin-black sidebar-mini">
            <div className="wrapper">
                <Header />
                <Sidebar />
                {
                    // selectedTicketId != 0
                    // &&
                    <TicketDetails
                        ticketid={selectedTicketId}
                        loadAgain={handleSearch}
                        ticketType={"deleted"}
                    />
                }

                {
                    usertype === "Dispatcher" &&
                    <>
                        <CreateTicket
                            customerid={selectedCustomerID}
                            refreshDashboard={refreshDashboard}
                            testId={testId}
                        />
                        <CustomerAddUpdateForm
                            screentype={screentype}
                            loadCustomers={loadCustomers}
                            customerinfo={customerinfo}
                            SelectNewAddedCustomer={SelectNewAddedCustomer}
                            testId={testId}
                        />
                    </>
                }


                <TicketFilters
                    status={status}
                    setStatus={setStatus}
                    assigneelist={assigneelist}
                    fromdate={fromdate}
                    setFromdate={setFromdate}
                    todate={todate}
                    setTodate={setTodate}
                    statusid={statusid}
                    setStatusid={setStatusid}
                    assigntoId={Number(assigntoId)}
                    setAssigntoId={setAssigntoId}
                    handleSearch={handleSearch}
                />

                <div className="content-wrapper">
                    {/* <!-- Main content --> */}
                    <section className="content content-body">
                        {/* <!-- Main row --> */}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="content-header">
                                    <h1 className="xs-tc-title">
                                        Deleted Tickets
                                    </h1>
                                    <div className="topbtn-right">
                                        <div className="pull-left serch-fild top-serching mr-15">
                                            <input type="text" name="table_search" className="form-control ih36" placeholder="" value={txtsearch} onChange={(e) => setTxtsearch(e.target.value)} />
                                            <i className="fa fa-search"></i>
                                        </div>
                                        <a type="" className="btn btn-theme filterbtn" data-toggle="modal" data-target="#Filters"><img src="images/funnel.svg" /></a>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Left col 8--> */}
                            <div className="col-md-12">
                                <div className="box box-solid">
                                    <div className="ticket-msg">{totaltickets}  &nbsp;
                                    </div>
                                </div>
                                <div className="box box-solid">
                                    {/* <!-- /.box-header --> */}
                                    {
                                        isLoaded == false ? <div className="loader"><img src="images/loader.gif" alt="" /></div>
                                            :
                                            <div className="box-body table-responsive ">
                                                <table className="table table-hover ticket-list">
                                                    <tbody>
                                                        <tr>
                                                            <th onClick={(e) => sortBy('ticketid', sortingDirection)}>Ticket ID</th>
                                                            <th onClick={(e) => sortBy('technicianname', sortingDirection)}>Description</th>
                                                            <th onClick={(e) => sortBy('customername', sortingDirection)}>Customer</th>
                                                            <th onClick={(e) => sortBy('jobname', sortingDirection)}>Job Name</th>
                                                            <th onClick={(e) => sortBy('jobcontactname', sortingDirection)}>Job Address</th>
                                                            <th onClick={(e) => sortBy('technicianname', sortingDirection)}>Assigned To</th>
                                                            <th onClick={(e) => sortBy('scheduledate', sortingDirection)}>Schedule On</th>
                                                            <th onClick={(e) => sortBy('priority', sortingDirection)}>Priority</th>
                                                            <th>Status</th>
                                                            <th></th>
                                                        </tr>
                                                        {
                                                            ticketList.length == 0
                                                                ?
                                                                "No Record Found"
                                                                :
                                                                ticketList.slice(selectedPageNo * recordshowperpage - recordshowperpage, selectedPageNo * recordshowperpage).map(item => (
                                                                    <tr>
                                                                        <td><a className="ticketID" data-toggle="modal" onClick={() => usertype !== "Dispatcher" ? OpenTicketDetails(item.ticketid) : ''} style={usertype !== "Dispatcher" ? { cursor: 'pointer' } : { cursor: "default" }}>{item.ticketid}</a></td>
                                                                        <td>{item.ordertype}</td>
                                                                        <td>{item.customername}</td>
                                                                        <td>{item.jobname}</td>
                                                                        <td>{item.address}</td>
                                                                        {
                                                                            (item.technicianname == "" || item.technicianname == null)
                                                                                ?
                                                                                <td>Not Assigned</td>
                                                                                :
                                                                                <td>
                                                                                    {/* {
                                                                                        item.profilepic != null && item.profilepic != "NA"
                                                                                        &&
                                                                                        <img src={item.profilepic} className="ass-circle" />
                                                                                    } */}

                                                                                    <span>{item.technicianname}</span></td>
                                                                        }

                                                                        <td className="">{item.scheduledate}<br />{item.scheduletime} </td>
                                                                        <td className=""> {item.priority}</td>

                                                                        <td className="" style={{ textAlign: "center" }}><span className="stsbtn sts-active " >{item.status}</span><br /> <p style={{ fontSize: "12px", color: "#666" }}>{item.statusactivity}</p>  </td>

                                                                        {/* added by Sonali 31-03-22 feedback */}
                                                                        {
                                                                            keyStatus !== "Draft Invoice" && keyStatus !== "Draft Estimate" && keyStatus !== "Submitted Estimate" ?
                                                                                <td className="" style={{ textAlign: "center" }}>
                                                                                    <a className='stsbtn sts-inactive' style={{ cursor: 'pointer' }} onClick={e => RestoreTicket(item.ticketid)}>Restore</a> </td>
                                                                                : ''
                                                                        }
                                                                    </tr>
                                                                ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                    }
                                    {/* <!-- /.box-body --> */}
                                </div>

                                <ul className="pagination pull-right mt0">
                                    {
                                        paginationarr.length > 0 &&
                                        paginationarr.map(item => (
                                            <li class={`paginate_button ${item == selectedPageNo && "active"}`}><a style={{ cursor: 'pointer' }} onClick={() => setSelectedPageNo(item)}>{item}</a></li>
                                        ))
                                    }

                                </ul>
                            </div>
                        </div>
                        {/* <!-- /.row (main row) --> */}
                    </section>
                    {/* <!-- /.content --> */}

                </div>
                {/* <!-- /.content-wrapper --> */}

            </div>

        </div>



    )
}

export default DeletedTickets
